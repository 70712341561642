import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

import hoc from '../../../HOC/hoc';
import LeftSideAdvantages from '../components/LeftSideAdvantages';
import FormField from '../../../components/Form/FormField';
import { StyledButton } from '../../../components/Button/Button';

import './styles.scss';

class NewPassword extends Component {
	state = {
		loading: false,
		formdata: {
			password: {
				element: 'input',
				value: '',
				config: {
					name: 'password_input',
					type: 'password',
					label: 'Новий пароль'
				},
				validation: {
					required: true,
					password: true
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
			confirmPassword: {
				element: 'input',
				value: '',
				config: {
					name: 'confirmPassword_input',
					type: 'password',
					label: 'Повторіть пароль'
				},
				validation: {
					required: true,
					password: true
				},
				valid: false,
				touched: false,
				validationMessage: ''
			}
		}
	};

	handleChange = element => {
		const target = element.event.target;
		const newFormData = { ...this.state.formdata };
		const newElement = { ...newFormData[element.id] };
		const value = newElement.config.type === 'checkbox' ? target.checked : target.value;
		newElement.value = value;

		if (element.blur) {
			let validData = this.validate(newElement);
			newElement.valid = validData[0];
			newElement.validationMessage = validData[1];
		}

		newElement.touched = element.blur;
		newFormData[element.id] = newElement;

		this.setState({
			formdata: newFormData
		});
	};

	validate = element => {
		let error = [true, ''];

		if (element.validation.email) {
			const valid = /\S+@\S+\.\S+/.test(element.value);
			const message = `${!valid ? 'Не дійсний адреса електронної пошти' : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if (element.validation.password) {
			const valid = element.value.length > 9;
			const message = `${!valid ? 'Пароль повинен бути більше 9 символів' : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if (element.validation.required) {
			const valid = element.value.trim() !== '';
			const message = `${!valid ? "Це поле обов'язкове" : ''}`;
			error = !valid ? [valid, message] : error;
		}
		return error;
	};

	handleSubmit = event => {
		event.preventDefault();
	};

	render() {
		return (
			<Grid container alignItems="center" className="container new-password-container">
				<Grid container direction="column" justify="center" alignItems="center">
					<form className="form form__login" onSubmit={this.handleSubmit}>
						<h1 className="h1">Введіть новий пароль</h1>
						<Grid container direction="column" justify="center">
							<FormField
								formdata={this.state.formdata.password}
								id="password"
								change={this.handleChange}
							/>
							<FormField
								formdata={this.state.formdata.confirmPassword}
								id="confirmPassword"
								change={this.handleChange}
							/>
							<StyledButton className="new-password-btn" variant="contained" type="submit">
								Відправити
							</StyledButton>
						</Grid>
					</form>
				</Grid>
			</Grid>
		);
	}
}

export default hoc(LeftSideAdvantages)(NewPassword);
