import {
	DISABLE_ENABLE_STEP,
	CHANGE_QUIZ_VALUES,
	HANDLE_CHANGE_STEP,
	HANDLE_SET_TOUCHED_STEP,
	HANDLE_SET_ISVALID_STEP,
	SET_CREATE_NEW_DECL_VALUES,
	RESET_DATA_STEPPERS
} from '../types/newQuizTypes';

const disableEnableStepQuiz = (stepId, newIsDisabled) => (dispatch, getState) => {
	const {
		newQuizReducer: { dataSteppers }
	} = getState();
	const newDataSteppers = [...dataSteppers];
	newDataSteppers.forEach(step => {
		if (step.id === stepId) {
			step.isDisabled = newIsDisabled;
			if (newIsDisabled) {
				// if disable step - set isTouched and isvalid to false
				step.isTouched = false;
				step.isValid = false;
			}
		}
	});
	dispatch({
		type: DISABLE_ENABLE_STEP,
		payload: newDataSteppers
	});
};

export const handleChangeStepAction = newStep => dispatch => {
	dispatch({
		type: HANDLE_CHANGE_STEP,
		newStep
	});
};

export const handleNextPrevActions = (currentValue, isPrevNext) => (dispatch, getState) => {
	const {
		newQuizReducer: { dataSteppers }
	} = getState();
	const dataSteppersLength = dataSteppers.length;
	if (dataSteppersLength === currentValue && isPrevNext === 'next') {
		console.log('Отримати Документи'); // TODO: send request
	} else if (isPrevNext === 'prev') {
		// check isDisabled on prev
		if (dataSteppers[currentValue - 2].isDisabled) {
			dispatch(handleChangeStepAction(currentValue - 2));
		} else {
			dispatch(handleChangeStepAction(currentValue - 1));
		}
	} else {
		// check isDisabled on prev
		if (dataSteppers[currentValue].isDisabled) {
			dispatch(handleChangeStepAction(currentValue + 2));
		} else {
			dispatch(handleChangeStepAction(currentValue + 1));
		}
	}
};

export const handleSetTouchedAction = stepTouched => (dispatch, getState) => {
	const {
		newQuizReducer: { dataSteppers }
	} = getState();

	const newDataSteppers = [...dataSteppers];
	newDataSteppers.forEach(step => {
		if (step.id === stepTouched) {
			step.isTouched = true;
		}
	});
	dispatch({
		type: HANDLE_SET_TOUCHED_STEP,
		payload: newDataSteppers
	});
};

export const changeIsValidAction = (stepId, isValidStatus) => (dispatch, getState) => {
	const {
		newQuizReducer: { dataSteppers }
	} = getState();

	const newDataSteppers = [...dataSteppers];
	newDataSteppers.forEach(step => {
		if (step.id === stepId) {
			step.isValid = isValidStatus;
		}
	});

	dispatch({
		type: HANDLE_SET_ISVALID_STEP,
		payload: newDataSteppers
	});
};

export const setStepperValuesAction = (stepId, newValues) => (dispatch, getState) => {
	const {
		newQuizReducer: { dataQuizQuestions }
	} = getState();
	const newDataQuestions = [...dataQuizQuestions];

	newDataQuestions.forEach((question, index, arrayQuestions) => {
		if (question.id === stepId) {
			question.values = newValues;

			// check if need to disable some step and clear it data
			// check only step - 4(one step)
			// FIXME: delete if not use in future !!!!
			if (question.disableStep.step) {
				if (newValues[question.name] === question.disableStep.answer) {
					dispatch(disableEnableStepQuiz(question.disableStep.step, true));
					arrayQuestions[question.disableStep.step - 1].values = { B4: '' }; // FIXME: need to fix
				} else {
					dispatch(disableEnableStepQuiz(question.disableStep.step, false));
				}
			}

			// check if has checked subvalues in not checked subquestion
			for (const key in newValues) {
				const keys = key.split('_');
				const [parentKey, isSub] = keys;
				const status = keys[3];

				if (isSub) {
					if (typeof newValues[parentKey] === 'string') {
						if (newValues[parentKey] !== key) {
							if (newValues[key] === 'string') {
								newValues[key] = '';
							} else {
								newValues[key] = [];
							}
						}
					} else {
						if (!newValues[parentKey].includes(key)) {
							if (status === 'date') {
								newValues[key] = null;
							} else {
								newValues[key] = [];
							}
						}
					}
				} else if (key.length === 4) {
					// if sub in sub
					// FIXME:
					const subInSubParent = `${key.substring(0, key.length - 2)}_sub_${key.substring(
						0,
						key.length - 1
					)}`;
					const subKey = `${key}_sub`;

					if (!newValues[subInSubParent].includes(subKey)) {
						newValues[key] = '';
					}
				}
			}
		}
	});

	dispatch({
		type: CHANGE_QUIZ_VALUES,
		payload: newDataQuestions
	});
};

export const sendCreacteNewDeclAction = async (newValues, setSubmitting) => (dispatch, getState) => {
	// TODO: add response
	const {
		newQuizReducer: { dataSteppers }
	} = getState();

	// check if need to disable B12 question
	if (newValues.C2 === 'C2A') {
		const newDataSteppers = [...dataSteppers];
		newDataSteppers[5].isDisabled = true;
		dispatch({
			type: DISABLE_ENABLE_STEP,
			payload: newDataSteppers
		});
	}

	dispatch({
		type: SET_CREATE_NEW_DECL_VALUES,
		payload: newValues
	});
	setSubmitting(false);

	return {
		status: 201
	};
};

export const resetDataSteppersAction = () => (dispatch, getState) => {
	const {
		newQuizReducer: { dataSteppers }
	} = getState();
	const newDataSteppers = [...dataSteppers];
	newDataSteppers.forEach(step => {
		step.isTouched = false;
		step.isValid = false;
		step.isDisabled = false;
	});
	dispatch({
		type: RESET_DATA_STEPPERS,
		payload: newDataSteppers
	});
};
