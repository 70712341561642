import React, { Fragment } from 'react';

import Input from './FormField/Input';
import Radio from './FormField/Radio';
import Checkbox from './FormField/Checkbox';
import Select from './FormField/Select';
import DatePicker from '../../../../../components/DatePicker';

import FormHelperText from '@material-ui/core/FormHelperText';

const Question = ({ item, state, change, handleChangeDate, changeSelect }) => {
	const renderFormFields = item => {
		switch (item.element) {
			case 'input':
				return <Input item={item} answer={state.answers} change={change} />;

			case 'radio':
				return <Radio item={item} answer={state.answers} change={change} />;

			case 'checkbox':
				return <Checkbox item={item} answer={state.answers} change={change} />;

			case 'select':
				return <Select item={item} answer={state.answers} change={changeSelect} />;

			case 'date':
				return <DatePicker item={item} answer={state.answers} handleChangeDate={handleChangeDate(item.id)} />;

			default:
				break;
		}
	};

	const renderAnswers = answers => {
		return answers.map((answer, i) => {
			return (
				<Fragment key={i}>
					{renderFormFields(answer)}
					{answer.subsection && state.answers[answer.id] && renderFormFields(answer.subsection)}
				</Fragment>
			);
		});
	};

	return (
		<div>
			<h3 className="page-title page-title-OFI">{item.title}</h3>
			<div className="OFI-input-wrap">{item.answers && renderAnswers(item.answers)}</div>
			{item.helpers &&
				item.helpers.map((helper, i) => (
					<FormHelperText className="help" key={i}>
						{helper}
					</FormHelperText>
				))}
		</div>
	);
};

export default Question;
