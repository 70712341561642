import { common } from '../../../data/declaration/sub-sections/COMMON';

import { GET_COMMON_DATA } from '../../actions/declaration_action';

const initialState = {
	...common,
	result: {},
	data: {}
};

const Common = (state = initialState, action) => {
	switch (action.type) {
		case GET_COMMON_DATA:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default Common;
