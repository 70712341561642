import React from 'react';
// import useForm from '../hooks/useForm';

import Question from './Question';

const QuestionBlock = ({ question, state, index, getAnswers, removeBlock }) => {
	return <Question removeBlock={removeBlock} item={question} answers={state} index={index} getAnswers={getAnswers} />;
};

export default QuestionBlock;
