import React from 'react';
import { Grid } from '@material-ui/core';
// import Header from '../components/Header/Header';

const Layout = props => {
	return (
		<Grid container className="layout">
			{/* <Header /> */}
			{props.children}
		</Grid>
	);
};

export default Layout;
