import { P } from '../_decl_subsections_id';
import { B4A } from '../../_questions_OLD';
import { P1, P1A, P1B, P1C, P1D, P1E, P1F, P1G, P1H, P1I, P1J, P1K, P1L, P2A, P2B } from '../_declaretion_answer_id.js';
import { T1RXXXXG2, T1RXXXXG3S, T1RXXXXG4, T1RXXXXG5, T1RXXXXG6, T1RXXXXG7 } from '../_xmlConstId';

const createObjAnswer = ({ id, title, xmlId, value }) => ({ id, title, coise: false, xmlId, value });

const propertyMovableNonMovable = (obj, type) => {
	const lease = {
		xmlId: T1RXXXXG7,
		question: 'Чи надавали Ваше майно в лізинг, оренду (суборенду), житловий найм (піднайм) ?',
		element: 'checkbox',
		mainParent: obj.id,
		placeholder: 'Чи надавали Ваше майно в лізинг, оренду (суборенду), житловий найм (піднайм) ?'
	};

	const movable = obj => ({
		subQuestions: [
			{
				xmlId: T1RXXXXG3S,
				question: "Оберіть країну та Вкажіть адресу об'єкта нерухомого майна або марку (модель) рухомого майна",
				elements: [
					{
						xmlId: T1RXXXXG3S,
						element: 'input',
						placeholder: 'Вкажіть марку (модель) рухомого майна',
						mainParent: obj.id,
						value: ''
					}
				]
			},
			{
				xmlId: T1RXXXXG4,
				question: 'Вкажіть рік набуття у власність/ рік випуску (для рухомого майна)',
				placeholder: 'Вкажіть рік випуску (для рухомого майна)',
				element: 'input',
				mainParent: obj.id,
				value: ''
			},
			lease
		]
	});

	const nonMovable = obj => ({
		subQuestions: [
			{
				xmlId: T1RXXXXG3S,
				question: "Оберіть країну та Вкажіть адресу об'єкта нерухомого майна або марку (модель) рухомого майна",
				elements: [
					{
						element: 'radio',
						placeholder: 'Оберіть країну',
						value: null,
						mainParent: obj.id,
						answers: [
							{ title: 'Україна', id: P2A, mainParent: obj.id, xmlId: T1RXXXXG3S },
							{ title: 'інші країни ', id: P2B, mainParent: obj.id, xmlId: T1RXXXXG3S }
						]
					},
					{
						element: 'input',
						xmlId: T1RXXXXG3S,
						mainParent: obj.id,
						placeholder: "Вкажіть адресу об'єкта нерухомого майна",
						value: ''
					}
				]
			},
			{
				xmlId: T1RXXXXG4,
				question: 'Вкажіть рік набуття у власність',
				placeholder: 'Вкажіть рік набуття у власність',
				element: 'input',
				mainParent: obj.id,
				value: ''
			},
			{
				xmlId: T1RXXXXG5,
				question: 'Вкажіть загальну площу нерухомого майна, кв.м',
				placeholder: 'Вкажіть загальну площу нерухомого майна, кв.м',
				element: 'input',
				mainParent: obj.id,
				value: ''
			},
			{
				xmlId: T1RXXXXG6,
				question: 'Вкажіть частку в загальній площі нерухомого майна',
				placeholder: 'Вкажіть частку в загальній площі нерухомого майна',
				element: 'input',
				mainParent: obj.id,
				value: ''
			},
			lease
		]
	});

	switch (type) {
		case 'movable':
			return {
				...obj,
				...movable(obj)
			};

		case 'nonMovable':
			return {
				...obj,
				...nonMovable(obj)
			};

		default:
			throw new Error('ПРОБЛЕМА В СОЗДАНИЕ ОБЪЕКТА');
	}
};

// const a = propertyMovableNonMovable(createObjAnswer(P1A, 'земельні ділянки', T1RXXXXG2), 'nonMovable');
// a

const answers = {
	P1A: { xmlId: T1RXXXXG2, id: P1A, title: 'Земельні ділянки', value: 1 },
	P1B: { xmlId: T1RXXXXG2, id: P1B, title: 'Житлові будинки', value: 2 },
	P1C: { xmlId: T1RXXXXG2, id: P1C, title: 'Квартири', value: 3 },
	P1D: { xmlId: T1RXXXXG2, id: P1D, title: 'Садові (дачні) будинки', value: 4 },
	P1E: { xmlId: T1RXXXXG2, id: P1E, title: 'Гаражі', value: 5 },
	P1F: { xmlId: T1RXXXXG2, id: P1F, title: 'Водойми', value: 6 },
	P1G: { xmlId: T1RXXXXG2, id: P1G, title: 'Автомобілі легкові', value: 7 },
	P1H: { xmlId: T1RXXXXG2, id: P1H, title: 'Автомобілі вантажні (спеціальні)', value: 8 },
	P1I: { xmlId: T1RXXXXG2, id: P1I, title: 'Водні  транспортні засоби', value: 9 },
	P1J: { xmlId: T1RXXXXG2, id: P1J, title: 'Повітряні судна', value: 10 },
	P1K: { xmlId: T1RXXXXG2, id: P1K, title: 'Мотоцикли (мопеди)', value: 11 },
	P1L: { xmlId: T1RXXXXG2, id: P1L, title: 'Інше нерухоме (рухоме) майно', value: 12 }
};

export const property = {
	id: P,
	srsName: 'property',
	title: 'Майно',
	quiz_answers: [B4A],
	helpers: [
		'Ви можете перевірити наявність нерухомого майна: https://kap.minjust.gov.ua',
		'Ви можете перевірити наявність транспортного засобу у фізичної чи юридичної особи : https://igov.org.ua',
		'Ви можете перевірити наявність водного судна: https://igov.org.ua',
		'Ви можете перевірити наявність земельної ділянки за кадастровим номером: https://igov.org.ua'
	],
	question: {
		id: P1,
		title: 'Категорія, до якої належить Ваше майно',
		xmlId: T1RXXXXG2,
		variants: [
			propertyMovableNonMovable(createObjAnswer(answers.P1A), 'nonMovable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1B), 'nonMovable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1C), 'nonMovable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1D), 'nonMovable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1E), 'nonMovable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1F), 'nonMovable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1G), 'movable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1H), 'movable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1I), 'movable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1J), 'movable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1K), 'movable'),
			propertyMovableNonMovable(createObjAnswer(answers.P1L), 'movable')
		]
	}
}; //?
