import React from 'react';
import { Grid } from '@material-ui/core';

import './styles.scss';

export const LeftSide = props => {
	return (
		<Grid item md={4} {...props} className={`left-side padding ${props.className}`}>
			{props.children}
		</Grid>
	);
};
