import { GET_DOCUMENTS } from '../actions/documents_list_action';
// import { docs } from '../../data/mochData';
const initialState = {
	loading: false,
	documents: [],
	answers: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_DOCUMENTS:
			return {
				...state,
				// documents: docs
				documents: action.payload
			};

		default:
			return state;
	}
}
