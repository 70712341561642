const HZ = 'HZ', // Звитна
	HZN = 'HZN', // Звитна нова
	HZU = 'HZU', // Уточнююча
	// 2
	HZY = 'HZY', // Год
	HZKV = 'HZKV',
	// 3
	HZYP = 'HZYP',
	HZKVP = 'HZKVP',
	//? ФИО
	HNAME = 'HNAME',
	HTIN = 'HTIN', // ИНН PASSPORT
	//? Контактные данные
	HREG = 'HREG', // адрес область
	HRAJ = 'HRAJ', // адрес район
	HCITY = 'HCITY', // адрес город
	HSTREET = 'HSTREET', // адрес улица
	HBUILD = 'HBUILD', // адрес номер дома
	HCORP = 'HCORP', // адрес корпус
	HAPT = 'HAPT', // адрес номер квартиры
	HZIP = 'HZIP', // адрес индекс
	HTEL = 'HTEL', // телефон
	HEMAIL = 'HEMAIL', // email
	// 5
	HSTI = 'HSTI',
	// 6 резидентский статус
	H01 = 'H01',
	H02 = 'H02',
	// 7 Заполнение декларации
	H03 = 'H03',
	H04 = 'H04',
	// 8 Категория платника
	H05 = 'H05',
	H06 = 'H06',
	H07 = 'H07',
	// 9
	HNAMEAG = 'HNAMEAG',
	HTINAG = 'HTINAG',
	// 10
	R010G3 = 'R010G3',
	R010G4 = 'R010G4',
	R010G5 = 'R010G5',
	R010G6 = 'R010G6',
	R010G7 = 'R010G7',
	//
	R0101G3 = 'R0101G3',
	R0101G4 = 'R0101G4',
	R0101G5 = 'R0101G5',
	R0101G6 = 'R0101G6',
	R0101G7 = 'R0101G7',
	//
	R0102G3 = 'R0102G3',
	R0102G4 = 'R0102G4',
	R0102G5 = 'R0102G5',
	R0102G6 = 'R0102G6',
	R0102G7 = 'R0102G7',
	//
	R0103G3 = 'R0103G3',
	R0103G4 = 'R0103G4',
	R0103G5 = 'R0103G5',
	R0103G6 = 'R0103G6',
	R0103G7 = 'R0103G7',
	//
	R0104G3 = 'R0104G3',
	R0104G4 = 'R0104G4',
	R0104G5 = 'R0104G5',
	R0104G6 = 'R0104G6',
	R0104G7 = 'R0104G7',
	//
	R0105G3 = 'R0105G3',
	R0105G4 = 'R0105G4',
	R0105G5 = 'R0105G5',
	R0105G6 = 'R0105G6',
	R0105G7 = 'R0105G7',
	//
	R0106G2S = 'R0106G2S',
	R0106G3 = 'R0106G3',
	R0106G4 = 'R0106G4',
	R0106G5 = 'R0106G5',
	R0106G6 = 'R0106G6',
	R0106G7 = 'R0106G7',
	//
	R0107G3 = 'R0107G3',
	R0107G4 = 'R0107G4',
	R0107G5 = 'R0107G5',
	R0107G6 = 'R0107G6',
	R0107G7 = 'R0107G7',
	//
	R0108G3 = 'R0108G3',
	R0108G4 = 'R0108G4',
	R0108G5 = 'R0108G5',
	R0108G6 = 'R0108G6',
	R0108G7 = 'R0108G7',
	//
	R0109G3 = 'R0109G3',
	R0109G4 = 'R0109G4',
	R0109G5 = 'R0109G5',
	R0109G6 = 'R0109G6',
	R0109G7 = 'R0109G7',
	//
	R01091G3 = 'R01091G3',
	R01091G4 = 'R01091G4',
	R01091G5 = 'R01091G5',
	R01091G6 = 'R01091G6',
	R01091G7 = 'R01091G7',
	//
	R011G3 = 'R011G3',
	R0111G3 = 'R0111G3',
	R0112G3 = 'R0112G3',
	R0113G3 = 'R0113G3',
	//
	R012G3 = 'R012G3',
	//
	R013G1 = 'R013G1',
	R013G2 = 'R013G2',
	R013G3 = 'R013G3',
	R013G4 = 'R013G4',
	R013G5 = 'R013G5',
	R013G6 = 'R013G6',
	R013G7 = 'R013G7',
	R013G8 = 'R013G8',
	//
	R014G3 = 'R014G3',
	R015G3 = 'R015G3',
	R016G3 = 'R016G3',
	R017G3 = 'R017G3',
	//
	//
	R018G3 = 'R018G3',
	R019G3 = 'R019G3',
	R020G3 = 'R020G3',
	R021G3 = 'R021G3',
	//
	R0221G3 = 'R0221G3',
	R0222G3 = 'R0222G3',
	//
	R0231G3 = 'R0231G3',
	//
	R024G3 = 'R024G3',
	R024G4 = 'R024G4',
	R025G3 = 'R025G3',
	R025G4 = 'R025G4',
	//
	R0261G3 = 'R0261G3',
	R0261G4 = 'R0261G4',
	R0262G3 = 'R0262G3',
	R0262G4 = 'R0262G4',
	//
	R027G3 = 'R027G3',
	R027G4 = 'R027G4',
	R028G3 = 'R028G3',
	R028G4 = 'R028G4',
	//
	HBANKACC = 'HBANKACC',
	HBANKNAME = 'HBANKNAME',
	HMFO = 'HMFO',
	//property
	T1RXXXXG2 = 'T1RXXXXG2',
	T1RXXXXG3S = 'T1RXXXXG3S',
	T1RXXXXG4 = 'T1RXXXXG4',
	T1RXXXXG5 = 'T1RXXXXG5',
	T1RXXXXG6 = 'T1RXXXXG6',
	T1RXXXXG7 = 'T1RXXXXG7',
	//
	HJ1 = 'HJ1',
	T2RXXXXG2S = 'T2RXXXXG2S',
	//
	HJ2 = 'HJ2',
	T3RXXXXG2S = 'T3RXXXXG2S',
	//
	HD1 = 'HD1',
	HD2 = 'HD2',
	HBOS = 'HBOS', // ФИО
	HFILL = 'HFILL'; // Дата

export {
	HZ,
	HZN,
	HZU,
	HZY,
	HZKV,
	HZYP,
	HZKVP,
	HNAME,
	HTIN,
	HREG,
	HRAJ,
	HCITY,
	HSTREET,
	HBUILD,
	HCORP,
	HAPT,
	HZIP,
	HTEL,
	HEMAIL,
	HSTI,
	H01,
	H02,
	H03,
	H04,
	H05,
	H06,
	H07,
	HNAMEAG,
	HTINAG,
	R010G3,
	R010G4,
	R010G5,
	R010G6,
	R010G7,
	R0101G3,
	R0101G4,
	R0101G5,
	R0101G6,
	R0101G7,
	R0102G3,
	R0102G4,
	R0102G5,
	R0102G6,
	R0102G7,
	R0103G3,
	R0103G4,
	R0103G5,
	R0103G6,
	R0103G7,
	R0104G3,
	R0104G4,
	R0104G5,
	R0104G6,
	R0104G7,
	R0105G3,
	R0105G4,
	R0105G5,
	R0105G6,
	R0105G7,
	R0106G2S,
	R0106G3,
	R0106G4,
	R0106G5,
	R0106G6,
	R0106G7,
	R0107G3,
	R0107G4,
	R0107G5,
	R0107G6,
	R0107G7,
	R0108G3,
	R0108G4,
	R0108G5,
	R0108G6,
	R0108G7,
	R0109G3,
	R0109G4,
	R0109G5,
	R0109G6,
	R0109G7,
	R01091G3,
	R01091G4,
	R01091G5,
	R01091G6,
	R01091G7,
	R011G3,
	R0111G3,
	R0112G3,
	R0113G3,
	R012G3,
	R013G1,
	R013G2,
	R013G3,
	R013G4,
	R013G5,
	R013G6,
	R013G7,
	R013G8,
	R014G3,
	R015G3,
	R016G3,
	R017G3,
	R018G3,
	R019G3,
	R020G3,
	R021G3,
	R0221G3,
	R0222G3,
	R0231G3,
	R024G3,
	R024G4,
	R025G3,
	R025G4,
	R0261G3,
	R0261G4,
	R0262G3,
	R0262G4,
	R027G3,
	R027G4,
	R028G3,
	R028G4,
	HBANKACC,
	HBANKNAME,
	HMFO,
	T1RXXXXG2,
	T1RXXXXG3S,
	T1RXXXXG4,
	T1RXXXXG5,
	T1RXXXXG6,
	T1RXXXXG7,
	HJ1,
	T2RXXXXG2S,
	HJ2,
	T3RXXXXG2S,
	HD1,
	HD2,
	HBOS,
	HFILL
};
