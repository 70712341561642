import { form_data_to_linked_doc_converter } from '../../misc/misc';

export const R0107G3 = function(formValues) {
	return form_data_to_linked_doc_converter('F01002', formValues, 'F01214')['R01G9'];
};

// Сума податку утриманого (сплаченого) податковим агентом
export const R0107G4 = function(formValues) {
	return 0;
};

// Сума збору утриманого (сплаченого) податковим агентом
export const R0107G5 = function(formValues) {
	return 0;
};

// Сума податку що підлягає сплаті самостійно
export const R0107G6 = function(formValues) {
	return form_data_to_linked_doc_converter('F01002', formValues, 'F01214')['R015G3'];
};

// Сума збору що підлягає сплаті самостійно
export const R0107G7 = function(formValues) {
	return form_data_to_linked_doc_converter('F01002', formValues, 'F01214')['R023G3'];
};
