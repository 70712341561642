export const api = {
	prod: {
		basePath: 'api.zvit.pure-agency.co',
		protocol: 'http://',
		port: ''
	},
	dev: {
		basePath: 'localhost',
		protocol: 'http://',
		port: '3000'
	}
};