import React, { Fragment } from 'react';

import NewDatePicker from '../NewDatePicker';

import moment from 'moment';

const NewDatePickerField = ({ stepperCurrent, formikError, name, setFieldValue, setFieldError, ...props }) => {
	const handleDateChange = selectedDate => {
		setFieldValue(name, moment(selectedDate).format('YYYY-MM-DD'));
	};

	const showError = formikError && stepperCurrent.isTouched;
	return (
		<Fragment>
			<NewDatePicker
				helperText={showError ? formikError : ''}
				error={!!showError}
				onChange={handleDateChange}
				onError={(_, error) => setFieldError(name, error)}
				{...props}
			/>
		</Fragment>
	);
};

export default NewDatePickerField;
