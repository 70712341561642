import { connect } from 'react-redux';

import Alim from '../subsections/Alim';
import { getAlimData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.ALIM.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getAlimData
};

const AlimContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Alim);

export default AlimContainer;
