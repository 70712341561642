import { connect } from 'react-redux';

import Charity from '../subsections/C';
import { getCData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.C.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getCData
};

const CContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Charity);

export default CContainer;
