import { documents } from '../../data/_documents';
import { flattenDeep as flatten, findIndex, uniq } from 'lodash';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';

export function getDocuments(answers) {
	let data = [];
	console.log('answers valera', answers); //FIXME:

	answers = flatten(answers);
	answers = answers.filter(item => typeof item !== 'number');
	answers = answers.forEach(answer => {
		let index = findIndex(documents, document => document.id === answer);
		if (index >= 0) return data.push(documents[index].document);
	});
	data = data.map(document => {
		return document.split(';');
	});
	data = uniq(flatten(data));

	return {
		type: GET_DOCUMENTS,
		payload: data
	};
}
