import { R0103G3, R0106G3, R021G3 } from '../_xmlConstId';
import {
	Lease1,
	Lease1A,
	Lease1AA,
	Lease1B,
	Lease1BA,
	Lease2,
	Lease2A,
	Lease2B,
	Lease2C,
	Lease2D,
	Lease2E
} from '../_declaretion_answer_id';
import { B4A } from '../../_questions_OLD';
import { INPUT, RADIO, SELECT, DATE } from '../../elementTypes';

import countries from '../../../data/countries.json';
import currency from '../../../data/currencies.json';

export const Lease = [
	{
		// Lease1 QUESTION
		id: Lease1,
		title: 'Оберіть вид суб’єкта господарювання Вашого орендаря / лізингоотримувача / наймача',
		name: Lease1,
		quizOpen: [B4A],
		question_title: true,
		answers: [
			{
				// Lease1 QUESTION > Lease1A
				id: Lease1A,
				title: "фізична особа, яка не є суб'єктом господарської діяльності",
				name: 'Lease1_g',
				sibling_children: [Lease1BA],
				element: RADIO
			},
			{
				// Lease1 QUESTION > Lease1B
				id: Lease1B,
				title: "юридична особа або фізична особа - суб'єкт господарської діяльності",
				name: 'Lease1_g',
				sibling_children: [Lease1AA],
				element: RADIO
			}
		]
	},
	{
		// Lease1 QUESTION > Lease1A > INPUT
		parent: Lease1A,
		id: Lease1AA,
		title:
			'Вкажіть річну суму лізингових платежів та або орендної плати, отриманих Вами згідно з укладеним договором лізингу, оренди (суборенди), найму (піднайму) майна',
		name: Lease1AA,
		xmlId: R0103G3,
		placeholder: 'Вкажіть суму',
		element: INPUT
	},
	{
		// Lease1 QUESTION > Lease1B > INPUT
		parent: Lease1B,
		id: Lease1BA,
		title:
			'Вкажіть річну суму лізингових платежів та або орендної плати, отриманих Вами згідно з укладеним договором лізингу, оренди (суборенди), найму (піднайму) майна',
		name: Lease1BA,
		xmlId: R0103G3,
		placeholder: 'Вкажіть суму',
		element: INPUT
	},
	{
		// Lease2 INPUT
		id: Lease2,
		title:
			'Вкажіть річну суму лізингових платежів та або орендної плати, отриманих Вами згідно з укладеним договором лізингу, оренди (суборенди), найму (піднайму) майна, перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
		name: Lease2,
		xmlId: R0106G3,
		placeholder: 'Вкажіть суму',
		// element: INPUT,
		quizOpen: [B4A],
		heplers: [
			"Підказка 1: Ви можете зменшити річне податкового зобов'язання на суму податків, сплачених за кордоном згідно з нормами міжнародних договорів про уникнення подвійного оподаткування.",
			'Підказка 2: У разі можливості неоднозначного трактування норм ПКУ рекомендуємо звернутися за індивідуальною податковою консультацією до контролюючого органу',
			'Підказка 3. У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою)'
		],
		answers: [
			{
				parent: Lease2,
				id: Lease2A,
				title: 'Kраїнa',
				xmlId: R0106G3,
				name: 'country_name',
				element: SELECT,
				options: countries
			},
			{
				parent: Lease2,
				id: Lease2B,
				title: 'Валюта',
				xmlId: R0106G3,
				name: 'currency_name',
				element: SELECT,
				options: currency
			},
			{
				parent: Lease2,
				id: Lease2C,
				title: 'Дата',
				xmlId: R0106G3,
				name: 'currency_date',
				element: DATE
			},
			{
				parent: Lease2,
				id: Lease2D,
				title:
					'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
				element: INPUT,
				name: 'revenue',
				placeholder: 'Введіть суму (грн., коп.)',
				xmlId: R0106G3
			},
			{
				parent: Lease2,
				id: Lease2E,
				// title:
				// 	'Вкажіть суму  сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
				element: INPUT,
				name: 'tax',
				placeholder: 'НАЛОГ',
				// placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R021G3
			}
		]
	}
];
