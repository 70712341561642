import React, { Fragment } from 'react';

import { FormHelperText } from '@material-ui/core';

import InputComponent from '../InputComponent';

const InputTextField = props => {
	const {
		field,
		form: { touched, errors }
	} = props;
	const showError = touched[field.name] && errors[field.name];
	return (
		<Fragment>
			<InputComponent error={!!showError} {...props} {...field} />
			<FormHelperText error className="labelError">
				{showError && errors[field.name] ? errors[field.name] : ''}
			</FormHelperText>
		</Fragment>
	);
};

export default InputTextField;
