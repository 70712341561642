export const data = {
	C_DOC: 'F01',
	C_DOC_CNT: 2,
	C_DOC_STAN: 2,
	C_DOC_SUB: '002',
	C_DOC_TYPE: 0,
	C_DOC_VER: '10',
	C_RAJ: '',
	C_REG: '',
	C_STI_ORIG: '',
	D_FILL: '23012019',
	H01: false,
	H02: false,
	H03: false,
	H04: false,
	H05: false,
	H06: false,
	H07: false,
	HAPT: false,
	HBANKACC: false,
	HBANKNAME: false,
	HBOS: false,
	HBUILD: false,
	HCITY: false,
	HCORP: false,
	HD1: false,
	HD2: false,
	HEMAIL: false,
	HFILL: '23/01/2019',
	HJ1: true,
	HJ2: false,
	HMFO: false,
	HNAME: false,
	HNAMEAG: false,
	HRAJ: false,
	HREG: false,
	HSTI: false,
	HSTREET: false,
	HTEL: false,
	HTIN: false,
	HTINAG: false,
	HZ: false,
	HZIP: false,
	HZN: false,
	HZU: false,
	HZY: false,
	HZYP: true,
	LINKED_DOCS: false,
	NUM: '1',
	PERIOD_MONTH: 12,
	PERIOD_TYPE: 5,
	PERIOD_YEAR: false,
	R010G3: 0,
	R010G4: 0,
	R010G5: 0,
	R010G6: 0,
	R010G7: 0,
	R011G3: 0,
	R012G3: 0,
	R013G1: false,
	R013G2: false,
	R013G3: false,
	R013G4: false,
	R013G5: false,
	R013G6: true,
	R013G7: false,
	R013G8: false,
	R014G3: 0,
	R015G3: 0,
	R016G3: 0,
	R017G3: 0,
	R018G3: 0,
	R019G3: 0,
	R020G3: 0,
	R021G3: 0,
	R024G3: 0,
	R024G4: 0,
	R025G3: 0,
	R025G4: 0,
	R027G3: 0,
	R027G4: 0,
	R028G3: 0,
	R028G4: 0,
	R0101G3: 0,
	R0101G4: 0,
	R0101G5: 0,
	R0101G6: 0,
	R0101G7: 0,
	R0102G3: 0,
	R0102G4: 0,
	R0102G5: 0,
	R0102G6: 0,
	R0102G7: 0,
	R0103G3: 0,
	R0103G4: 0,
	R0103G5: 0,
	R0103G6: 0,
	R0103G7: 0,
	R0104G3: 0,
	R0104G4: 0,
	R0104G5: 0,
	R0104G6: 0,
	R0104G7: 0,
	R0105G3: 0,
	R0105G4: 0,
	R0105G5: 0,
	R0105G6: 0,
	R0105G7: 0,
	R0106G2S: false,
	R0106G3: 0,
	R0106G4: 0,
	R0106G5: 0,
	R0106G6: 0,
	R0106G7: 0,
	R0107G3: 0,
	R0107G4: 0,
	R0107G5: 0,
	R0107G6: 0,
	R0107G7: 0,
	R0108G3: 0,
	R0108G4: 0,
	R0108G5: 0,
	R0108G6: 0,
	R0108G7: 0,
	R0109G3: 0,
	R0109G4: 0,
	R0109G5: 0,
	R0109G6: 0,
	R0109G7: 0,
	R0111G3: 0,
	R0112G3: 0,
	R0113G3: 0,
	R0221G3: 0,
	R0222G3: 0,
	R0231G3: 0,
	R0261G3: 0,
	R0261G4: 0,
	R0262G3: 0,
	R0262G4: 0,
	R01091G3: 0,
	R01091G4: 0,
	R01091G5: 0,
	R01091G6: 0,
	R01091G7: 0,
	T1RXXXXG2: [],
	T1RXXXXG3S: [],
	T1RXXXXG4: [],
	T1RXXXXG5: [],
	T1RXXXXG6: [],
	T1RXXXXG7: [],
	T2RXXXXG2S: [],
	T3RXXXXG2S: [],
	TIN: '1241241',
	TYPE: '2'
};

export const report = 'F0100210';
