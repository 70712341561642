import { connect } from 'react-redux';

import D from '../subsections/D';
import { getDData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.D.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getDData
};

const DContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(D);

export default DContainer;
