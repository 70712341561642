import React, { useState, useEffect } from 'react';
import Question from './components/Question';
// import CreateDeclaraionContainer from '../../components/CreateDeclaration';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const initialDataState = {
	R024G3: 0,
	R024G4: 0,
	R025G3: 0,
	R025G4: 0,
	R028G3: 0,
	R028G4: 0
};
const initialAnswersState = {
	PENALTY1A_input: '',
	PENALTY1B_input: '',
	PENALTY2A_input: '',
	PENALTY2B_input: '',
	PENALTY3A_input: '',
	PENALTY3B_input: ''
};

const Penalty = props => {
	const [data, setData] = useState(initialDataState);
	const [answers, setAnswers] = useState(initialAnswersState);

	useEffect(() => {
		if (load('penalty')) {
			const { data, answers } = load('penalty');
			setAnswers(answers);
			setData(data);
		}
	}, []);

	const handleChange = ({ currentTarget }) => {
		const { value, name, id, type } = currentTarget;
		let newData = { ...data };
		let newAnswers = { ...answers };

		switch (type) {
			case 'text':
				newData[name] = value;
				newAnswers[`${id}_input`] = value;
				break;
			default:
				break;
		}
		save('penalty', { data, answers });
		props.getPenaltyData(newData);
		setData(newData);
		setAnswers(prevState => ({ ...prevState, ...newAnswers }));
	};

	const hendleClickNextButton = () => props.getPenaltyData(data);

	return (
		<div className="penalty-wrap">
			{Object.values(props.subsection).map((item, i) => {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			})}

			<NextButton className="btn-create-declaration" link={props.nextSubSection} click={hendleClickNextButton} />
		</div>
	);
};

export default Penalty;
