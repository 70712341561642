export const HJ1 = function(formValues) {
	return formValues['HJ1'] ? formValues['HJ1'] : false;
};

export const HJ2 = function(formValues) {
	return formValues['HJ2'] ? formValues['HJ2'] : false;
};

export const T1RXXXXG7 = function(formValues) {
	return formValues['T1RXXXXG7'] ? formValues['T1RXXXXG7'] : [];
};

export const T2RXXXXG2S = function(formValues) {
	return !formValues['HJ1'] ? [] : formValues['T2RXXXXG2S'] ? formValues['T2RXXXXG2S'] : [];
};

export const T3RXXXXG2S = function(formValues) {
	return !formValues['HJ2'] ? [] : formValues['T3RXXXXG2S'] ? formValues['T3RXXXXG2S'] : [];
};
