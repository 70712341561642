import { precisionRound } from '../../../types/index';

export const R0105G3 = function(formValues) {
	return Object.keys(formValues['R0105G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0105G3']['q'][key] == 'yes';
			return key !== '0' && formValues['R0105G3']['a'][key] !== '';
		})
		.reduce((acc, key) => {
			let curr = parseFloat(formValues['R0105G3']['a'][key]);

			return acc + curr;
		}, 0);
};

//? Сума податку утриманого (сплаченого) податковим агентом
export const R0105G4 = function(formValues) {
	return Object.keys(formValues['R0105G3']['a'])
		.filter(key => {
			return key !== '0' && formValues['R0105G3']['a'][key] !== '';
		})
		.reduce((acc, key) => {
			let tax = 0;
			let curr = parseFloat(formValues['R0105G3']['a'][key]);
			switch (key) {
				case 'ID4AA':
					tax = (curr * 1.2195122 * 18) / 100;
					break;
				case 'ID3DA':
				case 'ID3EA':
					tax = (curr * 5) / 100;
					break;
				default:
					break;
			}

			return acc + tax;
		}, 0);
};

//? Сума збору утриманого (сплаченого) податковим агентом
// export const R0105G5 = function(formValues) {
// 	return Object.keys(formValues['R0105G3']['a'])
// 		.filter(key => {
// 			// return key != '0' && formValues['R0105G3']['q'][key] == 'yes';
// 			return key != '0';
// 		})
// 		.reduce((acc, key) => {
// 			let tax = parseFloat(formValues[`R0105G5_${key}`]);

// 			return acc + tax;
// 		}, 0);
// };
//! ===========================
export const R0105G5_partials = function(formValues) {
	return Object.keys(formValues['R0105G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0105G3']['q'][key] == 'yes';
			return key !== '0' && formValues['R0105G3']['a'][key] !== '';
		})
		.reduce((acc, key) => {
			let tax = 0;
			if (formValues[`R0105G6_${key}_override`]) {
				tax = parseFloat(formValues[`R0105G6_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0105G3']['a'][key]);

				switch (key) {
					case 'ID2AA':
					case 'ID3FA':
						tax += 0;
						break;
					case 'ID1BA':
					case 'ID1AA':
					case 'ID3AA':
					case 'ID3BA':
					case 'ID3CA':
					case 'ID3DA':
					case 'ID3EA':
					case 'ID4AA':
						tax += (curr * 1.5) / 100;
						break;
					default:
						break;
				}
			}

			acc[`R0105G6_${key}`] = precisionRound(tax, 2);

			return 0;
		}, {});
};

//! Сума збору утриманого (сплаченого) податковим агентом
export const R0105G5 = function(formValues) {
	let partials = R0105G5_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};
//! ===========================

//? Сума податку що підлягає сплаті самостійно
export const R0105G6_partials = function(formValues) {
	return Object.keys(formValues['R0105G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0105G3']['q'][key] == 'yes';
			return key !== '0' && formValues['R0105G3']['a'][key] !== '';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0105G6_${key}_override`]) {
				tax = parseFloat(formValues[`R0105G6_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0105G3']['a'][key]);

				switch (key) {
					case 'ID2AA':
					case 'ID3FA':
						tax += 0;
						break;
					case 'ID3AA':
					case 'ID3BA':
					case 'ID3CA':
					case '7':
					case '8':
						tax += (curr * 5) / 100;
						break;
					case 'ID1BA':
					case 'ID1AA':
					case '9':
					case '10':
						tax += (curr * 18) / 100;
						break;
					default:
						break;
				}
			}

			acc[`R0105G6_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума податку що підлягає сплаті самостійно
export const R0105G6 = function(formValues) {
	let partials = R0105G6_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};

// Сума збору що підлягає сплаті самостійно
export const R0105G7_partials = function(formValues) {
	return Object.keys(formValues['R0105G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0105G3']['q'][key] == 'yes';
			return key !== '0' && formValues['R0105G3']['a'][key] !== '';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0105G7_${key}_override`]) {
				tax = parseFloat(formValues[`R0105G7_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0105G3']['a'][key]);

				switch (key) {
					case 'ID3FA':
						tax += 0;
						break;
					case 'ID1BA':
					case 'ID1AA':
					case 'ID3AA':
					case 'ID3BA':
					case 'ID3CA':
					case '':
						tax += (curr * 1.5) / 100;
						break;
					default:
						break;
				}
			}

			acc[`R0105G7_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума збору що підлягає сплаті самостійно
export const R0105G7 = function(formValues) {
	let partials = R0105G7_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};
