import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

export const T1RXXXXG2S = function(formValues) {
	return Object.keys(formValues['R0107G3']['a'])
		.filter(key => {
			return parseInt(key) > 0;
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0107G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => fieldsSet['T1RXXXXG2S']);
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG3S = function(formValues) {
	return Object.keys(formValues['R0107G3']['a'])
		.filter(key => {
			return parseInt(key) > 0;
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0107G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => fieldsSet['T1RXXXXG3S']);
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG4 = function(formValues) {
	return Object.keys(formValues['R0107G3']['a'])
		.filter(key => {
			return parseInt(key) > 0;
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0107G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => parseFloat(fieldsSet['T1RXXXXG4']));
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG5 = function(formValues) {
	return Object.keys(formValues['R0107G3']['a'])
		.filter(key => {
			return parseInt(key) > 0;
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0107G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => parseFloat(fieldsSet['T1RXXXXG5']));
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG6 = function(formValues) {
	return Object.keys(formValues['R0107G3']['a'])
		.filter(key => {
			return parseInt(key) > 0;
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0107G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => parseFloat(fieldsSet['T1RXXXXG6']));
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG7 = function(formValues) {
	return Object.keys(formValues['R0107G3']['a'])
		.filter(key => {
			return parseInt(key) > 0;
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0107G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => parseFloat(fieldsSet['T1RXXXXG7']));
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG8 = function(formValues) {
	return Object.keys(formValues['R0107G3']['a'])
		.filter(key => {
			return parseInt(key) > 0;
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0107G3']['a'][key];

			if (isArray(keyValue)) {
				//result = keyValue.map((fieldsSet) => parseFloat(fieldsSet['T1RXXXXG8']));

				result = keyValue.map(fieldsSet => {
					if (!isObject(fieldsSet['T1RXXXXG8'])) {
						let val = parseFloat(fieldsSet['T1RXXXXG8']);

						return isNaN(val) ? 0 : val;
					}

					if (fieldsSet['T1RXXXXG8_present'] !== true) return 0;

					return Object.keys(fieldsSet['T1RXXXXG8']) // keys is array ['A1', 'A2', 'A3', 'A4']
						.map(group_code => {
							let val_1 = parseFloat(fieldsSet['T1RXXXXG8'][group_code].start_value);
							let val_2 = parseFloat(fieldsSet['T1RXXXXG8'][group_code].end_value);

							let amortization = (isNaN(val_1) ? 0 : val_1) - (isNaN(val_2) ? 0 : val_2);

							return amortization;
						})
						.reduce((acc, amortization) => {
							return acc + amortization;
						}, 0);
				});
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG8_raw = function(formValues) {
	return Object.keys(formValues['R0107G3']['a'])
		.filter(key => {
			return parseInt(key) > 0;
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0107G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => {
					let defaults = {
						A1: {
							start_value: 0,
							end_value: 0
						},
						A2: {
							start_value: 0,
							end_value: 0
						},
						A3: {
							start_value: 0,
							end_value: 0
						},
						A4: {
							start_value: 0,
							end_value: 0
						}
					};

					if (!isObject(fieldsSet['T1RXXXXG8']) || fieldsSet['T1RXXXXG8_present'] !== true) {
						return defaults;
					}

					return fieldsSet['T1RXXXXG8'];
				});
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG9 = function(formValues) {
	let revenues = T1RXXXXG4(formValues);

	let expenses1 = T1RXXXXG5(formValues);
	let expenses2 = T1RXXXXG6(formValues);
	let expenses3 = T1RXXXXG7(formValues);
	let expenses4 = T1RXXXXG8(formValues);

	return revenues.map((revenue, i) => {
		let profit = revenue - expenses1[i] - expenses2[i] - expenses3[i] - expenses4[i];

		return profit < 0 ? 0 : profit;
	});
};
