import React from 'react';

import NewYearPicker from '../NewYearPicker';

const NewYearPickerField = ({
	classNameTitle,
	title,
	touched,
	formikError,
	name,
	setFieldValue,
	setFieldError,
	setTouched,
	...props
}) => {
	const handleDateChange = selectedDate => {
		setFieldValue(name, new Date(selectedDate));
	};

	const showError = formikError && touched;
	return (
		<div className="question-block-wrap">
			<h3 className={`${classNameTitle ? classNameTitle : 'question-title'}`}>{title}</h3>
			<NewYearPicker
				helperText={showError ? formikError : ''}
				error={!!showError}
				onChange={handleDateChange}
				onError={(_, error) => setFieldError(name, error)}
				onBlur={() => {
					setTouched({ [name]: true });
				}}
				{...props}
			/>
		</div>
	);
};

export default NewYearPickerField;
