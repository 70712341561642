import React, { Fragment, useCallback } from 'react';

import Input from './FormFields/Input';
import Radio from './FormFields/Radio';
import Checkbox from './FormFields/Checkbox';
import Select from './FormFields/Select';

import FormHelperText from '@material-ui/core/FormHelperText';

const Question = React.memo(({ item, state, change, changeSelect }) => {
	const { answers } = state;

	const renderAnswers = useCallback(
		answers => {
			return answers.map((answer, i) => {
				return <Fragment key={i}>{renderFormFields(answer)}</Fragment>;
			});
		},
		[answers, change, changeSelect]
	);
	const renderFormFields = item => {
		switch (item.element) {
			case 'input':
				return <Input item={item} answer={state.answers} change={change} />;

			case 'radio':
				return <Radio item={item} answer={state.answers} change={change} />;

			case 'checkbox':
				return <Checkbox item={item} answer={state.answers} change={change} />;

			case 'select':
				return <Select item={item} answer={state.answers} change={changeSelect} />;

			default:
				break;
		}
	};

	return (
		<div className="Property-blocks-wrap">
			{item.question_title && <h3 className="page-title page-title-Property">{item.title}</h3>}
			{!item.question_title && item.title && <h3 className="page-title page-title-Property">{item.title}</h3>}
			{!item.question_title && item.answers && answers[item.id] && renderAnswers(item.answers)}
			{!item.question_title && !item.answers && renderFormFields(item)}
			{!item.question_title && item.answers && renderAnswers(item.answers)}
			<div className="Property-hints-wrap">
				{item.question_title &&
					item.helpers &&
					item.helpers.map((helper, i) => <FormHelperText key={i}>{helper}</FormHelperText>)}
			</div>
		</div>
	);
});

export default Question;
