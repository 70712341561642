// import isArray from 'lodash/isArray';
// import isObject from 'lodash/isObject';

// import deepEqual from 'deep-equal';

// export * from './fop_declaration/page_fop_declaration';
// export * from './F01001/page_F01001_declaration';
// export * from './F01002/page_F01002_declaration';
// import uniq from 'lodash/uniq';

const docsRevisionsRegistry = {
	F01001: '10',
	F01002: '10',
	fop_declaration: '06' //F01033
};

const linkedDocsRevisionsRegistry = {
	F01002: {
		linked_docs: {
			F01212: '10',
			F01214: '10'
		}
	},
	F01001: {
		linked_docs: {
			F01112: '10',
			F01114: '10'
		}
	}
};

export const getDocumentLatestRevision = document_type => {
	let revision = docsRevisionsRegistry[document_type];

	if (document_type === 'fop_declaration') {
		return `F01033${revision}`;
	} else {
		return `${document_type}${revision}`;
	}
};
export const getLinkedDocumentLatestRevision = (main_document_type, linked_doc_type) => {
	let data = linkedDocsRevisionsRegistry[main_document_type];
	let revision = '';

	if (linked_doc_type) {
		revision = data['linked_docs'][linked_doc_type];
	} else {
		return Object.keys(data['linked_docs']).map(key => {
			let linked_doc_type = key;
			let revision = data['linked_docs'][key];

			return `${linked_doc_type}${revision}`;
		});
	}

	return `${linked_doc_type}${revision}`;
};

export function get_form_data_definition(docType) {
	let module = null;
	let form_data_definition = null;

	if (docType === 'fop_declaration') {
		module = require('./' + docType + '/misc/definition_object');
		//return require("./" + docType + "/misc/definition_object")();

		form_data_definition = module['doc_definition'];
	} else {
		module = require('./' + docType + '/misc/form_data_definition');

		form_data_definition = module['get_form_data_definition']();
	}

	return form_data_definition;
}

export function migrate_form_data_definition(docType, migrate_from, migrate_to = null) {
	let migrated = { ...migrate_from };

	if (docType === 'fop_declaration') {
		//migrated = {...migrate_from};
	} else {
		let defaults = get_form_data_definition(docType);

		migrated._type = migrated._type ? migrated._type : defaults._type;
		migrated._revision = migrated._revision ? migrated._revision : defaults._revision;
		migrated._revision_versions =
			migrated._revision_versions != null ? migrated._revision_versions : { [migrated._revision]: -1 };

		// this would give effect only in case if 'migrate_from' misses somthing that is present in new defaults
		migrated = { ...defaults, ...migrated };

		migrate_to = migrate_to != null ? migrate_to : defaults;

		let module = null;
		try {
			module = require('./' + docType + '/misc/form_data_migrations');
		} catch (e) {
			console.error(e);
		}

		// while this block would transform something that is present in migrate_from to the shape that is in defaults
		// like transforming plain field value to object like ad etc
		if (module) {
			let current_revision = migrated._revision_versions[migrated._revision];
			let target_revision =
				migrate_to._revision_versions[migrated._revision] != null
					? migrate_to._revision_versions[migrated._revision]
					: -1;

			while (current_revision < target_revision) {
				let revision = migrated._revision;
				let update_num = current_revision + 1;
				let update_method = `${docType}_revision_${revision}_apply_update_${update_num}`;

				if (module[update_method] && typeof module[update_method] == 'function') {
					migrated = module[update_method](migrated, migrate_to);
				}

				migrated._revision_versions[migrated._revision] = current_revision + 1;

				current_revision++;
			}
		}
	}

	return migrated;
}

export const isInt = n => {
	return Number(n) === n && n % 1 === 0;
};

export const isFloat = n => {
	return Number(n) === n && n % 1 !== 0;
};

export const isString = value => {
	return typeof value === 'string';
};

export const isNumber = value => {
	return typeof value === 'number';
};

export const precisionRound = (number, precision = 2) => {
	//    let factor = Math.pow(10, precision);
	//    let result = Math.round(number * factor) / factor;
	//
	//    let result_as_string = result.toString();
	//    let result_as_arr = (result_as_string + (result_as_string.indexOf('.') != -1 ? '' : '.00')).split('.');
	//
	//    while (result_as_arr[1].length < 2) {
	//        result_as_arr[1] = result_as_arr[1] + '0';
	//    }
	//
	//    return parseFloat(result_as_arr.join('.'));

	var factor = Math.pow(10, precision);
	return Math.round(number * factor) / factor;
};
