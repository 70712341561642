import Api from './Api';
// import { save } from '../utils/localStorage';

class UserService extends Api {
	// constructor() {
	// 	super();
	// }

	googleLogin(code, userID) {
		this.setRequestHeaders();
		return this.post('/auth/login/google', { code, userID })
			.then(function({ data }) {
				localStorage.setItem('api_token', data.api_token);
				return data;
			})
			.catch(function(error) {
				console.error(error, 'user_service ERRROR');
			});
	}
}

export default new UserService();
