import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const R0103G3 = ['Lease1AA', 'Lease1BA'];
const initialAnswers = {
	Lease1AA: '',
	Lease1BA: '',
	Lease1_g: null,
	Lease2A: '',
	Lease2B: '',
	Lease2C: '',
	Lease2D: '',
	Lease2E: ''
};
const initialData = {
	R0106G3: {
		a: {
			Lease2: [
				{
					country_name: '',
					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0,
					tax: 0
				}
			]
		},
		q: { Lease2: null }
	},
	R0103G3: {
		a: { Lease1AA: 0, Lease1BA: 0 },
		q: { Lease1AA: null, Lease1BA: null }
	},
	R021G3: {
		a: { Lease2E: 0 },
		q: { Lease2E: null }
	}
};

const Lease = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, name, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (R0103G3.includes(id)) {
						newData.R0103G3.a[id] = value;
					}
					if (name === 'currency_date') {
						newData.R0106G3.a.Lease2[0][name] = value;
					}
					if (name === 'tax') {
						newData.R021G3.a[id] = value;
					}
					if (name === 'revenue') {
						newData.R0106G3.a.Lease2[0][name] = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					const children =
						labels[0].attributes['data-sibchildren'] && labels[0].attributes['data-sibchildren'].value;

					if (children) {
						let childs = children.split(',');
						childs.forEach(item => {
							newAnswers[item] = '';
							if (R0103G3.includes(item)) {
								newData.R0103G3['a'][item] = 0; //! Check why this value take data
							}
						});
					}
					newAnswers[groupName] = value;
					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];
					break;

				default:
					break;
			}
			save('lease', { answers: newAnswers, data: newData });
			props.getLeaseData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);

	const handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			let newData = { ...data };
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;
			newData.R0106G3.a.Lease2[0][dataset.name] = target.value;
			setData(newData);
			setAnswers({ ...answers, ...newAnswers });
			props.getLeaseData(newData);
			save('lease', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const handleChangeDate = useCallback(
		key => e => {
			let newData = { ...data };
			let newAnswers = { ...answers };

			const date = new Date(e._d);

			newAnswers[key] = date.getTime(); //! change answer
			newData.R0106G3.a[key.slice(0, -1)][0].currency_date = date.getTime(); //! change data

			setData(newData);
			setAnswers(newAnswers);
			props.getLeaseData(newData);
			save('lease', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (answers.Lease1_g === item.parent) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			}
			if (!['Lease1A', 'Lease1B'].includes(item.parent)) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			}
			return null;
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('lease')) {
			const { data, answers } = load('lease');
			setAnswers(answers);
			setData(data);
			props.getLeaseData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getLeaseData(data);

	return (
		<div className="Lease-wrap">
			{renderQuestion}
			<div className="btn-Lease-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default Lease;
