import React, { useMemo } from 'react';
import useForm from '../hooks/useForms';

import Question from './Question';

const QuestionBlock = React.memo(props => {
	const { state, _handleChange, _handleChangeSelect } = useForm(props.state, props.getAnswers);

	const render = () => {
		return Object.values(props.subsection).map((item, i) => {
			if (i > 0) {
				if (item.id) {
					return (
						<Question
							key={i}
							item={item}
							state={{ answers: state }}
							change={_handleChange}
							changeSelect={_handleChangeSelect}
						/>
					);
				}
				if ([1, 2, 3, 4, 5, 6].includes(state.T1RXXXXG2) && item.name === 'nonmovable') {
					return (
						<Question
							key={i}
							item={item}
							state={{ answers: state }}
							change={_handleChange}
							changeSelect={_handleChangeSelect}
						/>
					);
				}
				if ([7, 8, 9, 10, 11, 12].includes(state.T1RXXXXG2) && item.name === 'movable') {
					return (
						<Question
							key={i}
							item={item}
							state={{ answers: state }}
							change={_handleChange}
							changeSelect={_handleChangeSelect}
						/>
					);
				}
			}
			return null;
		});
	};

	const renderQuestion = useMemo(render, [state, _handleChange, _handleChangeSelect]);

	return (
		<div>
			{renderQuestion}
			{/* {props.index == props.length - 1 ? (
				<Button variant="contained" onClick={props.addBlock}>
					Додати ще
				</Button>
			) : (
				''
			)}
			{props.index < props.length - 1 ? (
				<Button variant="contained" onClick={props.removeBlock}>
					Видалити
				</Button>
			) : (
				''
			)} */}
		</div>
	);
});

export default QuestionBlock;
