import React, { Fragment, useEffect } from 'react';

import '../styles.scss';

import { Formik } from 'formik';
import * as yup from 'yup';

import { withRouter } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import {
	handleChangeStepAction,
	handleSetTouchedAction,
	changeIsValidAction,
	setStepperValuesAction,
	handleNextPrevActions,
	resetDataSteppersAction
} from '../../../store/actions/newQuizActions';

import hoc from '../../../HOC/hoc';

import NewStepper from '../../../components/NewStepper';
import NewStepperForm from '../../../components/NewStepperForm';

const NewQuizPage = props => {
	const {
		dataSteppers,
		activeStep,
		dataQuizQuestions,
		changeIsValidAction,
		setStepperValuesAction,
		handleNextPrevActions,
		resetDataSteppersAction
	} = props;

	// set touched to passed step
	useEffect(() => {
		return () => {
			const { handleSetTouchedAction } = props;
			handleSetTouchedAction(activeStep);
		};
	}, [activeStep]);

	useEffect(() => {
		// TODO: add seting values from back
		return () => resetDataSteppersAction();
	}, []);

	const handleChangeStep = index => {
		const { handleChangeStepAction } = props;
		handleChangeStepAction(index);
	};

	const _setShema = validation => {
		let validationData = {};
		for (const error of validation) {
			if (error.type === 'required') {
				validationData[error.name] = yup.string().required(error.message);
			} else if (error.type === 'parent_radio') {
				validationData[error.name] = yup.string().test(`parent${error.name}`, error.message, function(value) {
					const parent = this.parent[error.parent];
					if (!value && parent === error.name) {
						return false;
					} else {
						return true;
					}
				});
			} else if (error.type === 'parent_ckeckboxes') {
				validationData[error.name] = yup.string().test(`parent${error.name}`, error.message, function(value) {
					const parent = this.parent[error.parent];
					if (!value && parent.includes(error.name)) {
						return false;
					} else {
						return true;
					}
				});
			} else if (error.type === 'parent_datepicker') {
				validationData[error.name] = yup.mixed().test(`parent${error.name}`, error.message, function(value) {
					const parent = this.parent[error.parent];
					if (value === null && parent.includes(error.name)) {
						return false;
					} else {
						return true;
					}
				});
			}
		}
		return validationData;
	};

	const renderQuestions = () => {
		return (
			<Fragment>
				{dataQuizQuestions.map(question => {
					if (question.id === activeStep) {
						return (
							<Formik
								enableReinitialize={true}
								key={question.id}
								initialValues={question.values}
								validationSchema={yup.object().shape(_setShema(question.validation))}
								onSubmit={values => {
									console.log('values  ', values); // FIXME: delete after test stepper
								}}
								render={props => {
									return (
										<NewStepperForm
											setStepperValuesAction={setStepperValuesAction}
											changeIsValidAction={changeIsValidAction}
											activeStep={activeStep}
											question={question}
											dataSteppersLength={dataSteppers.length}
											stepperCurrent={dataSteppers[activeStep - 1]}
											handleNextPrevActions={handleNextPrevActions}
											{...props}
										/>
									);
								}}
							/>
						);
					} else {
						return null;
					}
				})}
			</Fragment>
		);
	};

	return (
		<div className="question">
			<div className="stepper-wrap">
				<NewStepper steps={dataSteppers} activeStep={activeStep} handleClickStep={handleChangeStep} />
			</div>
			<div className="question-block">{renderQuestions()}</div>
		</div>
	);
};

function mapStateProps(state) {
	return {
		dataSteppers: state.newQuizReducer.dataSteppers,
		activeStep: state.newQuizReducer.activeStep,
		dataQuizQuestions: state.newQuizReducer.dataQuizQuestions
	};
}

export default compose(
	connect(
		mapStateProps,
		{
			handleChangeStepAction,
			handleSetTouchedAction,
			changeIsValidAction,
			setStepperValuesAction,
			handleNextPrevActions,
			resetDataSteppersAction
		}
	),
	withRouter,
	hoc()
)(NewQuizPage);
