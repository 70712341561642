import { precisionRound } from '../../index';

export const R0101G3 = function(formValues) {
	return Object.keys(formValues['R0101G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0101G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.reduce((acc, key) => {
			let curr;
			if (key === 'W3A') {
				curr = parseFloat(formValues['R0101G3']['a'][key]) * 1.2195122;
			} else {
				curr = parseFloat(formValues['R0101G3']['a'][key]);
			}

			return acc + (key === 'W3A' && curr <= 2240 ? 0 : curr);
		}, 0);
};

export const R0101G4_partials = function(formValues) {
	return Object.keys(formValues['R0101G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0101G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;
			if (formValues[`R0101G4_${key}_override`]) {
				tax = parseFloat(formValues[`R0101G4_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0101G3']['a'][key]);

				switch (key) {
					case 'W1A':
						tax += (curr * 18) / 100;
						break;
					case 'W3A':
						tax += (curr * 1.2195122 * 18) / 100;
						break;
					case 'W4AB':
						tax += (curr * 18) / 100;
						break;
					default:
						break;
				}
			}

			acc[`R0101G4_${key}`] = precisionRound(tax, 2);
			return acc;
		}, {});
};

// Сума податку утриманого (сплаченого) податковим агентом
export const R0101G4 = function(formValues) {
	if (formValues['R0101G4_override']) {
		return parseFloat(formValues['R0101G4']);
	}

	let partials = R0101G4_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};

// Сума збору утриманого (сплаченого) податковим агентом
export const R0101G5_partials = function(formValues) {
	return Object.keys(formValues['R0101G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0101G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0101G5_${key}_override`]) {
				tax = parseFloat(formValues[`R0101G5_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0101G3']['a'][key]);

				switch (key) {
					case 'W1A':
						tax += (curr * 1.5) / 100;
						break;
					case 'W3A':
						tax += (curr * 1.5) / 100;
						break;
					case 'W4AB':
						tax += (curr * 1.5) / 100;
						break;
					default:
						break;
				}
			}

			acc[`R0101G5_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума збору утриманого (сплаченого) податковим агентом
export const R0101G5 = function(formValues) {
	if (formValues['R0101G5_override']) {
		return parseFloat(formValues['R0101G5']);
	}

	let partials = R0101G5_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};

// Сума податку що підлягає сплаті самостійно
export const R0101G6 = function() {
	return 0;
};

// Сума збору що підлягає сплаті самостійно
export const R0101G7 = function() {
	return 0;
};
