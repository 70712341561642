import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const RadioInput = ({ name, checked, onChange, onBlur, id, label }) => {
	return (
		<FormControlLabel
			control={
				<Radio
					name={name}
					id={id}
					color="default"
					value={id}
					checked={checked}
					onChange={onChange}
					onBlur={onBlur}
				/>
			}
			label={label}
		/>
	);
};

export default RadioInput;
