import {
	GET_QUESTIONS_SUCCESS,
	GET_ANSWER,
	GET_ANSWERS,
	GET_QUIZ_DATA,
	GET_PREV_QUESTION,
	GET_NEXT_QUESTION,
	GET_INDEX_QUESTION
} from '../actions/quiz_actions';
// import { mockAnswersData } from '../../data/mochData';
import { questions } from '../../data/_questions.1';
const initialState = {
	loading: false,
	questions,
	answers: [],
	currentQuestionIndex: 0,
	result: null,
	data: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_QUESTIONS_SUCCESS:
			return {
				...state,
				questions: action.payload
			};

		case GET_ANSWER:
			return {
				...state,
				answers: [...state.answers, action.answers],
				currentQuestionIndex: state.currentQuestionIndex + 1,
				showHideQuestion: action.showHideQuestion
			};

		case GET_ANSWERS:
			return {
				...state,
				answers: action.payload
			};

		case GET_QUIZ_DATA:
			return {
				...state,
				data: { ...state.data, ...action.payload },
				questions: [...action.questions]
				// questions:
				// 	action.payload.B1_g === 'B1B' ? [...questions.slice(0, 10), ...questions.slice(11, 12)] : questions
			};

		case GET_PREV_QUESTION:
			return {
				...state,
				currentQuestionIndex: state.currentQuestionIndex - 1
			};

		case GET_NEXT_QUESTION:
			return {
				...state,
				currentQuestionIndex: state.currentQuestionIndex + 1
			};

		case GET_INDEX_QUESTION:
			return {
				...state,
				currentQuestionIndex: action.payload
			};

		default:
			return state;
	}
}
