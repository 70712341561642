import React, { Component, Fragment } from 'react';

import Radio from './components/FormFields/Radio';
import Input from './components/FormFields/Input';

import NextButton from '../../components/NextButton';
// import { StyledButton } from '../../../../components/Button/Button';
import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

class Common extends Component {
	/**
	 * Задаем значения полей в стейте, чтобы при переключение с звитного на уточнюючий инпут с годом обнулялся
	 */
	state = {
		answers: {
			HZY: '',
			HZYP: ''
		},
		data: {}
	};

	componentDidMount() {
		let data = load('common');
		if (data) {
			this.setState({ answers: data, data });
			this.props.getCommonData(data);
		}
	}

	renderFormFields = (element, isVariant) => {
		switch (element.element) {
			case 'radio':
				return (
					<>
						<Radio
							blur={this.props.getCommonData}
							item={element}
							answer={this.state.answers}
							change={this.handleChange}
						/>
						{element &&
							element.subquestion &&
							element.subquestion.map((item, i) => (
								<Fragment key={i}>
									{this.state.answers &&
										this.state.answers[element.name] === element.xmlId &&
										this.renderFormFields(item)}
								</Fragment>
							))}
					</>
				);

			case 'input':
				return (
					<Input
						blur={this.props.getCommonData}
						item={element}
						answer={this.state.answers}
						change={this.handleChange}
						isVariant={isVariant}
					/>
				);

			default:
				break;
		}
	};

	handleChange = ({ target }) => {
		const {
			labels: [label]
		} = target;
		let answers = { ...this.state.answers };
		let data = { ...this.state.data };

		switch (target.type) {
			case 'radio': {
				answers[target.name] = target.value;

				/**
				 * Работа с Data для передачи в объект декларации
				 * Добавление положительно значения для выбранного элемента в DATA
				 * Проверка по соседям и удаление положительного значения для соседнего элемента одно группы
				 * Проверка на наличие вложенных детей и удаление их значений если не выбран родитель
				 */
				data[target.value] = answers[target.name] === target.value;
				data[target.name] = target.value;
				// Условия для удаления значений соседнего элемента из одной группы
				let siblings = label.attributes['data-siblings'] && label.attributes['data-siblings'].value.split(' ');
				let siblingChildren =
					label.attributes['data-sibling-children'] &&
					label.attributes['data-sibling-children'].value.split(' ');

				siblingChildren && siblingChildren.forEach(item => (data[item] ? (data[item] = null) : null));
				siblings.forEach(item => (data[item] ? (data[item] = false) : null));
				// Удаляем значения из Answers
				siblingChildren && siblingChildren.forEach(item => (answers[item] ? (answers[item] = '') : null));
				this.setState({ answers: answers, data });
				this.props.getCommonData(data);
				break;
			}
			case 'text':
				answers[target.name] = target.value;
				data[target.name] = target.value;

				this.setState({ answers, data });

				this.props.getCommonData(answers);
				break;
			default:
				break;
		}

		save('common', { ...data });
	};

	handleClickNextButton = () => {
		this.props.getCommonData(this.state.data);
	};

	render() {
		const {
			subsection: { questions }
		} = this.props;
		return (
			<div>
				{/* <h3 className="page-title title-shadow">{title}</h3> */}
				<div className="answers answers-common">
					{questions.map((item, i) => {
						const isVariantTrue =
							item.variants &&
							this.state.answers &&
							this.state.answers.decType &&
							item.variants.some(item => item === this.state.answers.decType);

						return (
							<Fragment key={i}>
								<h3 className="page-title page-title_common">
									{isVariantTrue ? item.title_v2 : item.title}
								</h3>
								<div className="answers inputs-common">
									{item.answers.map((answer, i) => (
										<Fragment key={i}>{this.renderFormFields(answer, isVariantTrue)}</Fragment>
									))}
								</div>
							</Fragment>
						);
					})}
					<div className="btn-Common-wrap">
						<NextButton link={this.props.nextSubSection.srsName} click={this.handleClickNextButton} />
					</div>
				</div>
			</div>
		);
	}
}

export default Common;
