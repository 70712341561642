import React from 'react';
import TextField from '@material-ui/core/TextField';

const InputForm = ({ isVariant, item, change, answer }) => {
	return (
		<>
			<TextField
				margin='dense'
				id={isVariant ? item.xmlId_v2 : item.xmlId}
				label={item.placeholder || item.title}
				name={isVariant ? item.xmlId_v2 : item.xmlId}
				value={(answer && answer[isVariant ? item.xmlId_v2 : item.xmlId]) || ''}
				onChange={change}
				style={{ width: '80%' }}
				helperText={item.helper}
			/>
		</>
	);
};

export default InputForm;
