import React, { Fragment } from 'react';

import Input from './FormField/Input';
import RemoveButton from '../../../components/ButtonAddRemove';
import useForm from '../hooks/useForm';

import FormHelperText from '@material-ui/core/FormHelperText';

const Question = ({ item, answers, getAnswers, removeBlock }) => {
	const { state, _handleChange } = useForm(answers, getAnswers);

	const renderFormFields = item => {
		switch (item.element) {
			case 'input':
				return <Input item={item} answer={state} change={_handleChange} />;

			default:
				break;
		}
	};

	const renderAnswers = answers => {
		return answers.map((answer, i) => {
			return (
				<Fragment key={i}>
					{renderFormFields(answer)}
					{answer.subsection && state[answer.id] && renderFormFields(answer.subsection)}
				</Fragment>
			);
		});
	};

	return (
		<div className="inputs-wrap">
			<h3 className="page-title page-title-disc">{item.title}</h3>
			{item.helpers && item.helpers.map((helper, i) => <FormHelperText key={i}>{helper}</FormHelperText>)}
			{item.answers && renderAnswers(item.answers)}
			<div className="inputs-wrap">{!item.answers && renderFormFields(item)}</div>
			<RemoveButton delete removeBlock={() => removeBlock(answers)} />
		</div>
	);
};

export default Question;
