import { ALIM } from '../../../data/declaration/sub-sections/Alim';
import { GET_ALIM_DATA } from '../../actions/declaration_action';

const initialState = {
	questions: { ...ALIM },
	subId: null,
	data: {}
};

const Alim = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALIM_DATA:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default Alim;
