import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { load } from '../../../../utils/localStorage';

import './styles.scss';

const firstLevelQuestions = [];
// const R0113G3 = [];
// const R0109G3 = [];

// const initialAnswers = {
// 	EntreprI1: '',
// 	EntreprI2: '',
// 	EntreprI3: '',
// 	EntreprI4: '',
// 	EntreprI5: '',
// 	EntreprI5A: '',
// 	EntreprI5B: '',
// 	EntreprI5C: '',
// 	EntreprI6: '',
// 	EntreprI6A_g: null,
// 	EntreprI6A: '',
// 	EntreprI6B: '',
// 	EntreprI6C: '',
// 	EntreprI6D: '',
// 	EntreprI6E: '',
// 	EntreprI7: '',
// };

// const initialData = {
// 	R0107G3: {
// 		q: {
// 			extra_1: true, // R012G3 from F01214
// 			extra_2: true, // R013G3 from F01214
// 			extra_3: true, // R022G3 from F01214

// 			'1': null
// 		},
// 		a: {
// 			extra_1: 0,
// 			extra_2: 0,
// 			extra_3: 0,

// 			'1': [
// 				{
// 					T1RXXXXG2S: '',
// 					T1RXXXXG3S: '',
// 					T1RXXXXG4: 0, // revenue
// 					T1RXXXXG5: 0, // expenses 'type 1'
// 					T1RXXXXG6: 0, // expenses 'type 2'
// 					T1RXXXXG7: 0, // expenses 'type 3'
// 					T1RXXXXG8: {
// 						// expenses 'type 4'
// 						A1: {
// 							start_value: 0,
// 							end_value: 0
// 						},
// 						A2: {
// 							start_value: 0,
// 							end_value: 0
// 						},
// 						A3: {
// 							start_value: 0,
// 							end_value: 0
// 						},
// 						A4: {
// 							start_value: 0,
// 							end_value: 0
// 						}
// 					},
// 					T1RXXXXG8_present: false,
// 					T1RXXXXG9: 0 // profit
// 				}
// 			]
// 		}
// 	},
// };

const initialAnswers = {
	EntreprI1: 3,
	EntreprI2: 'Розведення великої рогатої худоби молочних порід',
	EntreprI3: '01.41',
	EntreprI4: '2345',
	EntreprI5: '',
	EntreprI5A: '20000',
	EntreprI5B: '30000',
	EntreprI5C: '4000000',
	EntreprI6: '',
	EntreprI6A: '',
	EntreprI6A_g: 'EntreprI6AB',
	EntreprI6B: '1000000',
	EntreprI6C: '200000',
	EntreprI6D: '3000000',
	EntreprI6E: '4000000',
	EntreprI7: '50000000'
};

const initialData = {
	R0107G3: {
		q: {
			extra_1: true, // R012G3 from F01214
			extra_2: true, // R013G3 from F01214
			extra_3: true, // R022G3 from F01214

			'1': null
		},
		a: {
			extra_1: '100000000.00',
			extra_2: '10000.00',
			extra_3: '10000.00',

			'1': [
				{
					T1RXXXXG2S: 'Вирощування зернових культур (крім рису), бобових культур і насіння олійних культур',
					T1RXXXXG3S: '01.11',
					T1RXXXXG4: '100000000.00',
					T1RXXXXG5: '10000.00',
					T1RXXXXG6: '100000.00',
					T1RXXXXG7: '10000.00',
					T1RXXXXG8: {
						A1: { start_value: '100000.00', end_value: '10000.00' },
						A2: { start_value: 0, end_value: 0 },
						A3: { start_value: 0, end_value: 0 },
						A4: { start_value: 0, end_value: 0 }
					},
					T1RXXXXG9: 0
				}
			]
		}
	}
};

const Enterprl = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (id === 'EntreprI2') {
						newData.R0107G3.a[1][0].T1RXXXXG2S = value;
					}
					if (id === 'EntreprI3') {
						newData.R0107G3.a[1][0].T1RXXXXG3S = value;
					}
					if (id === 'EntreprI4') {
						newData.R0107G3.a[1][0].T1RXXXXG4 = value;
					}
					if (id === 'EntreprI5A') {
						newData.R0107G3.a[1][0].T1RXXXXG5 = value;
					}
					if (id === 'EntreprI5B') {
						newData.R0107G3.a[1][0].T1RXXXXG6 = value;
					}
					if (id === 'EntreprI5C') {
						newData.R0107G3.a[1][0].T1RXXXXG7 = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					// const children =
					// 	labels[0].attributes['data-sibchildren'] && labels[0].attributes['data-sibchildren'].value;

					newAnswers[groupName] = value;

					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];
					break;

				default:
					break;
			}
			// save('enterprI', { answers: newAnswers, data: newData });
			props.getIpaData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeAutocomplete = useCallback(
		value => {
			// let newData = { ...data };
			let newAnswers = { ...answers };
			let separateAnswer = value.split('||');

			newAnswers.EntreprI2 = separateAnswer[0];
			newAnswers.EntreprI3 = separateAnswer[1];

			// newData.R0106G3.a.IP2C[0][dataset.name] = target.value;
			// setData(newData);
			setAnswers(newAnswers);
			// props.getIpaData(newData);
			// save('enterprI', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			// let newData = { ...data };
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;
			// newData.R0106G3.a.IP2C[0][dataset.name] = target.value;
			// setData(newData);
			setAnswers({ ...answers, ...newAnswers });
			// props.getIpaData(newData);
			// save('enterprI', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title || firstLevelQuestions.includes(item.id)) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeAutocomplete={handleChangeAutocomplete}
						changeSelect={handleChangeSelect}
					/>
				);
			}
			if (answers.IP1AA_g === item.parent) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeAutocomplete={handleChangeAutocomplete}
						changeSelect={handleChangeSelect}
					/>
				);
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('enterprI')) {
			const { data, answers } = load('enterprI');
			setAnswers(answers);
			setData(data);
			props.getIpaData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getIpaData(data);
	return (
		<div className="IP-wrap">
			{renderQuestion}
			<div className="btn-IP-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default Enterprl;
