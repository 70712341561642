import { connect } from 'react-redux';
import GoogleAuth from '../Auth/GoogleAuth';

import { googleSignIn, googleSignOut } from '../../store/actions/user_actions';

const mapStateToProps = state => ({ isSignIn: state.user.isSignIn });

const mapDispatchToProps = { googleSignIn, googleSignOut };

const GoogleAuthContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(GoogleAuth);

export default GoogleAuthContainer;
