import React, { Fragment, useEffect } from 'react';

import { Field } from 'formik';

import { isEmpty } from 'lodash';

import CustomStepperButton from '../CustomStepperButton';
import RadioGroup from '../RadioGroup';
import RadioInputField from '../RadioInputField';
import NewStepperCheckboxGroup from '../NewStepperCheckboxGroup';
import NewStepperCheckBoxField from '../NewStepperCheckBoxField';
import NewStepperCheckBoxSubField from '../NewStepperCheckBoxSubField';
import NewStepperCheckBoxDateField from '../NewStepperCheckBoxDateField';
import NewStepperCheckBoxSubInSubField from '../NewStepperCheckBoxSubInSubField';
import NewStepperRadioSubField from '../NewStepperRadioSubField';

const NewStepperForm = ({
	question,
	changeIsValidAction,
	activeStep,
	dataSteppersLength,
	setStepperValuesAction,
	handleNextPrevActions,
	stepperCurrent,
	...props
}) => {
	// handle validate form(to know all erros)
	useEffect(() => {
		props.validateForm();
	}, []);

	//  set valid to stepperData (if has new errors)
	useEffect(() => {
		changeIsValidAction(activeStep, isEmpty(props.errors));
	}, [props.errors]);

	// set new values on every value changes
	useEffect(() => {
		setStepperValuesAction(activeStep, props.values);
	}, [props.values]);

	const handlePrev = () => {
		handleNextPrevActions(activeStep, 'prev');
	};

	const handleNext = () => {
		handleNextPrevActions(activeStep, 'next');
	};

	const renderRadioGroup = question => {
		return (
			<Fragment>
				<RadioGroup
					id={question.name}
					label={question.title}
					value={props.values[question.name]}
					error={props.errors[question.name]}
					touched={stepperCurrent.isTouched}
				>
					{question.answers.map(answer => {
						if (answer.type === 'radio_sub') {
							return (
								<Field
									key={answer.code}
									component={NewStepperRadioSubField}
									name={question.name}
									id={answer.code}
									label={answer.text}
									question={answer.subAnswerQuestion}
									stepperCurrent={stepperCurrent}
									values={props.values}
									errors={props.errors}
									setFieldValue={props.setFieldValue}
									setFieldTouched={props.setFieldTouched}
								/>
							);
						} else {
							return (
								<Field
									key={answer.code}
									component={RadioInputField}
									name={question.name}
									id={answer.code}
									label={answer.text}
								/>
							);
						}
					})}
				</RadioGroup>
			</Fragment>
		);
	};

	const renderCheckboxGroup = question => {
		return (
			<Fragment>
				<NewStepperCheckboxGroup
					id={question.name}
					label={question.title}
					value={props.values[question.name]}
					error={props.errors[question.name]}
					touched={stepperCurrent.isTouched}
					onChange={props.setFieldValue}
					onBlur={props.setFieldTouched}
				>
					{question.answers.map(answer => {
						if (answer.type === 'checkbox_sub') {
							return (
								<Field
									key={answer.code}
									component={NewStepperCheckBoxSubField}
									name={question.name}
									id={answer.code}
									label={answer.text}
									question={answer.subAnswerQuestion}
									stepperCurrent={stepperCurrent}
									values={props.values}
									errors={props.errors}
									setFieldValue={props.setFieldValue}
									setFieldTouched={props.setFieldTouched}
								/>
							);
						} else if (answer.type === 'checkbox_date_picker') {
							return (
								<Field
									key={answer.code}
									component={NewStepperCheckBoxDateField}
									name={question.name}
									id={answer.code}
									label={answer.text}
									question={answer.subAnswerQuestion}
									stepperCurrent={stepperCurrent}
									values={props.values}
									errors={props.errors}
									setFieldValue={props.setFieldValue}
									setFieldTouched={props.setFieldTouched}
									setFieldError={props.setFieldError}
								/>
							);
						} else if (answer.type === 'checkbox_sub_in_sub') {
							return (
								<Field
									key={answer.code}
									component={NewStepperCheckBoxSubInSubField}
									name={question.name}
									id={answer.code}
									label={answer.text}
									question={answer.subAnswerQuestion}
									stepperCurrent={stepperCurrent}
									values={props.values}
									errors={props.errors}
									setFieldValue={props.setFieldValue}
									setFieldTouched={props.setFieldTouched}
								/>
							);
						} else {
							return (
								<Field
									key={answer.code}
									component={NewStepperCheckBoxField}
									name={question.name}
									id={answer.code}
									label={answer.text}
								/>
							);
						}
					})}
				</NewStepperCheckboxGroup>
			</Fragment>
		);
	};

	return (
		<Fragment>
			<div className="question-block">
				{question.type === 'Radio' ? renderRadioGroup(question) : renderCheckboxGroup(question)}
				{!isEmpty(question.subQuestion) &&
				question.subQuestion.name === props.values[question.subQuestion.parent]
					? question.subQuestion.type === 'Radio'
						? renderRadioGroup(question.subQuestion)
						: renderCheckboxGroup(question.subQuestion)
					: null}
			</div>
			<div className="quiz-btn-block">
				{activeStep !== 1 && (
					<div className="quiz-btn-block__prev">
						<CustomStepperButton onClick={handlePrev} innerText="Назад" />
					</div>
				)}
				<div className="quiz-btn-block__next">
					<CustomStepperButton
						onClick={handleNext}
						innerText={activeStep === dataSteppersLength ? 'Отримати Документи' : 'Далi'}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default NewStepperForm;
