import React from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

// import Preview from './preview';

import { StyledButton } from '../Button/Button';

import './header.scss';

const navigation = [
	// {
	// 	//! DELETE AFTER DONE
	// 	name: 'Preview Declaration',
	// 	link: '/documents/declaration/preview'
	// },
	{
		name: 'Документи',
		link: '/documents'
	},
	{
		name: 'База знань',
		link: '/kb'
	},
	{
		name: 'Особистий кабінет',
		link: '/profile'
	}
];

const Header = props => {
	const regexForChangeButton = /\/?reg|\/$/;
	const regexForShowNav = /\/login*|\/$/;

	let template = null;
	const renderNav = () => {
		!regexForShowNav.test(props.location.pathname)
			? (template = (
					<Grid container justify="flex-end">
						{/* <CreateDeclaraionContainer /> */}
						{/* <Preview /> */}
						{navigation.map((nav, i) => (
							<NavLink
								exact
								className="nav_link"
								key={i}
								to={nav.link}
								activeStyle={{ color: '#111111' }}
							>
								{nav.name}
							</NavLink>
						))}
					</Grid>
			  ))
			: (template = (
					<Link to={regexForChangeButton.test(props.location.pathname) ? '/login' : '/'}>
						<StyledButton className="header-btn" variant="outlined">
							{regexForChangeButton.test(props.location.pathname) ? 'Увійти' : 'РЕЄСТРАЦІЯ'}
						</StyledButton>
					</Link>
			  ));
		return template;
	};

	return (
		<header className="header">
			<Grid container alignItems="center" justify="space-between">
				<Grid item md={4} className="header_left">
					<Link to="/" className="link_logo">
						<img className="logo-label" src="/img/logo-label.svg" alt="logo zvit" />
						<div className="name">ZVIT</div>
						<div className="vertical-line" />
						<div className="logo-slogan">
							<div className="logo-slogan__elem">Онлайн сервіс подання</div>
							<div className="logo-slogan__elem">Декларацій</div>
						</div>
					</Link>
				</Grid>

				<StyledButton
					className={`${
						regexForChangeButton.test(props.location.pathname) ||
						regexForShowNav.test(props.location.pathname)
							? 'hidden '
							: ''
					} header-middle-btn`}
					variant="outlined"
				>
					<div className="header-middle-btn__wrap">
						<div className="header-middle-btn__text">Загальні </div>
						<img className="header-middle-btn__chevron" src="/img/dropdown-chevron.svg" alt="chevron" />
					</div>
				</StyledButton>

				<div>
					<img
						className={`${
							regexForChangeButton.test(props.location.pathname) ||
							regexForShowNav.test(props.location.pathname)
								? 'hidden'
								: ''
						} header-burger`}
						src="/img/burger.svg"
						alt="burger"
					/>
				</div>

				<Grid
					// item md={8} container justify="flex-end"
					className={
						regexForChangeButton.test(props.location.pathname) ||
						regexForShowNav.test(props.location.pathname)
							? 'header_right header-btn-auth'
							: 'header_right'
					}
					// className="header_right header-btn-auth"
				>
					{renderNav()}
				</Grid>
			</Grid>
		</header>
	);
};

export default withRouter(Header);
