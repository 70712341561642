import axios from 'axios';

export const GET_KB_LIST_SUCCESS = 'GET_KB_LIST_SUCCESS';

export const getKbList = async () => {
	const data = await axios.get('https://api.zvit.ua/kb').then(({ data }) => data);
	return {
		type: GET_KB_LIST_SUCCESS,
		payload: data
	};
};
