export const dataQuizQuestions = [
	{
		id: 1,
		title: 'Ви володієте нерухомим або рухомим майном?',
		type: 'Radio',
		name: 'B4',
		disableStep: {},
		values: { B4: '' },
		validation: [
			{
				type: 'required',
				name: 'B4',
				message: 'It is required'
			}
		],
		answers: [
			{
				code: 'B4A',
				text: 'Так',
				type: 'radio',
				subAnswerQuestion: {}
			},
			{
				code: 'B4B',
				text: 'Ні',
				type: 'radio',
				subAnswerQuestion: {}
			}
		],
		subQuestion: {}
	},
	{
		id: 2,
		title: 'Оберіть види доходів, які Ви отримували упродовж звітного року',
		type: 'Checkbox',
		name: 'B5',
		disableStep: {},
		values: { B5: [], B5_sub_B5A: [], B5AA: '', B5AB: '', B5_sub_B5B: '', B5_sub_B5D: '', B5_sub_B5I: '' },
		validation: [
			{
				type: 'required',
				name: 'B5',
				message: 'It is required B5'
			},
			{
				type: 'parent_ckeckboxes',
				parent: 'B5',
				message: 'It is required if you check parent B5_sub_B5A',
				name: 'B5_sub_B5A'
			},
			{
				type: 'parent_ckeckboxes',
				parent: 'B5_sub_B5A',
				message: 'It is required if you check parent B5AA',
				name: 'B5AA'
			},
			{
				type: 'parent_ckeckboxes',
				parent: 'B5_sub_B5A',
				message: 'It is required if you check parent B5AB',
				name: 'B5AB'
			},
			{
				type: 'parent_ckeckboxes',
				parent: 'B5',
				message: 'It is required if you check parent B5_sub_B5B',
				name: 'B5_sub_B5B'
			},
			{
				type: 'parent_ckeckboxes',
				parent: 'B5',
				message: 'It is required if you check parent B5_sub_B5B',
				name: 'B5_sub_B5D'
			},
			{
				type: 'parent_ckeckboxes',
				parent: 'B5',
				message: 'It is required if you check parent B5_sub_B5B',
				name: 'B5_sub_B5I'
			}
		],
		answers: [
			{
				code: 'B5_sub_B5A',
				text: 'доходи та відшкодування, пов’язані з трудовими та цивільно-правовими відносинами',
				type: 'checkbox_sub_in_sub',
				subAnswerQuestion: {
					id: 'B5_sub_B5A',
					title: '',
					type: 'Checkbox',
					parent: 'B5',
					name: 'B5_sub_B5A',
					answers: [
						{
							code: 'B5AA_sub',
							text: 'заробітна плата та відшкодування, отримані від Роботодавця',
							type: 'checkbox_sub',
							subInSubQuestion: {
								id: 33,
								title: 'Оберіть країну-джерело походження доходів',
								type: 'Radio',
								parent: 'B5_sub_B5A',
								name: 'B5AA',
								subInSub: 'B5AA_sub',
								answers: [
									{
										code: 'B5AAA',
										text: 'Україна',
										type: 'radio'
									},
									{
										code: 'B5AAB',
										text: 'інші країни',
										type: 'radio'
									}
								]
							}
						},
						{
							code: 'B5AB_sub',
							text:
								'дохід від виконання робіт або надання послуг за договорами цивільно-правового характеру',
							type: 'checkbox_sub',
							subInSubQuestion: {
								id: 'B5AB',
								title: 'Оберіть країну-джерело походження доходів',
								type: 'Radio',
								parent: 'B5_sub_B5A',
								name: 'B5AB',
								subInSub: 'B5AB_sub',
								answers: [
									{
										code: 'B5ABA',
										text: 'Україна',
										type: 'radio'
									},
									{
										code: 'B5ABB',
										text: 'інші країни',
										type: 'radio'
									}
								]
							}
						}
					]
				}
			},
			{
				code: 'B5_sub_B5B',
				text: 'проценти або дисконтний дохід',
				type: 'checkbox_sub',
				subAnswerQuestion: {
					id: 'B5B_sub',
					title: 'Оберіть країну-джерело походження доходів',
					type: 'Checkbox',
					parent: 'B5',
					name: 'B5_sub_B5B',
					answers: [
						{
							code: 'B5BA',
							text: 'Україна',
							type: 'radio'
						},
						{
							code: 'B5BB',
							text: 'інші країни',
							type: 'radio'
						}
					]
				}
			},
			{
				code: 'B5C',
				text: 'дивіденди',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B5_sub_B5D',
				text: 'роялті',
				type: 'checkbox_sub',
				subAnswerQuestion: {
					id: 'B5D_sub',
					title: 'Оберіть країну-джерело походження доходів',
					type: 'Radio',
					parent: 'B5',
					name: 'B5_sub_B5D',
					answers: [
						{
							code: 'B5DA',
							text: 'Україна',
							type: 'radio'
						},
						{
							code: 'B5DB',
							text: 'інші країни',
							type: 'radio'
						}
					]
				}
			},
			{
				code: 'B5E',
				text: 'виграші та призи',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B5F',
				text: 'додаткове благо',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B5G',
				text: 'стипендія, державна премія, щомісячна довічна плата',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B5H',
				text: 'фінансова або майнова допомога',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B5_sub_B5I',
				text: 'інші доходи',
				type: 'checkbox_sub',
				subAnswerQuestion: {
					id: 'B5I_sub',
					title: 'Оберіть країну-джерело походження доходів',
					type: 'Radio',
					parent: 'B5',
					name: 'B5_sub_B5I',
					answers: [
						{
							code: 'B5IA',
							text: 'Україна',
							type: 'radio'
						},
						{
							code: 'B5IB',
							text: 'інші країни',
							type: 'radio'
						}
					]
				}
			}
		]
	},
	{
		id: 3,
		title: 'Оберіть вид операцій, які Ви здійснювали з майном упродовж звітного року',
		type: 'Radio',
		name: 'B6',
		disableStep: {},
		values: { B6: '', B6_sub_B6A: '' },
		validation: [
			{
				type: 'required',
				name: 'B6',
				message: 'A radio option is required B6'
			},
			{
				type: 'parent_radio',
				parent: 'B6',
				message: 'Parent is required B6_sub_B6A',
				name: 'B6_sub_B6A'
			}
		],
		answers: [
			{
				code: 'B6_sub_B6A',
				text: 'операції з продажу (обміну)',
				type: 'radio_sub',
				subAnswerQuestion: {
					id: 'B6A_sub',
					title: 'Оберіть країну-джерело походження доходів',
					type: 'Radio',
					parent: 'B6',
					name: 'B6_sub_B6A',
					answers: [
						{
							code: 'B6AA',
							text: 'Україна',
							type: 'radio'
						},
						{
							code: 'B6AAB',
							text: 'інші країни',
							type: 'radio'
						}
					]
				}
			},
			{
				code: 'B6B',
				text: 'отримання спадку (дарунку)',
				type: 'radio'
			}
		]
	},
	{
		id: 4,
		title: 'Чи отримували Ви страхові виплати за договорами страхування та накопичення упродовж звітного року?',
		type: 'Radio',
		name: 'B8',
		disableStep: {},
		values: { B8: '' },
		validation: [
			{
				type: 'required',
				name: 'B8',
				message: 'A radio option is required B8'
			}
		],
		answers: [
			{
				code: 'B8A',
				text: 'Так',
				type: 'radio',
				subAnswerQuestion: {}
			},
			{
				code: 'B8B',
				text: 'Ні',
				type: 'radio',
				subAnswerQuestion: {}
			}
		]
	},
	{
		id: 5,
		title: 'Оберіть Ваш спеціальний статус (у разі наявності)',
		type: 'Checkbox',
		name: 'B3',
		disableStep: {},
		values: { B3: [] },
		validation: [
			{
				type: 'required',
				name: 'B3',
				message: 'A radio option is required B3'
			}
		],
		answers: [
			{
				code: 'B3A',
				text: 'дитина з інвалідністю І групи',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B3B',
				text: 'дитина-сирота або дитина, позбавлена батьківського піклування',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B3C',
				text: 'особа з інвалідністю І групи',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B3D',
				text: 'внутрішньопереміщена особа',
				type: 'checkbox',
				subAnswerQuestion: {}
			}
		]
	},
	{
		id: 6,
		title: 'Є речі про які варто знати',
		type: 'Checkbox',
		name: 'B12',
		disableStep: {},
		values: { B12: [] },
		validation: [
			{
				type: 'required',
				name: 'B12',
				message: 'A radio option is required B12'
			}
		],
		answers: [
			{
				code: 'B12A',
				text: 'всиновлення, "ЕКО"',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B12B',
				text: 'благодійність',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B12C',
				text: 'освіта',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B12D',
				text: '"доступне житло"',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B12E',
				text: 'іпотека',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B12F',
				text: 'оренда',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B12G',
				text: 'ГБО',
				type: 'checkbox',
				subAnswerQuestion: {}
			},
			{
				code: 'B12H',
				text: 'страхування',
				type: 'checkbox',
				subAnswerQuestion: {}
			}
		]
	},
	{
		id: 7,
		title:
			'Чи виявляли Ви самостійно помилки у поданих деклараціях про майновий стан і доходи у попередніх звітних періодах?',
		type: 'Radio',
		name: 'B13',
		disableStep: {},
		values: { B4: '' },
		validation: [
			{
				type: 'required',
				name: 'B13',
				message: 'It is required B13'
			}
		],
		answers: [
			{
				code: 'B13A',
				text: 'Так',
				type: 'radio',
				subAnswerQuestion: {}
			},
			{
				code: 'B13B',
				text: 'Ні',
				type: 'radio',
				subAnswerQuestion: {}
			}
		],
		subQuestion: {}
	}
];

export const dataQuizSteppers = [
	{
		id: 1,
		isDisabled: false,
		isTouched: false,
		isValid: false
	},
	{
		id: 2,
		isDisabled: false,
		isTouched: false,
		isValid: false
	},
	{
		id: 3,
		isDisabled: false,
		isTouched: false,
		isValid: false
	},
	{
		id: 4,
		isDisabled: false,
		isTouched: false,
		isValid: false
	},
	{
		id: 5,
		isDisabled: false,
		isTouched: false,
		isValid: false
	},
	{
		id: 6,
		isDisabled: false,
		isTouched: false,
		isValid: false
	},
	{
		id: 7,
		isDisabled: false,
		isTouched: false
	}
];
