import React, { Component } from 'react';
import { Grid, createMuiTheme, MuiThemeProvider } from '@material-ui/core';

import { LeftSide, RightSide } from '../../../components/Sides';
import FormField from '../../../components/Form/FormField';
import { StyledButton } from '../../../components/Button/Button';
import Header from '../../../components/Header/Header';
import GoogleAuthContainer from '../../../components/containers/GoogleAuthContainer';

import LoginForm from './LoginForm';

import './styles.scss';

const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
		suppressDeprecationWarnings: true
	},
	palette: {
		type: 'dark'
	}
});

class Login extends Component {
	state = {
		loading: false,
		formdata: {
			ecp: {
				keys: {
					element: 'select',
					value: '',
					config: {
						name: 'keys_select',
						placeholder: 'Цент сертифікації ключів ',
						label:
							'* Зазвичай називається Key-6.dat.  Під час роботи сервісу інформація, що міститься у контейнерах з особистими ключами користувачів, оброблюєтся в браузері та не передається на сторону сервера НАЗК.',
						options: [
							{
								id: 0,
								name: 'Акредитований центр сертифікації ключів ІДД ДФС'
							},
							{ id: 1, name: 'АЦСК органів юстиції України' },
							{ id: 2, name: 'ЦСК Укрзалізниці' },
							{
								id: 3,
								name: 'АЦСК "MASTERKEY" ТОВ "АРТ-МАСТЕР"'
							},
							{ id: 4, name: 'АЦСК ТОВ "КС"' },
							{ id: 5, name: 'АЦСК ДП "УСС"' },
							{
								id: 6,
								name: 'АЦСК Публічного акціонерного товариства "УкрСиббанк"'
							},
							{ id: 7, name: 'АЦСК ПАТ КБ «ПРИВАТБАНК»' },
							{
								id: 8,
								name: 'Акредитований центр сертифікації ключів Збройних Сил'
							},
							{
								id: 9,
								name: 'АЦСК Державної прикордонної служби України'
							},
							{ id: 10, name: 'АЦСК ринку електричної енергії' },
							{ id: 11, name: 'АЦСК МВС України' },
							{
								id: 12,
								name: 'АЦСК Національного банку України'
							},
							{
								id: 13,
								name: 'АЦСК ТОВ "Центр сертифікації ключів "Україна"'
							},
							{
								id: 14,
								name: 'АЦСК Державної казначейської служби України'
							},
							{
								id: 15,
								name: 'Акредитований центр сертифікації ключів ПАТ НДУ'
							},
							{ id: 16, name: "АЦСК 'eSign' ТОВ 'Алтерсайн'" },
							{
								id: 17,
								name: "ДП 'Український інститут інтелектуальної власності'"
							}
						]
					},
					validation: {
						required: true
					},
					valid: false,
					touched: false,
					validationMessage: ''
				},
				password: {
					element: 'input',
					value: '',
					config: {
						name: 'password_input',
						type: 'password',
						label: 'Пароль'
					},
					validation: {
						required: true,
						password: true
					},
					valid: false,
					touched: false,
					validationMessage: ''
				},
				file: {
					element: 'file',
					value: '',
					config: {
						name: 'file_input',
						type: 'file'
					},
					validation: {
						required: true,
						file: true
					},
					valid: false,
					touched: false,
					validationMessage: ''
				}
			},
			login: {
				email: {
					element: 'input',
					value: '',
					config: {
						name: 'email_input',
						type: 'text',
						label: 'Електорнна пошта'
					},
					validation: {
						required: true,
						email: true
					},
					valid: false,
					touched: false,
					validationMessage: ''
				},
				password: {
					element: 'input',
					value: '',
					config: {
						name: 'password_input',
						type: 'password',
						label: 'Пароль'
					},
					validation: {
						required: true,
						password: true
					},
					valid: false,
					touched: false,
					validationMessage: ''
				}
			}
		}
	};

	handleChange = form => element => {
		const target = element.event.target;
		const newFormData = { ...this.state.formdata };
		const newElement = { ...newFormData[form][element.id] };
		const value = newElement.config.type === 'checkbox' ? target.checked : target.value;
		newElement.value = value;

		if (element.blur) {
			let validData = this.validate(newElement);
			newElement.valid = validData[0];
			newElement.validationMessage = validData[1];
		}

		newElement.touched = element.blur;
		newFormData[form][element.id] = newElement;

		this.setState({
			formdata: newFormData
		});
	};

	validate = element => {
		let error = [true, ''];

		if (element.validation.email) {
			const valid = /\S+@\S+\.\S+/.test(element.value);
			const message = `${!valid ? 'Не дійсний адреса електронної пошти' : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if (element.validation.password) {
			const valid = element.value.length > 9;
			const message = `${!valid ? 'Пароль повинен бути більше 9 символів' : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if (element.validation.required) {
			const valid = element.value.trim() !== '';
			const message = `${!valid ? "Це поле обов'язкове" : ''}`;
			error = !valid ? [valid, message] : error;
		}
		return error;
	};

	handleSubmit = () => event => {
		event.preventDefault();
	};

	handleLogin = (values, setSubmitting) => {
		// TODO: add request
		console.log('values  ', values);
		setSubmitting(false);
	};

	render() {
		const { ecp } = this.state.formdata;

		return (
			<Grid container alignItems="center" className="container">
				<Header />
				<LeftSide
					md={6}
					container
					direction="column"
					justify="center"
					alignItems="center"
					className="left-side-login"
				>
					<MuiThemeProvider theme={theme}>
						<h1 className="h1 h1-login-ecp">Вхід</h1>
						<p className="sub-title sub-title-ecp">за допомогою ЕЦП</p>
						<h2 className="mobile-title-for-ecp">Вхід з ЕЦП</h2>
						<form className="form form__ecp" onSubmit={this.handleSubmit('ecp')}>
							<Grid container direction="column" justify="center">
								<FormField formdata={ecp.keys} id="keys" change={this.handleChange('ecp')} />

								<FormField formdata={ecp.file} id="file" change={e => console.log(e.target.files[0])} />

								<FormField formdata={ecp.password} id="password" change={this.handleChange('ecp')} />
								<StyledButton className="left-side-sign-in-btn" variant="contained" type="submit">
									Увійти
								</StyledButton>
							</Grid>
						</form>
					</MuiThemeProvider>
				</LeftSide>

				<RightSide
					md={6}
					container
					direction="column"
					justify="center"
					alignItems="center"
					className="right-side-login"
				>
					<LoginForm handleLogin={this.handleLogin} />

					<p className="or">або</p>
					<div className="social-enter">
						<GoogleAuthContainer />
					</div>
				</RightSide>
			</Grid>
		);
	}
}

export default Login;
