import React from 'react';

import { StyledButton as Button } from '../Button/Button';

const CustomStepperButton = ({ onClick, innerText, disabled }) => {
	return (
		<Button className="btn" disabled={disabled} variant="contained" onClick={onClick}>
			{innerText}
		</Button>
	);
};

export default CustomStepperButton;
