import React, { Component } from 'react';

export default class htmlData1214 extends Component {
    constructor(props) {
        super(props);
        this.userData = this.props.userData;
    }

    render () {
        return (
            <div className="chat-container doc-preview">
                <table width="100%" >
                    <tbody>
                    <tr>
                        <td className="main_wrap text_left" >
                            <div className="item">F0121410</div>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <table width="100%"  className="border_1 main_wrap text_center">
                    <tbody>
                    <tr>
                        <td width="100%" style={{padding:0}}>
                            <table width="100%" className="main_wrap text_center table_border_none" style={{borderCollapse:'separate'}}>
                                <tbody>
                                <tr>
                                    <td width="37%" className="border" >&nbsp;<br />Відмітка про одержання<br />(штамп контролюючого органу, дата, вхідний
                                        номер)<br />&nbsp;</td>
                                    <td width="28%" />
                                    <td width="35%" className={'text_left'}>Додаток <b>Ф2</b><br />до податкової декларації про
                                        майновий<br />стан і доходи</td>
                                </tr>
                                <tr>
                                    <td colSpan="3" ><h3 >РОЗРАХУНОК<br />податкових
                                        зобов'язань з податку на доходи фізичних осіб та військового збору з
                                        доходів,<br />отриманих самозайнятою особою</h3></td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width="100%"  className="border_1 main_wrap text_center">
                    <tbody>
                    <tr>
                        <td width="100%" style={{padding:'5px'}}>
                            <table width="100%" cellSpacing="3" className="main_wrap text_center table_border_none">
                                <tbody>
                                <tr>
                                    <td width="70%" className={'text_left'} >Реєстраційний номер облікової картки
                                        платника податків або серія (за наявності) та номер
                                    </td>
                                    <td width="30%" className="border">
                                        <div className="item">{this.cell('HTIN')}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className={'text_left'}>паспорта (для фізичних осіб, які через свої релігійні
                                        переконання відмовляються від прийняття реєстраційного номера облікової картки
                                        платника податків та офіційно повідомили про це відповідний контролюючий орган і
                                        мають відмітку у паспорті)
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width="100%"  className="border_1 main_wrap text_center">
                    <tbody>
                    <tr>
                        <td width="100%" >
                            <table width="100%" cellSpacing="2" className="main_wrap text_center table_border_none"
                                   style={{borderCollapse:'separate'}}>
                                <tbody>
                                <tr>
                                    <td width="40%" colSpan="6" className={'text_left'} >Тип декларації:</td>
                                    <td width="24%" colSpan="4" className={'text_left'} >Звітний (податковий)
                                        період:
                                    </td>
                                    <td width="36%" colSpan="4" className={'text_left'}>Звітний (податковий) період, що
                                        уточнюється:
                                    </td>
                                </tr>
                                <tr>
                                    <td width="4%" className="border">
                                        <div className="item input_item readonly">{this.cell('HZ')}</div>
                                    </td>
                                    <td width="6%" className={'text_left'} >Звітна</td>
                                    <td width="4%" className="border">
                                        <div className="item input_item readonly">{this.cell('HZN')}</div>
                                    </td>
                                    <td width="10%" className={'text_left'}>Звітна нова</td>
                                    <td width="4%" className="border">
                                        <div className="item input_item readonly">{this.cell('HZU')}</div>
                                    </td>
                                    <td width="12%" className={'text_left'} >Уточнююча</td>

                                    <td width="6%" className="border">
                                        <div className="item">{this.cell('HZY')}</div>
                                    </td>
                                    <td width="4%" className={'text_left'}>рік</td>
                                    <td width="4%" className="border">
                                        <div className="item">{this.cell('HZKV')}
                                        </div>
                                    </td>
                                    <td width="10%" className={'text_left'}>квартал<sup>1</sup></td>

                                    <td width="6%" className="border">
                                        <div className="item">{this.cell('HZYP')}</div>
                                    </td>
                                    <td width="4%" className={'text_left'}>рік</td>
                                    <td width="4%" className="border">
                                        <div className="item">{this.cell('HZKVP')}</div>
                                    </td>
                                    <td width="22%" className={'text_left'}>квартал</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width="100%"  className="border_1 main_wrap text_center table_2_1">
                    <tbody>
                    <tr>
                        <td width="100%" colSpan="9"><b >І. ДОХОДИ ВІД ПРОВАДЖЕННЯ ГОСПОДАРСЬКОЇ
                            ДІЯЛЬНОСТІ</b></td>
                    </tr>
                    <tr>
                        <td width="5%" rowSpan="2" >№<br />з/п</td>
                        <td width="29%" rowSpan="2" >Назва виду економічної діяльності, за здійснення
                            якого одержано дохі
                        </td>
                        <td width="8%" rowSpan="2" >Код виду<br />еконо-<br />мічної діяльності згідно з
                            КВЕД</td>
                        <td width="9%" rowSpan="2">Сума<br />одер-<br />жаного<br />доходу<br />(грн, коп.)</td>
                        <td width="39%" colSpan="4">Вартість документально підтверджених витрат,
                            пов'язаних з господарською діяльністю (грн, коп.), у тому числі</td>
                        <td width="10%" rowSpan="2">Сума чистого оподаткову-<br />ваного доходу (грн,
                            коп.) (графа 4 – гра-<br />фа 5 – графа 6 – графа 7 – графа 8) (грн, коп.)</td>
                    </tr>
                    <tr>
                        <td width="13%">вартість придбаних товарно-матеріаль-<br />них цінностей, що
                            реалізовані або використані у виробництві продукції</td>
                        <td width="9%">витрати на оплату праці та нарахування на заробітну плату
                        </td>
                        <td width="9%">інші витрати, включаючи вартість виконаних робіт, наданих
                            послуг</td>
                        <td width="8%">аморти-<br />заційні відраху-<br />вання</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                        <td>4</td>
                        <td>5</td>
                        <td>6</td>
                        <td>7</td>
                        <td>8</td>
                        <td>9</td>
                    </tr>

                    {Array.isArray(this.getValue('T1RXXXXG2S')) && this.getValue('T1RXXXXG2S').map((val, idx)=>{
                        return (
                            <tr key={idx}>
                                <td className={'text_center'}>
                                    {idx+1}
                                </td>
                                <td >
                                    <div className="item">{this.pureCell(val)}</div>
                                </td>
                                <td>
                                    <div className="item">{this.pureCell(this.getValue('T1RXXXXG3S')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T1RXXXXG4')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T1RXXXXG5')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T1RXXXXG6')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T1RXXXXG7')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T1RXXXXG8')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T1RXXXXG9')[idx])}</div>
                                </td>
                            </tr>
                        )
                    })}

                    <tr >
                        <td colSpan="3">УСЬОГО</td>
                        <td>
                            <div className="item">{this.cell('R01G4')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R01G5')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R01G6')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R01G7')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R01G8')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R01G9')}</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width="100%"  className="border_1  main_wrap text_center">
                    <tbody>
                    <tr >
                        <td width="100%" colSpan="5"><b>ІІ. Інформація щодо нарахованої амортизації</b></td>
                    </tr>
                    <tr >
                        <td width="5%" rowSpan="2">Код<br />рядка</td>
                        <td width="10%" rowSpan="2">Номер<br />групи</td>
                        <td width="48%" colSpan="2">Вартість об'єкта основних засобів та/або нематеріальних активіві
                        </td>
                        <td width="37%" rowSpan="2">Розрахована сума амортизаційних відрахувань за звітний (податковий)
                            період відповідно до підпункту 177.4.9 пункту 177.4 статті 177 ПКУ
                        </td>
                    </tr>
                    <tr >
                        <td width="24%">на початок звітного<br />(податкового) періоду</td>
                        <td width="24%">на кінець звітного<br />(податкового) періоду</td>
                    </tr>
                    <tr >
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                        <td>4</td>
                        <td>5</td>
                    </tr>
                    <tr >
                        <td>А1</td>
                        <td>1</td>
                        <td>
                            <div className="item">{this.cell('R0221G3')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R0221G4')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R0221G5')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td>А2</td>
                        <td>2</td>
                        <td>
                            <div className="item">{this.cell('R0222G3')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R0222G4')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R0222G5')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td>А3</td>
                        <td>3</td>
                        <td>
                            <div className="item">{this.cell('R0223G3')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R0223G4')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R0223G5')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td>А4</td>
                        <td>4</td>
                        <td>
                            <div className="item">{this.cell('R0224G3')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R0224G4')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R0224G5')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td colSpan="4">УСЬОГО</td>
                        <td>
                            <div className="item">{this.cell('R022G5')}</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width="100%"  className="border_1 main_wrap text_center">
                    <tbody>
                    <tr >
                        <td width="5%">Код<br />рядка</td>
                        <td width="87%"><b>ІІІ. ПОДАТКОВІ ЗОБОВ'ЯЗАННЯ З ПОДАТКУ НА ДОХОДИ ФІЗИЧНИХ ОСІБ / ВІЙСЬКОВОГО
                            ЗБОРУ</b></td>
                        <td width="8%">Сума<br />(грн, коп.)</td>
                    </tr>
                    <tr >
                        <td>1</td>
                        <td className={'text_left'}><b>Розрахунки з бюджетом з податку на доходи фізичних осіб:</b></td>
                        <td>x</td>
                    </tr>
                    <tr >
                        <td>1.1</td>
                        <td className={'text_left'}>Розрахована сума податкових зобовязань з податку на доходи фізичних осіб за
                            результатами звітного (податкового) року, що підлягає сплаті до бюджету (додатне значення
                            графи 9 рядка "УСЬОГО" розділу І × на ставку податку (18 % ))
                        </td>
                        <td>
                            <div className="item">{this.cell('R011G3')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td>1.2</td>
                        <td className={'text_left'}>Сума податку на доходи фізичних осіб самостійно нарахована платником податку
                            протягом звітного (податкового) року за результатами останього базового (звітного) періоду,
                            у т.ч. у разі припинення підприємницької діяльності фізичної особи - підприємця протягом
                            звітного (податкового) року<sup>2</sup></td>
                        <td>
                            <div className="item">{this.cell('R012G3')}
                            </div>
                        </td>
                    </tr>
                    <tr >
                        <td>1.3</td>
                        <td className={'text_left'}>Загальна сума авансових платежів з податку на доходи фізичних осіб, що
                            розраховані платником податку самостійно згідно з фактичними даними, зазначеними у Книзі
                            обліку доходів і витрат кожного календарного кварталу (крім четвертого) та сплачені до
                            бюджету протягом звітного (податкового) року згідно з підпунктом 177.5.1 пункту 177.5 статті
                            177 розділу IV ПКУ
                        </td>
                        <td>
                            <div className="item">{this.cell('R013G3')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td>1.4</td>
                        <td className={'text_left'} >Сума надміру сплаченого податку, що підлягає зарахуванню в
                            рахунок майбутніх платежів або поверненню платнику згідно з підпунктом 177.5.3 пункту 177.5
                            статті 177 розділу IV ПКУ (від'ємне значення (рядок 1.1 ‐ рядок 1.3)) (значення вказується
                            без знака "‐")
                        </td>
                        <td>
                            <div className="item">{this.cell('R014G3')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td>1.5</td>
                        <td className={'text_left'} >Сума податку на доходи фізичних осіб, що підлягає сплаті до
                            бюджету самостійно платником податку за результатами звітного (податкового) року (рядок 1.1
                            - рядок 1.2) (сума переноситься до графи 6 рядка 10.7 декларації)
                        </td>
                        <td>
                            <div className="item">{this.cell('R015G3')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td>2</td>
                        <td className={'text_left'}><b>Розрахунки з бюджетом з військового збору:</b></td>
                        <td>x</td>
                    </tr>
                    <tr >
                        <td>2.1</td>
                        <td className={'text_left'}>Розрахована сума податкових зобовязань з податку на доходи фізичних осіб за
                            результатами звітного (податкового) року, що підлягає сплаті до бюджету (додатне значення
                            графи 9 рядка "УСЬОГО" розділу І × на ставку збору (1,5 %))
                        </td>
                        <td>
                            <div className="item">{this.cell('R021G3')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td>2.2</td>
                        <td className={'text_left'}>Сума військового збору самостійно нарахована платником податку протягом
                            звітного (податкового) року за результатами останього базового (звітного) періоду, у т.ч. у
                            разі припинення підприємницької діяльності фізичної особи - підприємця протягом звітного
                            (податкового) року <sup>2</sup></td>
                        <td>
                            <div className="item">{this.cell('R022G3')}
                            </div>
                        </td>
                    </tr>
                    <tr >
                        <td>2.3</td>
                        <td className={'text_left'} >Сума військового збору, що підлягає сплаті до бюджету
                            самостійно платником податку за результатами звітного (податкового) року (рядок 2.1 - рядок
                            2.2) (сума переноситься до графи 7 рядка 10.7 декларації)
                        </td>
                        <td>
                            <div className="item">{this.cell('R023G3')}</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width="100%"  className="border_1 main_wrap text_center table_2_4" >
                    <tbody>
                    <tr>
                        <td width="100%" colSpan="9"><b>ІV. ДОХОДИ ВІД ПРОВАДЖЕННЯ НЕЗАЛЕЖНОЇ ПРОФЕСІЙНОЇ ДІЯЛЬНОСТІ</b>
                        </td>
                    </tr>
                    <tr>
                        <td width="5%" rowSpan="2">№<br />з/п</td>
                        <td width="8%" rowSpan="2">Категорія незалеж-<br />ної про-<br />фесійної діяль-<br />ності*</td>
                        <td width="30%" rowSpan="2">Назва виду економічної діяльності, за здійснення якого одержано
                            дохід
                        </td>
                        <td width="8%" rowSpan="2">Код виду<br />еконо-<br />мічної<br />діяль-<br />ності згідно з КВЕД</td>
                        <td width="9%" rowSpan="2">Сума<br />одержа-<br />ного<br />доходу<br />(грн, коп.)</td>
                        <td width="31%" colSpan="3">Вартість документально підтверджених витрат,
                            необхідних для провадження незалежної професійної діяльності (грн, коп.), у тому
                            числі</td>
                        <td width="9%" rowSpan="2">Сума чистого оподатко-<br />вуваного доходу (графа 5 ‐ графа 6 ‐ графа
                            7 ‐ графа 8)<br />(грн, коп.)</td>
                    </tr>
                    <tr>
                        <td width="13%">вартість придбаних товарно-матеріаль-<br />них цінностей для
                            використання при провадженні незалежної професійної діяльності</td>
                        <td width="9%">витрати на оплату праці та нарахування на заробітну плату</td>
                        <td width="9%">інші витрати, включаючи вартість виконаних робіт, наданих
                            послуг</td>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                        <td>4</td>
                        <td>5</td>
                        <td>6</td>
                        <td>7</td>
                        <td>8</td>
                        <td>9</td>
                    </tr>

                    {Array.isArray(this.getValue('T4RXXXXG2')) && this.getValue('T4RXXXXG2').map((val, idx)=>{
                        return (
                            <tr key={idx}>
                                <td className={'text_center'}>
                                    {idx+1}
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(val)}</div>
                                </td>
                                <td>
                                    <div className="item">{this.pureCell(this.getValue('T4RXXXXG3S')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T4RXXXXG4S')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T4RXXXXG5')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T4RXXXXG6')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T4RXXXXG7')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T4RXXXXG8')[idx])}</div>
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.pureCell(this.getValue('T4RXXXXG9')[idx])}</div>
                                </td>

                            </tr>
                        )
                    })}



                    <tr >
                        <td colSpan="4" ><b>УСЬОГО</b></td>
                        <td>
                            <div className="item">{this.cell('R04G5')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R04G6')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R04G7')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R04G8')}</div>
                        </td>
                        <td>
                            <div className="item">{this.cell('R04G9')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td colSpan="9" className={'text_left'} >* Категорія
                            незалежної професійної діяльності: 1 - приватний нотаріус; 2 - адвокат; 3 - арбітражний
                            керуючий (розпорядник майна, керуючий санацією, ліквідатор); 4 - аудитор; 5 - бухгалтер; 6 -
                            оцінщик; 7 - інженер чи архітектор; 8 - особа, зайнята релігійною (місіонерською)
                            діяльністю; 9 - науковий, літературний, артистичний, художній, освітній або викладацькій
                            діяч; 10 - лікар; 11 - інша подібна діяльність.</td>
                    </tr>
                    </tbody>
                </table>
                <table width="100%"  className="border_1 main_wrap text_center">
                    <tbody>
                    <tr >
                        <td width="5%">Код<br />рядка</td>
                        <td width="86%" ><b>V. РОЗРАХУНОК ПОДАТКОВИХ ЗОБОВ'ЯЗАНЬ</b></td>
                        <td width="9%">Сума<br />(грн, коп.)</td>
                    </tr>
                    <tr >
                        <td>3</td>
                        <td className={'text_left'}><b>Розрахунки з бюджетом з податку на доходи фізичних осіб:</b></td>
                        <td>x</td>
                    </tr>
                    <tr >
                        <td>3.1</td>
                        <td className={'text_left'}>Сума податку на доходи фізичних осіб, що підлягає сплаті до бюджету самостійно
                            платником податку за результатами звітного (податкового) року <i>(додатне значення графи 9
                                рядка "УСЬОГО" розділу ІV × на ставку податку (18 %)) (сума переноситься до графи 6
                                рядка 10.8 декларації)</i></td>
                        <td>
                            <div className="item">{this.cell('R031G3')}</div>
                        </td>
                    </tr>
                    <tr >
                        <td>4</td>
                        <td className={'text_left'}><b>Розрахунки з бюджетом з військового збору:</b></td>
                        <td>x</td>
                    </tr>
                    <tr >
                        <td>4.1</td>
                        <td className={'text_left'}>Сума військового збору, що підлягає сплаті до бюджету самостійно платником
                            податку за результатами звітного (податкового) року <i>(додатне значення графи 9 рядка
                                "УСЬОГО" розділу ІV × на ставку збору (1,5 %)) (сума переноситься до графи 7 рядка 10.8
                                декларації)</i></td>
                        <td>
                            <div className="item">{this.cell('R041G3')}</div>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <br /><br />
                    <table width="100%" className="main_wrap table_border_none" >
                        <tbody>
                        <tr>
                            <td width="100%"><sup>1</sup> Заповнюється у разі подання декларації
                                фізичною особою – підприємцем за результатами звітного кварталу, в якому розпочата
                                господарська діяльність або відбувся перехід на загальну систему оподаткування.
                            </td>
                        </tr>
                        <tr>
                            <td width="100%"><sup>2</sup> Заповнюється для фізичних осіб - підприємців,
                                які припинили підприємницьку діяльність протягом звітного (податкового) року та подали
                                декларарацію відповідно до абзацу другого пункту 177.11 статті 177 ПКУ.</td>
                        </tr>
                        </tbody>
                    </table>

                    <br /><br />
                        <table width="100%" className="main_wrap text_center table_border_none" >
                            <tbody>
                            <tr>
                                <td width="27%" className={'text_left'}><b>Фізична особа – платник податку</b></td>
                                <td width="73%" colSpan="4"/>
                            </tr>
                            <tr>
                                <td width="27%" className={'text_left'}><b>або уповноважена особа</b></td>
                                <td width="2%"/>
                                <td width="27%" className="border_bottom"/>
                                <td width="2%"/>
                                <td width="42%" className="border_bottom">
                                    <div className="item">{this.cell('HBOS')}</div>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td />
                                <td >(підпис)</td>
                                <td/>
                                <td>(ініціали та прізвище)</td>
                            </tr>
                            </tbody>
                        </table>


            </div>
        )
    }

    cell = (key, label = false, className = '') => {
        let val = this.getValue(key);

        return this.buildCell(val, label, className);
    };

    pureCell = (value, label = false) => {
        let val = this.prepareValue(value);
        return this.buildCell(val, label);
    };

    buildCell = (val, label, extClassName = '') => {
        let className = 'inner-cell '+extClassName;
        if (val === '__empty__'){
            val = <span>&nbsp;&nbsp;</span>;
            className = 'inner-cell-empty';
        }

        return (
            <div className="grid-x ">
                <div className={'cell '+className}>{val}</div>
                {label && <div className={'cell f-10 inner-cell-label'}>{label}</div>}
            </div>
        )
    };

    prepareValue(val){
        if(typeof(val) === "boolean"){
            val = val===true?'X':'__empty__'
        } else if (val === null || (typeof(val) === "string" && val.trim() === '')){
            val = '__empty__';
        }
        return val;
    }

    getValue = (key) => {
        let val = '__empty__';

        if (this.getUserData().hasOwnProperty(key)){
            val = this.getUserData()[key];
            val = this.prepareValue(val);
        }

        return val;
    };

    getUserData = ()=>{
        return this.userData;
    };


}