import { InvestP } from '../../../data/declaration/sub-sections/investP';
import { GET_INVESTP_DATA } from '../../actions/declaration_action';

const initialState = {
	questions: { ...InvestP },
	subId: null,
	data: {}
};

const investp = (state = initialState, action) => {
	switch (action.type) {
		case GET_INVESTP_DATA:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default investp;
