import React, { Component } from 'react';

import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';

import './styles.scss';

class Panel extends Component {
	state = {
		expanded: false
	};
	render() {
		const { kb } = this.props;

		return (
			<div className="panel">
				<ExpansionPanel
					className="border-none border-bottom no-radius"
					onChange={(e, expanded) => this.setState({ expanded })}
				>
					<ExpansionPanelSummary className="wrap-in-panel">
						<h3 className="panel_title pr-0">
							<span className="panel_icon">{this.state.expanded ? '— ' : '+ '}</span>
							<div className="first-letter-uppercase">{kb.name}</div>
						</h3>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<div className="panel_content">{kb.description}</div>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</div>
		);
	}
}

export default Panel;
