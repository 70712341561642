import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { load } from '../../../../utils/localStorage';

import './styles.scss';

const firstLevelQuestions = [];
// const R0113G3 = [];
// const R0109G3 = [];

const initialAnswers = {
	EntreprI1: '',
	EntreprI2: '',
	EntreprI3: '',
	EntreprI4: '',
	EntreprI5: '',
	EntreprI5A: '',
	EntreprI5B: '',
	EntreprI5C: '',
	EntreprI6: '',
	EntreprI6A_g: null,
	EntreprI6A: '',
	EntreprI6B: '',
	EntreprI6C: '',
	EntreprI6D: '',
	EntreprI6E: '',
	EntreprI7: ''
};
const initialData = {
	R0109G3: {
		a: {},
		q: {}
	},
	R0113G3: {
		a: {},
		q: {}
	},
	R021G3: {
		a: {},
		q: {}
	}
};

const EI_TA = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					// if (R0109G3.includes(id)) {
					// 	newData.R0109G3.a[id] = value;
					// }
					// if (R0113G3.includes(id)) {
					// 	newData.R0113G3.a[id] = value;
					// }
					// if (name === 'tax') {
					// 	newData.R021G3.a[id] = value;
					// }
					// if (name === 'revenue') {
					// 	newData.R0106G3.a.IP2C[0][name] = value;
					// }

					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;
					// const children =
					// 	labels[0].attributes['data-sibchildren'] && labels[0].attributes['data-sibchildren'].value;

					// if (children) {
					// 	let childs = children.split(',');
					// 	childs.forEach(item => {
					// 		newAnswers[item] = '';
					// 		if (R0109G3.includes(item)) {
					// 			newData.R0109G3['a'][item] = 0; //! Check why this value take data
					// 		}
					// 		if (R0113G3.includes(item)) {
					// 			newData.R0113G3['a'][item] = 0; //! Check why this value take data
					// 		}
					// 	});
					// }

					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];
					break;

				default:
					break;
			}
			// save('enterprI', { answers: newAnswers, data: newData });
			// props.getIpaData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeAutocomplete = useCallback(
		value => {
			// let newData = { ...data };
			let newAnswers = { ...answers };
			let separateAnswer = value.split('||');

			newAnswers.EntreprI2 = separateAnswer[0];
			newAnswers.EntreprI3 = separateAnswer[1];

			// newData.R0106G3.a.IP2C[0][dataset.name] = target.value;
			// setData(newData);
			setAnswers(newAnswers);
			// props.getIpaData(newData);
			// save('enterprI', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			// let newData = { ...data };
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;
			// newData.R0106G3.a.IP2C[0][dataset.name] = target.value;
			// setData(newData);
			setAnswers({ ...answers, ...newAnswers });
			// props.getIpaData(newData);
			// save('enterprI', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title || firstLevelQuestions.includes(item.id)) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeAutocomplete={handleChangeAutocomplete}
						changeSelect={handleChangeSelect}
					/>
				);
			}
			if (answers.IP1AA_g === item.parent) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeAutocomplete={handleChangeAutocomplete}
						changeSelect={handleChangeSelect}
					/>
				);
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('enterprI')) {
			const { data, answers } = load('enterprI');
			setAnswers(answers);
			setData(data);
			props.getIpaData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getIpaData(data);
	return (
		<div className="IP-wrap">
			{renderQuestion}
			<div className="btn-IP-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default EI_TA;
