import {
	B2BA,
	B2BB,
	B2D,
	B4AA,
	B5AA,
	B5AB,
	B5B,
	B5CA,
	B5CB,
	B5D,
	B5FA,
	B5G,
	B5HA,
	B5HB,
	// B5IA,
	// B5IB,
	B6AA,
	B6AB,
	B6B,
	B7A,
	B7B,
	B7C,
	B8A,
	B10A,
	B10AA,
	B10AB,
	B10AC,
	B10AD,
	B10AE,
	B10AF,
	B10AG,
	B10AH,
	B10AI,
	B10AJ,
	B10AL,
	B10AМ,
	B10AN,
	B11A,
	B12A,
	B12B,
	B12C,
	B12D,
	B12EA,
	B12EB,
	B12F,
	B12G,
	B12H
} from '../_questions_OLD';

import {
	W,
	ID,
	IP,
	LEASE,
	S,
	INT,
	D,
	R,
	// ALIM,
	SALEPRO,
	INVESTP,
	ADDB,
	WP,
	DISC,
	ENTREPRIB2BA,
	ENTREPRIB2BB,
	IPA,
	OFI,
	OTI,
	C,
	ONTI
} from './_decl_subsections_id';

import { common, property } from './sub-sections';
import Penalty from '../../pages/declaration/subsections/Penalty';

export const declarationSubSections = [
	{ ...common },
	{ ...property },
	{
		id: W,
		srsName: 'wages',
		title: "Доходи та відшкодування, пов'язані з трудовими відносинами",
		quiz_answers: [B5AA, B5AB, B5B, B5CA, B5CB]
	},
	{
		id: ENTREPRIB2BA,
		srsName: 'entrepreneurial-income-B2BA',
		title: 'Підприємницькі доходи',
		quiz_answers: [B2BA]
	},
	{
		id: ENTREPRIB2BB,
		srsName: 'entrepreneurial-income-B2BB',
		title: 'Підприємницькі доходи (у разі припинення діяльності)',
		quiz_answers: [B2BB]
	},
	{
		id: IPA,
		srsName: 'income-from-independent-professional-activities',
		title: 'Дохіди від незалежної професійної діяльності',
		quiz_answers: [B2D]
	},
	{
		id: SALEPRO,
		srsName: 'sale-of-movable-immovable-property-exchange-operations',
		title: 'Доходи від продажу/обміну',
		quiz_answers: [B6AA, B6AB]
	},
	{
		id: ID,
		srsName: 'income-from-inheritance-and-donation',
		title: 'Спадщина',
		quiz_answers: [B6B]
	},
	{
		id: LEASE,
		srsName: 'lease',
		title: 'Доходи від оренди, лізингу, найму',
		quiz_answers: [B4AA]
	},
	{
		id: INT,
		srsName: 'interest',
		title: 'Проценти',
		quiz_answers: [B5FA]
	},
	{
		id: D,
		srsName: 'dividends',
		title: 'Дивіденди',
		quiz_answers: [B5G]
	},
	{
		id: R,
		srsName: 'royalty',
		title: 'Роялті',
		quiz_answers: [B5HA, B5HB]
	},
	{
		id: INVESTP,
		srsName: 'investment-profit',
		title: 'Інвестиційний прибуток',
		quiz_answers: [B7A, B7B, B7C]
	},
	{
		id: IP,
		srsName: 'insurance-payments-pensions',
		title: 'Страхові виплати',
		quiz_answers: [B8A]
	},
	{
		id: WP,
		srsName: 'winnings-and-prizes',
		title: 'Виграші та призи',
		quiz_answers: [B10AC]
	},
	{
		id: ADDB,
		srsName: 'additional-benefit',
		title: 'Додаткове благо',
		quiz_answers: [B10A]
	},
	// {
	// 	id: ALIM,
	// 	srsName: 'alimony',
	// 	title: 'Аліменти',
	// 	quiz_answers: [B5IA, B5IB]
	// },
	{
		id: S,
		srsName: 'scholarships',
		title: 'Стипендії та державні премії',
		quiz_answers: [B5D]
	},
	{
		id: OFI,
		srsName: 'other-foreign-incomes',
		title: 'Інші іноземні доходи',
		quiz_answers: [B11A]
	},
	{
		id: C,
		srsName: 'charity',
		title: 'Доходи у вигляді допомоги',
		quiz_answers: [B10AH]
	},
	{
		id: OTI,
		srsName: 'other-taxable-income',
		title: 'Інші оподатковувані доходи',
		quiz_answers: [B10AA, B10AB, B10AD, B10AE, B10AF, B10AG, B10AI, B10AJ, B10AL, B10AМ, B10AN]
	},
	{
		id: ONTI,
		srsName: 'other-non-taxable-income',
		title: 'Інші неоподатковувані доходи',
		quiz_answers: [B10AN]
	},
	{
		id: DISC,
		srsName: 'tax-discounts',
		title: 'Податкові знижки',
		quiz_answers: [B12A, B12B, B12C, B12D, B12EA, B12EB, B12F, B12G, B12H]
	},
	{
		id: Penalty,
		srsName: 'penalty',
		title: 'Штрафи'
	}
];

// export const sectionsNav = [
// 	{
// 		id: 'B',
// 		name: 'quiz',
// 		title: 'Анкета',
// 		link: '/documents/quiz'
// 	},
// 	{
// 		id: 'docs',
// 		name: 'docs-list',
// 		title: 'Перелік документів',
// 		link: '/documents/docs-list'
// 	},
// 	{
// 		id: 'decl',
// 		name: 'declaration',
// 		title: 'Декларація',
// 		link: '/documents/declaration',
// 		has_sub_sections: true,
// 		sub_sections: [...declarationSubSections]
// 	}
// ];
