import * as tableCells from './T4RXXXX';

export const R04G5 = function(formValues) {
	return tableCells.T4RXXXXG5(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R04G6 = function(formValues) {
	return tableCells.T4RXXXXG6(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R04G7 = function(formValues) {
	return tableCells.T4RXXXXG7(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R04G8 = function(formValues) {
	return tableCells.T4RXXXXG8(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R04G9 = function(formValues) {
	return tableCells.T4RXXXXG9(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

//Сума податку на доходи фізичних осіб, що підлягає сплаті до бюджету самостійно платником податку за
//результатами звітного (податкового) року (додатне значення графи 9 рядка "УСЬОГО" розділу ІІІ× на ставку
//податку (18 %)) (сума переноситься до графи 6 рядка 10.8 декларації)
export const R031G3 = function(formValues) {
	let result = R04G9(formValues);

	return result > 0 ? (result / 100) * 18 : 0;
};

//Сума військового збору, що підлягає сплаті до бюджету самостійно платником податку за результатами звітного
//(податкового) року (додатне значення графи 9 рядка "УСЬОГО" розділу ІІІ× на ставку збору
//(1,5 %)) (сума переноситься до графи 7 рядка 10.8 декларації)
export const R041G3 = function(formValues) {
	let result = R04G9(formValues);

	return result > 0 ? (result / 100) * 1.5 : 0;
};
