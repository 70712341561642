import { connect } from 'react-redux';

import ID from '../subsections/ID';
import { getIdData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.ID.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getIdData
};

const IDContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ID);

export default IDContainer;
