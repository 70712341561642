import React, { Fragment, useState, useEffect, useMemo } from 'react';

import QuestionBlock from './components/QuestionBlock';
import NextButton from '../../components/NextButton';
import AddRemoveButton from '../../components/ButtonAddRemove';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const initialAnswers = {
	ALIM1A: [''],
	ALIM2A: [''],
	ALIM2B: [''],
	ALIM2C: [''],
	ALIM2D: [''],
	ALIM2E: ['']
};
const initialData = {
	R0106G3: {
		a: {
			ALIM2: [
				{
					country_name: '',
					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0,
					tax: 0
				}
			]
		},
		q: { ALIM2: null }
	},
	R0113G3: {
		a: { ALIM1A: 0 },
		q: { ALIM1A: null }
	},
	R021G3: {
		a: { ALIM2E: 0 },
		q: { ALIM2E: null }
	}
};

const Alim = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const getAnswers = index => answer => {
		const newAnswers = { ...answers };
		const newData = { ...data };
		const keys = Object.keys(answer);

		keys.map(key => {
			newAnswers[key][index] = answer[key];

			if (key === 'ALIM1A') {
				newData.R0113G3.a.ALIM1A = answers[key].reduce((acc, cur) => {
					acc += parseFloat(cur);
					return acc;
				}, 0);
			}

			if (key === 'ALIM2E') {
				newData.R021G3.a.ALIM2E = answers[key].reduce((acc, cur) => {
					acc += parseFloat(cur);
					return acc;
				}, 0);
			}
			if (key === 'ALIM2A') {
				newAnswers[key].forEach((val, i) => {
					if (!newData.R0106G3.a.ALIM2[i]) newData.R0106G3.a.ALIM2[i] = { ...initialData.R0106G3.a.ALIM2[0] };
					newData.R0106G3.a.ALIM2[i].country_name = val;
				});
			}
			if (key === 'ALIM2B') {
				newAnswers[key].forEach((val, i) => {
					if (!newData.R0106G3.a.ALIM2[i]) newData.R0106G3.a.ALIM2[i] = { ...initialData.R0106G3.a.ALIM2[0] };
					newData.R0106G3.a.ALIM2[i].currency_name = val;
				});
			}
			if (key === 'ALIM2C') {
				newAnswers[key].forEach((val, i) => {
					if (!newData.R0106G3.a.ALIM2[i]) newData.R0106G3.a.ALIM2[i] = { ...initialData.R0106G3.a.ALIM2[0] };
					newData.R0106G3.a.ALIM2[i].currency_date = val;
				});
			}
			if (key === 'ALIM2D') {
				newAnswers[key].forEach((val, i) => {
					if (!newData.R0106G3.a.ALIM2[i]) newData.R0106G3.a.ALIM2[i] = { ...initialData.R0106G3.a.ALIM2[0] };
					newData.R0106G3.a.ALIM2[i].revenue = val;
				});
			}
			return null;
		});

		setAnswers(newAnswers);
		setData(newData);
		props.getAlimData(newData);
		save('alim', { answers: newAnswers, data: newData });
	};

	const addBlock = state => {
		let newAnswers = { ...answers };
		const keys = Object.keys(state);
		keys.map(key => {
			newAnswers[key].push('');
			return key;
		});
		setAnswers(newAnswers);
		save('alim', { answers: newAnswers, data });
	};

	const removeBlock = index => state => {
		let newAnswers = { ...answers };
		const keys = Object.keys(state);
		keys.map(key => {
			newAnswers[key].splice(index, 1);
			return key;
		});
		setAnswers(newAnswers);
		save('alim', { answers: newAnswers, data });
	};

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((question, i) => {
			if (question.id === 'ALIM1') {
				return (
					<Fragment key={i}>
						{answers.ALIM1A.map((item, i) => {
							return (
								<QuestionBlock
									key={i}
									index={i}
									question={question}
									state={{ ALIM1A: answers.ALIM1A[i] }}
									getAnswers={getAnswers(i)}
									removeBlock={removeBlock(i)}
								/>
							);
						})}
						<AddRemoveButton add addBlock={() => addBlock({ ALIM1A: answers.ALIM1A[i] })} />
					</Fragment>
				);
			}

			if (question.id === 'ALIM2') {
				return (
					<Fragment key={i}>
						{answers.ALIM2A.map((item, i) => {
							return (
								<QuestionBlock
									key={i}
									index={i}
									question={question}
									state={{
										ALIM2A: answers.ALIM2A[i],
										ALIM2B: answers.ALIM2B[i],
										ALIM2C: answers.ALIM2C[i],
										ALIM2D: answers.ALIM2D[i],
										ALIM2E: answers.ALIM2E[i]
									}}
									getAnswers={getAnswers(i)}
									removeBlock={removeBlock(i)}
								/>
							);
						})}
						<AddRemoveButton
							add
							addBlock={() =>
								addBlock({
									ALIM2A: answers.ALIM2A[i],
									ALIM2B: answers.ALIM2B[i],
									ALIM2C: answers.ALIM2C[i],
									ALIM2D: answers.ALIM2D[i],
									ALIM2E: answers.ALIM2E[i]
								})
							}
						/>
					</Fragment>
				);
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('alim')) {
			const { data, answers } = load('alim');
			setAnswers(answers);
			setData(data);
			props.getAlimData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getAlimData(data);

	return (
		<div className="Alim-wrap">
			{renderQuestion}
			<div className="btn-next-Alim-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default Alim;
