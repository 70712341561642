import { R014G3 } from '../_xmlConstId';
import { Disc1, Disc2, Disc3, Disc4, Disc4B, Disc5, Disc6, Disc7, Disc8 } from '../_declaretion_answer_id';
import { B1A, B12A, B12B, B12C, B12EA, B12EB, B12F, B12G, B12H, B12I } from '../../_questions_OLD';
import { INPUT } from '../../elementTypes';

export const Disc = [
	[
		{
			id: Disc1,
			title: 'Вкажіть суму процентів за користування іпотечним житловим кредитом, сплачену у звітному році.',
			xmlId: R014G3,
			name: Disc1,
			element: INPUT,
			placeholder: 'Вкажіть площу об’єкта житлової іпотеки',
			quizOpen: [B12A, B1A],
			answers: [
				{
					id: 'Disc1',
					title:
						'Вкажіть суму процентів за користування іпотечним житловим кредитом, сплачену у звітному році.',
					xmlId: R014G3,
					name: 'expenses',
					element: INPUT,
					placeholder: 'Вкажіть суму процентів',
					quizOpen: [B12A, B1A]
				},
				{
					id: 'Disc1',
					// title: 'Вкажіть суму процентів за користування іпотечним житловим кредитом, сплачену у звітному році.',
					xmlId: R014G3,
					name: 'apartment_area',
					element: INPUT,
					placeholder: 'Площа об’єкта житлової іпотеки',
					quizOpen: [B12A, B1A]
				}
			]
		}
	],
	[
		{
			id: Disc2,
			title:
				'Вкажіть суму коштів, яку Ви передали у вигляді пожертвувань або благодійних внесків неприбутковим організаціям у звітному році',
			xmlId: R014G3,
			name: 'expenses',
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			quizOpen: [B12B, B1A]
		}
	],
	[
		{
			id: Disc3,
			title: 'Вкажіть суму коштів, яку Ви сплатили на користь вітчизняних закладів освіти за звітний рік',
			xmlId: R014G3,
			name: 'expenses',
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			quizOpen: [B12C, B1A]
		}
	],
	[
		{
			id: Disc4,
			title:
				'Вкажіть суму страхових платежів згідно з Вашими договорами довгострокового страхування життя та/або недержавного пенсійного страхування або внесків на Ваш банківський пенсійний депозитний рахунок упродовж звітного року',
			xmlId: R014G3,
			name: 'expenses',
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			quizOpen: [B12EA, B1A]
		}
	],
	[
		{
			id: Disc4B,
			title:
				'Вкажіть кількість членів сім’ї першого ступеня споріднення, на користь яких Ви здійснювали страхові платежі або внески згідно з договорами довгострокового страхування життя та/або недержавного пенсійного страхування або внески на банківський пенсійний депозитний рахунок.',
			xmlId: R014G3,
			name: Disc4B,
			element: INPUT,
			placeholder: 'Вкажіть суму таких страхових платежів або внесків',
			quizOpen: [B12EB, B1A],
			answers: [
				{
					id: 'Disc4B',
					title:
						'Вкажіть кількість членів сім’ї першого ступеня споріднення, на користь яких Ви здійснювали страхові платежі або внески згідно з договорами довгострокового страхування життя та/або недержавного пенсійного страхування або внески на банківський пенсійний депозитний рахунок.',
					xmlId: R014G3,
					name: 'family',
					element: INPUT,
					placeholder: 'Кількість членів сім’ї',
					quizOpen: [B12EB, B1A]
				},
				{
					id: 'Disc4B',
					title: 'Вкажіть суму таких страхових платежів або внесків.',
					xmlId: R014G3,
					name: 'expenses',
					element: INPUT,
					placeholder: 'Сума платежів або внесків',
					quizOpen: [B12EB, B1A]
				}
			]
		}
	],
	[
		{
			id: Disc5,
			title:
				'Вкажіть суму коштів, яку Ви витратили на оплату вартості допоміжних репродуктивних технологій або вартості державних послуг, пов’язаних з усиновленням дитини включаючи сплату державного мита упродовж звітного року',
			xmlId: R014G3,
			name: 'expenses',
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			quizOpen: [B12F, B1A]
		}
	],
	[
		{
			id: Disc6,
			title:
				'Вкажіть суму коштів, яку Ви витратили на переобладнання Вашого транспортного засобу упродовж звітного року',
			xmlId: R014G3,
			name: 'expenses',
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			quizOpen: [B12G, B1A]
		}
	],
	[
		{
			id: Disc7,
			title:
				'Вкажіть суму коштів, яку Ви витратили на будівництво (придбання) доступного житла, у тому числі на погашення пільгового іпотечного житлового кредиту, наданого на придбання такого доступного житла та процентів за ним упродовж звітного року',
			xmlId: R014G3,
			name: 'expenses',
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			quizOpen: [B12H, B1A]
		}
	],
	[
		{
			id: Disc8,
			title: 'Вкажіть суму орендної плати за договором оренди житла (квартири, будинку) упродовж звітного року',
			xmlId: R014G3,
			name: 'expenses',
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			quizOpen: [B12I, B1A]
		}
	]
];
