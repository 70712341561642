import { IPA1, IPA2, IPA3, IPA4, IPA5, IPA6, IPA7 } from '../_declaretion_answer_id';
// import { R0108G3 } from '../_xmlConstId';
// import {  } from '../../_questions'; //! LOOK
import { INPUT, SELECT } from '../../elementTypes';

import kved from '../../kved.json';

export const IPA = [
	{
		id: IPA1,
		title: 'Оберіть категорію незалежної професійної діяльності',
		name: IPA1,
		placeholder: 'Оберіть професійну діяльність',
		element: SELECT,
		options: [
			{
				value: 1,
				name: 'приватний нотаріус'
			},
			{
				value: 2,
				name: 'адвокат'
			},
			{
				value: 3,
				name: 'арбітражний керуючий (розпорядник майна, керуючий санацією, ліквідатор)'
			},
			{
				value: 4,
				name: 'аудитор'
			},
			{
				value: 5,
				name: 'бухгалтер'
			},
			{
				value: 6,
				name: 'оцінювач'
			},
			{
				value: 7,
				name: 'інженер чи архітектор'
			},
			{
				value: 8,
				name: 'особа, зайнята релігійною (місіонерською) діяльністю'
			},
			{
				value: 9,
				name: 'науковий, літературний, артистичний, художній, освітній або викладацький діяч'
			},
			{
				value: 10,
				name: ' лікар'
			},
			{
				value: 11,
				name: 'інша подібна діяльність'
			}
		]
	},
	{
		id: IPA2,
		title: 'Вкажіть назви видів економічної діяльності, за якими був отриманий дохід',
		name: IPA2,
		placeholder: 'Вкажіть вид економічної діяльності',
		element: 'autocomplete',
		options: kved
	},
	{
		id: IPA3,
		title: 'Вкажіть Код виду економічної діяльності згідно з КВЕД',
		name: IPA3,
		placeholder: 'Вкажіть код',
		element: INPUT,
		disabled: true
	},
	{
		id: IPA4,
		title: 'Вкажіть суму одержаного доходу, за окремими видами діяльності, згідно КВЕД',
		name: IPA4,
		placeholder: 'Введіть суму',
		// xmlId: , //! LOOK
		element: INPUT
	},
	{
		id: IPA5,
		title:
			'Вкажіть вартість придбаних товарно-матеріальних цінностей для використання при  провадженні  незалежної професійної діяльності, згідно Книги обліку доходів і витрат',
		name: IPA5,
		placeholder: 'Введіть суму',
		// xmlId: , //! LOOK
		element: INPUT
	},
	{
		id: IPA6,
		title:
			'Вкажіть витрати на оплату праці та нарахування на заробітну плату, згідно Книги обліку доходів і витрат',
		name: IPA6,
		placeholder: 'Введіть суму',
		// xmlId: , //! LOOK
		element: INPUT
	},
	{
		id: IPA7,
		title:
			'Вкажіть інші витрати, включаючи вартість виконаних робіт, наданих послуг, згідно Книги обліку доходів і витрат',
		name: IPA7,
		placeholder: 'Введіть суму',
		// xmlId: , //! LOOK
		element: INPUT
	}
];
