import { R0101G3, R0113G3, R0106G3, R021G3, R0109G3 } from '../_xmlConstId';
import {
	W1A,
	W1B,
	W1BA,
	W1BB,
	W1BC,
	W1BD,
	W1BE,
	W2A,
	W3A,
	W4A,
	W4AA,
	W4AAA,
	W4AAB,
	W5,
	W5A,
	W5B,
	W5C,
	W5D,
	W5E,
	W6,
	W6A,
	W6B,
	W6AA,
	W7,
	W7A,
	W7AA,
	W7B
} from '../_declaretion_answer_id';
import { B5AA, B5B, B5CB, B11A } from '../../_questions_OLD';
import { INPUT, RADIO, SELECT, DATE } from '../../elementTypes';

import countries from '../../../data/countries.json';
import currency from '../../../data/currencies.json';

export const wages = [
	[
		{
			id: W1A,
			title: 'Вкажіть обсяг заробітної плати, яку Ви отримали протягом звітного року в Україні',
			xmlId: R0101G3,
			name: W1A,
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			helpers: [
				'Підказка: дані (усього отримано доходу) з довідок про доходи фізичної особи, отриманих у  Роботодавців, у яких Ви працювали упродовж звітного року'
			],
			quizOpen: [B5AA]
		}
	],

	[
		{
			id: W1B,
			title:
				'Вкажіть суму доходів, яку Ви отримали протягом звітного року за кордоном, перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
			helpers: [
				"Підказка 1. Ви можете зменшити річне податкового зобов'язання на суму податків, сплачених за кордоном згідно з нормами міжнародних договорів про уникнення подвійного оподаткування.",
				'Підказка 2. У разі можливості неоднозначного трактування норм ПКУ рекомендуємо звернутися за індивідуальною податковою консультацією до контролюючого органу.',
				'Підказка 3. У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою).',
				'Підказка 4. У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою)'
			],
			quizOpen: [B11A],
			answers: [
				{
					parent: W1B,
					id: W1BA,
					title: 'Kраїнa',
					xmlId: R0106G3,
					name: 'country_name',
					element: SELECT,
					options: countries
				},
				{
					parent: W1B,
					id: W1BB,
					title: 'Валюта',
					xmlId: R0106G3,
					name: 'currency_name',
					element: SELECT,
					options: currency
				},
				{
					parent: W1B,
					id: W1BC,
					title: 'Дата',
					xmlId: R0106G3,
					name: 'currency_date',
					element: DATE
				},
				{
					parent: W1B,
					id: W1BD,
					title:
						'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
					element: INPUT,
					name: 'revenue',
					placeholder: 'Введіть суму доходу (грн., коп.)',
					xmlId: R0106G3
				},
				{
					parent: W1B,
					id: W1BE,
					title:
						'Вкажіть суму  сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
					element: INPUT,
					name: 'tax',
					placeholder: 'Введіть суму доходу (грн., коп.)',
					xmlId: R021G3
				}
			]
		}
	],
	[
		{
			id: W2A,
			title:
				'Вкажіть обсяг нецільової (разової) матеріальної допомоги упродовж звітного року, отриманої від Роботодавця в Україні',
			xmlId: R0113G3,
			xmlIds: [R0113G3, R0109G3],
			name: W2A,
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			helpers: [
				'Підказка: дані з довідок про доходи фізичної особи, отриманих у  Роботодавців, у яких Ви працювали упродовж звітного року'
			],
			quizOpen: [B5B]
		}
	],
	[
		{
			id: W3A,
			title:
				'Вкажіть обсяг коштів на відрядження або під звіт, які Ви не повернули у встановлені законодавством терміни протягом звітного року',
			xmlId: R0101G3,
			name: W3A,
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			quizOpen: B5B
		}
	],
	[
		{
			id: 'W3B',
			title:
				'Вкажіть обсяг коштів на відрядження, отриманих від Роботодавця на відшкодування добових та підтверджених документально витрат,  протягом звітного року',
			xmlId: R0113G3,
			name: 'W3B',
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			quizOpen: B5B
		}
	],
	[
		{
			id: W4A,
			title:
				'Вкажіть обсяг грошових виплат або винагород, які Ви отримали за надані Вами послуги або виконані роботи упродовж звітного року відповідно до умов цивільно-правового договору  в Україні',
			xmlId: R0101G3,
			name: W4A,
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			quizOpen: B5B
		},

		{
			id: W4AA,
			title: 'Вашим Замовником була фізична особа - СПД платник єдиного податку четвертої групи?',
			answers: [
				{
					id: W4AAA,
					title: 'Так',
					xmlId: R0101G3,
					element: RADIO,
					name: 'W4A_g'
				},
				{
					id: W4AAB,
					title: 'Ні',
					xmlId: R0101G3,
					element: RADIO,
					name: 'W4A_g'
				}
			]
		}
	],

	[
		{
			id: W5,
			title:
				'Вкажіть обсяг грошових виплат або винагород, які Ви отримали за надані Вами послуги або виконані роботи упродовж звітного року відповідно до умов цивільно-правового договору за кордоном, перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
			xmlId: R0106G3,
			helpers: [
				'дані з договорів цивільно-правового характеру, укладених з іноземними компаніями, та/або актів виконаних робіт або наданих послуг з іноземними компаніями',
				"Ви можете зменшити річне податкового зобов'язання на суму податків, сплачених за кордоном згідно з нормами міжнародних договорів про уникнення подвійного оподаткування.",
				'У разі можливості неоднозначного трактування норм ПКУ рекомендуємо звернутися за індивідуальною податковою консультацією до контролюючого органу',
				'У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою).'
			],
			quizOpen: B5CB,
			answers: [
				{
					parent: W5,
					id: W5A,
					title: 'Kраїнa',
					xmlId: R0106G3,
					name: 'country_name',
					element: SELECT,
					options: countries
				},
				{
					parent: W5,
					id: W5B,
					title: 'Валюта',
					xmlId: R0106G3,
					name: 'currency_name',
					element: SELECT,
					options: currency
				},
				{
					parent: W5,
					id: W5C,
					title: 'Дата',
					xmlId: R0106G3,
					name: 'currency_date',
					element: DATE
				},
				{
					parent: W5,
					id: W5D,
					title:
						'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
					element: INPUT,
					name: 'revenue',
					placeholder: 'Введіть суму доходу (грн., коп.)',
					xmlId: R0106G3
				},
				{
					parent: W5,
					id: W5E,
					title:
						'Вкажіть суму  сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
					element: INPUT,
					name: 'tax',
					placeholder: 'Введіть суму доходу (грн., коп.)',
					xmlId: R021G3
				}
			]
		}
	],
	[
		{
			id: W6,
			title:
				"На Вашу користь перераховувались суми інших внесків на обов'язкове страхування, ніж єдиний внесок на загальнообов'язкове державне соціальне страхування?",
			xmlId: R0113G3,
			quizOpen: B5B,
			answers: [
				{
					id: W6A,
					title: 'Так',
					name: 'W6_g',
					element: RADIO,
					xmlId: R0113G3
				},
				{
					id: W6B,
					title: 'Ні',
					name: 'W6_g',
					xmlId: R0113G3,
					element: RADIO
				}
			]
		},
		{
			parent: W6A,
			id: W6AA,
			title: 'Вкажіть суму внесків',
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			xmlId: R0113G3,
			name: W6AA
		}
	],

	[
		{
			id: W7,
			title:
				"На Вашу користь перераховувались єдині внески на загальнообов'язкове державне соціальне страхування платника податку, що вносяться за рахунок Роботодавця у розмірах, визначених законом?",
			xmlId: R0113G3,
			// name: 'W7',
			// element: RADIO,
			quizOpen: B5B,
			answers: [
				{
					id: W7A,
					title: 'Так',
					element: RADIO,
					xmlId: R0113G3,
					name: 'W7_g'
				},
				{
					id: W7B,
					title: 'Ні',
					element: RADIO,
					xmlId: R0113G3,
					name: 'W7_g'
				}
			]
		},
		{
			parent: W7A,
			id: W7AA,
			title: 'Вкажіть суму внесків',
			element: INPUT,
			placeholder: 'Введіть суму доходу (грн., коп.)',
			xmlId: R0113G3,
			name: W7AA
		}
	]
];
