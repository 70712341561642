import React from 'react';
import Question from './Question';

const QuestionBlock = ({ subsection, change, state, handleChangeDate, changeSelect }) => {
	const { answers } = state;

	return (
		<div>
			{subsection &&
				Object.values(subsection).map((item, i) => {
					// render 1 question
					if (i === 0) {
						return (
							<Question
								key={i}
								item={item}
								state={state}
								change={change}
								changeSelect={changeSelect}
								handleChangeDate={handleChangeDate}
							/>
						);
					}
					//render 1 question - 1 subquestion
					if (answers['ID1'] === item.parent) {
						return (
							<Question
								key={i}
								item={item}
								state={state}
								change={change}
								changeSelect={changeSelect}
								handleChangeDate={handleChangeDate}
							/>
						);
					}
					// render 1 question - 2 subquestion
					if (answers['ID1'] !== 'ID1A' && answers['ID1B'] === item.parent) {
						return (
							<Question
								key={i}
								item={item}
								state={state}
								change={change}
								changeSelect={changeSelect}
								handleChangeDate={handleChangeDate}
							/>
						);
					}

					// render 2 question - 1 sub
					if (answers['ID1'] === 'ID1A' && answers['ID2'] === item.parent) {
						return (
							<Question
								key={i}
								item={item}
								state={state}
								change={change}
								changeSelect={changeSelect}
								handleChangeDate={handleChangeDate}
							/>
						);
					}
					// то что не рендерим
					if (
						answers['ID1'] &&
						!['ID1BA', 'ID1BB', 'ID1B'].some(el => el === item.parent) && // не рендерим
						item.id !== 'ID2' && // не рендерим
						item.id !== 'ID2AA' && // не рендерим
						item.id !== 'ID4AA' && // не рендерим
						item.id !== 'ID1AA' // не рендерим
					) {
						return (
							<Question
								key={i}
								item={item}
								state={state}
								change={change}
								changeSelect={changeSelect}
								handleChangeDate={handleChangeDate}
							/>
						);
					}
					// рендер 4 вопроса - подвопрос
					if (answers['ID4'] === 'ID4A' && answers['ID4'] === item.parent) {
						return (
							<Question
								key={i}
								item={item}
								state={state}
								change={change}
								changeSelect={changeSelect}
								handleChangeDate={handleChangeDate}
							/>
						);
					}
					return null;
				})}
		</div>
	);
};

export default QuestionBlock;
