import {
	// isInt,
	isFloat,
	precisionRound
	// get_form_data_definition
} from '../index';

import pick from 'lodash/pick';
// import isObjectLike from 'lodash/isObjectLike';
// import isArray from 'lodash/isArray';

import moment from 'moment';

/**
 * Получение шаблона документа объекта декларации до ее вычисления
 * @param {*} doc_type - тип декларации
 */
export const get_doc_definition = doc_type => {
	let module = null;

	try {
		module = require(`../${doc_type}/doc_definition`);
	} catch (e) {
		console.error(e);
	}
	return module.doc_definition;
};

/**
 * Получение функции для расчета конкретной ячейки декларации
 * @param {*} doc_type тип документа декларации
 * @param {*} field_name имя ячейки декларации - подклюяение функции расчета
 */
export const fields_calculation_rules = (doc_type, field_name) => {
	let module = null;

	try {
		module = require(`../${doc_type}/calc_rules/index`);
	} catch (e) {
		console.error(e);
	}

	if (module) return module[field_name];
	return;
};

/**
 *
 * @param {*} doc_type - тип документа декларации
 * @param {*} doc_field - поле документа
 * @param {*} formData - дата объекта
 * @param {*} skipEmpty
 */
const get_doc_field_value_from_form_data = function(doc_type, doc_field, formData, skipEmpty = false) {
	let result = undefined;
	let calc_func = fields_calculation_rules(doc_type, doc_field);

	if (calc_func != null) {
		result = calc_func(formData);
	} else {
		result = formData[doc_field];
	}

	if (isFloat(result)) {
		result = precisionRound(result, 2);

		if (skipEmpty) {
			result = result !== 0 ? result : false;
		}
	}
	return result;
};

/**
 *
 * @param {*} doc_type тип декларации
 * @param {*} formData дата с ответами сабсекций
 * @param {*} skipEmpty
 */
export const form_data_to_doc_converter = function(doc_type, formData, skipEmpty = false) {
	let doc_definition = get_doc_definition(doc_type);

	if (!doc_definition) {
		return;
	}

	let doc = Object.keys(doc_definition.body).reduce((acc, doc_field) => {
		let value = get_doc_field_value_from_form_data(doc_type, doc_field, formData, skipEmpty);

		return Object.assign({}, acc, {
			[doc_field]: value
		});
	}, {});
	return doc;
};

export const prepare_doc_body = (doc_type, formData, skipEmpty = false) => {
	return form_data_to_doc_converter(doc_type, formData, skipEmpty);
};

const prepare_doc_header = (doc_type, formData, params) => {
	let doc_definition = get_doc_definition(doc_type);

	if (!doc_definition) {
		return;
	}

	let fixed = {
		...doc_definition.head.fixed
	};

	let dynamic = Object.keys(doc_definition.head.dynamic).reduce((acc, dynamic_header_field) => {
		let value = null;

		switch (dynamic_header_field) {
			case 'C_DOC_CNT':
				value = params && params.C_DOC_CNT != null ? params.C_DOC_CNT : 1;
				break;

			case 'C_DOC_STAN':
				// 1-отчётный документ, 2-новый отчётный документ ,3-уточняющий документ
				value = formData.HZ === true ? 1 : formData.HZN === true ? 2 : formData.HZU === true ? 3 : 4;

				break;

			case 'TIN':
				value = formData.HTIN;
				break;

			case 'C_REG':
				value = formData.C_REG;
				break;

			case 'C_RAJ':
				value = formData.C_RAJ;
				break;

			case 'C_STI_ORIG':
				// Код инспекции, в которую подаётся оригинал документа
				//value = [acc.C_REG.toString(), acc.C_RAJ.toString()].join('');
				value = formData.C_STI_ORIG;
				break;
			case 'HSTI':
				// Название инспекции, в которую подаётся оригинал документа
				value = formData.HSTI;
				break;

			case 'PERIOD_TYPE':
				// Код отчётного периода (1-месяц, 2-квартал, 3-полугодие, 4-девять мес., 5-год).

				if (formData.HZU === false) {
					value = formData.H1KV === true ? 2 : formData.HHY === true ? 3 : formData.H3KV === true ? 4 : 5;
				} else {
					value = formData.H1KVP === true ? 2 : formData.HHYP === true ? 3 : formData.H3KVP === true ? 4 : 5;
				}
				break;

			case 'PERIOD_MONTH':
				// Отчётным месяцем считается последний месяц в отчётном периоде (для месяцев - порядковый номер месяца,
				// для квартала - 3,6,9,12 месяц, полугодия - 6 и 12, для года - 12)

				switch (acc.PERIOD_TYPE) {
					case 2:
						value = 3;
						break;
					case 3:
						value = 6;
						break;
					case 4:
						value = 9;
						break;
					case 5:
						value = 12;
						break;
					default:
						value = 12;
				}
				break;

			case 'PERIOD_YEAR':
				// Отчётный год	Формат YYYY
				value = formData.HZU === false ? formData.HZY : formData.HZYP;
				break;

			case 'D_FILL':
				// Дата заполнения документ Формат ddmmyyyy
				value = formData.HFILL ? moment(formData.HFILL, 'DD/MM/YYYY') : moment(new Date());

				if (value.isValid()) {
					value = value.format('DDMMYYYY');
				} else {
					value = moment(new Date()).format('DDMMYYYY');
				}

				break;

			case 'C_DOC_TYPE':
				value = params && params.C_DOC_TYPE != null ? params.C_DOC_TYPE : 0;
				break;
			default:
				return null;
		}

		return Object.assign({}, acc, {
			[dynamic_header_field]: value
		});
	}, {});

	return {
		...fixed,
		...dynamic
	};
};

//? ===========================================================================
const prepare_linked_doc_header = (doc_type, formData, linked_doc_type, mainDocHeader) => {
	let doc_definition = get_doc_definition(doc_type);

	if (!doc_definition) {
		return;
	}

	let linked_doc_definition = doc_definition.linked_docs.filter(obj => obj.type === linked_doc_type).pop().ref;

	let fixed = {
		...linked_doc_definition.head.fixed
	};

	let dynamic = Object.keys(linked_doc_definition.head.dynamic).reduce((acc, dynamic_header_field) => {
		return Object.assign({}, acc, {
			[dynamic_header_field]: mainDocHeader[dynamic_header_field]
		});
	}, {});

	return {
		...fixed,
		...dynamic
	};
};

export const prepare_linked_doc_body = (doc_type, formData, linked_doc_type) => {
	return form_data_to_linked_doc_converter(doc_type, formData, linked_doc_type);
};

export const form_data_to_linked_doc_converter = function(doc_type, formData, linked_doc_type) {
	let doc_definition = get_doc_definition(doc_type);

	if (!doc_definition) {
		return;
	}

	let linked_doc_definition = doc_definition.linked_docs.filter(obj => obj.type === linked_doc_type).pop();

	let doc = Object.keys(linked_doc_definition.ref.body).reduce((acc, linked_doc_field) => {
		let value = get_linked_doc_field_value_from_form_data(doc_type, linked_doc_type, linked_doc_field, formData);
		return Object.assign({}, acc, {
			[linked_doc_field]: value
		});
	}, {});

	return doc;
};

const prepare_linked_doc_main_doc_ref = (doc_type, mainDocHeader) => {
	let doc_definition = get_doc_definition(doc_type);

	if (!doc_definition) {
		return;
	}

	mainDocHeader = pick(mainDocHeader, [
		...Object.keys(doc_definition.head.fixed),
		...['C_DOC_TYPE', 'C_DOC_CNT', 'C_DOC_STAN']
	]);

	return {
		...mainDocHeader,
		...doc_definition.meta
	};
};

const get_linked_doc_field_value_from_form_data = function(doc_type, linked_doc_type, linked_doc_field, formData) {
	let result = undefined;

	let module = null;
	try {
		module = require(`../F01002/linked_docs/${linked_doc_type}/fields/calc_rules/index`);
	} catch (e) {
		console.error(e);
	}

	if (module) {
		let calc_func = module[linked_doc_field];

		if (calc_func != null) {
			result = calc_func(formData);
		} else {
			result = formData[linked_doc_field] ? formData[linked_doc_field] : null;
		}
	} else {
		result = formData[linked_doc_field] ? formData[linked_doc_field] : null;
	}

	if (isFloat(result)) {
		result = precisionRound(result, 2);
		result = result !== 0 ? result : false;
	}

	return result;
};

/**
 * Функция конвертации в объект декларации для передачи в Налоговую
 * @param {*} doc_type - тип декларации
 * @param {*} formData - дата с ответами сабсекций
 * @param {*} params
 */

export const convertToDeclarationObject = (doc_type, formData, params) => {
	let doc_definition = get_doc_definition(doc_type);

	if (!doc_definition) return;

	let xml_doc_header = prepare_doc_header(doc_type, formData, params);
	let xml_doc_body = prepare_doc_body(doc_type, formData, true);

	/**
	 *  Получение додатков
	 */
	let linked_docs = doc_definition.linked_docs
		.map(obj => {
			let linked_doc_definition = obj.ref;
			let linked_doc_type = obj.type;

			let hd1_value;
			let hd2_value;
			let tmpFormData;

			switch (doc_type) {
				case 'F01001':
					hd1_value = formData['R0104G4'] + formData['R0104G5'] + formData['R0104G6'] + formData['R0104G7'];
					formData['HD1'] = hd1_value > 0;

					if (!formData['HD1'] && linked_doc_type === 'F01112') {
						return false;
					}

					if (linked_doc_type === 'F01114') {
						tmpFormData = prepare_linked_doc_body(doc_type, formData, linked_doc_type);
						hd2_value = tmpFormData['R01G9'] + tmpFormData['R022G5'] + tmpFormData['R04G9'];
						formData['HD2'] = hd2_value > 0;
						if (!formData['HD2']) {
							return false;
						}
					}

					break;
				case 'F01002':
					hd1_value = formData['R0104G4'] + formData['R0104G5'] + formData['R0104G6'] + formData['R0104G7'];
					formData['HD1'] = hd1_value > 0;
					formData['HD1'] = true; // Правил вручную для отображения - дальше удалить проверить работу formData['HD1'] = hd1_value > 0;

					if (!formData['HD1'] && linked_doc_type === 'F01212') {
						return false;
					}

					if (linked_doc_type === 'F01214') {
						tmpFormData = prepare_linked_doc_body(doc_type, formData, linked_doc_type);

						hd2_value = tmpFormData['R01G9'] + tmpFormData['R022G5'] + tmpFormData['R04G9'];
						formData['HD2'] = hd2_value > 0;
						formData['HD2'] = true; // Правил вручную для отображения - дальше удалить проверить работу formData['HD2'] = hd2_value > 0;

						if (!formData['HD2']) {
							return false;
						}
					}

					break;
				default:
					break;
			}

			let head = prepare_linked_doc_header(doc_type, formData, linked_doc_type, xml_doc_header);
			let body = prepare_linked_doc_body(doc_type, formData, linked_doc_type);

			let main_doc_ref = {
				DOC: prepare_linked_doc_main_doc_ref(doc_type, xml_doc_header)
			};

			return {
				DOC: {
					...linked_doc_definition.meta,
					...head,
					...body,
					LINKED_DOCS: [main_doc_ref]
				}
			};
		})
		.filter(linked_doc => linked_doc !== false);

	linked_docs = linked_docs.length > 0 ? linked_docs : false;

	return {
		...doc_definition.meta,
		...doc_definition.body,
		...xml_doc_header,
		...xml_doc_body,
		LINKED_DOCS: linked_docs
	};
};
