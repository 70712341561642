import React, { Fragment, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';

import Input from './FormFields/Input';
import Radio from './FormFields/Radio';
import Checkbox from './FormFields/Checkbox';
import Select from './FormFields/Select';
import DatePicker from '../../../../../components/DatePicker';
import RemoveButton from '../../../components/ButtonAddRemove';

import useForms from '../hooks/useForm';

import FormHelperText from '@material-ui/core/FormHelperText';

const Question = React.memo(({ item, answers, getAnswers, removeBlock }) => {
	const { _handleChange, _handleChangeDate, _handleChangeSelect, state } = useForms(answers, getAnswers);

	const renderAnswers = useCallback(
		answers => {
			return answers.map((answer, i) => {
				return (
					<Fragment key={i}>
						{renderFormFields(answer)}
						{answer.subsection && state.answers[answer.id] && renderFormFields(answer.subsection)}
					</Fragment>
				);
			});
		},
		[answers, state]
	);
	const renderFormFields = item => {
		switch (item.element) {
			case 'input':
				return <Input item={item} answer={state} change={_handleChange} />;

			case 'radio':
				return <Radio item={item} answer={state} change={_handleChange} />;

			case 'checkbox':
				return <Checkbox item={item} answer={state} change={_handleChange} />;

			case 'select':
				return <Select item={item} answer={state} change={_handleChangeSelect} />;

			case 'date':
				return <DatePicker item={item} answer={state} handleChangeDate={_handleChangeDate} />;

			default:
				break;
		}
	};

	return (
		<Paper className='paper Alim-blocks-wrap'>
			<div className="form-Alim-wrap">
				<h3 className="page-title page-title-Alim">{item.question_title && item.title}</h3>
				{item.element && renderFormFields(item)}
				{item.helpers && item.helpers.map((helper, i) => <FormHelperText key={i}>{helper}</FormHelperText>)}
				{item.answers && renderAnswers(item.answers)}
			</div>
			<RemoveButton delete removeBlock={() => removeBlock(answers)} />
		</Paper>
	);
});

export default Question;
