import { connect } from 'react-redux';
import { compose } from 'redux';
import hoc from '../../../../HOC/hoc';

import PreviewDeclaration from './PreviewDeclaration';

// import { getSubSectionDatas } from '../../../../store/reducers';
import { getDeclaration } from '../../../../store/actions/declaration_action';

const mapStateToProps = state => ({
	dataP: state.declaration.P.data,
	dataCommon: state.declaration.Common.data,
	dataQuiz: state.quiz.data,
	dataPenalty: state.declaration.Penalty.data,
	data: state.declaration.declaration
	// dataW: state.declaration.W.data,
	// data: getSubSectionDatas(state)
});

const mapDispatchToProps = { getDeclaration };

const PreviewDeclarationContainer = compose(
	hoc(),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(PreviewDeclaration);

export default PreviewDeclarationContainer;
