import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const firstLevelQuestions = [
	'InvestP1A',
	'InvestP1B',
	'InvestP1C',
	'InvestP1D',
	'InvestP1E',
	'InvestP1F',
	'InvestP1G',
	'InvestP1H',
	'InvestP1I',
	'InvestP1J',
	'InvestP1K',
	'InvestP1L',
	'InvestP1M'
];
const R0104G3 = [
	'InvestP1AA',
	'InvestP1AB',
	'InvestP1AC',
	'InvestP1BA',
	'InvestP1BB',
	'InvestP1BC',
	'InvestP1CA',
	'InvestP1CB',
	'InvestP1CC',
	'InvestP1DA',
	'InvestP1DB',
	'InvestP1DC',
	'InvestP1EA',
	'InvestP1EB',
	'InvestP1EC'
];
const R0109G3 = ['InvestP1FA'];
const R0113G3 = ['InvestP1GA', 'InvestP1HA', 'InvestP1LAA', 'InvestP1MAA'];
// const R041G6 = [];

const initialAnswers = {
	InvestP1A: false,
	InvestP1AA: '',
	InvestP1AB: '',
	InvestP1AC: '',

	InvestP1B: false,
	InvestP1BA: '',
	InvestP1BB: '',
	InvestP1BC: '',

	InvestP1C: false,
	InvestP1CA: '',
	InvestP1CB: '',
	InvestP1CC: '',

	InvestP1D: false,
	InvestP1DA: '',
	InvestP1DB: '',
	InvestP1DC: '',

	InvestP1E: false,
	InvestP1EA: '',
	InvestP1EB: '',
	InvestP1EC: '',

	InvestP1F: false,
	InvestP1FA: '',
	InvestP1FB: '',

	InvestP1G: false,
	InvestP1GA: '',

	InvestP1H: false,
	InvestP1HA: '',

	InvestP1I: '',
	InvestP1J: '',
	InvestP1K: '',

	InvestP1L_g: null,
	InvestP1LAA: '',
	InvestP1M_g: null,
	InvestP1MAA: ''
};
const initialData = {
	R0104G3: {
		q: {
			InvestP1A: null,
			InvestP1B: null,
			InvestP1C: null,
			InvestP1D: null,
			InvestP1E: null
		},
		a: {
			'0': 0,
			// Можливі данні для колонки T1RXXXXG2:
			//  1 – корпоративні права (інші ніж цінні папери);
			//  2 – цінні папери чи деривативи, що перебувають в обігу на фондовій біржі;
			//  3 – цінні папери чи деривативи, що перебувають в обігу не на фондовій біржі.
			// '-'– інше
			InvestP1A: [
				{
					T1RXXXXG2: '1',
					T1RXXXXG3S: '', // їмя активу
					T1RXXXXG4: 0, // доходи
					T1RXXXXG5: 0 // витрати
				}
			],
			InvestP1B: [
				{
					T1RXXXXG2: '1',
					T1RXXXXG3S: '', // їмя активу
					T1RXXXXG4: 0, // доходи
					T1RXXXXG5: 0 // витрати
				}
			],
			InvestP1C: [
				{
					T1RXXXXG2: '1',
					T1RXXXXG3S: '', // їмя активу
					T1RXXXXG4: 0, // доходи
					T1RXXXXG5: 0 // витрати
				}
			],
			InvestP1D: [
				{
					T1RXXXXG2: '2',
					T1RXXXXG3S: '', // їмя активу
					T1RXXXXG4: 0, // доходи
					T1RXXXXG5: 0 // витрати
				}
			],
			InvestP1E: [
				{
					T1RXXXXG2: '3',
					T1RXXXXG3S: '', // їмя активу
					T1RXXXXG4: 0, // доходи
					T1RXXXXG5: 0 // витрати
				}
			]
		}
	},
	R0109G3: {
		a: {
			InvestP1FA: 0
			// InvestP1FB: 0,
			// InvestP1I: 0,
			// InvestP1J: 0,
			// InvestP1K: 0
		},
		q: {
			InvestP1FA: null
			// InvestP1FB: null,
			// InvestP1I: null,
			// InvestP1J: null,
			// InvestP1K: null
		}
	},
	R0113G3: {
		a: {
			InvestP1GA: 0,
			InvestP1HA: 0,
			InvestP1LAA: 0,
			InvestP1MAA: 0
		},
		q: {
			InvestP1GA: null,
			InvestP1HA: null,
			InvestP1LAA: null,
			InvestP1MAA: null
		}
	}
};

const InvestP = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, name, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (R0104G3.includes(id)) {
						newData.R0104G3.a[id.slice(0, -1)][0][name] = value;
					}
					if (R0109G3.includes(id)) {
						newData.R0109G3.a[id] = value;
					}
					if (R0113G3.includes(id)) {
						newData.R0113G3.a[id] = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;
					const children =
						labels[0].attributes['data-sibchildren'] && labels[0].attributes['data-sibchildren'].value;

					if (children) {
						let childs = children.split(',');
						childs.forEach(item => {
							newAnswers[item] = '';
							// if (R0102G3.includes(item)) {
							// 	newData.R0102G3['a'][item] = 0; //! Check why this value take data
							// }
							// if (R0112G3.includes(item)) {
							// 	newData.R0112G3['a'][item] = 0; //! Check why this value take data
							// }
						});
					}

					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];

					break;

				default:
					break;
			}
			save('investP', { answers: newAnswers, data: newData });
			props.getInvestPData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title || firstLevelQuestions.includes(item.id)) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (answers.InvestP1L_g === item.parent || answers.InvestP1M_g === item.parent) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('investP')) {
			const { data, answers } = load('investP');
			setAnswers(answers);
			setData(data);
			props.getInvestPData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getInvestPData(data);
	return (
		<div className="IP-wrap">
			{renderQuestion}
			<div className="btn-IP-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default InvestP;
