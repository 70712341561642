import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { StyledButton as Button } from '../../../../components/Button/Button';

import { calculateDeclaration } from '../../../../store/actions/declaration_action';

const mapStateToProps = state => ({
	currentStepIndex: state.declaration.currentStepIndex
});

const mapDispatchToProps = {
	calculateDeclaration
};

const NextBtn = ({ link, click, currentStepIndex, questionLength, calculateDeclaration, ...props }) => {
	if (link && currentStepIndex === questionLength) {
		return (
			<Link to={`/documents/declaration/${link.srsName}`} onClick={click}>
				<Button className="btn-declaration-common" variant="contained">
					Далі
				</Button>
			</Link>
		);
	}

	if (link && currentStepIndex < questionLength) {
		return (
			<Button onClick={click} className="btn-declaration-common" variant="contained">
				Далі
			</Button>
		);
	}

	if (!link) {
		return (
			<Link to="/documents/declaration/preview" onClick={calculateDeclaration}>
				<Button variant="contained" {...props}>
					Створити декларацию
				</Button>
			</Link>
		);
	}
	return (
		<Link to={`/documents/declaration/${link.srsName || link}`} onClick={click}>
			<Button className="btn-declaration-common" variant="contained">
				Далі
			</Button>
		</Link>
	);
};

const NextButton = connect(
	mapStateToProps,
	mapDispatchToProps
)(NextBtn);

export default NextButton;
