import { combineReducers } from 'redux';

import quiz from './quiz_reducer';
import documents from './documents_list_reducer';
import user from './user_reducer';
import kb from './kb_reducer';
import navigation, * as fromNav from './nav_reducer';
import declaration, * as fromDeclaraion from './declaration_reducer';
// import * as fromP from './decl_subsectons/p_reducer';
import newQuizReducer from '../reducers/newQuizReducer';

const rootReducer = combineReducers({
	documents,
	quiz,
	user,
	kb,
	navigation,
	declaration,
	newQuizReducer
});

export default rootReducer;

//! Selectors

/**
 * Get actual  navigation by subsection from the questionnaire
 * @param {object} state - redux state
 *
 */
export const getActualNavigation = state => {
	return fromNav.getActualNavigation(state.quiz.data, state.navigation.navSubSections);
};

/**
 * Get current subsection
 * @param {object} state - redux state
 */
export const getCurrentSuSection = state => {
	return fromDeclaraion.getSubSection(state, state.declaration.currentIndex);
};
/**
 * Subsection P - get property subquestion
 * @param {object} state
 */
// export const getPropertySubQuestion = state => {
// 	return fromDeclaraion.getPropertySubQuestion(state, state.declaration.P.subId);
// };

/**
 * Subsection P - get saved data to show Chips
 * @param {object} state
 */
// export const getPropertiesChips = state => {
// 	return fromP.getPropertiesChips(state.declaration.P);
// };

export const getNextSubssection = (state, currentSubsectionName) => {
	// return fromDeclaraion.getNextSubssection(state.declaration, currentSubsectionName);
	return fromDeclaraion.getNextSubssection(state, currentSubsectionName);
};

export const getSubSectionDatas = state => {
	return fromDeclaraion.getSubSectionDatas(state.declaration);
};
