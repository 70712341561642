import { connect } from 'react-redux';

import OTI from '../subsections/OTI';
import { getOtiData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.OTI.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getOtiData
};

const OTIContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(OTI);

export default OTIContainer;
