import {GET_KB_LIST_SUCCESS} from '../actions/kb_actions'

const initialState = {
    kbList: [],

}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_KB_LIST_SUCCESS:
            return {
                ...state,
                kbList: action.payload.data
            }
            
        default:
            return state;
    }   
}