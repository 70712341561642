import isArray from 'lodash/isArray';

import * as tableCells from './T1RXXXX';

export const R001G4 = function(formValues) {
	return tableCells.T1RXXXXG4(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R001G5 = function(formValues) {
	return tableCells.T1RXXXXG5(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R001G6 = function(formValues) {
	return tableCells.T1RXXXXG6(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

//Сума від'ємного значення загального фінансового результату операцій з інвестиційними активами попереднього звітного періоду
export const R002G6 = function(formValues) {
	return parseFloat(formValues['R0104G3']['a']['0']);
};

// Загальний фінансовий результат операцій з інвестиційними активами (графа 6 "УСЬОГО" рядка 1 ‐ рядок 2)
export const R003G6 = function(formValues) {
	return R001G6(formValues) - R002G6(formValues);
};

// Додатне значення рядка 3 --> інвестиційний прибуток
export const R031G6 = function(formValues) {
	let result = R003G6(formValues);

	return result < 0 ? 0 : result;
};

// Від'ємне значення рядка 3 --> інвестиційний збиток, (значення вказується без знака "‐")
export const R032G6 = function(formValues) {
	let result = R003G6(formValues);

	return result < 0 ? result * -1 : 0;
};

// Сума податку на доходи фізичних осіб, у тому числі: (рядок 3.1 × на ставку податку 18%)
export const R004G6 = function(formValues) {
	return (R031G6(formValues) / 100) * 18;
};

// Сума податку утриманого (сплаченого) податковим агентом
export const R041G6 = function(formValues) {
	//return parseFloat(formValues['R041G6']);
	//return parseFloat(formValues['R0104G4']);

	return Object.keys(formValues['R0104G3']['a'])
		.filter(key => {
			return key !== '0' && formValues['R0104G3']['q'][key] === 'yes';
		})
		.map(key => {
			let result = [];
			let keyValue = formValues[`R0104G4_${key}`];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => {
					let value = parseFloat(fieldsSet.value);
					return isNaN(value) ? 0 : value;
				});
			}

			return result.reduce((acc, val) => acc + val, 0);
		})
		.reduce((acc, val) => {
			return acc + val;
		}, 0);
};

// Сума податку яка підлягає сплаті платником податку самостійно до бюджету (додатне значення (рядок 4 ‐ рядок 4.1))
export const R042G6 = function(formValues) {
	let result = R004G6(formValues) - R041G6(formValues);

	return result < 0 ? 0 : result;
};

// Сума військового збору, у тому числі: (рядок 3.1 × на ставку збору 1,5 %)
export const R005G6 = function(formValues) {
	return (R031G6(formValues) / 100) * 1.5;
};

// Сума збору утриманого (сплаченого) податковим агентом
export const R051G6 = function(formValues) {
	//return parseFloat(formValues['R051G6']);
	//return parseFloat(formValues['R0104G5']);
	return Object.keys(formValues['R0104G3']['a'])
		.filter(key => {
			return key !== '0' && formValues['R0104G3']['q'][key] === 'yes';
		})
		.map(key => {
			let result = [];
			let keyValue = formValues[`R0104G5_${key}`];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => {
					let value = parseFloat(fieldsSet.value);
					return isNaN(value) ? 0 : value;
				});
			}

			return result.reduce((acc, val) => acc + val, 0);
		})
		.reduce((acc, val) => {
			return acc + val;
		}, 0);
};

// Сума збору яка підлягає сплаті платником податку самостійно до бюджету (додатне значення (рядок 4 ‐ рядок 4.1))
export const R052G6 = function(formValues) {
	let result = R005G6(formValues) - R051G6(formValues);

	return result < 0 ? 0 : result;
};
