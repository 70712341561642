import isArray from 'lodash/isArray';
import { precisionRound } from '../../index';

// Назва країни, з якої отримано іноземні доходи, та назва валюти
export const R0106G2S = function(formValues) {
	let result = Object.keys(formValues['R0106G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0106G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0106G3']['a'][key];
			//! СТРАНА ПРОИСХОЖДЕНИ + ВАЛЮТА
			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => {
					return fieldsSet['country_name'] || fieldsSet['currency_name']
						? [fieldsSet['country_name'], fieldsSet['currency_name']].join(', ')
						: '';
				});
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, [])
		.reduce((acc, cur) => {
			if (acc) return acc + ', ' + cur;
			else return cur;
		}, '');

	return result ? result : false;
};

export const R0106G3 = function(formValues) {
	return Object.keys(formValues['R0106G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0106G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0106G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => {
					let value = parseFloat(fieldsSet['revenue']);
					return value ? value : 0;
				});
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, [])
		.reduce((acc, cur) => {
			return acc + cur;
		}, 0);
};

// Сума податку утриманого (сплаченого) податковим агентом
export const R0106G4 = function() {
	return 0;
};

// Сума збору утриманого (сплаченого) податковим агентом
export const R0106G5 = function() {
	return 0;
};

// Сума податку що підлягає сплаті самостійно
export const R0106G6 = function(formValues) {
	let partials = R0106G6_partials(formValues);
	return Object.keys(partials)
		.map(key => {
			return Object.values(partials[key]);
		})
		.reduce((acc, taxes) => {
			return (
				acc +
				taxes.reduce((tax, val) => {
					if (val.override) {
						return tax + val.override;
					} else {
						return tax + val.value;
					}
				}, 0)
			);
		}, 0);
};

export const R0106G6_partials = function(formValues) {
	return Object.keys(formValues['R0106G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0106G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0106G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map((fieldsSet, idx) => {
					if (
						formValues[`R0106G6_${key}`] &&
						formValues[`R0106G6_${key}`][idx] &&
						formValues[`R0106G6_${key}`][idx]['override'] === true
					) {
						return parseFloat(formValues[`R0106G6_${key}`][idx]['value']);
					}

					let tax = 0;
					let curr = parseFloat(fieldsSet['revenue']);
					curr = curr ? curr : 0;

					switch (key) {
						case 'W1B':
						case 'W5':
						case 'OFI1':
						case 'ID1BB':
						case 'R1B':
						case 'ALIM2':
						case 'SalePro3':
						case 'Int1A':
							tax += (curr * 18) / 100;
							break;
						case 'D3A':
							tax = (curr * 9) / 100;
							break;
						default:
							break;
					}

					return precisionRound(tax, 2);
				});
			}

			let personalTaxes = result.map(tax => {
				return {
					value: tax
				};
			});

			return {
				[`R0106G6_${key}`]: personalTaxes
			};
		})
		.reduce((acc, obj) => {
			return { ...acc, ...obj };
		}, {});
};

// Сума збору що підлягає сплаті самостійно
export const R0106G7 = function(formValues) {
	let partials = R0106G7_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return Object.values(partials[key]);
		})
		.reduce((acc, taxes) => {
			return (
				acc +
				taxes.reduce((tax, val) => {
					if (val.override) {
						return tax + val.override;
					} else {
						return tax + val.value;
					}
				}, 0)
			);
		}, 0);
};

export const R0106G7_partials = function(formValues) {
	return Object.keys(formValues['R0106G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0106G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0106G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map((fieldsSet, idx) => {
					if (
						formValues[`R0106G6_${key}`] &&
						formValues[`R0106G6_${key}`][idx] &&
						formValues[`R0106G6_${key}`][idx]['override'] === true
					) {
						return parseFloat(formValues[`R0106G7_${key}`][idx]['value']);
					}

					let tax = 0;
					let curr = parseFloat(fieldsSet['revenue']);
					curr = curr ? curr : 0;

					switch (key) {
						case 'W1B':
						case 'W5':
						case 'OFI1':
						case 'ID1BB':
						case 'IP2C':
						case 'ALIM2':
						case 'Lease2':
						case 'R1B':
						default:
							tax += (curr * 1.5) / 100;
							break;
					}

					return precisionRound(tax, 2);
				});
			}

			let personalTaxes = result.map(tax => {
				return {
					value: tax
				};
			});

			return {
				[`R0106G7_${key}`]: personalTaxes
			};
		})
		.reduce((acc, obj) => {
			return { ...acc, ...obj };
		}, {});
};
