import { useState, useCallback } from 'react';

const initialState = {
	ALIM1A: '',
	ALIM2A: '',
	ALIM2B: '',
	ALIM2C: '',
	ALIM2D: '',
	ALIM2E: ''
};

export const useForms = (state = initialState, cb = () => {}) => {
	const [answers, setAnswers] = useState(state);
	const _handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type } = currentTarget;
			let newAnswers = { ...answers };
			switch (type) {
				case 'text':
					newAnswers[id] = value;
					break;

				default:
					break;
			}
			setAnswers(newAnswers);
			cb(newAnswers);
		},
		[answers]
	);
	const _handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;

			setAnswers(newAnswers);
			cb(newAnswers);
		},
		[answers]
	);

	const _handleChangeDate = useCallback(
		e => {
			let newAnswers = { ...answers };
			const date = new Date(e._d);
			newAnswers.ALIM2C = date.getTime(); //! change answer

			setAnswers(newAnswers);
			cb(newAnswers);
		},
		[answers]
	);

	return {
		state,
		_handleChange,
		_handleChangeSelect,
		_handleChangeDate
	};
};

export default useForms;
