import React from 'react';

import { FormHelperText } from '@material-ui/core';

import CheckboxComponent from '../CheckboxComponent';

const CheckboxField = props => {
	const { field, classNameWrapper, classNameError, isShowError, errorText } = props;
	return (
		<div className={classNameWrapper}>
			<CheckboxComponent {...props} {...field} />
			<FormHelperText error className={classNameError}>
				{isShowError ? errorText : ''}
			</FormHelperText>
		</div>
	);
};

export default CheckboxField;
