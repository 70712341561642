import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';
import Stepper from '../../containers/StepperContainer';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const subQuestionsRender = ['W7AA', 'W6AA'];
const R0113G3 = ['W2A', 'W3B', 'W6AA', 'W7AA'];
const R0101G3 = ['W1A', 'W3A', 'W4A'];
const R0109G3 = ['W2A'];
// const R0106G3 = ['W2A'];

const initialAnswers = {
	W1A: '', // R0101G3
	W1BA: '', // R0106G3
	W1BB: '', // R0106G3
	W1BC: '', // R0106G3
	W1BD: '', // R0106G3
	W1BE: '', //R021G3
	W2A: '', //R0113G3 R0109G3
	W3A: '', //R0101G3
	W3B: '', // R0113G3
	W4A: '', // R0101G3
	W4A_g: null,
	W5A: '', //R0106G3
	W5B: '', //R0106G3
	W5C: '', //R0106G3
	W5D: '', //R0106G3
	W5E: '', // R021G3
	W6_g: null,
	W6AA: '', // R0113G3
	W7: '', //
	W7AA: '', // R0113G3
	W7_g: null
};
const initialData = {
	R021G3: {
		a: {
			W1BE: 0,
			W5E: 0
		},
		q: {
			W1BE: null,
			W5E: null
		}
	},
	R0101G3: {
		q: {
			W1A: null,
			W3A: null,
			W4AA: null,
			W4AB: null
		},
		a: {
			W1A: 0,
			W3A: 0,
			W4AA: 0,
			W4AB: 0
		}
	},
	R0106G3: {
		q: { W1B: null, W5: null },
		a: {
			W1B: [
				{
					country_name: '',
					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0
				}
			],
			W5: [
				{
					country_name: '',
					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0
				}
			]
		}
	},
	R0109G3: {
		q: { W2A: null },
		a: { W2A: 0 }
	},
	R0113G3: {
		q: {
			W2A: null,
			W3B: null,
			W4A: null,
			W6AA: null,
			W7AA: null
		},
		a: {
			W2A: 0,
			W3B: 0,
			W4A: 0,
			W6AA: 0,
			W7AA: 0
		}
	}
};

const Wages = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, name, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					newAnswers[`${id}`] = value;
					if (R0101G3.includes(id)) {
						if (id === 'W4A') {
							if (newAnswers.W4A_g === 'W4AAA') {
								newData.R0101G3.a.W4AA = value;
							} else {
								newData.R0101G3.a.W4AB = value;
							}
							break;
						}
						newData.R0101G3.a[id] = value;
					}
					if (R0109G3.includes(id)) {
						newData.R0109G3.a[id] = value;
					}
					if (R0113G3.includes(id)) {
						newData.R0113G3.a[id] = value;
					}
					if (name === 'tax') {
						newData.R021G3.a[id] = value;
					}
					if ((name === 'currency_date' || name === 'revenue') && ['W5C', 'W5D', 'W5E'].includes(id)) {
						newData.R0106G3.a.W5[0][name] = value;
					}
					if ((name === 'currency_date' || name === 'revenue') && ['W1BC', 'W1BD', 'W1BE'].includes(id)) {
						newData.R0106G3.a.W1B[0][name] = value;
					}
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;

					if (value === 'W4AAB') {
						newData.R0101G3.a.W4AB = newData.R0101G3.a.W4AA;
						newData.R0101G3.a.W4AA = 0;
					}

					if (value === 'W4AAA') {
						newData.R0101G3.a.W4AA = newData.R0101G3.a.W4AB;
						newData.R0101G3.a.W4AB = 0;
					}
					newAnswers[groupName] = value;

					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];
					break;

				default:
					break;
			}
			save('w', { answers: newAnswers, data: newData });
			props.getWagesData({ data: newData, answers: newAnswers });
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			let newData = { ...data };
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;

			if (['W5A', 'W5B'].includes(dataset.id)) {
				newData.R0106G3.a.W5[0][dataset.name] = target.value;
			}
			if (['W1BA', 'W1BB'].includes(dataset.id)) {
				newData.R0106G3.a.W1B[0][dataset.name] = target.value;
			}
			setData(newData);
			setAnswers({ ...answers, ...newAnswers });
			props.getWagesData({ data: newData, answers: newAnswers });
			save('w', { data: newData, answers: newAnswers });
		},
		[answers, data]
	);

	const handleChangeDate = useCallback(
		key => e => {
			let newData = { ...data };
			let newAnswers = { ...answers };

			const date = new Date(e._d);

			newAnswers[key] = date.getTime(); //! change answer
			newData.R0106G3.a[key.slice(0, -1)][0].currency_date = date.getTime(); //! change data

			setData(newData);
			setAnswers(newAnswers);
			props.getWagesData({ data: newData, answers: newAnswers });
			save('w', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection[props.currentStepIndex]).map((item, i) => {
			if (
				(subQuestionsRender.includes(item.id) && answers.W7_g === item.parent) ||
				answers.W6_g === item.parent
			) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			}
			if (!subQuestionsRender.includes(item.id)) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData, props.currentStepIndex]);

	useEffect(() => {
		if (props.currentStepIndex > 0) {
			props.setStepIndex(0);
		}
		if (load('w')) {
			const { data, answers } = load('w');
			setAnswers(answers);
			setData(data);
			props.getWagesData({ data, answers });
		}
	}, []);

	const handleClickNextButton = () => {
		if (props.currentStepIndex === props.subsection.length - 1) {
			props.getWagesData({ data, answers });
		} else {
			props.getWagesData({ data, answers });
			props.setStepIndex(props.currentStepIndex + 1);
		}
	};
	return (
		<div className="W-wrap">
			<Stepper steps={props.subsection} activeIndex={props.currentStepIndex} />
			{renderQuestion}
			<div className="btn-W-wrap">
				<NextButton
					link={props.nextSubSection}
					questionLength={props.subsection.length - 1}
					click={handleClickNextButton}
				/>
			</div>
		</div>
	);
});

export default Wages;
