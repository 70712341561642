import React, { Fragment } from 'react';

import { Field } from 'formik';

import RadioGroup from '../RadioGroup';
import RadioInputField from '../RadioInputField';
import RadioInput from '../RadioInput';

const NewStepperRadioSubField = ({
	field: { value, onChange, onBlur, name },
	question,
	stepperCurrent,
	values,
	errors,
	id,
	...props
}) => {
	return (
		<Fragment>
			<RadioInput
				key={name}
				name={name}
				id={id}
				onChange={onChange}
				onBlur={onBlur}
				value={id}
				checked={id === value}
				{...props}
			/>

			{values[question.parent] === question.name && (
				<RadioGroup
					classNameTitle="question-title sub-title"
					id={question.name}
					label={question.title}
					value={values[question.name]}
					error={errors[question.name]}
					touched={stepperCurrent.isTouched}
				>
					{question.answers.map(answer => {
						return (
							<Field
								key={answer.code}
								component={RadioInputField}
								name={question.name}
								id={answer.code}
								label={answer.text}
								answer={answer}
							/>
						);
					})}
				</RadioGroup>
			)}
		</Fragment>
	);
};

export default NewStepperRadioSubField;
