import { connect } from 'react-redux';

import AddB from '../subsections/ADDB';
import { getAddBData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.ADDB.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getAddBData
};

const AddBContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(AddB);

export default AddBContainer;
