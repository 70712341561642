import React, { Component } from 'react';
import findIndex from 'lodash/findIndex';
import { NavLink, withRouter } from 'react-router-dom';

import ButtonCreateDeclaration from '../../pages/declaration/components/ButtonCreateDeclaration';
import './styles.scss';

class Navigation extends Component {
	state = {
		openSubNav: false
	};

	componentDidMount() {
		this.props.getLeftNavigation();
		this.props.setCurrentSubsectionIndex(
			findIndex(this.props.navSubSections, sub => sub.srsName === this.props.match.params.subsection)
		);
	}

	renderSections = sections => {
		const { location } = this.props;
		const currentLinkIndex = findIndex(sections, item => item.link === location.pathname);
		/*
		 * Styles for items in left side
		 *
		 * @{ param - active-title } - active item
		 * @{ param - success-title } - in item all okey
		 * @{ param - error-title } - in item something wrong
		 * @{ param - passive-title } - item without any action
		 *
		 */
		return (
			<ul className="navbar">
				{sections.map((item, i) => (
					<li key={i} className="element-center fadebar">
						<NavLink
							className="navbar-item"
							activeClassName={location.pathname === item.link ? 'active-title' : null}
							to={item.link}
						>
							{item.title}
						</NavLink>
						<div
							className={
								location.pathname === item.link
									? 'circle-big'
									: i < currentLinkIndex
									? 'circle-small__top'
									: 'circle-small'
							}
						/>
					</li>
				))}
			</ul>
		);
	};

	renderSubSections = subSections => {
		const { location } = this.props;

		return (
			<ul className="navbar declaration-block " style={{ display: 'block' }}>
				<li className="main-title-block mb43">
					<div className="decl-back" onClick={() => this.props.history.push('/documents')}>
						<img src="/img/back.svg" alt="back" />
					</div>
					<p className="active-title main-active-title">Декларація</p>
				</li>
				{subSections.map((item, i) => {
					let regex = new RegExp(item.srsName);

					return (
						<li key={i} className={'element-center'}>
							<NavLink
								to={`/documents/declaration/${item.srsName}`}
								onClick={() => this.props.setCurrentSubsectionIndex(item.index ? item.index : i)}
								activeClassName={regex.test(location.pathname) ? 'navbar-down-item_active' : null}
								className="navbar-down-item"
								/*
								Styles & img
								navbar-down-item_active - when item is active - /img/active.svg
								navbar-down-item_passive - when item not visited - /img/passive.svg
								navbar-down-item_success - all is done true - /img/done.svg
								navbar-down-item_error - something went wrong - /img/alert.svg
							*/
							>
								{item.title}
							</NavLink>

							<img className="declaration-menu-icon" src="/img/passive.svg" alt="passive" />
						</li>
					);
				})}
				<li className="btn-create">
					<ButtonCreateDeclaration />
				</li>
			</ul>
		);
	};

	render() {
		const { location, navSections, navSubSections } = this.props;
		const currentLinkIndex = findIndex(navSections, item => item.link === location.pathname);

		return (
			<div className="zvit-bar">
				<div className="navbar-wrap">
					<div className="navigation-bar">
						{currentLinkIndex < 2 &&
							currentLinkIndex > -1 &&
							navSections &&
							this.renderSections(navSections)}
					</div>

					{(currentLinkIndex < 0 || currentLinkIndex > 1) && this.renderSubSections(navSubSections)}
				</div>
			</div>
		);
	}
}

export default withRouter(Navigation);
