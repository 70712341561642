import { connect } from 'react-redux';

import S from '../subsections/S';
import { getSData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.S.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getSData
};

const SContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(S);

export default SContainer;
