import React from 'react';

import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';

import MomentUtils from '@date-io/moment';

const NewDatePicker = ({ name, style, onChange, error, value, helperText, onError }) => {
	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<DatePicker
				name={name}
				style={style}
				keyboard
				error={error}
				value={value}
				onChange={onChange}
				helperText={helperText}
				onError={onError}
				format={'MM.DD.YYYY'}
				mask={value => (value ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/] : [])}
			/>
		</MuiPickersUtilsProvider>
	);
};

export default NewDatePicker;
