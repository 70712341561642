import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const RadioForm = ({ item, change, answer }) => {
	return (
		<FormControlLabel
			control={
				<Radio
					id={item.id}
					checked={answer[item.name] === item.id}
					onChange={change}
					value={item.id}
					name={item.xmlId}
					color="default"
					aria-label={item.title}
				/>
			}
			label={item.title}
			data-name={item.name}
			data-parent={item.parent}
			data-xml={item.xmlId}
			data-sibchildren={item.sibling_children}
			data-subid={item.sub_id}
		/>
	);
};

export default RadioForm;
