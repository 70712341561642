import React, { Fragment } from 'react';

import { Field } from 'formik';

import NewStepperCheckBox from '../NewStepperCheckBox';
import NewStepperCheckboxGroupSub from '../NewStepperCheckboxGroupSub';
import NewStepperCheckBoxSubField from '../NewStepperCheckBoxSubField';

const NewStepperCheckBoxSubInSubField = ({
	field: { name, value, onChange, onBlur },
	id,
	question,
	stepperCurrent,
	values,
	errors,
	setFieldValue,
	setFieldTouched,
	...props
}) => {
	return (
		<Fragment>
			<NewStepperCheckBox
				name={name}
				id={id}
				value={name}
				checked={value}
				onChange={onChange}
				onBlur={onBlur}
				{...props}
			/>
			{values[question.parent].includes(question.name) && (
				<NewStepperCheckboxGroupSub
					id={question.name}
					value={values[question.name]}
					error={errors[question.name]}
					touched={stepperCurrent.isTouched}
					onChange={setFieldValue}
					onBlur={setFieldTouched}
				>
					{question.answers.map(answer => {
						return (
							<Field
								key={answer.code}
								component={NewStepperCheckBoxSubField}
								name={answer.name}
								id={answer.code}
								label={answer.text}
								question={answer.subInSubQuestion}
								stepperCurrent={stepperCurrent}
								values={values}
								errors={errors}
								setFieldValue={setFieldValue}
								setFieldTouched={setFieldTouched}
								answer={answer}
							/>
						);
					})}
				</NewStepperCheckboxGroupSub>
			)}
		</Fragment>
	);
};

export default NewStepperCheckBoxSubInSubField;
