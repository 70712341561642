import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const firstLevelQuestions = [];
// const R0113G3 = [];
// const R0109G3 = [];

const initialAnswers = {
	IPA1: '',
	IPA2: '',
	IPA3: '',
	IPA4: '',
	IPA5: '',
	IPA6: '',
	IPA7: ''
};
const initialData = {
	R0108G3: {
		q: {
			'0': true,
			'1': null
		},
		a: {
			'0': 0,
			'1': [
				{
					T4RXXXXG2: '',
					T4RXXXXG3S: '',
					T4RXXXXG4S: '',
					T4RXXXXG5: 0, // revenue
					T4RXXXXG6: 0, // expenses 'type 1'
					T4RXXXXG7: 0, // expenses 'type 2'
					T4RXXXXG8: 0, // expenses 'type 3'
					T4RXXXXG9: 0 // profit
				}
			]
		}
	}
};

const IPA = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (id === 'IPA1') {
						newData.R0108G3.a[1][0].T4RXXXXG2 = value;
					}
					// if (id === 'IPA2') {
					// 	newData.R0108G3.a[1][0].T4RXXXXG3S = value;
					// }
					// if (id === 'IPA3') {
					// 	newData.R0108G3.a[1][0].T4RXXXXG4S = value;
					// }
					if (id === 'IPA4') {
						newData.R0108G3.a[1][0].T4RXXXXG5 = value;
					}
					if (id === 'IPA5') {
						newData.R0108G3.a[1][0].T4RXXXXG6 = value;
					}
					if (id === 'IPA6') {
						newData.R0108G3.a[1][0].T4RXXXXG7 = value;
					}
					if (id === 'IPA7') {
						newData.R0108G3.a[1][0].T4RXXXXG8 = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'checkbox':
					newAnswers[value] = !newAnswers[value];
					break;

				default:
					break;
			}
			save('ipa', { answers: newAnswers, data: newData });
			props.getIpaData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeAutocomplete = useCallback(
		value => {
			let newData = { ...data };
			let newAnswers = { ...answers };
			let separateAnswer = value.split('||');

			newAnswers.IPA2 = separateAnswer[0];
			newAnswers.IPA3 = separateAnswer[1];

			newData.R0108G3.a[1][0].T4RXXXXG3S = separateAnswer[0];
			newData.R0108G3.a[1][0].T4RXXXXG4S = separateAnswer[1];

			setData(newData);
			setAnswers(newAnswers);
			props.getIpaData(newData);
			save('ipa', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			let newData = { ...data };
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;
			newData.R0108G3.a[1][0].T4RXXXXG2 = target.value;
			setData(newData);
			setAnswers({ ...answers, ...newAnswers });
			props.getIpaData(newData);
			save('ipa', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title || firstLevelQuestions.includes(item.id)) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeAutocomplete={handleChangeAutocomplete}
						changeSelect={handleChangeSelect}
					/>
				);
			}
			if (answers.IP1AA_g === item.parent) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeAutocomplete={handleChangeAutocomplete}
						changeSelect={handleChangeSelect}
					/>
				);
			}
			return null;
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('ipa')) {
			const { data, answers } = load('ipa');
			setAnswers(answers);
			setData(data);
			props.getIpaData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getIpaData(data);
	return (
		<div className="IP-wrap">
			{renderQuestion}
			<div className="btn-IP-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default IPA;
