// Сабсекции - id
const COMMON = 'COMMON',
	P = 'P',
	W = 'W',
	ID = 'ID',
	IP = 'IP',
	LEASE = 'LEASE',
	S = 'S',
	INT = 'INT',
	D = 'D',
	R = 'R',
	ALIM = 'ALIM',
	SALEPRO = 'SALEPRO',
	INVESTP = 'INVESTP',
	ADDB = 'ADDB',
	WP = 'WP',
	DISC = 'DISC',
	ENTREPRIB2BA = 'ENTREPRIB2BA',
	ENTREPRIB2BB = 'ENTREPRIB2BB',
	IPA = 'IPA',
	OFI = 'OFI',
	OTI = 'OTI',
	C = 'С',
	ONTI = 'ONTI';

export {
	COMMON,
	P,
	W,
	ID,
	IP,
	LEASE,
	S,
	INT,
	D,
	R,
	ALIM,
	SALEPRO,
	INVESTP,
	ADDB,
	WP,
	DISC,
	ENTREPRIB2BA,
	ENTREPRIB2BB,
	IPA,
	OFI,
	OTI,
	C,
	ONTI
};
