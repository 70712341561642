import { precisionRound } from '../../../types/index';

export const R0109G3 = function(formValues) {
	return Object.keys(formValues['R0109G3']['a'])
		.filter(key => {
			return key !== '0' && key !== '';
		})
		.reduce((acc, key) => {
			let curr = parseFloat(formValues['R0109G3']['a'][key]);
			let month = parseFloat(formValues['R0109G3']['a']['AddB12B']);

			switch (key) {
				case 'W2A':
					curr = curr <= 2470 ? 0 : curr - 2470;
					break;
				case 'IP1AAAB': //0.6
				case 'IP1AABA':
				case 'IP1BABAA':
				case 'IP1CAA':
				case 'IP1DAAAA':
					curr = curr * 0.6;
					break;
				case 'AddB5A':
					curr = curr > 930.75 ? curr - 930.75 : 0;
					break;
				case 'AddB12A':
					curr = curr * month > 11169 * month ? (curr * month - 11169 * month) / 100 : 0;
					break;
				case 'AddB12B':
					curr = 0;
					break;
				case 'C1DA':
				case 'C1EAAA':
					curr = curr > 2470 ? curr - 2470 : 0;
					break;

				case 'C1KAABA':
				case 'C1KBABA':
				case 'C1KCABA':
				case 'C1KDABA':
					curr += curr > 2086500 ? curr - 2086500 : 0;
					break;

				case 'C1MA':
					curr += curr > 4940 ? curr - 4940 : 0;
					break;

				case 'C1EABA':
					curr = curr > 2470 ? curr * 1.2195122 - 2470 : 0;
					break;

				default:
					break;
			}
			return acc + curr;
		}, 0);
};

// Сума податку утриманого (сплаченого) податковим агентом

export const R0109G4_partials = function(formValues) {
	return Object.keys(formValues['R0109G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0109G4_${key}_override`]) {
				tax = parseFloat(formValues[`R0109G6_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0109G3']['a'][key]);
				let month = parseFloat(formValues['R0109G3']['a']['AddB12B']);

				switch (key) {
					case 'W2A':
						tax += curr > 2470 ? ((curr - 2470) * 18) / 100 : 0;
						break;
					case 'IP1FAA':
						tax += (curr * 18) / 100;
						break;
					case 'Int1BA':
					case 'D2BA':
					case 'R1A':
					case 'WP1AA':
					case 'AddB3A':
					case 'OTI_1':
					case 'OTI_2A':
					case 'OTI_2B':
					case 'OTI_3':
					case 'OTI_4':
					case 'OTI_5':
					case 'OTI_6':
					case 'OTI_7':
					case 'S1AA':
						tax = (curr * 18) / 100;
						break;
					case 'WP1BA':
						tax = curr >= 930.75 ? (curr * 1.2195122 * 18) / 100 : 0;
						break;
					case 'AddB1A':
					case 'AddB4A':
					case 'AddB9A':
						tax = (curr * 1.2195122 * 18) / 100;
						break;
					case 'AddB12A':
						tax = curr * month > 11169 * month ? ((curr * month - 11169 * month) * 18) / 100 : 0;
						break;
					case 'AddB12B':
					case 'Int1EA':
					case 'Int1DA':
						tax = 0;
						break;
					case 'AddB14A':
						tax = curr > 18625 ? (curr * 1.2195122 * 18) / 100 : 0;
						break;

					case 'C1MA':
						tax += curr > 4940 ? ((curr - 4940) * 18) / 100 : 0;
						break;

					case 'C1EAAA':
						tax += curr > 2470 ? ((curr - 2470) * 18) / 100 : 0;
						break;
					case 'C1EABA':
						tax += curr > 2470 ? ((curr - 2470) * 1.2195122 * 18) / 100 : 0;
						break;
					default:
						break;
				}
			}

			acc[`R0109G4_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума податку що підлягає сплаті самостійно
export const R0109G4 = function(formValues) {
	let partials = R0109G4_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};

// Сума збору утриманого (сплаченого) податковим агентом

export const R0109G5_partials = function(formValues) {
	return Object.keys(formValues['R0109G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0109G5_${key}_override`]) {
				tax = parseFloat(formValues[`R0109G7_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0109G3']['a'][key]);
				let month = parseFloat(formValues['R0109G3']['a']['AddB12B']);

				switch (key) {
					case 'W2A':
						tax += curr > 2470 ? ((curr - 2470) * 1.5) / 100 : 0;
						break;
					case 'C1MA':
						tax += curr > 4940 ? ((curr - 4940) * 1.5) / 100 : 0;
						break;

					case 'IP1FAA':
						tax += (curr * 1.5) / 100;
						break;

					case 'AddB12A':
						tax = curr * month > 11169 * month ? ((curr * month - 11169 * month) * 1.5) / 100 : 0;
						break;

					case 'C1KAABA':
					case 'C1KBABA':
					case 'C1KCABA':
					case 'C1KDABA':
						tax += curr > 2086500 ? ((curr - 2086500) * 1.5) / 100 : 0;
						break;

					case 'AddB12B':
						tax = 0;
						break;

					case 'C1EAAA':
					case 'C1EABA':
						tax += curr > 2470 ? ((curr - 2470) * 1.5) / 100 : 0;
						break;

					default:
						tax += (curr * 1.5) / 100;
						break;
				}
			}

			acc[`R0109G5_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума збору що підлягає сплаті самостійно
export const R0109G5 = function(formValues) {
	let partials = R0109G5_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};

// Сума податку що підлягає сплаті самостійно
export const R0109G6_partials = function(formValues) {
	return Object.keys(formValues['R0109G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0109G6_${key}_override`]) {
				tax = parseFloat(formValues[`R0109G6_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0109G3']['a'][key]);

				switch (key) {
					case 'IP1AAAB': //0.6
					case 'IP1AABA':
					case 'IP1BABAA':
					case 'IP1CAA':
					case 'IP1DAAAA':
						tax += (curr * 0.6 * 18) / 100;
						break;

					case 'IP1EAA':
					case 'IP1JAA':
					case 'IP1NABAA':
					case 'IP1PAB':
					case 'IP2AB':
					case 'C1AA':
					case 'C2AA':
						tax += (curr * 18) / 100;
						break;

					case 'C1DA':
						tax += curr > 2470 ? ((curr - 2470) * 18) / 100 : 0;
						break;

					case 'C1KAABA':
					case 'C1KBABA':
					case 'C1KCABA':
					case 'C1KDABA':
						tax += curr > 2086500 ? ((curr - 2086500) * 18) / 100 : 0;
						break;

					case 'AddB5A':
						tax = curr > 930.75 ? ((curr - 930.75) * 5) / 100 : 0;
						break;

					case 'D3BA':
						tax += (curr * 9) / 100;
						break;

					case 'D3CA': {
						tax += (curr * 5) / 100;

						// Прожиточный минимум
						//let min = 1700 * 10;
						// Оподатковується позитивна різниця
						//tax += curr > min ? ((curr - min) * 5 / 100) : 0;
						break;
					}
					case 'D1AA': {
						tax += 0;
						break;
					}
					// case '19': {
					// 	// оподатковується різниця між сумою виплати та сумою внесених платежів протягом дії договору
					// 	tax += (curr * 18) / 100;
					// 	break;
					// }
					// case '20': {
					// 	// (база оподаткування 60% від страхової виплати)
					// 	tax += (curr * 18) / 100;
					// 	break;
					// }
					// case '27':
					// 	tax += (curr * 5) / 100;
					// 	break;

					default:
						break;
				}
			}

			acc[`R0109G6_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума податку що підлягає сплаті самостійно
export const R0109G6 = function(formValues) {
	let partials = R0109G6_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			// return 0;
			return acc + tax;
		}, 0);
};

// Сума збору що підлягає сплаті самостійно
export const R0109G7_partials = function(formValues) {
	return Object.keys(formValues['R0109G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0109G7_${key}_override`]) {
				tax = parseFloat(formValues[`R0109G7_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0109G3']['a'][key]);

				switch (key) {
					case 'IP1AAAB': //0.6
					case 'IP1AABA':
					case 'IP1BABAA':
					case 'IP1CAA':
					case 'IP1DAAAA':
						tax += (curr * 0.6 * 1.5) / 100;
						break;

					case 'C2AA':
					case 'C1AA':
						tax += (curr * 1.5) / 100;
						break;

					case 'AddB5A':
						tax += curr > 930.75 ? ((curr - 930.75) * 1.5) / 100 : 0;
						break;

					case 'C1DA':
						tax += curr > 2470 ? ((curr - 2470) * 1.5) / 100 : 0;
						break;

					case '15':
						tax += 0;
						break;
					default:
						break;
				}
			}

			acc[`R0109G7_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума збору що підлягає сплаті самостійно
export const R0109G7 = function(formValues) {
	let partials = R0109G7_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			// return 0;
			return acc + tax;
		}, 0);
};
