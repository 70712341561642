import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const R0113G3 = ['S1AA', 'S1BA', 'S1CA', 'S1DA'];
const R0109G3 = ['S1AA'];

const initialAnswers = {
	S1A: false,
	S1AA: '',
	S1B: false,
	S1BA: '',
	S1C: false,
	S1CA: '',
	S1D: false,
	S1DA: ''
};
const initialData = {
	R0109G3: {
		a: { S1AA: 0 },
		q: { S1AA: null }
	},
	R0113G3: {
		a: {
			S1AA: 0,
			S1BA: 0,
			S1CA: 0,
			S1DA: 0
		},
		q: {
			S1AA: null,
			S1BA: null,
			S1CA: null,
			S1DA: null
		}
	}
};

const IP = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (R0109G3.includes(id)) {
						newData.R0109G3.a[id] = value;
					}
					if (R0113G3.includes(id)) {
						newData.R0113G3.a[id] = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;

					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];
					break;

				default:
					break;
			}
			save('s', { answers: newAnswers, data: newData });
			props.getSData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (
				answers.IP1AA_g === item.parent ||
				item.parent === answers.IP1BA_g ||
				item.parent === answers.IP1DA_g ||
				item.parent === answers.IP1NA_g
			) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('s')) {
			const { data, answers } = load('s');
			setAnswers(answers);
			setData(data);
			props.getSData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getSData(data);
	return (
		<div className="S-wrap">
			{renderQuestion}
			<div className="btn-S-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default IP;
