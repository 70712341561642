import { connect } from 'react-redux';

import Penalty from '../subsections/Penalty';
import { getPenaltyData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.Penalty.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getPenaltyData
};

const PenaltyContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Penalty);

export default PenaltyContainer;
