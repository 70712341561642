import React, { useState, useEffect, useCallback } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const initialDataState = {
	R0106G3: {
		q: { OFI1: null },
		a: {
			OFI1: [
				{
					country_name: '',
					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0,
					tax: 0
				}
			]
		}
	},
	R021G3: {
		q: { OFI_1E: null },
		a: { OFI_1E: 0 }
	}
};

const initialAnswersState = {
	OFI_1A: '',
	OFI_1B: '',
	OFI_1C_input: '',
	OFI_1D_input: '',
	OFI_1E_input: '',
	OFI_1C: null,
	OFI_1D: null,
	OFI_1E: null
};

const OFI = props => {
	const [data, setData] = useState(initialDataState);
	const [answers, setAnswers] = useState(initialAnswersState);

	useEffect(() => {
		if (load('ofi')) {
			const { data, answers } = load('ofi');
			setAnswers(answers);
			setData(data);
			props.getOfiData(data);
		}
	}, []);

	const handleChange = ({ currentTarget }) => {
		const { value, name, id, type } = currentTarget;
		let newData = { ...data };
		let newAnswers = { ...answers };

		switch (type) {
			case 'text':
				if (id === 'OFI_1E' && name === 'tax') {
					newData.R021G3['a']['OFI_1E'] = value;
				} else {
					newData.R0106G3['a']['OFI1'][0][name] = value;
				}
				newAnswers[`${id}_input`] = value;

				break;
			default:
				break;
		}
		save('ofi', { data, answers });
		props.getOfiData(newData);
		setData(newData);
		setAnswers(prevState => ({ ...prevState, ...newAnswers }));
	};

	const handleChangeSelect = ({ target, currentTarget: { dataset } }) => {
		let newData = { ...data };
		newData.R0106G3.a.OFI1[0][dataset.name] = target.value;

		setData(newData);
		props.getOfiData(newData);
		save('ofi', { data, answers });
		setAnswers({ ...answers, [dataset.id]: target.value });
	};

	const handleChangeDate = useCallback(
		key => e => {
			let newData = { ...data };
			let newAnswers = { ...answers };

			const date = new Date(e._d);
			newAnswers[key] = date.getTime(); //! change answer
			newData.R0106G3.a.OFI1[0].currency_date = date.getTime(); //! change data

			setData(newData);
			setAnswers(newAnswers);
			props.getOfiData(newData);
			save('ofi', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const handleClickNextButton = () => props.getOfiData(data);

	return (
		<div className="OFI-wrap">
			{Object.values(props.subsection).map((item, i) => {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			})}

			<div className="btn-OFI-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
};

export default OFI;
