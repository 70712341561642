import { R0109G3 } from '../_xmlConstId';

import { OTI_1, OTI_2A, OTI_2B, OTI_3, OTI_4, OTI_5, OTI_6, OTI_7 } from '../_declaretion_answer_id';
import { B10AD, B10AE, B10AF, B10AL, B10AB, B10AМ, B10AA } from '../../_questions_OLD';
import { INPUT } from '../../elementTypes';

export const OTI = [
	{
		id: OTI_1,
		title:
			'Вкажіть суму коштів або вартість майна, класифіковані, згідно з рішенням суду, як хабар або як викрадений чи знайдений скарб, який не був зданий державі згідно із законом, визначені обвинувальним вироком, незалежно від призначеної судом міри покарання.',
		xmlId: R0109G3,
		name: OTI_1,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)',
		helpers: [
			'Підказка 1. Рішення суду має набрати законної сили у звітному періоді.',
			'Підказка 2. Рішення суду можна віднайти в електронному вигляді за посиланням: http://reyestr.court.gov.ua'
		],
		quizOpen: B10AD
	},
	{
		id: OTI_2A,
		title:
			'Вкажіть позитивну різницю між сумою коштів, отриманих Вами внаслідок відмови від участі або відступлення іншим особам прав участі у фонді фінансування будівництва (у тому числі якщо таке відступлення здійснено на підставі договору купівлі-продажу), та сумою коштів, внесених Вами до такого фонду.',
		xmlId: R0109G3,
		name: OTI_2A,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)',
		helpers: [
			'Підказка 1. Дохід не виникає у випадках, коли Ви одночасно передали кошти, отримані з фонду фінансування будівництва, в управління тому самому управителю у той самий або інший фонд фінансування будівництва.'
		],
		quizOpen: B10AE
	},
	{
		id: OTI_2B,
		title:
			'Вкажіть позитивну різницю у вигляді коштів, отриманих Вами від інших осіб внаслідок відступлення на їх користь права вимоги за договором про участь у фонді фінансування будівництва (у тому числі, якщо таке відступлення здійснено на підставі договору купівлі-продажу), та сумою коштів, внесених Вами до такого фонду за цим договором.',
		xmlId: R0109G3,
		name: OTI_2B,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)',
		helpers: [],
		quizOpen: B10AE
	},
	{
		id: OTI_3,
		title:
			"Вкажіть суму грошового доходу, отриманого Вами у вигляді неустойки (штрафів, пені), відшкодування матеріальної або немайнової (моральної) шкоди, крім тих, які визначені в абзацах а-ґ п. 164.2.14 ст. 164 ПКУ",
		xmlId: R0109G3,
		name: OTI_3,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)',
		helpers: [
			'ПІДКАЗКА 1: До виключень відносяться: а) суми, що за рішенням суду спрямовуються на відшкодування збитків, завданих платнику податку внаслідок заподіяння йому матеріальної шкоди, а також шкоди життю та здоров\'ю; б) відсотки, отриманих від боржника внаслідок прострочення виконання ним договірного зобов\'язання; в) пеня, що сплачується на користь платника податку за рахунок бюджету (цільового страхового фонду) внаслідок несвоєчасного повернення надміру сплачених грошових зобов\'язань або інших сум бюджетного відшкодування; г) суми втрат, заподіяних платнику податку актами, визнаними неконституційними, або незаконними рішеннями, діями чи бездіяльністю органів, що здійснюють оперативно-розшукову діяльність, органів досудового розслідування, прокуратури або суду, що відшкодовуються державою в порядку, встановленому законом; ґ) виплати з державного бюджету, пов\'язані з виконанням рішень закордонних юрисдикційних органів, у тому числі Європейського суду з прав людини, прийнятих за наслідками розгляду справ проти України.',
			'ПІДКАЗКА 2:  Дія цього підпункту не поширюється на оподаткування сум страхових виплат, страхових відшкодувань і викупних сум за договорами страхування.'
		],
		quizOpen: B10AF
	},
	{
		id: OTI_4,
		title:
			'Вкажіть суму позитивної різниці між сумою коштів, витрачених на придбання права вимоги за депозитом (вкладом) та коштів, отриманих у результаті реалізації права вимоги такого депозиту (вкладу).',
		xmlId: R0109G3,
		name: OTI_4,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)',
		helpers: [
			'Підказка. Дія цього підпункту не поширюється на оподаткування сум страхових виплат, страхових відшкодувань і викупних сум за договорами страхування.'
		],
		quizOpen: B10AL
	},
	{
		id: OTI_5,
		title:
			'Вкажіть суму несплаченої заборгованості в сумі більше ніж 881  грн. за розрахунками по укладеним цивільно-правовим договорам за яким минув строк позовної давності у звітному році?',
		xmlId: R0109G3,
		name: OTI_5,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)',
		helpers: [
			'Підказка: Строк позовної давності = 1095 днів 50 % від місячного прожиткового мінімуму на 01.01.2018 року = 1762 /2 =881 грн.'
		],
		quizOpen: B10AB
	},
	{
		id: OTI_6,
		title: 'Вкажіть суму інших доходів, отриманих упродовж звітного року.',
		xmlId: R0109G3,
		name: OTI_6,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)',
		helpers: [],
		quizOpen: B10AМ
	},
	{
		id: OTI_7,
		title:
			'Вкажіть суму виявлених Вами або нарахованих контролюючими органами доходів  у звітному році,  не включених до розрахунку загальних  доходів минулих податкових періодів.',
		xmlId: R0109G3,
		name: OTI_7,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)',
		helpers: [],
		quizOpen: B10AA
	}
];
