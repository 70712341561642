import {
	ONTI1,
	ONTI1A,
	ONTI1B,
	ONTI1AA,
	ONTI1AB,
	// ONTI1AC,
	ONTI2,
	ONTI3,
	ONTI3A,
	ONTI3B,
	ONTI3AA,
	ONTI4,
	ONTI4A,
	ONTI4B,
	ONTI4AA,
	ONTI5,
	ONTI5A,
	ONTI5B,
	ONTI5AA,
	ONTI6,
	ONTI6A,
	ONTI6B,
	ONTI6AA,
	ONTI7,
	ONTI7A,
	ONTI7B,
	ONTI7AA,
	ONTI8,
	ONTI8A,
	ONTI8B,
	ONTI8AA,
	ONTI9,
	ONTI9A,
	ONTI9B,
	ONTI9AA,
	ONTI10,
	ONTI10A,
	ONTI10AA,
	ONTI10B,
	ONTI10BA,
	ONTI10C,
	ONTI10CA,
	ONTI10D,
	ONTI10DA,
	ONTI11,
	ONTI11A,
	ONTI11B,
	ONTI11AA,
	ONTI12,
	ONTI12A,
	ONTI12B,
	ONTI12AA,
	ONTI13,
	ONTI13A,
	ONTI13B,
	ONTI13AA,
	ONTI14,
	ONTI14A,
	ONTI14B,
	ONTI14AA,
	ONTI15,
	ONTI15A,
	ONTI15B,
	ONTI15AA,
	ONTI16,
	ONTI16A,
	ONTI16B,
	ONTI16AA,
	ONTI17,
	ONTI17A,
	ONTI17B,
	ONTI17AA
} from '../_declaretion_answer_id';

import { R0109G3, R0111G3, R0113G3 } from '../_xmlConstId';
import { B10AJ } from '../../_questions_OLD';
import { INPUT, RADIO, CHECKBOX } from '../../elementTypes';

export const ONTI = [
	//! 1 Q RB
	{
		id: ONTI1,
		title:
			'Ви отримували доходи від продажу власної сільськогосподарської продукції, що вирощена, відгодована, виловлена, зібрана, виготовлена, вироблена, оброблена та/або перероблена безпосередньо Вами на земельних ділянках, наданих у розмірах, встановлених Земельним кодексом України (http://zakon0.rada.gov.ua/laws/show/2768-14), для ведення садівництва та/або будівництва і обслуговування житлового будинку, господарських будівель і споруд (присадибні ділянки) та/або для індивідуального дачного будівництва та особистого селянського господарства та/або земельні частки (паї), виділені в натурі (на місцевості)',
		name: ONTI1,
		quizOpen: [B10AJ],
		answers: [
			{
				id: ONTI1A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI1_g'
			},
			{
				id: ONTI1B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI1_g'
			}
		]
	},
	//First RB > input
	{
		id: ONTI1AA,
		title: 'Вкажіть суму доходу отриманого від продажу власної сільськогосподарської продукції ',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI1AA,
		placeholder: 'Вкажіть суму'
	},
	{
		id: ONTI1AB,
		title:
			' Вкажіть суму доходу отриманого від продажу власної продукції тваринництва груп 1-5, 15, 16 та 41 УКТ ЗЕД у сумі що перевищує 50 розмірів мінімальної заробітної плати встановленої законом на 1 січня звітного (податкового) року або доходу від продажу продукції з ділянок, що перевищує 2 гектари, або за відсутності належним чином оформлених довідок',
		element: INPUT,
		xmlId: R0109G3,
		name: ONTI1AB,
		placeholder: 'Вкажіть суму'
	},
	// {
	// 	id: ONTI1AC,
	// 	title:
	// 		'2. Користуючись відомостями з Державного реєстру фізичних осіб – платників податків про суми виплачених доходів та утриманих податків, визначте суми ПДФО та ВЗ, які були утримані та перераховані до бюджету податковими агентами.3. Визначте суму податків, яка підлягає визначенню в декларації',
	// 	element: INPUT,
	// 	name: ONTI1AC,
	// 	placeholder: ''
	// },
	//! 2 Q
	{
		id: ONTI2,
		title:
			'Вкажіть суму доходу за здані (продані) вторинну сировину, побутові відходи, брухт чорних металів, брухт кольорових металів, включаючи використані (виснажені) акумулятори електричні свинцево-кислотні, залишки та брухт електричних акумуляторів із вмістом свинцю, та брухт дорогоцінних металів, проданий Національному банку України',
		element: INPUT,
		xmlId: R0109G3,
		name: ONTI2,
		placeholder: 'Вкажіть суму'
	},
	//! 3 Q
	{
		id: ONTI3,
		name: ONTI3,
		title:
			'Ви отримували доходи як фізична особа – платник єдиного податку за спрощеною системою оподаткування упродовж звітного року? ',
		helpers: [
			'Підказка: за посиланням можна перевірити чи є особа зареєстрованою як фізична особа - підприємець платник єдиного податку: https://cabinet.sfs.gov.ua/registers/edpod'
		],
		quizOpen: [],
		answers: [
			{
				id: ONTI3A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI3_g'
			},
			{
				id: ONTI3B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI3_g'
			}
		]
	},
	//First RB > input
	{
		id: ONTI3AA,
		title: 'Вкажіть суму доходів',
		element: INPUT,
		xmlId: R0111G3,
		name: ONTI3AA,
		placeholder: 'Вкажіть суму'
	},
	//! 4 Q
	{
		id: ONTI4,
		title:
			'Ви отримували суму грошового або майнового утримання чи забезпечення як військовослужбовець строкової служби (у тому числі як особа, що проходить альтернативну службу), яка виплачується з бюджету?',
		name: ONTI4,
		quizOpen: [],
		answers: [
			{
				id: ONTI4A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI4_g'
			},
			{
				id: ONTI4B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI4_g'
			}
		]
	},
	//First RB > input
	{
		id: ONTI4AA,
		title: 'Вкажіть суму грошового або майнового забезпечення, яку Ви отримали упродовж звітного року ',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI4AA,
		placeholder: 'Вкажіть суму'
	},
	//! 5 Q
	{
		id: ONTI5,
		title:
			'Ви отримували товари як гарантійну заміну у порядку, встановленому законом, а також грошову компенсацію вартості таких товарів, надану в разі їхнього повернення, але не вищу ніж ціна придбання таких товарів?',
		name: ONTI5,
		quizOpen: [],
		answers: [
			{
				id: ONTI5A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI5_g'
			},
			{
				id: ONTI5B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI5_g'
			}
		]
	},
	//First RB > input
	{
		id: ONTI5AA,
		title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI5AA,
		placeholder: 'Вкажіть суму'
	},
	//! 6 Q
	{
		id: ONTI6,
		title:
			"Ви отримували кошти або вартість майна (нематеріальних активів) за рішенням суду в результаті поділу спільної сумісної власності подружжя у зв'язку з розірванням шлюбу чи визнанням його недійсним або за добровільним рішенням сторін?",
		name: ONTI6,
		quizOpen: [],
		answers: [
			{
				id: ONTI6A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI6_g'
			},
			{
				id: ONTI6B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI6_g'
			}
		]
	},
	//First RB > input
	{
		id: ONTI6AA,
		title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI6AA,
		placeholder: 'Вкажіть суму'
	},
	//! 7 Q
	{
		id: ONTI7,
		title:
			'Ви отримували кошти в рахунок компенсації (відшкодування) вартості майна (нематеріальних активів), примусово відчуженого державою, або компенсацію у не грошовій формі?',
		name: ONTI7,
		quizOpen: [],
		answers: [
			{
				id: ONTI7A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI7_g'
			},
			{
				id: ONTI7B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI7_g'
			}
		]
	},
	//First RB > input
	{
		id: ONTI7AA,
		title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI1AA,
		placeholder: 'Вкажіть суму'
	},
	//! 8 Q
	{
		id: ONTI8,
		title:
			'Ви отримували доходи внаслідок примусової реалізації заставленого майна фінансовою установою у зв’язку з невиконанням Вами своїх зобов’язань за договором кредиту (позики) за умови, що таке майно було придбано за рахунок такого кредиту (позики)?',
		name: ONTI8,
		quizOpen: [],
		answers: [
			{
				id: ONTI8A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI8_g'
			},
			{
				id: ONTI8B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI8_g'
			}
		]
	},
	//First RB > input
	{
		id: ONTI8AA,
		title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI8AA,
		placeholder: 'Вкажіть суму'
	},
	//! 9 Q
	{
		id: ONTI9,
		title:
			"Вам повертались надмірно сплачені грошові зобов'язання, страхові внески з бюджетів або державних цільових страхових фондів та/або бюджетне відшкодування внаслідок застосування права на податкову знижку?",
		name: ONTI9,
		quizOpen: [],
		answers: [
			{
				id: ONTI9A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI9_g'
			},
			{
				id: ONTI9B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI9_g'
			}
		]
	},
	{
		id: ONTI9AA,
		title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI9AA,
		placeholder: 'Вкажіть суму'
	},
	//! 10 Q
	// //! ПРимер чекбокса ниже я тебе прописал
	{
		// MAIN QUESTION
		id: ONTI10,
		title:
			'Ви отримували вугілля та вугільні або торф’яні брикети, безоплатно надані в обсягах та за переліком професій, що встановлені Кабінетом Міністрів України, у тому числі компенсації вартості такого вугілля та вугільних або торф’яних брикетів працівнику категорії, до якої Ви відноситесь:',
		name: ONTI10,
		quizOpen: [],
		question_title: true
	},

	{
		parent: ONTI10,
		id: ONTI10A,
		title: 'працівники з видобутку (переробки) вугілля та вуглебудівних підприємств',
		name: ONTI10A,
		quizOpen: [],
		element: CHECKBOX,
		answers: [
			{
				id: ONTI10AA,
				xmlId: R0113G3,
				title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
				name: ONTI10AA,
				element: INPUT,
				placeholder: 'Вкажіть суму'
			}
		]
	},

	{
		parent: ONTI10,
		id: ONTI10B,
		title:
			'пенсіонери, які пропрацювали на підприємствах з видобутку (переробки) вугілля, вуглебудівних підприємствах: на підземних роботах – не менше ніж 10 років для чоловіків і не менше ніж 7 років 6 місяців для жінок; на роботах, пов’язаних із підземними умовами, – не менше ніж 15 років для чоловіків і не менше ніж 12 років 6 місяців для жінок; на роботах технологічної лінії на поверхні діючих шахт чи на шахтах, що будуються, розрізах, збагачувальних та брикетних фабриках – не менше ніж 20 років для чоловіків і не менше ніж 15 років для жінок',
		name: ONTI10B,
		quizOpen: [],
		element: CHECKBOX,
		answers: [
			{
				id: ONTI10BA,
				xmlId: R0113G3,
				title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
				name: ONTI10BA,
				element: INPUT,
				placeholder: 'Вкажіть суму'
			}
		]
	},

	{
		parent: ONTI10,
		id: ONTI10C,
		title:
			'інваліди і ветерани війни та праці, особи, нагороджені знаками «Шахтарська слава» або «Шахтарська доблесть» I, II, III ступенів, особам, інвалідність яких настала внаслідок загального захворювання, коли вони користувалися цим правом до настання інвалідності',
		name: ONTI10C,
		quizOpen: [],
		element: CHECKBOX,
		answers: [
			{
				id: ONTI10CA,
				xmlId: R0113G3,
				title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
				name: ONTI10CA,
				element: INPUT,
				placeholder: 'Вкажіть суму'
			}
		]
	},

	{
		parent: ONTI10,
		id: ONTI10D,
		title:
			'член сім’ї працівників, які загинули (померли) на підприємствах з видобутку (переробки) вугілля, що отримують пенсії у зв’язку із втратою годувальника, а також вдовам померлих пенсіонерів, зазначених у цьому підпункті, які за життя мали таке право',
		name: ONTI10D,
		quizOpen: [],
		element: CHECKBOX,
		answers: [
			{
				id: ONTI10DA,
				xmlId: R0113G3,
				title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
				name: ONTI10DA,
				element: INPUT,
				placeholder: 'Вкажіть суму'
			}
		]
	},
	//! 11 Q
	{
		id: ONTI11,
		title:
			'Ви отримували дохід за здавання крові, грудного жіночого молока, інших видів донорства, які оплачуються з бюджету чи бюджетною установою?',
		name: ONTI11,
		quizOpen: [],
		answers: [
			{
				id: ONTI11A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI11_g'
			},
			{
				id: ONTI11B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI11_g'
			}
		]
	},
	{
		id: ONTI11AA,
		title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI11AA,
		placeholder: 'Вкажіть суму'
	},
	//! 12 Q
	{
		id: ONTI12,
		title: 'Ви підвищували кваліфікацію (перепідготовку) за рахунок Вашого Роботодавця?',
		name: ONTI12,
		quizOpen: [],
		answers: [
			{
				id: ONTI12A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI12_g'
			},
			{
				id: ONTI12B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI12_g'
			}
		]
	},
	{
		id: ONTI12AA,
		title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI12AA,
		placeholder: 'Вкажіть суму'
	},
	//! 13 Q
	{
		id: ONTI13,
		title:
			'Ви отримували ордени, медалі, знаки, кубки, дипломи, грамоти та квіти як працівник та/або переможець змагань, конкурсів?',
		name: ONTI13,
		quizOpen: [],
		answers: [
			{
				id: ONTI13A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI13_g'
			},
			{
				id: ONTI13B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI13_g'
			}
		]
	},
	{
		id: ONTI13AA,
		title: 'Вкажіть вартість нагород, які Ви отримали упродовж звітного року',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI13AA,
		placeholder: 'Вкажіть суму'
	},
	//! 14 Q
	{
		id: ONTI14,
		title:
			'Ви здійснювали майнові та немайнові внески до статутного фонду юридичної особи в обмін на корпоративні права?',
		name: ONTI14,
		quizOpen: [],
		answers: [
			{
				id: ONTI14A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI14_g'
			},
			{
				id: ONTI14B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI14_g'
			}
		]
	},
	{
		id: ONTI14AA,
		title: 'Вкажіть вартість корпоративних прав, отриманих упродовж звітного року',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI14AA,
		placeholder: 'Вкажіть суму'
	},
	//! 15 Q
	{
		id: ONTI15,
		title:
			'Ви здійснювали збирання лісової підстилки, заготівлю лікарських рослин, очерету та інших побічних лісових користувань для власного споживання?',
		name: ONTI15,
		quizOpen: [],
		answers: [
			{
				id: ONTI15A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI15_g'
			},
			{
				id: ONTI15B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI15_g'
			}
		]
	},
	{
		id: ONTI15AA,
		title: 'Вкажіть вартість заготівельних матеріалів за звітний рік',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI15AA,
		placeholder: 'Вкажіть суму'
	},
	//! 16 Q
	{
		id: ONTI16,
		title:
			' Ви отримували доходи від операцій (купівля-продаж, обмін) з валютними цінностями (крім цінних паперів), пов’язаних з переходом права власності на такі валютні цінності, за винятком доходів, оподаткування яких прямо передбачено Податковим кодексом України?',
		name: ONTI16,
		quizOpen: [],
		answers: [
			{
				id: ONTI16A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI16_g'
			},
			{
				id: ONTI16B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI16_g'
			}
		]
	},
	{
		id: ONTI16AA,
		title: 'Вкажіть суму доходів, яку Ви отримали упродовж звітного року',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI16AA,
		placeholder: 'Вкажіть суму'
	},
	//! 17 Q
	{
		id: ONTI17,
		title:
			'Ви отримували інші доходи, які згідно з Податковим кодексом України не включаються до складу загального місячного (річного) оподатковуваного доходу?',
		name: ONTI17,
		quizOpen: [],
		answers: [
			{
				id: ONTI17A,
				title: 'Да',
				element: RADIO,
				name: 'ONTI17_g'
			},
			{
				id: ONTI17B,
				title: 'Ні',
				element: RADIO,
				name: 'ONTI17_g'
			}
		]
	},
	{
		id: ONTI17AA,
		title: ' Вкажіть суму отриманих доходів',
		element: INPUT,
		xmlId: R0113G3,
		name: ONTI17AA,
		placeholder: ' Вкажіть суму'
	}
];
