import React from 'react';

import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';

import InputTextField from '../../../components/InputTextField';
import { StyledButton } from '../../../components/Button/Button';

const LoginFormShema = yup.object().shape({
	email: yup
		.string()
		.email('Не дійсний адреса електронної пошти')
		.required("Це поле обов'язкове"),
	password: yup
		.string()
		.min(9, 'Пароль повинен бути більше 9 символів')
		.required("Це поле обов'язкове")
});

const LoginForm = props => {
	const { handleSubmit, isValid, isSubmitting } = props;
	return (
		<div className="form form__login">
			<h1 className="h1">Вхід</h1>
			<p className="sub-title">за допомогою електронної пошти</p>
			<Grid container direction="column" justify="center">
				<Field component={InputTextField} label="Електорнна пошта" type="text" name="email" />
				<Field component={InputTextField} label="Пароль" type="password" name="password" />
				<Link to="/login/fogot" className="form_link__fogot">
					Забули пароль?
				</Link>
				<StyledButton
					disabled={!isValid || isSubmitting}
					className="login-btn"
					variant="contained"
					type="submit"
					onClick={handleSubmit}
				>
					Увійти
				</StyledButton>
			</Grid>
		</div>
	);
};

export default withFormik({
	mapPropsToValues: () => ({
		email: '',
		password: ''
	}),
	validationSchema: LoginFormShema,
	handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
		await props.handleLogin(values, setSubmitting, setErrors);
	}
})(LoginForm);
