import Axios from 'axios';
import { api } from '../configs';

class Api {
	constructor() {
		this.axios = Axios.create({
			baseURL: this.getDefaultUrl(),
			timeout: 30000
		});
	}

	getDefaultUrl() {
		return [api.prod.protocol, api.prod.basePath].join('');
	}

	setRequestHeaders() {
		const api_token = localStorage.getItem('api_token');

		if (api_token) {
			this.axios.defaults.headers.common['Authorization'] = `Bearer ${api_token}`;
		}
		// this.axios.defaults.post['Content-Type'] = 'application/json';
		// this.axios.defaults.post['Accept'] = 'application/json';
		this.axios.defaults.headers.common = {};
		this.axios.defaults.headers.common['Accept'] = 'application/json';
		this.axios.defaults.headers.common['Content-Type'] = 'application/json';
		console.dir(this.axios);
	}

	get(relUrl, data, conf, baseUrl) {
		baseUrl = baseUrl || this.axios.defaults.baseURL;

		return this.axios
			.get(baseUrl + relUrl, data)
			.then(response => {
				return response;
			})
			.catch(error => {
				console.error(error);
			});
	}

	post(relUrl, data, conf, baseUrl) {
		baseUrl = baseUrl || this.axios.defaults.baseURL;
		return this.axios
			.post(baseUrl + relUrl, data, {
				headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
			})
			.then(response => response)
			.catch(error => {
				console.error(error, '💩');
			});
	}

	put(relUrl, data, conf, baseUrl) {
		baseUrl = baseUrl || this.axios.defaults.baseURL;

		return this.axios
			.put(baseUrl + relUrl, data)
			.then(response => {
				return response;
			})
			.catch(error => {
				console.error(error);
			});
	}

	delete(relUrl, data, conf, baseUrl) {
		baseUrl = baseUrl || this.axios.defaults.baseURL;

		return this.axios
			.delete(baseUrl + relUrl, data)
			.then(response => {
				return response;
			})
			.catch(error => {
				console.error(error);
			});
	}

	// handleError(error) {
	//     if (error.response) {
	//         // The request was made and the server responded with a status code
	//         // that falls out of the range of 2xx
	//         let errorObj = this.handleBadStatus(error.response);

	//         if (errorObj)
	//             throw errorObj;

	//     }
	//     else {
	//         if (error.request) {
	//         // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the
	//         // browser and an instance of http.ClientRequest in node.js

	//             if (error && error.message == "Network Error") {
	//                 throw new Error(this.getTranslate('error.network'));
	//             }
	//             else {
	//                 throw new Error(error.message);
	//             }
	//         }
	//         else {
	//             // Something happened in setting up the request that triggered an Error

	//             throw new Error(error.message);
	//         }
	//     }
	// }
}

export default Api;
