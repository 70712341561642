import * as tableCells from './T1RXXXX';

export const R01G4 = function(formValues) {
	return tableCells.T1RXXXXG4(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R01G5 = function(formValues) {
	return tableCells.T1RXXXXG5(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R01G6 = function(formValues) {
	return tableCells.T1RXXXXG6(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R01G7 = function(formValues) {
	return tableCells.T1RXXXXG7(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R01G8 = function(formValues) {
	return tableCells.T1RXXXXG8(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

export const R01G9 = function(formValues) {
	return tableCells.T1RXXXXG9(formValues).reduce((acc, cur) => {
		return acc + cur;
	}, 0);
};

const R022XG3 = function(formValues, group_code) {
	let values = tableCells.T1RXXXXG8_raw(formValues);

	return values
		.map(value => {
			let val = parseFloat(value[group_code].start_value);

			return isNaN(val) ? 0 : val;
		})
		.reduce((acc, value) => {
			return acc + value;
		}, 0);
};

const R022XG4 = function(formValues, group_code) {
	let values = tableCells.T1RXXXXG8_raw(formValues);

	return values
		.map(value => {
			let val = parseFloat(value[group_code].end_value);

			return isNaN(val) ? 0 : val;
		})
		.reduce((acc, value) => {
			return acc + value;
		}, 0);
};

const R022XG5 = function(formValues, group_code) {
	let values = tableCells.T1RXXXXG8_raw(formValues);

	return values
		.map(value => {
			let val_1 = parseFloat(value[group_code].start_value);
			let val_2 = parseFloat(value[group_code].end_value);

			return (isNaN(val_1) ? 0 : val_1) - (isNaN(val_2) ? 0 : val_2);
		})
		.reduce((acc, value) => {
			return acc + value;
		}, 0);
};

export const R0221G3 = function(formValues) {
	let result = R022XG3(formValues, 'A1');

	return result;
};

export const R0221G4 = function(formValues) {
	let result = R022XG4(formValues, 'A1');

	return result;
};

export const R0221G5 = function(formValues) {
	//return R0221G3(formValues) - R0221G4(formValues);
	let result = R022XG5(formValues, 'A1');

	return result;
};

export const R0222G3 = function(formValues) {
	let result = R022XG3(formValues, 'A2');

	return result;
};

export const R0222G4 = function(formValues) {
	let result = R022XG4(formValues, 'A2');

	return result;
};

export const R0222G5 = function(formValues) {
	//return R0222G3(formValues) - R0222G4(formValues);
	let result = R022XG5(formValues, 'A2');

	return result;
};

export const R0223G3 = function(formValues) {
	let result = R022XG3(formValues, 'A3');

	return result;
};

export const R0223G4 = function(formValues) {
	let result = R022XG4(formValues, 'A3');

	return result;
};

export const R0223G5 = function(formValues) {
	//return R0223G3(formValues) - R0223G4(formValues);
	let result = R022XG5(formValues, 'A3');

	return result;
};

export const R0224G3 = function(formValues) {
	let result = R022XG3(formValues, 'A4');

	return result;
};

export const R0224G4 = function(formValues) {
	let result = R022XG4(formValues, 'A4');

	return result;
};

export const R0224G5 = function(formValues) {
	//return R0224G3(formValues) - R0224G4(formValues);
	let result = R022XG5(formValues, 'A4');

	return result;
};

export const R022G5 = function(formValues) {
	let result = R0221G5(formValues) + R0222G5(formValues) + R0223G5(formValues) + R0224G5(formValues);

	return result;
};

// (додатне значення графи 9 рядка "УСЬОГО" розділу І × на ставку податку (18 % )
export const R011G3 = function(formValues) {
	let result = R01G9(formValues);

	return result > 0 ? (result / 100) * 18 : 0;
};

//Сума податку на доходи фізичних осіб самостійно нарахована платником податку протягом звітного (податкового) року за
//результатами останього базового (звітного) періоду, у т.ч. у разі припинення підприємницької діяльності фізичної
//особи - підприємця протягом звітного (податкового) року
export const R012G3 = function(formValues) {
	return parseFloat(formValues['R0107G3']['a']['extra_1']);
};

//Загальна сума авансових платежів з податку на доходи фізичних осіб, що розраховані платником податку самостійно згідно
//з фактичними даними, зазначеними у Книзі обліку доходів і витрат кожного календарного кварталу (крім четвертого) та
//сплачені до бюджету протягом звітного (податкового) року
export const R013G3 = function(formValues) {
	return parseFloat(formValues['R0107G3']['a']['extra_2']);
};

//Сума надміру сплаченого податку, що підлягає зарахуванню в рахунок майбутніх платежів або поверненню платнику згідно
//з підпунктом 177.5.3 пункту 177.5 статті 177 розділу IV ПКУ (від'ємне значення (рядок 1.1 ‐ рядок 1.3))
//(значення вказується без знака " ‐")
export const R014G3 = function(formValues) {
	let result = R011G3(formValues) - R013G3(formValues);

	return result < 0 ? result * -1 : 0;
};

//Сума податку на доходи фізичних осіб, що підлягає сплаті до бюджету самостійно платником податку за результатами
//звітного (податкового) року (рядок 1.1 -рядок 1.2)
// !!!(сума переноситься до графи 6 рядка 10.7 декларації)
export const R015G3 = function(formValues) {
	return R011G3(formValues) - R012G3(formValues);
};

// Розрахована сума податкових зобовязань з податку на доходи фізичних осіб за результатами звітного (податкового)
// року, що підлягає сплаті до бюджету (додатне значення графи 9 рядка "УСЬОГО" розділу І × на ставку збору (1,5 %))
export const R021G3 = function(formValues) {
	let result = R01G9(formValues);

	return result > 0 ? (result / 100) * 1.5 : 0;
};

// Сума військового збору самостійно нарахована платником податку протягом звітного (податкового) року за результатами
// останього базового (звітного) періоду, у т.ч. у разі припинення підприємницької діяльності фізичної особи - підприємця
// протягом звітного (податкового) року
export const R022G3 = function(formValues) {
	return parseFloat(formValues['R0107G3']['a']['extra_3']);
};

// Сума військового збору, що підлягає сплаті до бюджету самостійно платником податку за результатами звітного (податкового) року
// (рядок 2.1 - рядок 2.2)
// (сума переноситься до графи 7 рядка 10.7 декларації)
export const R023G3 = function(formValues) {
	return R021G3(formValues) - R022G3(formValues);
};
