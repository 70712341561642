import React from 'react';
import TextField from '@material-ui/core/TextField';

const InputForm = ({ item, change, answer }) => {
	return (
		<>
			<TextField
				id={item.id}
				label={item.placeholder || item.title}
				name={item.name}
				value={answer[`${item.id}_input`]}
				onChange={change}
				style={{ width: '100%' }}
				helperText={item.helper}
			/>
			<br />
		</>
	);
};

export default InputForm;
