import { connect } from 'react-redux';

import OFI from '../subsections/OFI';
import { getOfiData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.OFI.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getOfiData
};

const OFIContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(OFI);

export default OFIContainer;
