import React, { Component } from 'react';

export default class htmlData110 extends Component {
	constructor(props) {
		super(props);
		this.userData = this.props.userData;
	}

	render() {
		return (
			<div className={'doc-preview'}>
				<div>F0100110</div>
				<div className="doc-preview-container">
					<div className="grid-x doc-preview--row">
						<div className={'cell auto'}>
							<div className="grid-x">
								<div className="cell auto text-center ">
									<div className={'border-bottom-right f-9'}>
										<div>Відмітка про одержання</div>
										<div>(штамп контролюючого органу, дата, вхідний номер)</div>
									</div>
								</div>
								<div className="cell auto align-center">&nbsp;</div>
								<div className="cell auto align-left f-9">
									ЗАТВЕРДЖЕНО
									<br />
									Наказ Міністерства фінансів України
									<br />
									02 жовтня 2015 року No 859
									<br />
									(у редакції наказу Міністерства фінансів України
									<br />
									від 06.06.2017 року No 556)
									<br />
								</div>
							</div>
							<div className="grid-x">
								<div className="cell auto text-center ">
									<div className={'f-11-b'}>
										<div>ПОДАТКОВА ДЕКЛАРАЦІЯ</div>
										<div>ПРО МАЙНОВИЙ СТАН І ДОХОДИ</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="grid-x doc-preview--row-lrb">
						<div className={'cell auto f-10-b text-center'}>І. ЗАГАЛЬНІ ВІДОМОСТІ</div>
					</div>
					<div className="grid-x doc-preview--row-lrb">
						<div className={'cell shrink f-12-b text-center p-3'}>1</div>
						<div className={'cell large-3 f-10-b'}>
							Тип декларації:
							<div className="grid-x">
								<div className="cell shrink ">{this.cell('HZ', 'Звітна')}</div>
								<div className="cell shrink ">{this.cell('HZN', 'Звітна нова')}</div>
								<div className="cell shrink ">{this.cell('HZU', 'Уточнююча')}</div>
							</div>
						</div>
						<div className={'cell shrink f-12-b text-center p-3'}>2</div>
						<div className={'cell auto f-12-b '}>
							<div className="grid-x">
								<div className="cell large-6 ">
									Звітний (податковий) період:
									<div className="grid-x">
										<div className="cell shrink ">{this.cell('HZY', 'рік')}</div>
										<div className="cell shrink ">{this.cell('HZKV', 'квартал1')}</div>
									</div>
								</div>
								<div className="cell large-6 ">
									Звітний (податковий) період, що уточнюється:
									<div className="grid-x">
										<div className="cell shrink ">{this.cell('HZYP', 'рік')}</div>
										<div className="cell shrink ">{this.cell('HZKVP', 'квартал1')}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="grid-x doc-preview--row-lrb">
						<div className={'cell auto f-10-b text-center'}>Інформація про платника податку</div>
					</div>
				</div>
			</div>
		);
	}

	cell = (key, label = false) => {
		return (
			<div className="grid-x ">
				<div className={'cell shrink inner-cell'}>{this.getValue(key)}</div>
				{label && <div className={'cell shrink f-10 inner-cell-label'}>{label}</div>}
			</div>
		);
	};

	getValue = key => {
		let val = '&nbsp;';

		if (this.getUserData().hasOwnProperty(key)) {
			val = this.getUserData()[key];

			if (typeof val === 'boolean') {
				val = val === true ? 'X' : '&nbsp;';
			} else if (val === null) {
				val = '&nbsp;';
			}
		}

		return val;
	};

	getUserData = () => {
		return this.userData;
	};
}
