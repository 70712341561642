import { get_form_data_definition } from '../../pages/declaration/types/F01002/form_data';
import { convertToDeclarationObject } from '../../pages/declaration/types/misc/misc';

export const GET_CURRENT_DECLARATION_INDEX = 'GET_CURRENT_DECLARATION_INDEX';

export const getCurrentDeclarationIndex = index => {
	return {
		type: GET_CURRENT_DECLARATION_INDEX,
		payload: index
	};
};

export const GET_DECLARATIONS = 'GET_DECLARATIONS';

export const getDeclarations = () => {
	return {
		type: GET_DECLARATIONS
	};
};

//! Declaration final to xml
export const CALCULATE_DECLARATION = 'CALCULATE_DECLARATION';
export const GET_ALL_DATA = 'GET_ALL_DATA';

const getAllData = data => ({
	type: GET_ALL_DATA,
	payload: data
});

const migrate_data = (...datas) => {
	let data = get_form_data_definition();

	let result = datas.reduce((acc, obj) => {
		Object.keys(obj).map(key => {
			const objA = obj[key]['a'];
			const objQ = obj[key]['q'];

			if (objA || objQ) {
				acc[key]['a'] = { ...acc[key]['a'], ...objA }; //
				acc[key]['q'] = { ...acc[key]['q'], ...objQ }; //
			} else {
				acc[key] = obj[key];
			}
			return key;
		});

		return acc;
	}, data);
	return result;
};

export const calculateDeclaration = () => (dispatch, getSate) => {
	const {
		declaration: {
			Common,
			P,
			W,
			ID,
			Disc,
			OFI,
			OTI,
			WP,
			IP,
			C,
			R,
			ALIM,
			LEASE,
			S,
			INT,
			D,
			ADDB,
			ONTI,
			SALEPRO,
			INVESTP,
			IPA
		}
	} = getSate();

	dispatch(
		getAllData(
			migrate_data(
				Common.data,
				P.data,
				W.data,
				ID.data,
				Disc.data,
				OFI.data,
				OTI.data,
				WP.data,
				IP.data,
				C.data,
				R.data,
				ALIM.data,
				LEASE.data,
				S.data,
				INT.data,
				D.data,
				ADDB.data,
				ONTI.data,
				SALEPRO.data,
				INVESTP.data,
				IPA.data
			)
		)
	);

	const {
		declaration: { data }
	} = getSate();

	let declaration = convertToDeclarationObject('F01002', data);

	let prepared_data = [declaration];

	if (
		declaration.hasOwnProperty('LINKED_DOCS') &&
		Array.isArray(declaration['LINKED_DOCS']) &&
		declaration['LINKED_DOCS'].length > 0
	) {
		Object.values(declaration['LINKED_DOCS']).map(item => {
			if (item.hasOwnProperty('DOC')) {
				prepared_data.push(item['DOC']);
			}
			return item;
		});
	}

	dispatch({
		type: CALCULATE_DECLARATION,
		payload: prepared_data
	});
};

export const GET_DECLARATION = 'GET_DECLARATION';

export const getDeclaration = data => {
	return {
		type: GET_DECLARATION,
		payload: data
	};
};

/**
 * Subsection actions
 */

export const GET_DECLARATION_ANSWERS = 'GET_DECLARATION_ANSWERS';

export const getDeclarationAnswers = answers => ({
	type: GET_DECLARATION_ANSWERS,
	payload: answers
});

//! SubSection P
export const GET_PROPERTY_SUB_QUESTIONS = 'GET_PROPERTY_SUB_QUESTIONS';

export const getPropertySubquestions = categoryId => {
	return {
		type: GET_PROPERTY_SUB_QUESTIONS,
		payload: categoryId
	};
};

export const ADD_PROPERTY = 'ADD_PROPERTY';

export const addProperty = property => {
	return {
		type: ADD_PROPERTY,
		payload: property
	};
};

export const DELETE_PROPERTY = 'DELETE_PROPERTY';

export const deleteProperty = id => {
	return {
		type: DELETE_PROPERTY,
		payload: id
	};
};

export const GET_PROPERTY_DATA = 'GET_PROPERTY_DATA';

export const getPropertyData = data => {
	return {
		type: GET_PROPERTY_DATA,
		payload: data
	};
};

//! SubSection Common
export const GET_COMMON_DATA = 'GET_COMMON_DATA';

export const getCommonData = data => {
	return {
		type: GET_COMMON_DATA,
		payload: data
	};
};

//! SubSection W
export const GET_WAGES_DATA = 'GET_WAGES_DATA';
export const GET_WAGES_ANSWERS = 'GET_WAGES_ANSWERS';

export const getWagesData = ({ answers, data }) => dispatch => {
	dispatch({
		type: GET_WAGES_ANSWERS,
		payload: answers
	});

	dispatch({
		type: GET_WAGES_DATA,
		payload: data
	});
	dispatch(getDeclarationAnswers(answers));
};

// export const getWagesAnswers = ({ answers }) => ({
// 	type: GET_WAGES_ANSWERS,
// 	paylaod: answers
// });

//! Subsection ID
export const GET_ID_DATA = 'GET_ID_DATA';

export const getIdData = data => {
	return {
		type: GET_ID_DATA,
		payload: data
	};
};

//! Subsection OFI
export const GET_OFI_DATA = 'GET_OFI_DATA';

export const getOfiData = data => {
	return {
		type: GET_OFI_DATA,
		payload: data
	};
};

//! SUBSECTION PENALTY
export const GET_PENALTY_DATA = 'GET_PENALTY_DATA';

export const getPenaltyData = data => {
	return {
		type: GET_PENALTY_DATA,
		payload: data
	};
};

//! Subsection OTI
export const GET_OTI_DATA = 'GET_OTI_DATA';

export const getOtiData = data => {
	return {
		type: GET_OTI_DATA,
		payload: data
	};
};

//! Subsection Disc
export const GET_DISC_DATA = 'GET_DISC_DATA';
export const GET_DISC_ANSWERS = 'GET_DISC_ANSWERS';

export const getDiscData = ({ data, answers }) => dispatch => {
	dispatch({ type: GET_DISC_DATA, payload: data });
	dispatch({ type: GET_DISC_ANSWERS, payload: answers });
};

//! Subsection WP
export const GET_WP_DATA = 'GET_WP_DATA';

export const getWpData = data => ({
	type: GET_WP_DATA,
	payload: data
});

//! Subsection IP
export const GET_IP_DATA = 'GET_IP_DATA';

export const getIpData = data => ({
	type: GET_IP_DATA,
	payload: data
});

//! Subsection C
export const GET_C_DATA = 'GET_C_DATA';

export const getCData = data => ({
	type: GET_C_DATA,
	payload: data
});

//! Subsection R
export const GET_R_DATA = 'GET_R_DATA';

export const getRData = data => ({
	type: GET_R_DATA,
	payload: data
});

//! Subsection ALIM
export const GET_ALIM_DATA = 'GET_ALIM_DATA';

export const getAlimData = data => ({
	type: GET_ALIM_DATA,
	payload: data
});

//! Subsection LEASE
export const GET_LEASE_DATA = 'GET_LEASE_DATA';

export const getLeaseData = data => ({
	type: GET_LEASE_DATA,
	payload: data
});

//! Subsection S
export const GET_S_DATA = 'GET_S_DATA';

export const getSData = data => ({
	type: GET_S_DATA,
	payload: data
});

//! Subsection Int
export const GET_INT_DATA = 'GET_INT_DATA';

export const getIntData = data => ({
	type: GET_INT_DATA,
	payload: data
});

//! Subsection D
export const GET_D_DATA = 'GET_D_DATA';

export const getDData = data => ({
	type: GET_D_DATA,
	payload: data
});

//! Subsection AddB
export const GET_ADDB_DATA = 'GET_ADDB_DATA';

export const getAddBData = data => ({
	type: GET_ADDB_DATA,
	payload: data
});

//! Subsection ONTI
export const GET_ONTI_DATA = 'GET_ONTI_DATA';

export const getOntiData = data => ({
	type: GET_ONTI_DATA,
	payload: data
});

//! Subsection ALEPRO
export const GET_SALEPRO_DATA = 'GET_SALEPRO_DATA';

export const getSaleProData = data => ({
	type: GET_SALEPRO_DATA,
	payload: data
});

//! Subsection InvestP
export const GET_INVESTP_DATA = 'GET_INVESTP_DATA';

export const getInvestPData = data => ({
	type: GET_INVESTP_DATA,
	payload: data
});

//! Subsection IPA
export const GET_IPA_DATA = 'GET_IPA_DATA';

export const getIpaData = data => ({
	type: GET_IPA_DATA,
	payload: data
});

//! Subsection ENTERPRL
export const GET_ENTERPRL_DATA = 'GET_ENTERPRL_DATA';

export const getEnterprlData = data => ({
	type: GET_ENTERPRL_DATA,
	payload: data
});

//! Subsection EI_TA
export const GET_EITA_DATA = 'GET_EITA_DATA';

export const getEITAData = data => ({
	type: GET_EITA_DATA,
	payload: data
});

//! STEPPER

export const SET_STEP_INDEX = 'SET_STEP_INDEX';

export const setStepIndex = index => ({
	type: SET_STEP_INDEX,
	payload: index
});
