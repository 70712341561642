import React from 'react';

import { Grid } from '@material-ui/core';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';

import InputTextField from '../../../../components/InputTextField';
import { StyledButton } from '../../../../components/Button/Button';

const ForgotPasswordFormShema = yup.object().shape({
	email: yup
		.string()
		.email('Не дійсний адреса електронної пошти')
		.required("Це поле обов'язкове")
});

const ForgotPasswordForm = props => {
	const { handleSubmit, isValid, isSubmitting } = props;
	return (
		<div className="form form__login">
			<h1 className="h1">Забули пароль?</h1>
			<p className="sub-title">Введіть електронну пошту, щоб змінити пароль</p>
			<Grid container direction="column" justify="center">
				<Field component={InputTextField} label="Електорнна пошта" type="text" name="email" />
				<StyledButton
					disabled={!isValid || isSubmitting}
					className="fogot-btn"
					variant="contained"
					type="submit"
					onClick={handleSubmit}
				>
					Відправити
				</StyledButton>
			</Grid>
		</div>
	);
};

export default withFormik({
	mapPropsToValues: () => ({
		email: ''
	}),
	validationSchema: ForgotPasswordFormShema,
	handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
		await props.handleSendEmail(values, setSubmitting, setErrors);
	}
})(ForgotPasswordForm);
