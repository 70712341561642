import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const RadioForm = ({ item, change, answer }) => {
	let siblings = item.siblings && item.siblings.join(' ');
	let siblingChildren = item.siblingChildren && item.siblingChildren.join(' ');
	return (
		<FormControlLabel
			control={
				<Radio
					checked={answer && answer[item.name] === item.xmlId}
					onChange={change}
					value={item.xmlId}
					name={item.name}
					color="default"
					aria-label={item.title}
				/>
			}
			label={item.title}
			data-siblings={siblings}
			data-sibling-children={siblingChildren}
		/>
	);
};

export default RadioForm;
