import React, { Component } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';

import './styles.scss';


class Search extends Component {
  render() {
    return (
      <Paper elevation={0} className="search border-bottom no-radius">
          <InputBase placeholder='Search' className="search__input"></InputBase>
          <SearchIcon 
            className="search-icon"
          ></SearchIcon>
      </Paper>
    )
  }
}

export default Search;