import React from 'react';

import InputFeedback from '../InputFeedback';

const RadioGroup = ({ error, touched, label, children, classNameTitle }) => {
	return (
		<div className="question-block-wrap">
			<h3 className={`${classNameTitle ? classNameTitle : 'question-title'}`}>{label}</h3>
			{children}
			<InputFeedback error={error} touched={touched} />
		</div>
	);
};

export default RadioGroup;
