import * as tableCells from './index';

//Загальна сума доходів, які включаються до загального річного оподатковуваного доходу, в тому числі
//(рядок 10.1 + рядок 10.2 + рядок 10.3 + рядок 10.4 + рядок 10.5 + рядок 10.6 + рядок 10.7 + рядок10.8 + рядок 10.9)
export const R010G3 = function(formValues) {
	const cells = [
		'R0101G3',
		'R0102G3',
		'R0103G3',
		'R0104G3',
		'R0105G3',
		'R0106G3',
		'R0107G3',
		'R0108G3',
		'R0109G3',
		'R01091G3'
	];

	return cells
		.map(cell => {
			return tableCells[cell] ? tableCells[cell](formValues) : 0;
		})
		.reduce((acc, cur) => {
			return acc + parseFloat(+cur);
		}, 0);
};

export const R010G4 = function(formValues) {
	const cells = [
		'R0101G4',
		'R0102G4',
		'R0103G4',
		'R0104G4',
		'R0105G4',
		'R0106G4',
		'R0107G4',
		'R0108G4',
		'R0109G4',
		'R01091G4'
	];

	return cells
		.map(cell => {
			return tableCells[cell] ? tableCells[cell](formValues) : 0;
		})
		.reduce((acc, cur) => {
			return acc + cur;
		}, 0);
};

// Сума збору утриманого (сплаченого) податковим агентом
export const R010G5 = function(formValues) {
	const cells = [
		'R0101G5',
		'R0102G5',
		'R0103G5',
		'R0104G5',
		'R0105G5',
		'R0106G5',
		'R0107G5',
		'R0108G5',
		'R0109G5',
		'R01091G5'
	];

	return cells
		.map(cell => {
			return tableCells[cell] ? tableCells[cell](formValues) : 0;
		})
		.reduce((acc, cur) => {
			return acc + cur;
		}, 0);
};

export const R010G6 = function(formValues) {
	const cells = [
		'R0101G6',
		'R0102G6',
		'R0103G6',
		'R0104G6',
		'R0105G6',
		'R0106G6',
		'R0107G6',
		'R0108G6',
		'R0109G6',
		'R01091G6'
	];

	return cells
		.map(cell => {
			return tableCells[cell] ? tableCells[cell](formValues) : 0;
		})
		.reduce((acc, cur) => {
			return acc + cur;
		}, 0);
};

// Сума збору що підлягає сплаті самостійно
export const R010G7 = function(formValues) {
	const cells = [
		'R0101G7',
		'R0102G7',
		'R0103G7',
		'R0104G7',
		'R0105G7',
		'R0106G7',
		'R0107G7',
		'R0108G7',
		'R0109G7',
		'R01091G7'
	];

	return cells
		.map(cell => {
			return tableCells[cell] ? tableCells[cell](formValues) : 0;
		})
		.reduce((acc, cur) => {
			return acc + cur;
		}, 0);
};
