import React, { Fragment } from 'react';

import { Checkbox } from '@material-ui/core';

const CheckboxComponent = ({ color, className, onChange, value, name, label, onClickLabel, id }) => {
	return (
		<Fragment>
			<Checkbox
				color={color}
				className={className}
				onChange={onChange}
				checked={value}
				name={name}
				value={name}
				id={id}
			/>
			<label onClick={onClickLabel} htmlFor={id}>
				{label}
			</label>
		</Fragment>
	);
};

export default CheckboxComponent;
