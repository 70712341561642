export const DISABLE_ENABLE_STEP = 'DISABLE_ENABLE_STEP';

export const CHANGE_QUIZ_VALUES = 'CHANGE_QUIZ_VALUES';

export const HANDLE_CHANGE_STEP = 'HANDLE_CHANGE_STEP';

export const HANDLE_SET_TOUCHED_STEP = 'HANDLE_SET_TOUCHED_STEP';

export const HANDLE_SET_ISVALID_STEP = 'HANDLE_SET_ISVALID_STEP';

export const SET_CREATE_NEW_DECL_VALUES = 'SET_CREATE_NEW_DECL_VALUES';

export const RESET_DATA_STEPPERS = 'RESET_DATA_STEPPERS';
