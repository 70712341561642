import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxForm = ({ item, change, answer }) => {
	return (
		<FormControlLabel
			control={
				<Checkbox id={item.xmlId} checked={answer[item.id]} onChange={change} value={item.id} color="default" />
			}
			label={item.title}
		/>
	);
};

export default CheckboxForm;
