import {
	GET_PROPERTY_SUB_QUESTIONS,
	ADD_PROPERTY,
	DELETE_PROPERTY,
	GET_PROPERTY_DATA
} from '../../actions/declaration_action';
import { property } from '../../../data/declaration/sub-sections/P.new';

const initialState = {
	questions: { ...property },
	subId: null,
	data: {}
};

const addProperty = (state, action) => {
	let key = Object.keys(action.payload)[0];
	switch (action.type) {
		case ADD_PROPERTY:
			return {
				...action.payload[key]
			};

		default:
			return state;
	}
};

const p = (state = initialState, action) => {
	switch (action.type) {
		case GET_PROPERTY_SUB_QUESTIONS:
			return {
				...state,
				subId: action.payload
			};

		case ADD_PROPERTY: {
			let key = Object.keys(action.payload)[0];
			return {
				...state,
				result: {
					...state.result,
					[key]: [...(state.result[key] || []), addProperty(state.result[key], action)]
				},
				subId: null
			};
		}
		case DELETE_PROPERTY:
			return {
				...state
			};

		case GET_PROPERTY_DATA:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default p;
