export const R024G3 = function(formValues) {
	return parseFloat(formValues['R024G3']);
};

export const R024G4 = function(formValues) {
	return parseFloat(formValues['R024G4']);
};

export const R025G3 = function(formValues) {
	return parseFloat(formValues['R025G3']);
};

export const R025G4 = function(formValues) {
	return parseFloat(formValues['R025G4']);
};

export const R0261G3 = function(formValues) {
	return parseFloat(formValues['R0261G3']);
};

export const R0261G4 = function(formValues) {
	return parseFloat(formValues['R0261G4']);
};

export const R0262G3 = function(formValues) {
	return parseFloat(formValues['R0262G3']);
};

export const R0262G4 = function(formValues) {
	return parseFloat(formValues['R0262G4']);
};

export const R027G2 = function(formValues) {
	return parseFloat(formValues['R027G2']);
};

export const R027G3 = function(formValues) {
	return parseFloat(formValues['R027G3']);
};

export const R027G4 = function(formValues) {
	return parseFloat(formValues['R027G4']);
};

export const R028G3 = function(formValues) {
	return parseFloat(formValues['R028G3']);
};

export const R028G4 = function(formValues) {
	return parseFloat(formValues['R028G4']);
};
