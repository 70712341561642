import { precisionRound } from '../../../types/index';

export const R0103G3 = function(formValues) {
	return Object.keys(formValues['R0103G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.reduce((acc, key) => {
			let curr = parseFloat(formValues['R0103G3']['a'][key]);

			return acc + curr;
		}, 0);
};

// // Сума податку утриманого (сплаченого) податковим агентом
// export const R0103G4 = function(formValues) {
// 	return Object.keys(formValues['R0103G3']['a'])
// 		.filter(key => {
// 			// return key != '0' && formValues['R0103G3']['q'][key] == 'yes';
// 			return key != '0';
// 		})
// 		.reduce((acc, key) => {
// 			let tax = parseFloat(formValues[`R0103G4_${key}`]);

// 			return acc + tax;
// 		}, 0);
// };

// // Сума збору утриманого (сплаченого) податковим агентом
// export const R0103G5 = function(formValues) {
// 	return Object.keys(formValues['R0103G3']['a'])
// 		.filter(key => {
// 			// return key != '0' && formValues['R0103G3']['q'][key] == 'yes';
// 			return key != '0';
// 		})
// 		.reduce((acc, key) => {
// 			let tax = parseFloat(formValues[`R0103G5_${key}`]);

// 			return acc + tax;
// 		}, 0);
// };

// Сума податку утриманого (сплаченого) податковим агентом
export const R0103G4_partials = function(formValues) {
	return Object.keys(formValues['R0103G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0103G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0103G6_${key}_override`]) {
				tax = parseFloat(formValues[`R0103G6_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0103G3']['a'][key]);

				switch (key) {
					case 'Lease1BA':
						tax = (curr * 18) / 100;
						break;
					case 'Lease1AA':
						tax = 0;
						break;
					default:
						break;
				}
			}

			//acc[`R0103G6_${key}`] = round?precisionRound(tax, 2):tax;
			acc[`R0103G6_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума податку утриманого (сплаченого) податковим агентом
export const R0103G4 = function(formValues) {
	let partials = R0103G4_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};

// Сума податку утриманого (сплаченого) податковим агентом
export const R0103G5_partials = function(formValues) {
	return Object.keys(formValues['R0103G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0103G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0103G7_${key}_override`]) {
				tax = parseFloat(formValues[`R0103G7_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0103G3']['a'][key]);

				switch (key) {
					case 'Lease1BA':
						tax = (curr * 1.5) / 100;
						break;
					case 'Lease1AA':
						tax = 0;
						break;
					default:
						break;
				}
			}

			//acc[`R0103G7_${key}`] = tax;
			acc[`R0103G7_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума податку утриманого (сплаченого) податковим агентом
export const R0103G5 = function(formValues) {
	let partials = R0103G5_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};

//! =============================

// Сума податку що підлягає сплаті самостійно
export const R0103G6_partials = function(formValues) {
	return Object.keys(formValues['R0103G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0103G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0103G6_${key}_override`]) {
				tax = parseFloat(formValues[`R0103G6_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0103G3']['a'][key]);

				switch (key) {
					case 'Lease1AA':
						tax = (curr * 18) / 100;
						break;
					case 'Lease1BA':
						tax = 0;
						break;
					default:
						break;
				}
			}

			//acc[`R0103G6_${key}`] = round?precisionRound(tax, 2):tax;
			acc[`R0103G6_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума податку що підлягає сплаті самостійно
export const R0103G6 = function(formValues) {
	let partials = R0103G6_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};

// Сума збору що підлягає сплаті самостійно
export const R0103G7_partials = function(formValues) {
	return Object.keys(formValues['R0103G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0103G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0103G7_${key}_override`]) {
				tax = parseFloat(formValues[`R0103G7_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0103G3']['a'][key]);

				switch (key) {
					case 'Lease1AA':
						tax = (curr * 1.5) / 100;
						break;
					case 'Lease1BA':
						tax = 0;
						break;
					default:
						break;
				}
			}

			//acc[`R0103G7_${key}`] = tax;
			acc[`R0103G7_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума збору що підлягає сплаті самостійно
export const R0103G7 = function(formValues) {
	let partials = R0103G7_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};
