import isArray from 'lodash/isArray';

export const T1RXXXXG2 = function(formValues) {
	return Object.keys(formValues['R0104G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0104G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => fieldsSet['T1RXXXXG2']);
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG3S = function(formValues) {
	return Object.keys(formValues['R0104G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0104G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => fieldsSet['T1RXXXXG3S']);
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG4 = function(formValues) {
	return Object.keys(formValues['R0104G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0104G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => parseFloat(fieldsSet['T1RXXXXG4']));
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG5 = function(formValues) {
	return Object.keys(formValues['R0104G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.map(key => {
			let result = [];
			let keyValue = formValues['R0104G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => parseFloat(fieldsSet['T1RXXXXG5']));
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T1RXXXXG6 = function(formValues) {
	let revenues = T1RXXXXG4(formValues);

	let expenses = T1RXXXXG5(formValues);

	return revenues.map((revenue, i) => {
		let profit = revenue - expenses[i];

		return profit;
	});
};
