import React, { useState, useEffect, useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
// import Icon from 'react-eva-icons';

// import { StyledButton as Button } from '../../../../components/Button/Button';
import NextButton from '../../components/NextButton';
import QuestionBlock from './components/QuestionBlock';
import Question from './components/Question';

import AddRemoveButton from '../../components/ButtonAddRemove';

import { load } from '../../../../utils/localStorage';

import './styles.scss';

const initialData = {
	T1RXXXXG2: [''],
	T1RXXXXG3S: [''],
	T1RXXXXG4: [''],
	T1RXXXXG5: [''],
	T1RXXXXG6: [''],
	T1RXXXXG7: ['']
};

const Property = React.memo(props => {
	const [data, setData] = useState(initialData);

	const handleClickNextButton = () => props.getPropertyData(data);

	const getAnswers = index => answer => {
		const newData = { ...data };
		const keys = Object.keys(newData);
		keys.map(key => {
			newData[key][index] = answer[key];
			return key;
		});
		setData(newData);
		props.getPropertyData(newData);
	};

	const addBlock = () => {
		let newData = { ...data };
		const keys = Object.keys(newData);
		keys.map(key => {
			newData[key].push('');
			return key;
		});
		setData(newData);
	};

	const removeBlock = index => () => {
		let newData = { ...data };
		const keys = Object.keys(newData);
		keys.map(key => {
			newData[key].splice(index, 1);
			return key;
		});
		setData(newData);
	};

	const renderQuestion = useMemo(() => {
		return data.T1RXXXXG2.map((item, i) => {
			return (
				<Paper className="Property-card paper" key={i}>
					<div className="Property-card-left-side">
						<QuestionBlock
							state={{
								T1RXXXXG2: data.T1RXXXXG2[i],
								T1RXXXXG3S: data.T1RXXXXG3S[i],
								T1RXXXXG4: data.T1RXXXXG4[i],
								T1RXXXXG5: data.T1RXXXXG5[i],
								T1RXXXXG6: data.T1RXXXXG6[i],
								T1RXXXXG7: data.T1RXXXXG7[i]
							}}
							subsection={props.subsection}
							getAnswers={getAnswers(i)}
							index={i}
							length={data.T1RXXXXG2.length}
							addBlock={addBlock}
							removeBlock={removeBlock(i)}
						/>
					</div>

					<AddRemoveButton delete removeBlock={removeBlock(i)} />
				</Paper>
			);
		});
	}, [data, setData, removeBlock, addBlock]);

	useEffect(() => {
		if (load('p')) {
			const { data } = load('p');
			setData(data);
			props.getPropertyData(data);
		}
	}, []);
	return (
		<div className="Property-wrap">
			<Question item={props.subsection[0]} state={{ answers: initialData }} />
			{renderQuestion}
			<AddRemoveButton add addBlock={addBlock} />

			<div className="btn-Property-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default Property;
