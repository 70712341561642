import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';

const Modal = ({ open, close }) => {
	return (
		<Dialog onClose={close} aria-labelledby="customized-dialog-title" open={open}>
			<DialogTitle id="customized-dialog-title" onClose={close}>
				Modal title
			</DialogTitle>
			<DialogContent>
				<Typography gutterBottom>
					Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas
					eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
				</Typography>
				<Typography gutterBottom>
					Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue
					laoreet rutrum faucibus dolor auctor.
				</Typography>
				<Typography gutterBottom>
					Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl
					consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={close}>Save changes</Button>
			</DialogActions>
		</Dialog>
	);
};

// class Modal extends Component {
// 	state = {
// 		open: false
// 	};
// 	render() {
// 		return (
// 			<Dialog
// 				// onClose={this.handleClose}
// 				aria-labelledby="customized-dialog-title"
// 				open={this.state.open}
// 			>
// 				<DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
// 					Modal title
// 				</DialogTitle>
// 				<DialogContent>
// 					<Typography gutterBottom>
// 						Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in,
// 						egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
// 					</Typography>
// 					<Typography gutterBottom>
// 						Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel
// 						augue laoreet rutrum faucibus dolor auctor.
// 					</Typography>
// 					<Typography gutterBottom>
// 						Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque
// 						nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.
// 					</Typography>
// 				</DialogContent>
// 				<DialogActions>
// 					<Button onClick={this.handleClose}>Save changes</Button>
// 				</DialogActions>
// 			</Dialog>
// 		);
// 	}
// }

export default Modal;
