import React from 'react';
import { Grid } from '@material-ui/core';

import './styles.scss';

export const RightSide = props => {
	return (
		<Grid item md={8} {...props} className={`right-side padding ${props.className}`}>
			{props.children}
		</Grid>
	);
};
