import { connect } from 'react-redux';

import Royalty from '../subsections/R';
import { getRData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.R.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getRData
};

const RContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Royalty);

export default RContainer;
