import { precisionRound } from '../../../types/index';

export const R0102G3 = function(formValues) {
	return Object.keys(formValues['R0102G3']['a'])
		.filter(key => {
			return formValues['R0102G3']['a'][key] !== '0' && formValues['R0102G3']['a'][key] !== '';
		})
		.reduce((acc, key) => {
			let curr = parseFloat(formValues['R0102G3']['a'][key]);
			return acc + curr;
		}, 0);
};

// Сума податку утриманого (сплаченого) податковим агентом
export const R0102G4 = function(formValues) {
	return Object.keys(formValues['R0102G3']['a'])
		.filter(key => {
			return formValues['R0102G3']['a'][key] !== '0' && formValues['R0102G3']['a'][key];
		})
		.reduce((acc, key) => {
			let tax = parseFloat(formValues[`R0102G4_${key}`]);

			return acc + tax;
		}, 0);
};

// Сума збору утриманого (сплаченого) податковим агентом
export const R0102G5 = function(formValues) {
	return Object.keys(formValues['R0102G3']['a'])
		.filter(key => {
			return formValues['R0102G3']['a'][key] !== '0' && formValues['R0102G3']['a'][key];
		})
		.reduce((acc, key) => {
			let tax = parseFloat(formValues[`R0102G5_${key}`]);

			return acc + tax;
		}, 0);
};

// Сума податку що підлягає сплаті самостійно
export const R0102G6_partials = function(formValues) {
	return Object.keys(formValues['R0102G3']['a'])
		.filter(key => {
			return formValues['R0102G3']['a'][key] !== '0' && formValues['R0102G3']['a'][key];
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0102G6_${key}_override`]) {
				tax = parseFloat(formValues[`R0102G6_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0102G3']['a'][key]);

				switch (key) {
					case 'SalePro1AA':
					case 'SalePro1BA':
					case 'SalePro1CA':
					case 'SalePro2AA':
					case 'SalePro2BA':
					case 'SalePro2CA':
					case 'SalePro2DA':
						tax += (curr * 18) / 100;
						break;
					default:
						break;
				}
			}

			acc[`R0102G6_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума податку що підлягає сплаті самостійно
export const R0102G6 = function(formValues) {
	let partials = R0102G6_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};

// Сума збору що підлягає сплаті самостійно
export const R0102G7_partials = function(formValues) {
	return Object.keys(formValues['R0102G3']['a'])
		.filter(key => {
			return formValues['R0102G3']['a'][key] !== '0' && formValues['R0102G3']['a'][key];
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R0102G7_${key}_override`]) {
				tax = parseFloat(formValues[`R0102G7_${key}`]);
			} else {
				let curr = parseFloat(formValues['R0102G3']['a'][key]);

				switch (key) {
					case 'SalePro1AA':
					case 'SalePro1BA':
					case 'SalePro1CA':
					case 'SalePro2AA':
					case 'SalePro2BA':
					case 'SalePro2CA':
					case 'SalePro2DA':
						tax += (curr * 1.5) / 100;
						break;
					default:
						break;
				}
			}

			acc[`R0102G7_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума збору що підлягає сплаті самостійно
export const R0102G7 = function(formValues) {
	let partials = R0102G7_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};
