import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import CommonContainer from './containers/CommonContainer';
import PContainer from './containers/PContainer';
import WContainer from './containers/WContainer';
import IDContainer from './containers/IDContainer';
import OFIContainer from './containers/OFIContainer';
import PenaltyConatainer from './containers/PenaltyContainer';
import OTIContainer from './containers/OTIContainer';
import DiscContainer from './containers/DiscContainer';
import WPContainer from './containers/WPContainer';
import IPContainer from './containers/IPContainer';
import CContainer from './containers/CContainer';
import RContainer from './containers/RContainer';
import AlimContainer from './containers/AlimContainer';
import LeaseContainer from './containers/LeaseContainer';
import SContainer from './containers/SContainer';
import IntContainer from './containers/IntContainer';
import DContainer from './containers/DContainer';
import AddBContainer from './containers/AddBContainer';
import OntiContainer from './containers/OntiContainer';
import SaleProContainer from './containers/SaleProContainer';
import InvestPContainer from './containers/InvestPContainer';
import IPAContainer from './containers/IPAContainer';
import EnterprlContainer from './containers/EnterprlContainer';
import EITAContainer from './containers/EI_TAContainer';

import hoc from '../../HOC/hoc';
import './styles.scss';

// const DeclarationRoute = ({ user, component: Comp, ...rest }) => {
// 	return <Route {...rest} component={props => <Comp {...props} user={user} />} />;
// };

class Declaration extends Component {
	render() {
		return (
			<Switch>
				<Route
					path="/documents/declaration/common"
					component={props => (
						<CommonContainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
				<Route
					path="/documents/declaration/property"
					component={props => <PContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/wages"
					component={props => <WContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/income-from-inheritance-and-donation"
					component={props => <IDContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/other-foreign-incomes"
					component={props => <OFIContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/penalty"
					component={props => (
						<PenaltyConatainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
				<Route
					path="/documents/declaration/other-taxable-income"
					component={props => <OTIContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/tax-discounts"
					component={props => (
						<DiscContainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
				<Route
					path="/documents/declaration/winnings-and-prizes"
					component={props => <WPContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/insurance-payments-pensions"
					component={props => <IPContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/charity"
					component={props => <CContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/royalty"
					component={props => <RContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/alimony"
					component={props => (
						<AlimContainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
				<Route
					path="/documents/declaration/lease"
					component={props => (
						<LeaseContainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
				<Route
					path="/documents/declaration/scholarships"
					component={props => <SContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/interest"
					component={props => <IntContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/dividends"
					component={props => <DContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/additional-benefit"
					component={props => (
						<AddBContainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
				<Route
					path="/documents/declaration/other-non-taxable-income"
					component={props => (
						<OntiContainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
				<Route
					path="/documents/declaration/sale-of-movable-immovable-property-exchange-operations"
					component={props => (
						<SaleProContainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
				<Route
					path="/documents/declaration/investment-profit"
					component={props => (
						<InvestPContainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
				<Route
					path="/documents/declaration/income-from-independent-professional-activities"
					component={props => <IPAContainer subsectionName={this.props.match.params.subsection} {...props} />}
				/>
				<Route
					path="/documents/declaration/entrepreneurial-income-B2BA"
					component={props => (
						<EnterprlContainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
				<Route
					path="/documents/declaration/entrepreneurial-income-B2BB"
					component={props => (
						<EITAContainer subsectionName={this.props.match.params.subsection} {...props} />
					)}
				/>
			</Switch>
		);
	}
}

export default hoc()(Declaration);
