import {
	DISABLE_ENABLE_STEP,
	CHANGE_QUIZ_VALUES,
	HANDLE_CHANGE_STEP,
	HANDLE_SET_TOUCHED_STEP,
	HANDLE_SET_ISVALID_STEP,
	SET_CREATE_NEW_DECL_VALUES,
	RESET_DATA_STEPPERS
} from '../types/newQuizTypes';

import { dataQuizSteppers, dataQuizQuestions } from './newStaticData/quizData';

const initialState = {
	activeStep: 1,
	firstStepValues: {
		C1: '',
		C2: '',
		С3А: null,
		C4: ''
	},
	dataSteppers: [...dataQuizSteppers],
	dataQuizQuestions
};

export default function(state = initialState, action) {
	switch (action.type) {
		case HANDLE_CHANGE_STEP:
			return {
				...state,
				activeStep: action.newStep
			};
		case HANDLE_SET_TOUCHED_STEP:
		case DISABLE_ENABLE_STEP:
		case HANDLE_SET_ISVALID_STEP:
			return {
				...state,
				dataSteppers: action.payload
			};
		case CHANGE_QUIZ_VALUES:
			return {
				...state,
				dataQuizQuestions: action.payload
			};
		case SET_CREATE_NEW_DECL_VALUES:
			return {
				...state,
				firstStepValues: action.payload
			};
		case RESET_DATA_STEPPERS:
			return {
				...state,
				dataSteppers: action.payload
			};
		default:
			return state;
	}
}
