import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const firstLevelQuestions = [
	'ONTI1',
	'ONTI2',
	'ONTI3',
	'ONTI4',
	'ONTI5',
	'ONTI6',
	'ONTI7',
	'ONTI8',
	'ONTI9',
	'ONTI10',
	'ONTI10A',
	'ONTI10B',
	'ONTI10C',
	'ONTI10D',
	'ONTI11',
	'ONTI12',
	'ONTI13',
	'ONTI14',
	'ONTI15',
	'ONTI16',
	'ONTI17'
];
const R0113G3 = [
	'ONTI1AA',
	'ONTI4AA',
	'ONTI5AA',
	'ONTI6AA',
	'ONTI7AA',
	'ONTI8AA',
	'ONTI9AA',
	'ONTI10AA',
	'ONTI10BA',
	'ONTI10CA',
	'ONTI10DA',
	'ONTI11AA',
	'ONTI12AA',
	'ONTI13AA',
	'ONTI14AA',
	'ONTI15AA',
	'ONTI16AA',
	'ONTI17AA'
];
const R0109G3 = ['ONTI1AB', 'ONTI2'];
const R0111G3 = ['ONTI3AA'];

const initialAnswers = {
	ONTI1_g: null,
	ONTI1AA: '', // R0113G3
	ONTI1AB: '', // R0109G3
	ONTI1AC: '', //
	ONTI2: '', // R0109G3
	ONTI3_g: null,
	ONTI3AA: '', // R0111G3
	ONTI4_g: null,
	ONTI4AA: '', // R0113G3
	ONTI5_g: null,
	ONTI5AA: '', // R0113G3
	ONTI6_g: null,
	ONTI6AA: '', // R0113G3
	ONTI7_g: null,
	ONTI7AA: '', // R0113G3
	ONTI8_g: null,
	ONTI8AA: '', // R0113G3
	ONTI9_g: null,
	ONTI9AA: '', // R0113G3
	ONTI10A: false,
	ONTI10AA: '', // R0113G3
	ONTI10B: false,
	ONTI10BA: '', // R0113G3
	ONTI10C: false,
	ONTI10CA: '', // R0113G3
	ONTI10D: false,
	ONTI10DA: '', // R0113G3
	ONTI11_g: null,
	ONTI11AA: '', // R0113G3
	ONTI12_g: null,
	ONTI12AA: '', // R0113G3
	ONTI13_g: null,
	ONTI13AA: '', // R0113G3
	ONTI14_g: null,
	ONTI14AA: '', // R0113G3
	ONTI15_g: null,
	ONTI15AA: '', // R0113G3
	ONTI16_g: null,
	ONTI16AA: '', // R0113G3
	ONTI17_g: null,
	ONTI17AA: '' // R0113G3
};
const initialData = {
	R0111G3: {
		a: { ONTI3AA: 0 },
		q: { ONTI3AA: null }
	},
	R0109G3: {
		a: { ONTI1AB: 0, ONTI2: 0 },
		q: { ONTI1AB: null, ONTI2: null }
	},
	R0113G3: {
		a: {
			ONTI1AA: 0,
			ONTI4AA: 0,
			ONTI5AA: 0,
			ONTI6AA: 0,
			ONTI7AA: 0,
			ONTI8AA: 0,
			ONTI9AA: 0,
			ONTI10AA: 0,
			ONTI10BA: 0,
			ONTI10CA: 0,
			ONTI10DA: 0,
			ONTI11AA: 0,
			ONTI12AA: 0,
			ONTI13AA: 0,
			ONTI14AA: 0,
			ONTI15AA: 0,
			ONTI16AA: 0,
			ONTI17AA: 0
		},
		q: {
			ONTI1AA: null,
			ONTI4AA: null,
			ONTI5AA: null,
			ONTI6AA: null,
			ONTI7AA: null,
			ONTI8AA: null,
			ONTI9AA: null,
			ONTI10AA: null,
			ONTI10BA: null,
			ONTI10CA: null,
			ONTI10DA: null,
			ONTI11AA: null,
			ONTI12AA: null,
			ONTI13AA: null,
			ONTI14AA: null,
			ONTI15AA: null,
			ONTI16AA: null,
			ONTI17AA: null
		}
	}
};

const ONTI = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (R0109G3.includes(id)) {
						newData.R0109G3.a[id] = value;
					}
					if (R0113G3.includes(id)) {
						newData.R0113G3.a[id] = value;
					}
					if (R0111G3.includes(id)) {
						newData.R0111G3.a[id] = value;
					}
					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;

					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];

					break;

				default:
					break;
			}
			save('onti', { answers: newAnswers, data: newData });
			props.getOntiData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title || firstLevelQuestions.includes(item.id)) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}

			if (
				answers.ONTI1_g === item.id.slice(0, -1) ||
				answers.ONTI3_g === item.id.slice(0, -1) ||
				answers.ONTI4_g === item.id.slice(0, -1) ||
				answers.ONTI5_g === item.id.slice(0, -1) ||
				answers.ONTI6_g === item.id.slice(0, -1) ||
				answers.ONTI7_g === item.id.slice(0, -1) ||
				answers.ONTI8_g === item.id.slice(0, -1) ||
				answers.ONTI9_g === item.id.slice(0, -1) ||
				answers.ONTI11_g === item.id.slice(0, -1) ||
				answers.ONTI12_g === item.id.slice(0, -1) ||
				answers.ONTI13_g === item.id.slice(0, -1) ||
				answers.ONTI14_g === item.id.slice(0, -1) ||
				answers.ONTI15_g === item.id.slice(0, -1) ||
				answers.ONTI16_g === item.id.slice(0, -1) ||
				answers.ONTI17_g === item.id.slice(0, -1)
			) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('onti')) {
			const { data, answers } = load('onti');
			setAnswers(answers);
			setData(data);
			props.getOntiData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getOntiData(data);
	return (
		<div className="IP-wrap">
			{renderQuestion}
			<div className="btn-ONTI-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default ONTI;
