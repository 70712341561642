import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// The `withStyles()` higher-order component is injecting a `classes`
// property that is used by the `Button` component.
export const StyledButton = withStyles({
	root: {
		minWidth: '118px',
		minHeight: '36px',
		display: 'block',
		borderRadius: '3px',
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 'normal',
		textAlign: 'center',
		cursor: 'pointer',
		outline: 'none',
		color: '#ffffff',
		border: '2px solid #fff'
		// '&:hover': {
		// 	boxShadow: '0 2px 4px 0 rgba(255, 255, 255, 0.2)',
		// },
		// '&:active': {boxShadow: 'inset 0 0 5px 0 rgba(255, 255, 255, 0.2)'},
	},
	contained: {
		backgroundColor: '#4ca34c',
		border: '1px solid #4ca34c',
		color: '#fff',
		'&$disabled': {
			backgroundColor: 'rgba(76, 163, 76, 0.33)',
			borderColor: 'rgba(76, 163, 76, 0.33)',
			color: '#fff'
		},
		'&:hover': {
			textDecoration: 'none',
			// boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
			backgroundColor: '#4ca34c'
		}
		// '&:active': {boxShadow: 'inset 0 0 5px 0 rgba(0, 0, 0, 0.2)'},
	},
	outlined: {
		backgroundColor: 'inherit',
		border: '1px solid #4ca34c',
		color: '#4ca34c',
		'&:hover': {
			// boxShadow: '0 2px 4px 0 rgba(76, 163, 76, 0.2)',
			background: 'inherit',
			'&$disabled': {}
		},
		// '&:active': { boxShadow: 'inset 0 0 5px 0 rgba(255, 255, 255, 0.2)' },
		'&$disabled': { opacity: '0.33', color: '#4ca34c' }
	},
	disabled: {},
	label: {
		textTransform: 'uppercase'
	}
})(Button);
