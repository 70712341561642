import React, { Component } from 'react';

export default class htmlDataJ1499201 extends Component {
    constructor(props) {
        super(props);
        this.state={height:'400px'};
        this.userData = this.props.userData;

        this.partial = this.props.partial&&this.props.partial===true;
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () =>{
        this.setState({height:(window.innerHeight-290)+'px'})
    }

    render () {
        return (
            <div className="chat-container doc-preview">
                <div>J1499201</div>
                <table width="100%" className="main_wrap border_1">
                    <tbody>
                        <tr className="report_row">
                            <td className={'text-center'}>
                                <b>Квитанція No 2</b>
                            </td>
                        </tr>

                        <tr>
                            <td >
                                <table width="100%" className="table_border_none">
                                    <tbody>
                                        <tr className="report_row">
                                            <td width="10%" className={'valign'}>
                                                Платник податків:
                                            </td>
                                            <td width="90%">
                                                <table width="100%" className="table_border_none text_center">
                                                    <tbody>
                                                        <tr className="report_row">
                                                            <td width="100%" className={'border_bottom'}>
                                                                <div className={'item'}>{this.cell('HTIN')}</div>
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%">
                                                                (код згідно з ЄДРПОУ або РНОКПП або серія та номер паспорта)
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%" className={'border_bottom'}>
                                                                <div className={'item'}>{this.cell('HNAME')}</div>
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%">
                                                                ( найменування або прізвище, ім'я, по батькові )
                                                            </td>
                                                        </tr>
                                                        {
                                                        /*<tr className="report_row">
                                                            <td width="100%" className={'border_bottom'}>
                                                                <div className={'item'}>{this.cell('HEMAIL')}</div>
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%">
                                                                (адреса електронної пошти (E-mail))
                                                            </td>
                                                        </tr>
                                                        */
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>


                            </td>
                        </tr>

                        <tr className={'border_bottom'}>
                            <td >
                                <table width="100%" className="table_border_none">
                                    <tbody>
                                        <tr className="report_row">
                                            <td width="10%" className={'valign'}>
                                                Документ:
                                            </td>
                                            <td width="90%">
                                                <table width="100%" className="table_border_none text_center">
                                                    <tbody>
                                                        <tr className="report_row">
                                                            <td width="100%" className={'border_bottom'}>
                                                                <div className={'item'}>{this.cell('HDOCKOD')}</div>
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%">
                                                                (код форми документу)
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%" className={'border_bottom'}>
                                                                <div className={'item'}>{this.cell('HDOCNAME')}</div>
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%">
                                                                (назва документу звітності)
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%" className={'border_bottom'}>
                                                                <div className={'item'}>{this.cell('HFILENAME')}</div>
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%">
                                                                (ім’я файлу)
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%" className={'border_bottom'}>
                                                                <div className={'item'}>{this.cell('HDOCSTAN')}</div>
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%">
                                                                (стан документа)
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%" className={'border_bottom'}>
                                                                <span><div className={'item'}>{this.cell('HPERIOD')}</div></span>
                                                                <span><div className={'item'}>{this.cell('HZY')}</div></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%">
                                                                (назва звітного періоду) (звітний рік)
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%" className={'border_bottom'}>
                                                                <div className={'item'}>{this.cell('HDTERM')}</div>
                                                            </td>
                                                        </tr>
                                                        <tr className="report_row">
                                                            <td width="100%">
                                                                (граничний термін подання (для уточнюючих не зазначається))
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>


                            </td>
                        </tr>


                        <tr>
                            <td >
                                <table width="100%" className="table_border_none">
                                    <tbody>
                                    <tr className="report_row" >
                                        <td width="10%" className={'valign'}>
                                            Підписи документа:
                                        </td>
                                        <td width="90%">
                                            <table width="100%" className="table_border_none text_center">
                                                <tbody>
                                                    <tr className="report_row">
                                                        <td width="100%" className={''}>
                                                            (зазначається лише в залежності від статусу особи платника податків та складу посадових осіб, що мають право підпису):
                                                        </td>
                                                    </tr>
                                                    <tr className="report_row">
                                                        <td width="100%">

                                                            <table width="100%" className="table_border_none text_center">
                                                                <tbody>
                                                                    <tr className="report_row">
                                                                        <td width="30%" className={'valign text_left'}>
                                                                            печатка:
                                                                        </td>
                                                                        <td width="35%" className={'border_bottom'}>
                                                                            <div className={'item'}>{this.cell('HTIN1')}</div>
                                                                        </td>
                                                                        <td width="35%" className={'border_bottom'}>
                                                                            <div className={'item'}>{this.cell('HNAME1')}</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="report_row">
                                                                        <td width="30%" className={''}>
                                                                        </td>
                                                                        <td width="35%" className={''}>
                                                                            ( код згідно з ЄДРПОУ або РНОКПП або серія та номер паспорта )
                                                                        </td>
                                                                        <td width="35%" className={''}>
                                                                            (найменування або прізвище, ім'я, по батькові)
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="report_row">
                                                                        <td width="30%" className={'valign text_left'}>
                                                                            Керівник або фізична особа – платник податку, або співробітник з правом підпису ПН
                                                                        </td>
                                                                        <td width="35%" className={'border_bottom'}>
                                                                            <div className={'item'}>{this.cell('HKBOS')}</div>
                                                                        </td>
                                                                        <td width="35%" className={'border_bottom'}>
                                                                            <div className={'item'}>{this.cell('HBOS')}</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="report_row">
                                                                        <td width="30%" className={''}>
                                                                        </td>
                                                                        <td width="35%" className={''}>
                                                                            (РНОКПП або серія та номер паспорта )
                                                                        </td>
                                                                        <td width="35%" className={''}>
                                                                            (прізвище, ім'я, по батькові )
                                                                        </td>
                                                                    </tr>

                                                                    <tr className="report_row">
                                                                        <td width="30%" className={'valign text_left'}>
                                                                            бухгалтер:
                                                                        </td>
                                                                        <td width="35%" className={'border_bottom'}>
                                                                            <div className={'item'}>{this.cell('HKBUH')}</div>
                                                                        </td>
                                                                        <td width="35%" className={'border_bottom'}>
                                                                            <div className={'item'}>{this.cell('HBUH')}</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="report_row">
                                                                        <td width="30%" className={''}>
                                                                        </td>
                                                                        <td width="35%" className={''}>
                                                                            (РНОКПП або серія та номер паспорта )
                                                                        </td>
                                                                        <td width="35%" className={''}>
                                                                            (прізвище, ім'я, по батькові)
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>

                                                        </td>
                                                    </tr>



                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>


                            </td>
                        </tr>


                        <tr className={'border_bottom'}>
                            <td >
                                <table width="100%" className="table_border_none">
                                    <tbody>
                                        <tr className="report_row">
                                            <td width="10%" className={'valign'}>
                                                Результат обробки:
                                            </td>
                                            <td width="90%">
                                                <table width="100%" className="table_border_none text_center">
                                                    <tbody>
                                                    <tr className="report_row">
                                                        <td width="100%" className={''}>
                                                            <table width="100%" className="table_border_none text_left">
                                                                <tbody>
                                                                <tr className="report_row">
                                                                    <td width="45%" className={''}>
                                                                        Документ доставлено до
                                                                    </td>
                                                                    <td width="55%" className={''}>
                                                                        <table width="100%" className="table_border_none text_center">
                                                                            <tbody>
                                                                            <tr className="report_row">
                                                                                <td className={'border_bottom'}>
                                                                                    <div className={'item'}>{this.cell('HKODSTI')}</div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className={''}>
                                                                                    (код органу ДФС України)
                                                                                </td>
                                                                            </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>

                                                                </tbody>
                                                            </table>




                                                        </td>
                                                    </tr>

                                                    <tr className="report_row">
                                                        <td width="100%" className={'border_bottom'}>
                                                            <div className={'item'}>{this.cell('HSTI')}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="report_row">
                                                        <td width="100%">
                                                            (назва органу ДФС України)
                                                        </td>
                                                    </tr>


                                                    <tr className="report_row">
                                                        <td width="100%" className={'border_bottom'}>
                                                            <div className={'item'}>{this.cell('HRESULT')}</div>
                                                        </td>
                                                    </tr>

                                                    <tr className="report_row">
                                                        <td width="100%" className={'border_bottom'}>
                                                            <span><div className={'item'}>{this.cell('HDATE')}</div></span>
                                                            <span><div className={'item'}>{this.cell('HTIME')}</div></span>
                                                        </td>
                                                    </tr>
                                                    <tr className="report_row">
                                                        <td width="100%">
                                                            (дата) (час)
                                                        </td>
                                                    </tr>

                                                    <tr className="report_row">
                                                        <td width="100%" className={'text_left'}>
                                                            <table width="100%" className="table_border_none ">
                                                                <tbody>
                                                                <tr className="report_row">
                                                                    <td width="45%" className={'text_left'}>
                                                                        Реєстраційний No
                                                                    </td>
                                                                    <td width="55%" className={'text_center'}>
                                                                        <div className={'item'}>{this.cell('HNUMREG')}</div>
                                                                    </td>
                                                                </tr>

                                                                </tbody>
                                                            </table>

                                                        </td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>


                            </td>
                        </tr>


                        <tr className={'border_bottom'}>
                            <td >
                                <table width="100%" className="table_border_none">
                                    <tbody>
                                    <tr className="report_row">
                                        <td width="10%" className={'valign'}>
                                            Виявлені помилки:
                                        </td>
                                        <td width="90%">
                                            <table width="100%" className="table_border_none text_center">
                                                <tbody>
                                                    {Array.isArray(this.getValue('T1RXXXXG1S')) && this.getValue('T1RXXXXG1S').map((val, idx)=>{
                                                        return (
                                                            <tr key={idx} className="report_row">
                                                                <td width="100%" className={'border_bottom'}>
                                                                    <div className={'item'}>{this.pureCell(val)}</div>
                                                                </td>
                                                            </tr>)
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>


                            </td>
                        </tr>
                        <tr className={'border_bottom'}>
                            <td >
                                <table width="100%" className="table_border_none">
                                    <tbody>
                                    <tr className="report_row">
                                        <td width="10%" className={'valign'}>
                                            Відправник:
                                        </td>
                                        <td width="90%">
                                            <table width="100%" className="table_border_none text_center">
                                                <tbody>
                                                    <tr className="report_row">
                                                        <td width="100%" className={'border_bottom'}>
                                                            <div className={'item'}>{this.cell('HSENDER')}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="report_row">
                                                        <td width="100%" className={''}>
                                                            (інформація про відправника)
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>


                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        )
    }


    cell = (key, label = false, className = '') => {
        let val = this.getValue(key);

        return this.buildCell(val, label, className);
    };

    pureCell = (value, label = false) => {
        let val = this.prepareValue(value);
        return this.buildCell(val, label);
    };

    buildCell = (val, label, extClassName = '') => {
        let className = 'inner-cell '+extClassName;
        if (val === '__empty__'){
            val = <span>&nbsp;&nbsp;</span>;
            className = 'inner-cell-empty';
        }

        return (
            <div className="grid-x ">
                <div className={'cell '+className}>{val}</div>
                {label && <div className={'cell f-10 inner-cell-label'}>{label}</div>}
            </div>
        )
    }

    prepareValue(val){
        if(typeof(val) === "boolean"){
            val = val===true?'X':'__empty__'
        } else if (val === null || (typeof(val) === "string" && val.trim() === '')){
            val = '__empty__';
        }
        return val;
    }

    getValue = (key) => {
        let val = '__empty__';

        if (this.getUserData().hasOwnProperty(key)){
            val = this.getUserData()[key];
            val = this.prepareValue(val);
        }

        return val;
    }

    getUserData = ()=>{
        return this.userData;
    };


}