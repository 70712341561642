import {
	EI_TAI1,
	EI_TAI2,
	EI_TAI3,
	EI_TAI4,
	EI_TAI5,
	EI_TAI5A,
	EI_TAI5B,
	EI_TAI5C,
	EI_TAI6,
	EI_TAI6A,
	EI_TAI6AA,
	EI_TAI6AB,
	EI_TAI6AC,
	EI_TAI6AD,
	EI_TAI6B,
	EI_TAI6C,
	EI_TAI6D,
	EI_TAI6E,
	EI_TAI7
} from '../_declaretion_answer_id';
import {} from '../_xmlConstId'; //! LOOK
import {} from '../../_questions_OLD'; //! LOOK
import { INPUT, SELECT, RADIO } from '../../elementTypes';

import kved from '../../kved.json';

//! NO xmlIds?

export const EI_TAI = [
	{
		// SELECT 1
		id: 'EI_TAI',
		title: 'Оберіть номер відповідного кварталу арабською цифрою від 1 до 3 за який Ви звітуєтесь. ',
		name: 'EI_TAI',
		question_title: true
	},
	{
		// SELECT 1
		id: EI_TAI1,
		// title:
		// 	'У разі якщо Ви розпочали господарську діяльність або перейшли на загальну систему оподаткування не з початку року оберіть номер відповідного кварталу за який Ви звітуєтесь арабською цифрою від 1 до 3',
		name: EI_TAI1,
		element: SELECT,
		placeholder: 'Номер квартала',
		helpers: ['Підказка: Якщо Ви звітуєтесь за календарний рік квартал обирати не потрібно'],
		options: [{ name: 1 }, { name: 2 }, { name: 3 }]
	},
	{
		// SELECT 2
		id: EI_TAI2,
		title: 'Оберіть назву виду економічної діяльності, за здійснення якого одержано дохід',
		name: EI_TAI2,
		element: 'autocomplete',
		placeholder: 'Вкажіть вид економічної діяльності',
		options: kved
	},
	{
		// SELECT 3
		id: EI_TAI3,
		title: 'Оберіть коди виду економічної діяльності згідно з КВЕД',
		name: EI_TAI3,
		element: INPUT,
		disabled: true
		// placeholder: ''
	},
	{
		// INPUT
		id: EI_TAI4,
		title: 'Вкажіть суму одержаного доходу від за окремими видами діяльності згідно КВЕД (грн, коп.)',
		name: EI_TAI4,
		element: INPUT,
		placeholder: 'Введіть суму'
	},
	{
		// INPUT
		id: EI_TAI5,
		title: "Вкажіть вартість документально підтверджених витрат, пов'язаних з господарською діяльністю",
		name: EI_TAI5,
		question_title: true,
		// element: INPUT,
		// placeholder: 'Введіть суму (грн, коп.)'
		answers: [
			{
				id: EI_TAI5A,
				title:
					'витрати на придбання товарно-матеріальних цінностей, що реалізовані або використані у виробництві продукції (грн, коп.)',
				name: EI_TAI5A,
				element: INPUT,
				placeholder: 'Введіть суму'
			},
			{
				id: EI_TAI5B,
				title:
					'витрати на придбання товарно-матеріальних цінностей, що реалізовані або використані у виробництві продукції (грн, коп.)',
				name: EI_TAI5B,
				element: INPUT,
				placeholder: 'Введіть суму'
			},
			{
				id: EI_TAI5C,
				title: 'інші витрати, включаючи вартість виконаних робіт, наданих послуг (грн, коп.)',
				name: EI_TAI5C,
				element: INPUT,
				placeholder: 'Введіть суму'
			}
		]
	},
	{
		// QUESTION EI_TAI6
		id: EI_TAI6,
		title: 'Амортизаційні відрахування',
		name: EI_TAI6,
		helpers: [
			'ПІДКАЗКА: На ці витрати можна зменшувати оподатковуваний дохід у разі якщо основні засоби придбані після 1.01.2017 р. відповідно до Закону України 1797-VIII від 21.12.2016 р. "Про внесення змін до ПКУ щодо покращання інвестиційного клімату в України"'
		],
		question_title: true,
		// element: INPUT,
		// placeholder: 'Введіть суму (грн, коп.)'
		// answers: []
	},
	{
		// QUESTION EI_TAI6 > RADIO QUESTION
		id: EI_TAI6A,
		title: 'Оберіть групу основних засобів або нематеріальних активів',
		name: EI_TAI6A,
		answers: [
			{
				// QUESTION EI_TAI6 > RADIO QUESTION > 1 RB
				id: EI_TAI6AA,
				title: '1 - Капітальні витрати на поліпшення земель, не пов’язані з будівництвом',
				name: 'EI_TAI6A_g',
				element: RADIO,
				value: 15,
				helpers: ['Мінімально допустимі строки корисного використання, років: 15']
			},
			{
				// QUESTION EI_TAI6 > RADIO QUESTION > 2 RB
				id: EI_TAI6AB,
				title: '2 - Будівлі, споруди, передавальні пристрої',
				name: 'EI_TAI6A_g',
				element: RADIO,
				value: 10,
				helpers: ['Мінімально допустимі строки корисного використання, років: 10']
			},
			{
				// QUESTION EI_TAI6 > RADIO QUESTION > 3 RB
				id: EI_TAI6AC,
				title: '3 - Машини, обладнання, тварини, багаторічні насадження та інше',
				name: 'EI_TAI6A_g',
				element: RADIO,
				value: 5,
				helpers: ['Мінімально допустимі строки корисного використання, років: 5']
			},
			{
				// QUESTION EI_TAI6 > RADIO QUESTION > 4 RB
				id: EI_TAI6AD,
				title: '4 - Нематеріальні активи',
				name: 'EI_TAI6A_g',
				element: RADIO,
				value: '',
				helpers: ['Відповідно до правовстановлюючого документа, але не менш як два роки']
			}
		]
	},
	{
		id: EI_TAI6B,
		title: 'Вкажіть первісну вартість основного засобу (нематеріального) активу, придбаного після 1.01.2017 р.',
		name: EI_TAI6B,
		element: INPUT,
		placeholder: 'Введіть суму'
	},
	{
		id: EI_TAI6C,
		title:
			'Вкажіть строк корисного використання основного засобу (нематеріального) активу придбаного  після 1.01.2017 р.',
		name: EI_TAI6C,
		element: INPUT,
		placeholder: 'Введіть суму'
	},
	{
		id: EI_TAI6D,
		title:
			"Вкажіть вартість об'єкта основних засобів та/або нематеріальних активів на початок звітного (податкового) періоду (грн, коп.)",
		name: EI_TAI6D,
		element: INPUT,
		placeholder: 'Введіть суму'
	},
	{
		id: EI_TAI6E,
		title:
			'Вкажіть період введення в експлуатацію, який має бути документально оформленим (п. 29 П(С)БО 7 «Основні засоби» амортизація починає нараховуватись з місяця наступного за місяцем введення об’єкта в експлуатацію (у разі введення в експлуатацію  основного засобу в поточному році)',
		name: EI_TAI6E,
		element: INPUT,
		placeholder: 'Період'
	},
	{
		// QUESTION EI_TAI7
		id: EI_TAI7,
		title:
			'Вкажіть загальну суму авансових платежів з податку на доходи фізичних осіб, розраховану Вами самостійно згідно з фактичними даними, зазначеними у Книзі обліку доходів і витрат кожного календарного кварталу (крім четвертого) та сплачені до бюджету протягом звітного (податкового) року (грн, коп.)',
		name: EI_TAI7,
		helpers: [
			'Підказка: У разі якщо є переплата, вона автоматично зараховується у рахунок майбутніх платежів з ПДФО.  Ви також можете звернутися із заявою у довільній формі (п. 87.1 ПКУ) до органу ДФС за місцем реєстрації для зарахування переплати у рахунок сплати інших податків або повернення грошей '
		],
		element: INPUT,
		placeholder: 'Введіть суму'
	}
];
