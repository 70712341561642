import React, { useEffect } from 'react';

import { withFormik, Field } from 'formik';
import * as yup from 'yup';

import RadioGroup from '../../../components/RadioGroup';
import RadioInputField from '../../../components/RadioInputField';
import NewYearPickerField from '../../../components/NewYearPickerField';
import NewStepperCheckboxGroup from '../../../components/NewStepperCheckboxGroup';
import NewStepperCheckBoxField from '../../../components/NewStepperCheckBoxField';
import CustomStepperButton from '../../../components/CustomStepperButton';

const NewCreateDeclFormShema = yup.object().shape({
	C1: yup.string().required("Це поле обов'язкове C1"),
	C2: yup.string().required("Це поле обов'язкове C2"),
	C3A: yup.mixed().test('date', "Це поле обов'язкове С3А", value => (value === null ? false : true)),
	C4: yup.string().required("Це поле обов'язкове C4")
});

const NewCreateDeclForm = props => {
	const {
		handleBack,
		handleSubmit,
		isValid,
		values,
		errors,
		touched,
		setFieldValue,
		setFieldError,
		isSubmitting,
		setFieldTouched,
		setTouched
	} = props;

	// change C4 initial value if change C1
	useEffect(() => {
		if (values.C1 === 'C1A') {
			setFieldValue('C4', []);
		} else if (values.C1 === 'C1B') {
			setFieldValue('C4', '');
		}
	}, [values.C1]);

	const renderSubQuestion = C1value => {
		if (C1value === 'C1A') {
			return (
				<NewStepperCheckboxGroup
					id="C4"
					label="Оберіть категорію, до якої Ви себе відносите"
					value={values.C4}
					error={errors.C4}
					touched={touched.C4}
					onChange={setFieldValue}
					onBlur={setFieldTouched}
				>
					<Field
						component={NewStepperCheckBoxField}
						name={values.C4}
						id="C4A"
						label="фізична особа-підприємець, яка припинила підприємницьку діяльність"
					/>
					<Field
						component={NewStepperCheckBoxField}
						name={values.C4}
						id="C4B"
						label="фізична особа-підприємець на спрощеній системі оподаткування"
					/>
					<Field
						component={NewStepperCheckBoxField}
						name={values.C4}
						id="C4C"
						label="фізична особа-підприємець на загальній системі оподаткування"
					/>
				</NewStepperCheckboxGroup>
			);
		} else if (C1value === 'C1B') {
			return (
				<RadioGroup
					id="C4"
					label="Оберіть форму, яку Вам необхідно створити"
					value={values.C4}
					error={errors.C4}
					touched={touched.C4}
				>
					<Field component={RadioInputField} name="C4" id="C4D" label="фізична особа-громадянин" />
					<Field
						component={RadioInputField}
						name="C4"
						id="C4E"
						label="фізична особа, яка провадить незалежну 
						професійну діяльність"
					/>
				</RadioGroup>
			);
		} else {
			return null;
		}
	};

	return (
		<div className="question">
			<div className="stepper-wrap create-new-wrap">
				<div className="question-block">
					<h1>Створення нової декларації</h1>
					<RadioGroup
						id="C1"
						label="Оберіть форму, яку Вам необхідно створити"
						value={values.C1}
						error={errors.C1}
						touched={touched.C1}
					>
						<Field
							component={RadioInputField}
							name="C1"
							id="C1A"
							label="Податкова декларацiя про майновий стан i доходи (для підприємців)"
						/>
						<Field
							component={RadioInputField}
							name="C1"
							id="C1B"
							label="Податкова декларацiя про майновий стан i доходи (для громадян та осіб, які провадять незалежну професійну діяльність)"
						/>
					</RadioGroup>
					<RadioGroup
						id="C2"
						label="Оберіть свій резидентський статус"
						value={values.C2}
						error={errors.C2}
						touched={touched.C2}
					>
						<Field component={RadioInputField} name="C2" id="C2A" label="резидент" />
						<Field component={RadioInputField} name="C2" id="C2B" label="нерезидент" />
					</RadioGroup>
					<NewYearPickerField
						name="C3A"
						title="Оберіть податковий (звітний) період"
						label="Оберіть період"
						formikError={errors.C3A}
						setFieldValue={setFieldValue}
						setFieldError={setFieldError}
						value={values.C3A || null}
						touched={touched.C3A}
						setTouched={setTouched}
					/>
					{renderSubQuestion(values.C1)}
				</div>
			</div>
			<div className="quiz-btn-block">
				<div className="quiz-btn-block__prev">
					<CustomStepperButton onClick={handleBack} innerText="Повернутися" />
				</div>
				<div className="quiz-btn-block__next">
					<CustomStepperButton
						disabled={!isValid || isSubmitting}
						onClick={handleSubmit}
						innerText="Створити"
					/>
				</div>
			</div>
		</div>
	);
};

export default withFormik({
	mapPropsToValues: () => ({
		C1: '',
		C2: '',
		C3A: null,
		C4: ''
	}),
	validationSchema: NewCreateDeclFormShema,
	handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
		await props.handleCreateNewDecl(values, setSubmitting, setErrors);
	}
})(NewCreateDeclForm);
