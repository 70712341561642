import React from 'react';
import TextField from '@material-ui/core/TextField';

const InputForm = ({ item, change, answer }) => {
	return (
		<>
			{item.title && <h3 className="subtitle-for-inputs">{item.title}</h3>}
			<TextField
				id={item.id}
				label={item.placeholder || item.title}
				name={item.name || item.xmlId}
				value={answer[`${item.id}`]}
				onChange={change}
				helperText={item.helper}
			/>
		</>
	);
};

export default InputForm;
