import React, { useState, useEffect } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const initialDataState = {
	R0109G3: {
		q: {
			OTI_1: null,
			OTI_2A: null,
			OTI_2B: null,
			OTI_3: null,
			OTI_4: null,
			OTI_5: null,
			OTI_6: null,
			OTI_7: null
		},
		a: {
			OTI_1: 0,
			OTI_2A: 0,
			OTI_2B: 0,
			OTI_3: 0,
			OTI_4: 0,
			OTI_5: 0,
			OTI_6: 0,
			OTI_7: 0
		}
	}
};
const initialAnswersState = {
	OTI_1: '',
	OTI_2A: '',
	OTI_2B: '',
	OTI_3: '',
	OTI_4: '',
	OTI_5: '',
	OTI_6: '',
	OTI_7: ''
};

const OTI = props => {
	const [data, setData] = useState(initialDataState);
	const [answers, setAnswers] = useState(initialAnswersState);

	useEffect(() => {
		if (load('oti')) {
			const { data, answers } = load('oti');
			setAnswers(answers);
			setData(data);
			props.getOtiData(data);
		}
	}, []);

	const handleChange = ({ currentTarget }) => {
		const { value, id, type } = currentTarget;
		let newData = { ...data };
		let newAnswers = { ...answers };

		switch (type) {
			case 'text':
				newData.R0109G3.a[id] = value;
				newAnswers[`${id}`] = value;
				break;

			default:
				break;
		}
		save('oti', { answers: newAnswers, data: newData });
		props.getOtiData(newData);
		setData(newData);
		setAnswers(prevState => ({ ...prevState, ...newAnswers }));
	};

	const handleClickNextButton = () => props.getOtiData(data);
	return (
		<div className="OTI-wrap">
			<div className="OTI-content-wrap">
				{Object.values(props.subsection).map((item, i) => {
					return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
				})}
			</div>
			<div className="btn-OTI-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
};

export default OTI;
