import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const firstLevelQuestions = [
	'IP1A',
	'IP1B',
	'IP1C',
	'IP1D',
	'IP1E',
	'IP1F',
	'IP1G',
	'IP1H',
	'IP1J',
	'IP1K',
	'IP1L',
	'IP1M',
	'IP1N',
	'IP1P',
	'IP2A',
	'IP2B',
	'IP2C'
];
const R0113G3 = [
	'IP1AAAA', //0 0
	'IP1AAAB', //агентом  18 1.5
	'IP1BAAAA', //0 0
	'IP1DABAA', //0 0
	'IP1GAA', //0 0
	'IP1HAA',
	'IP1KAA',
	'IP1LAA',
	'IP1MAA',
	'IP1NAAAA',
	'IP1PAA',
	'IP2AAA',
	'IP2BA',
	// 'IP1AAAB',
	'IP1AABA',
	'IP1BABAA',
	'IP1CAA',
	'IP1DAAAA'
];
const R0109G3 = [
	'IP1AAAB', //агентом  18 1.5
	'IP1AABA', // агентом  18 1.5
	'IP1BABAA', //агентом  18 1.5
	'IP1CAA', //агентом  18 1.5
	'IP1DAAAA', //агентом  18 1.5
	'IP1EAA', //агентом  18 1.5
	'IP1FAA', //Самостоятельно  18 1.5
	'IP1JAA', //агентом  18 1.5
	'IP1NABAA', //агентом  18 1.5
	'IP1PAB', //агентом  18 1.5
	'IP2AB' //агентом  18 1.5
];

const initialAnswers = {
	// Q1
	IP1A: false,
	IP1AA_g: null, // Q1> radio
	// First CB > First RB > INPUTS QUESTION
	IP1AAAA: '', // R0113G3 0 0
	IP1AAAB: '', //! R0113G3 R0109G3 агентом  18 1.5 0.6
	// First CB > Second RB > INPUT QUESTION
	IP1AABA: '', //! R0109G3 агентом  18 1.5 0.6
	// Q2
	IP1B: false,
	IP1BA_g: null, // Second CB > 2 RB
	IP1BAAAA: '', //! Second CB > First RB > INPUT // R0113G3  0 0
	IP1BABAA: '', //! Second CB > Second RB > INPUT // R0109G3 !!! агентом  18 1.5 0.6
	// Q3
	IP1C: false,
	IP1CAA: '', //! Third CB > INPUT //R0109G3 агентом  18 1.5 0.6
	//Q4
	IP1D: false,
	IP1DA_g: null, // Fourth CB > 2 RB
	IP1DAAAA: '', //! Fourth CB > First RB > INPUT // R0109G3 агентом  18 1.5 0.6
	IP1DABAA: '', // Fourth CB > Second RB > INPUT // R0113G3  0 0
	//Q5
	IP1E: false,
	IP1EAA: '', //! R0109G3 агентом  18 1.5
	//Q6
	IP1F: false,
	IP1FAA: '', // R0109G3 Самостоятельно  18 1.5
	//Q7
	IP1G: false,
	IP1GAA: '', //R0113G3 0 0
	//Q8
	IP1H: false,
	IP1HAA: '', //R0113G3 0 0
	//Q9
	IP1J: false,
	IP1JAA: '', //! R0109G3 агентом 18 1.5
	//Q10
	IP1K: false,
	IP1KAA: '', // R0113G3
	//Q11
	IP1L: false,
	IP1LAA: '', //R0113G3
	//Q12
	IP1M: false,
	IP1MAA: '', //R0113G3
	//Q13
	IP1N: false,
	IP1NA_g: null, // Thirteenth CB > 2 RB
	IP1NAAAA: '', // Thirteenth CB > First RB > INPUT QUESTION //R0113G3
	IP1NABAA: '', //! Thirteenth CB > Second RB > INPUT QUESTION //R0109G3 агентом  18 1.5
	//Q14
	IP1P: false,
	IP1PAA: '', //R0113G3 0 0
	IP1PAB: '', //!R0109G3  агентом  18 1.5

	// QQQ 2
	IP2A: false,
	IP2AAA: '', //R0113G3
	IP2AB: '', //! R0109G3 агентом  18 1.5

	IP2B: false,
	IP2BA: '', //R0113G3

	IP2C: false, // 18 1.5
	IP2CA: '',
	IP2CB: '',
	IP2CC: '',
	IP2CD: '',
	IP2CE: ''
};
const initialData = {
	R0106G3: {
		a: {
			IP2C: [
				{
					country_name: '',
					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0,
					tax: 0
				}
			]
		},
		q: { IP2C: null }
	},
	R0109G3: {
		a: {
			IP1AAAB: 0,
			IP1AABA: 0,
			IP1BABAA: 0,
			IP1CAA: 0,
			IP1DAAAA: 0,
			IP1EAA: 0,
			IP1FAA: 0,
			IP1JAA: 0,
			IP1NABAA: 0,
			IP1PAB: 0,
			IP2AB: 0
		},
		q: {
			IP1AAAB: null,
			IP1AABA: null,
			IP1BABAA: null,
			IP1CAA: null,
			IP1DAAAA: null,
			IP1EAA: null,
			IP1FAA: null,
			IP1JAA: null,
			IP1NABAA: null,
			IP1PAB: null,
			IP2AB: null
		}
	},
	R0113G3: {
		a: {
			IP1AAAA: 0,
			IP1AAAB: 0,
			IP1BAAAA: 0,
			IP1DABAA: 0,
			IP1GAA: 0,
			IP1HAA: 0,
			IP1KAA: 0,
			IP1LAA: 0,
			IP1MAA: 0,
			IP1NAAAA: 0,
			IP1PAA: 0,
			IP2AAA: 0,
			IP2BA: 0
		},
		q: {
			IP1AAAA: null,
			IP1AAAB: null,
			IP1BAAAA: null,
			IP1DABAA: null,
			IP1GAA: null,
			IP1HAA: null,
			IP1KAA: null,
			IP1LAA: null,
			IP1MAA: null,
			IP1NAAAA: null,
			IP1PAA: null,
			IP2AAA: null,
			IP2BA: null
		}
	},
	R021G3: {
		a: { IP2C: 0 },
		q: { IP2C: null }
	}
};

const IP = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, name, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (R0109G3.includes(id)) {
						newData.R0109G3.a[id] = value;
					}
					if (R0113G3.includes(id)) {
						newData.R0113G3.a[id] = value;
					}
					if (name === 'tax') {
						newData.R021G3.a[id] = value;
					}
					if (name === 'revenue') {
						newData.R0106G3.a.IP2C[0][name] = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;
					const children =
						labels[0].attributes['data-sibchildren'] && labels[0].attributes['data-sibchildren'].value;

					if (children) {
						let childs = children.split(',');
						childs.forEach(item => {
							newAnswers[item] = '';
							if (R0109G3.includes(item)) {
								newData.R0109G3['a'][item] = 0; //! Check why this value take data
							}
							if (R0113G3.includes(item)) {
								newData.R0113G3['a'][item] = 0; //! Check why this value take data
							}
						});
					}

					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];

					if (!newAnswers[value] && value === 'IP1A') {
						newAnswers.IP1AA_g = null;
						newAnswers.IP1AAAA = '';
						newAnswers.IP1AAAB = '';
						newAnswers.IP1AABA = '';
						newData.R0113G3.a.IP1AAAA = 0;
						newData.R0113G3.a.IP1AAAB = 0;
						newData.R0109G3.a.IP1AAAB = 0;
						newData.R0109G3.a.IP1AABA = 0;
					}
					if (!newAnswers[value] && value === 'IP1B') {
						newAnswers.IP1BA_g = null;
						newAnswers.IP1BAAAA = '';
						newAnswers.IP1BABAA = '';
						newData.R0113G3.a.IP1BAAAA = 0;
						newData.R0109G3.a.IP1BABAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1C') {
						newAnswers.IP1CAA = '';
						newData.R0109G3.a.IP1CAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1D') {
						newAnswers.IP1DA_g = null;
						newAnswers.IP1DAAAA = '';
						newAnswers.IP1DABAA = '';
						newData.R0113G3.a.IP1DABAA = 0;
						newData.R0109G3.a.IP1DAAAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1E') {
						newAnswers.IP1EAA = '';
						newData.R0109G3.a.IP1EAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1F') {
						newAnswers.IP1FAA = '';
						newData.R0109G3.a.IP1FAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1G') {
						newAnswers.IP1GAA = '';
						newData.R0113G3.a.IP1GAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1H') {
						newAnswers.IP1HAA = '';
						newData.R0113G3.a.IP1HAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1J') {
						newAnswers.IP1JAA = '';
						newData.R0109G3.a.IP1JAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1K') {
						newAnswers.IP1KAA = '';
						newData.R0113G3.a.IP1KAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1L') {
						newAnswers.IP1LAA = '';
						newData.R0113G3.a.IP1LAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1M') {
						newAnswers.IP1MAA = '';
						newData.R0113G3.a.IP1MAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1N') {
						newAnswers.IP1NA_g = null;
						newAnswers.IP1NAAAA = '';
						newAnswers.IP1NABAA = '';
						newData.R0113G3.a.IP1NAAAA = 0;
						newData.R0109G3.a.IP1NABAA = 0;
					}
					if (!newAnswers[value] && value === 'IP1P') {
						newAnswers.IP1PAA = '';
						newAnswers.IP1PAB = '';
						newData.R0113G3.a.IP1PAA = 0;
						newData.R0109G3.a.IP1PAB = 0;
					}
					if (!newAnswers[value] && value === 'IP2A') {
						newAnswers.IP2AAA = '';
						newAnswers.IP2AB = '';
						newData.R0113G3.a.IP2AAA = 0;
						newData.R0109G3.a.IP2AB = 0;
					}
					if (!newAnswers[value] && value === 'IP2B') {
						newAnswers.IP2BA = '';
						newData.R0113G3.a.IP2BA = 0;
					}
					if (!newAnswers[value] && value === 'IP2C') {
						newAnswers.IP2CA = '';
						newAnswers.IP2CB = '';
						newAnswers.IP2CC = '';
						newAnswers.IP2CD = '';
						newData.R021G3.a.IP2C = 0;
						newData.R0106G3.a.IP2C[0].revenue = 0;
						newData.R0106G3.a.IP2C[0].country_name = '';
						newData.R0106G3.a.IP2C[0].currency_name = '';
					}
					break;

				default:
					break;
			}
			save('ip', { answers: newAnswers, data: newData });
			props.getIpData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			let newData = { ...data };
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;
			newData.R0106G3.a.IP2C[0][dataset.name] = target.value;
			setData(newData);
			setAnswers({ ...answers, ...newAnswers });
			props.getIpData(newData);
			save('ip', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const handleChangeDate = useCallback(
		key => e => {
			let newData = { ...data };
			let newAnswers = { ...answers };

			const date = new Date(e._d);

			newAnswers[key] = date.getTime(); //! change answer
			newData.R0106G3.a[key.slice(0, -1)][0].currency_date = date.getTime(); //! change data

			setData(newData);
			setAnswers(newAnswers);
			props.getIpData(newData);
			save('ip', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title || firstLevelQuestions.includes(item.id)) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			}
			if (
				answers.IP1AA_g === item.parent ||
				item.parent === answers.IP1BA_g ||
				item.parent === answers.IP1DA_g ||
				item.parent === answers.IP1NA_g
			) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('ip')) {
			const { data, answers } = load('ip');
			setAnswers(answers);
			setData(data);
			props.getIpData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getIpData(data);

	return (
		<div className="IP-wrap">
			{renderQuestion}
			<div className="btn-IP-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default IP;
