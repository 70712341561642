import {
	AddB,
	AddB1,
	AddB1A,
	AddB2,
	AddB2A,
	AddB3,
	AddB3A,
	AddB4,
	AddB4A,
	AddB5,
	AddB5A,
	AddB6,
	AddB6A,
	AddB7,
	AddB7A,
	AddB8,
	AddB8A,
	AddB9,
	AddB9A,
	AddB10,
	AddB10A,
	AddB11,
	AddB11A,
	AddB12,
	AddB12A,
	AddB13,
	AddB13A,
	AddB14,
	AddB14A
} from '../_declaretion_answer_id';
import { R0109G3, R0113G3 } from '../_xmlConstId';
import { B10AK } from '../../_questions_OLD';
import { INPUT, CHECKBOX } from '../../elementTypes';

export const ADDB = [
	{
		// MAIN QUESTION
		id: AddB,
		title: 'Оберіть додаткові блага, які Ви отримували упродовж звітного року ',
		quizOpen: [B10AK],
		question_title: true
	},
	{
		parent: AddB,
		id: AddB1,
		title:
			"вартість використання житла або інших об'єктів майна, що належать Роботодавцю, та/або компенсації вартості такого майна, безкоштовного харчування за рахунок Роботодавця або безкоштовних послуг з побутового обслуговування Вас або членів Вашої родини, включаючи ремонт або спорудження майна, які були оплачені Роботодавцем та не зумовлені виконанням Ваших трудових функцій відповідно до трудового договору (контракту) чи передбачені нормами колективного договору або відповідно до закону в установлених ними межах.",
		name: AddB1,
		xmlId: R0109G3,
		element: CHECKBOX,
		answers: [
			{
				id: AddB1A,
				xmlId: R0109G3,
				title: 'Вкажіть вартість додаткового блага',
				name: AddB1A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB2,
		title:
			'вартість безоплатного лікувально-профілактичного харчування, молока або рівноцінних йому харчових продуктів, газованої солоної води, мийних і знешкоджувальних засобів, а також спеціального одягу, спеціального взуття та інших засобів індивідуального захисту, якими Роботодавець забезпечував Вас відповідно до Закону України «Про охорону праці», а також спеціальний (формений) одяг та взуття, що надавалися Вам у тимчасове користування Роботодавцем, з яким Ви перебували у трудових відносинах у звітному році',
		name: AddB2,
		element: CHECKBOX,
		xmlId: R0113G3,
		answers: [
			{
				id: AddB2A,
				xmlId: R0113G3,
				title: 'Вкажіть вартість додаткового блага',
				name: AddB2A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB3,
		title:
			'грошове відшкодування будь-яких витрат (крім тих, що обов’язково відшкодовуються згідно із законом за рахунок бюджету або звільняються від оподаткування)',
		name: AddB3,
		element: CHECKBOX,
		xmlId: R0109G3,
		answers: [
			{
				id: AddB3A,
				xmlId: R0109G3,
				title: 'Вкажіть суму відшкодування',
				name: AddB3A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB4,
		title:
			'майнове відшкодування будь-яких витрат (крім тих, що обов’язково відшкодовуються згідно із законом за рахунок бюджету або звільняються від оподаткування)',
		name: AddB4,
		element: CHECKBOX,
		xmlId: R0109G3,
		answers: [
			{
				id: AddB4A,
				xmlId: R0109G3,
				title: 'Вкажіть вартість відшкодування',
				name: AddB4A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB5,
		title:
			'прощення на Вашу користь основної суми боргу або кредиту за самостійним рішенням кредитора, не пов’язаним з процедурою банкрутства до закінчення строку позовної давності',
		name: AddB5,
		element: CHECKBOX,
		answers: [
			{
				id: AddB5A,
				xmlId: R0109G3,
				xmlIds: [R0109G3, R0113G3],
				title: 'Вкажіть основну суму боргу або кредиту ',
				name: AddB5A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB6,
		title:
			'прощення на Вашу користь суми процентів, комісії та/або штрафних санкцій (пені), прощених (анульованих) кредитором за його самостійним рішенням, не пов’язаним із процедурою його банкрутства, до закінчення строку позовної давності',
		name: AddB6,
		element: CHECKBOX,
		xmlId: R0113G3,
		answers: [
			{
				id: AddB6A,
				xmlId: R0113G3,
				title: 'Вкажіть суми  процентів, комісії та/або штрафних санкцій (пені)',
				name: AddB6A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB7,
		title:
			'прощення на Вашу користь різниці між основною сумою боргу за фінансовим кредитом в іноземній валюті, не погашеним до 1 січня 2014 р., визначена за офіційним курсом НБУ на дату зміни валюти зобов’язання за таким кредитом з іноземної валюти у гривню, та сумою такого боргу, визначеною за офіційним курсом НБУ станом на 1 січня 2014 р., а також сума процентів, комісії та/або штрафних санкцій (пені) за такими кредитами',
		name: AddB7,
		element: CHECKBOX,
		helpers: [
			'Підказка. Курс валют на 01.01.2014 р. : USD 100 Долар США 799,3000; EUR 100 Євро 1104,1530 - джерело «Національний Банк України»'
		],
		xmlId: R0113G3,
		answers: [
			{
				id: AddB7A,
				xmlId: R0113G3,
				title: 'Вкажіть прощену Вам суму ',
				name: AddB7A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB8,
		title:
			'суми за кредитами в іноземній валюті, що отримані на придбання єдиного житла (іпотечні кредити), прощені (анульовані) кредитором у порядку, передбаченому законом щодо реструктуризації зобов’язань громадян України',
		name: AddB8,
		element: CHECKBOX,
		xmlId: R0113G3,
		answers: [
			{
				id: AddB8A,
				xmlId: R0113G3,
				title: 'Вкажіть прощену суму кредиту',
				name: AddB8A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB9,
		title:
			'вартість безоплатно отриманих товарів (робіт, послуг), визначена за правилами звичайної ціни, а також суми знижки звичайної ціни (вартості) товарів (робіт, послуг), призначені індивідуально Вам',
		name: AddB9,
		element: CHECKBOX,
		xmlId: R0109G3,
		answers: [
			{
				id: AddB9A,
				xmlId: R0109G3,
				title: 'Вкажіть вартість безоплатно отриманих товарів (робіт, послуг)',
				name: AddB9A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB10,
		title:
			'суми ненарахованих процентів за користування кредитом відповідно до Закону України «Про соціальний і правовий захист військовослужбовців та членів їх сімей» (http://zakon2.rada.gov.ua/laws/main/2011-12)',
		name: AddB10,
		element: CHECKBOX,
		xmlId: R0113G3,
		answers: [
			{
				id: AddB10A,
				xmlId: R0113G3,
				title: 'Вкажіть суми ненарахованих процентів',
				name: AddB10A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB11,
		title:
			'вартість житла, яке передається з державної або комунальної власності у Вашу власність безоплатно або зі знижкою відповідно до закону, а також суму державної підтримки на будівництво чи придбання доступного житла.',
		name: AddB11,
		element: CHECKBOX,
		xmlId: R0113G3,
		answers: [
			{
				id: AddB11A,
				xmlId: R0113G3,
				title: 'Вкажіть вартість житла',
				name: AddB11A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB12,
		title:
			'суми, сплачені будь-якою юридичною або фізичною особою на користь вітчизняних вищих та професійно-технічних навчальних закладів за здобуття освіти, за Вашу підготовку чи перепідготовку',
		name: AddB12,
		element: CHECKBOX,
		answers: [
			{
				id: AddB12A,
				xmlId: R0109G3,
				xmlIds: [R0109G3, R0113G3],
				title: 'Вкажіть вартість навчання',
				name: AddB12A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			},
			{
				id: 'AddB12B',
				xmlId: R0109G3,
				xmlIds: [R0109G3, R0113G3],
				title: 'Вкажіть кількість місяців Вашого навчання',
				name: AddB12A,
				element: INPUT,
				placeholder: 'Вкажіть кількість місяців Вашого навчання'
			}
		]
	},
	{
		parent: AddB,
		id: AddB13,
		title:
			"на відпочинок, оздоровлення та лікування, у тому числі на реабілітацію інвалідів, на території України Вас та/або Ваших дітей віком до 18 років, які надаються Вам безоплатно або зі знижкою (у розмірі такої знижки) професійною спілкою, до якої зараховуються Ваші профспілкові внески - члена такої професійної спілки, створеної відповідно до законодавства України, або за рахунок коштів відповідного фонду загальнообов'язкового державного соціального страхування",
		name: AddB13,
		element: CHECKBOX,
		xmlId: R0113G3,
		answers: [
			{
				id: AddB13A,
				xmlId: R0113G3,
				title: 'Вкажіть вартість путівки / путівок ',
				name: AddB13A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: AddB,
		id: AddB14,
		title:
			'на відпочинок, оздоровлення та лікування, у тому числі на реабілітацію інвалідів, на території України Вас та/або членів Вашої сім’ї першого ступеня споріднення, які надаються Роботодавцем - платником податку на прибуток підприємств безоплатно або із знижкою (у розмірі такої знижки) один раз на календарний рік, за умови, що вартість путівки (розмір знижки):',
		name: AddB14,
		element: CHECKBOX,
		answers: [
			{
				id: AddB14A,
				xmlId: R0109G3,
				xmlIds: [R0109G3, R0113G3],
				title: 'Вкажіть вартість путівки / путівок ',
				name: AddB14A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	}
];
