import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const firstLevelQuestions = ['D1', 'D1A', 'D1B'];
const R0109G3 = ['D1AA', 'D2BA', 'D3BA', 'D3CA'];

const initialAnswers = {
	D1A: false,
	D1AA: '', //R0109G3
	D1B: false,
	D2A: false,
	D2B: false,
	D2BA: '', // R0109G3
	D3A: false,
	D3AA: '',
	D3AB: '',
	D3AC: '',
	D3AD: '',
	D3AE: '',
	D3B: false,
	D3BA: '', // R0109G3
	D3C: false,
	D3CA: '' // R0109G3
};
const initialData = {
	R0106G3: {
		a: {
			D3A: [
				{
					country_name: '',
					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0,
					tax: 0
				}
			]
		},
		q: { D3A: null }
	},
	R0109G3: {
		a: {
			D1AA: 0,
			D2BA: 0,
			D3BA: 0,
			D3CA: 0
		},
		q: {
			D1AA: null,
			D2BA: null,
			D3BA: null,
			D3CA: null
		}
	},
	R021G3: {
		a: { D3AE: 0 },
		q: { D3AE: null }
	}
};

const D = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, name, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (R0109G3.includes(id)) {
						newData.R0109G3.a[id] = value;
					}
					if (name === 'tax') {
						newData.R021G3.a[id] = value;
					}
					if (name === 'revenue') {
						newData.R0106G3.a.D3A[0][name] = value;
					}
					if (name === 'currency_date') {
						newData.R0106G3.a.D3A[0][name] = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'radio':
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;

					break;

				case 'checkbox':
					newAnswers[value] = !newAnswers[value];

					if (!newAnswers[value]) {
						newAnswers[`${value}A`] = '';
						newData.R0109G3['a'][`${value}A`] = 0;
					}

					break;

				default:
					break;
			}
			save('d', { answers: newAnswers, data: newData });
			props.getDData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			let newData = { ...data };
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;
			newData.R0106G3.a.D3A[0][dataset.name] = target.value;
			setData(newData);
			setAnswers({ ...answers, ...newAnswers });
			props.getDData(newData);
			save('d', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const handleChangeDate = useCallback(
		key => e => {
			let newData = { ...data };
			let newAnswers = { ...answers };

			const date = new Date(e._d);

			newAnswers[key] = date.getTime(); //! change answer
			newData.R0106G3.a[key.slice(0, -1)][0].currency_date = date.getTime(); //! change data

			setData(newData);
			setAnswers(newAnswers);
			props.getDData(newData);
			save('d', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title === '' || firstLevelQuestions.includes(item.id)) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			}
			if (answers.D1B && (item.id === 'D2' || item.id === 'D2A' || item.id === 'D2B')) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			}
			if (answers.D2A && (item.id === 'D3' || item.id === 'D3A' || item.id === 'D3B' || item.id === 'D3C')) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			}
			return null;
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('d')) {
			const { data, answers } = load('d');
			setAnswers(answers);
			setData(data);
			props.getDData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getDData(data);
	return (
		<div className="D-wrap">
			{renderQuestion}
			<div className="btn-D-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default D;
