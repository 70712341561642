import { Disc } from '../../../data/declaration/sub-sections/Disc';
import { GET_DISC_DATA, GET_DISC_ANSWERS } from '../../actions/declaration_action';

const initialState = {
	questions: Disc,
	subId: null,
	data: {},
	answers: {}
};

const disc = (state = initialState, action) => {
	switch (action.type) {
		case GET_DISC_DATA:
			return {
				...state,
				data: action.payload
			};

		case GET_DISC_ANSWERS:
			return {
				...state,
				answers: action.payload
			};

		default:
			return state;
	}
};

export default disc;
