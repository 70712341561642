import UserService from '../../services/user_service';
// import Axios from 'axios';
// import { save } from '../../utils/localStorage';

export const GOOGLE_SIGN_IN_SUCCESS = 'GOOGLE_SIGN_IN_SUCCESS';
export const GOOGLE_SIGN_IN_FAIL = 'GOOGLE_SIGN_IN_FAIL';

export const googleSignIn = (code, userID) => {
	return dispatch => {
		// dispatch({
		// 	type: GOOGLE_SIGN_IN_SUCCESS
		// });
		// return Axios({ headers: { 'content-type': 'application/json' } })
		// return (
		// 	Axios({
		// 		headers: { 'content-type': 'application/json' },
		// 		method: 'POST',
		// 		url: 'http://api.zvit.pure-agency.co/auth/login/google',
		// 		data: { code, userID }
		// 	})
		// 		// .post('http://api.zvit.pure-agency.co/auth/login/google', {
		// 		// 	code,
		// 		// 	userID
		// 		// })
		// 		.then(({ data }) => {
		// 			dispatch({
		// 				type: GOOGLE_SIGN_IN_SUCCESS,
		// 				payload: data
		// 			});
		// 		})
		// 		.catch(err => {
		// 			console.error(err);
		// 			dispatch({
		// 				type: GOOGLE_SIGN_IN_FAIL,
		// 				payload: err
		// 			});
		// 		})
		// );

		return UserService.googleLogin(code, userID)
			.then(data => {
				// save('api_token', data.api_token);

				dispatch({
					type: GOOGLE_SIGN_IN_SUCCESS,
					payload: data
				});
			})
			.catch(response => {
				dispatch({
					type: GOOGLE_SIGN_IN_FAIL,
					payload: response
				});
			});
	};
};

export const GOOGLE_SIGN_OUT_SUCCESS = 'GOOGLE_SIGN_OUT_SUCCESS';
export const GOOGLE_SIGN_OUT_FAIL = 'GOOGLE_SIGN_OUT_FAIL';

export const googleSignOut = () => {
	return {
		type: GOOGLE_SIGN_OUT_SUCCESS
	};
};

// export const EMAIL_SIGN_IN_SUCCESS = 'EMAIL_SIGN_IN_SUCCESS';
// export const EMAIL_SIGN_IN_FAIL = 'EMAIL_SIGN_IN_FAIL';

// export const signIn = () => {
// 	return {
// 		type: EMAIL_SIGN_IN_SUCCESS
// 	};
// };

// export const SIGN_OUT = 'SIGN_OUT';

// export const signOut = () => {
// 	return {
// 		type: SIGN_OUT
// 	};
// };
