import React, { Fragment } from 'react';
import {
	TextField,
	// FormControlLabel,
	Checkbox,
	FormHelperText,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Grid
} from '@material-ui/core';

import { StyledButton } from '../Button/Button';

import './styles.scss';

const style = { display: 'flex', alignItems: 'center', textDecoration: 'underline', cursor: 'pointer', opacity: 0.4 };

const FormField = ({ formdata, change, id, color, open }) => {
	const showError = () => {
		let errorMessage = null;

		if (formdata.validation && !formdata.valid) {
			errorMessage = (
				<FormHelperText error className="labelError">
					{formdata.validationMessage}
				</FormHelperText>
			);
		}

		return errorMessage;
	};

	const renderTemplate = () => {
		let formTemplate = null;

		switch (formdata.element) {
			case 'input':
				formTemplate = (
					<Fragment>
						<TextField
							{...formdata.config}
							error={!formdata.valid && formdata.touched ? true : false}
							label={formdata.config.label}
							onBlur={event => change({ event, id, blur: true })}
							onChange={event => change({ event, id, blur: false })}
							value={formdata.value}
						/>
						<Fragment>{showError()}</Fragment>
					</Fragment>
				);
				break;

			case 'checkbox':
				formTemplate = (
					<div style={style}>
						{/* <FormControlLabel
							control={ */}
						<Checkbox
							color="default"
							{...formdata.config}
							className="checkbox_input"
							onChange={event => change({ event, id, blur: false })}
							checked={formdata.value}
							value="agree"
						/>
						{/* // label="Згоден з умовами оферти"
							className="checkbox_label" */}
						{/* /> */}
						<span onClick={open}>Згоден з умовами оферти</span>
					</div>
				);
				// formTemplate = (
				// 	<FormControlLabel
				// 		control={
				// 			<Checkbox
				// 				color="default"
				// 				{...formdata.config}
				// 				className="checkbox_input"
				// 				onChange={event => change({ event, id, blur: false })}
				// 				checked={formdata.value}
				// 				value="agree"
				// 			/>
				// 		}
				// 		label="Згоден з умовами оферти"
				// 		className="checkbox_label"
				// 	/>
				// );
				break;

			case 'select':
				formTemplate = (
					<FormControl>
						<InputLabel>{formdata.config.placeholder}</InputLabel>
						<Select value={formdata.value} onChange={event => change({ event, id, blur: false })}>
							{formdata.config.options.map((item, i) => (
								<MenuItem key={i} value={item.id}>
									{item.name}
								</MenuItem>
							))}
						</Select>
						<FormHelperText className="select-form-helper-text" style={{ color: `${color}` }}>
							{formdata.config.label}
						</FormHelperText>
					</FormControl>
				);
				break;

			case 'file':
				formTemplate = (
					<Grid container className="input-file">
						<input
							id="contained-button-file"
							multiple
							type="file"
							style={{ display: 'none' }}
							onChange={event => change(event)}
						/>
						<label htmlFor="contained-button-file">
							<StyledButton component="span">Обрати приватний ключ</StyledButton>
						</label>
					</Grid>
				);
				break;

			default:
				formTemplate = null;
				break;
		}

		return formTemplate;
	};

	return <Fragment>{renderTemplate()}</Fragment>;
};

export default FormField;
