import React, { Children, Fragment } from 'react';

import InputFeedback from '../InputFeedback';

const NewStepperCheckboxGroupSub = props => {
	const handleChange = event => {
		const target = event.currentTarget;
		let valueArray = [...props.value] || [];
		if (target.checked) {
			valueArray.push(target.id);
		} else {
			valueArray.splice(valueArray.indexOf(target.id), 1);
		}

		props.onChange(props.id, valueArray);
	};

	const handleBlur = () => {
		props.onBlur(props.id, true);
	};

	const { value, error, touched, children } = props;
	return (
		<div className="question-block-wrap_sub">
			{Children.map(children, child => {
				return (
					<Fragment>
						{React.cloneElement(child, {
							field: {
								value: value.includes(child.props.id),
								onChange: handleChange,
								onBlur: handleBlur
							}
						})}
					</Fragment>
				);
			})}
			<InputFeedback error={error} touched={touched} />
		</div>
	);
};

export default NewStepperCheckboxGroupSub;
