import { connect } from 'react-redux';

import Int from '../subsections/Int';
import { getIntData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.INT.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getIntData
};

const IntContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Int);

export default IntContainer;
