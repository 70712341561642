import { questions, B12 } from '../../data/_questions.1';
import { checkAnswerToShowHideQuestion } from '../../utils/checkAnswerToShowHide';
import uniq from 'lodash/uniq';

const checkQuestionB12 = checkAnswerToShowHideQuestion(B12, questions);

export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';

export const getQuestions = () => ({
	type: GET_QUESTIONS_SUCCESS,
	payload: questions
});

export const GET_ANSWER = 'GET_ANSWER';

export const getAnswer = answers => {
	return {
		type: GET_ANSWER,
		answers,
		showHideQuestion: checkQuestionB12(answers)
	};
};

export const GET_ANSWERS = 'GET_ANSWERS';

export const getAnswers = () => (dispatch, getState) => {
	const {
		quiz: { data }
	} = getState();
	let answers = uniq(
		Object.keys(data)
			.filter(key => (data[key] ? key : null))
			.reduce((acc, key) => {
				if (data[key] === true) {
					acc.push(key);
				} else {
					acc.push(data[key]);
				}

				return acc;
			}, [])
	);

	dispatch({ type: GET_ANSWERS, payload: answers });
};

export const GET_QUIZ_DATA = 'GET_QUIZ_DATA';

export const getQuizData = data => (dispatch, getState) => {
	const { quiz } = getState();
	let questions = [...quiz.questions];

	if (data.B1_g === 'B1B') {
		questions[10][0].disabled = true;
	} else {
		questions[10][0].disabled = false;
	}

	dispatch({
		type: GET_QUIZ_DATA,
		payload: data,
		questions
	});
};

export const GET_PREV_QUESTION = 'GET_PREV_QUESTION';

export const getPrevQuizQuestion = () => ({
	type: GET_PREV_QUESTION
});

export const GET_NEXT_QUESTION = 'GET_NEXT_QUESTION';

export const getNextQuestion = () => ({ type: GET_NEXT_QUESTION });

export const GET_INDEX_QUESTION = 'GET_INDEX_QUESTION';

export const getIndexQuestion = index => ({
	type: GET_INDEX_QUESTION,
	payload: index
});
