import { R0106G3, R0113G3, R021G3 } from '../_xmlConstId';
import { ALIM1, ALIM1A, ALIM2, ALIM2A, ALIM2B, ALIM2C, ALIM2D, ALIM2E } from '../_declaretion_answer_id';
import { B5IA, B5IB } from '../../_questions_OLD';
import { INPUT, SELECT } from '../../elementTypes';

import countries from '../../../data/countries.json';
import currency from '../../../data/currencies.json';

export const ALIM = [
	{
		id: ALIM1,
		title:
			'Вкажіть річну суму аліментів, що виплачуються згідно з рішенням суду або за добровільним рішенням сторін',
		quizOpen: [B5IA],
		question_title: true,
		answers: [
			{
				id: ALIM1A,
				xmlId: R0113G3,
				element: INPUT,
				name: ALIM1A,
				placeholder: 'Pічнa сумa аліментів'
			}
		]
	},
	{
		id: ALIM2,
		title:
			'Вкажіть  річну суму аліментів, що виплачуються згідно з рішенням суду або за добровільним рішенням сторін нерезидентом, перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
		quizOpen: [B5IB],
		question_title: true,
		helper: [
			"Підказка 1. Ви можете зменшити річне податкового зобов'язання на суму податків, сплачених за кордоном згідно з нормами міжнародних договорів про уникнення подвійного оподаткування.",
			'Підказка 2. У разі можливості неоднозначного трактування норм ПКУ рекомендуємо звернутися за індивідуальною податковою консультацією до контролюючого органу',
			'Підказка 3. У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою)'
		],
		answers: [
			{
				parent: ALIM2,
				id: ALIM2A,
				title: 'Kраїнa',
				xmlId: R0106G3,
				name: 'country_name',
				element: SELECT,
				options: countries
			},
			{
				parent: ALIM2,
				id: ALIM2B,
				title: 'Валюта',
				xmlId: R0106G3,
				name: 'currency_name',
				element: SELECT,
				options: currency
			},
			{
				parent: ALIM2,
				id: ALIM2C, //! id element
				title: 'Дата',
				xmlId: R0106G3,
				name: 'currency_date',
				element: 'date' //! date
			},
			{
				parent: ALIM2,
				id: ALIM2D,
				title:
					'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
				element: INPUT,
				name: 'revenue',
				placeholder: 'Введіть суму (грн., коп.)',
				xmlId: R0106G3
			},
			{
				parent: ALIM2,
				id: ALIM2E,
				element: INPUT,
				name: 'tax',
				placeholder: 'НАЛОГ',
				xmlId: R021G3
			}
		]
	}
];
