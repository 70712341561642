import { connect } from 'react-redux';

import IP from '../subsections/IP';
import { getIpData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.IP.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getIpData
};

const IPContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(IP);

export default IPContainer;
