// import { P } from '../_decl_subsections_id';
import { B4A } from '../../_questions_OLD';
import { P1A, P1B, P1C, P1D, P1E, P1F, P1G, P1H, P1I, P1J, P1K, P1L } from '../_declaretion_answer_id.js';
import { T1RXXXXG2, T1RXXXXG3S, T1RXXXXG4, T1RXXXXG5, T1RXXXXG6, T1RXXXXG7 } from '../_xmlConstId';
import { SELECT, INPUT, CHECKBOX } from '../../elementTypes';

export const property = [
	{
		id: T1RXXXXG2,
		title: 'Оберіть категорію, до якої належить Ваше майно',
		xmlId: T1RXXXXG2,
		helpers: [
			'Ви можете перевірити наявність нерухомого майна: https://kap.minjust.gov.ua',
			'Ви можете перевірити наявність транспортного засобу у фізичної чи юридичної особи : https://igov.org.ua',
			'Ви можете перевірити наявність водного судна: https://igov.org.ua',
			'Ви можете перевірити наявність земельної ділянки за кадастровим номером: https://igov.org.ua'
		],
		question_title: true
	},
	{
		id: T1RXXXXG2,
		palaceholder: 'Оберіть категорію, до якої належить Ваше майно',
		element: SELECT,
		xmlId: T1RXXXXG2,
		options: [
			{ xmlId: T1RXXXXG2, id: P1A, title: 'Земельні ділянки', value: 1 },
			{ xmlId: T1RXXXXG2, id: P1B, title: 'Житлові будинки', value: 2 },
			{ xmlId: T1RXXXXG2, id: P1C, title: 'Квартири', value: 3 },
			{ xmlId: T1RXXXXG2, id: P1D, title: 'Садові (дачні) будинки', value: 4 },
			{ xmlId: T1RXXXXG2, id: P1E, title: 'Гаражі', value: 5 },
			{ xmlId: T1RXXXXG2, id: P1F, title: 'Водойми', value: 6 },
			{ xmlId: T1RXXXXG2, id: P1G, title: 'Автомобілі легкові', value: 7 },
			{ xmlId: T1RXXXXG2, id: P1H, title: 'Автомобілі вантажні (спеціальні)', value: 8 },
			{ xmlId: T1RXXXXG2, id: P1I, title: 'Водні  транспортні засоби', value: 9 },
			{ xmlId: T1RXXXXG2, id: P1J, title: 'Повітряні судна', value: 10 },
			{ xmlId: T1RXXXXG2, id: P1K, title: 'Мотоцикли (мопеди)', value: 11 },
			{ xmlId: T1RXXXXG2, id: P1L, title: 'Інше нерухоме (рухоме) майно', value: 12 }
		],
		quiz_answers: [B4A]
	},
	{
		name: 'movable',
		answers: [
			// {
			// 	xmlId: T1RXXXXG3S,
			// 	question: "Оберіть країну та Вкажіть адресу об'єкта нерухомого майна або марку (модель) рухомого майна",
			// 	elements: []
			// },
			{
				id: T1RXXXXG3S,
				xmlId: T1RXXXXG3S,
				element: INPUT,
				placeholder: 'Вкажіть марку (модель) рухомого майна'
			},
			{
				id: T1RXXXXG4,
				xmlId: T1RXXXXG4,
				// question: 'Вкажіть рік набуття у власність/ рік випуску (для рухомого майна)',
				placeholder: 'Вкажіть рік випуску (для рухомого майна)',
				element: INPUT
			},
			{
				id: T1RXXXXG7,
				xmlId: T1RXXXXG7,
				// question: 'Чи надавали Ваше майно в лізинг, оренду (суборенду), житловий найм (піднайм) ?',
				element: CHECKBOX,
				title: 'Чи надавали Ваше майно в лізинг, оренду (суборенду), житловий найм (піднайм)?'
			}
		]
	},
	{
		name: 'nonmovable',
		answers: [
			// {
			// 	xmlId: T1RXXXXG3S,
			// 	question: "Оберіть країну та Вкажіть адресу об'єкта нерухомого майна або марку (модель) рухомого майна",
			// 	elements: [
			// 		{
			// 			element: RADIO,
			// 			placeholder: 'Оберіть країну',
			// 			value: null,

			// 			answers: [{ title: 'Україна', xmlId: T1RXXXXG3S }, { title: 'інші країни ', xmlId: T1RXXXXG3S }]
			// 		}
			// 	]
			// },
			{
				id: T1RXXXXG3S,
				xmlId: T1RXXXXG3S,
				element: INPUT,
				placeholder: "Вкажіть адресу об'єкта нерухомого майна"
			},
			{
				id: T1RXXXXG4,
				xmlId: T1RXXXXG4,
				// question: 'Вкажіть рік набуття у власність',
				placeholder: 'Вкажіть рік набуття у власність',
				element: INPUT
			},
			{
				id: T1RXXXXG5,
				xmlId: T1RXXXXG5,
				// question: 'Вкажіть загальну площу нерухомого майна, кв.м',
				placeholder: 'Вкажіть загальну площу нерухомого майна, кв.м',
				element: INPUT
			},
			{
				id: T1RXXXXG6,
				xmlId: T1RXXXXG6,
				// question: 'Вкажіть частку в загальній площі нерухомого майна',
				placeholder: 'Вкажіть частку в загальній площі нерухомого майна',
				element: INPUT
			},
			{
				id: T1RXXXXG7,
				xmlId: T1RXXXXG7,
				// question: 'Чи надавали Ваше майно в лізинг, оренду (суборенду), житловий найм (піднайм) ?',
				element: CHECKBOX,
				title: 'Чи надавали Ваше майно в лізинг, оренду (суборенду), житловий найм (піднайм)?'
			}
		]
	}
];
