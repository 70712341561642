import {
	HZ,
	HZN,
	HZU,
	HZY,
	HZKV,
	HZYP,
	HZKVP,
	HNAME,
	HTIN,
	HREG,
	HRAJ,
	HCITY,
	HSTREET,
	HBUILD,
	HCORP,
	HAPT,
	HZIP,
	HTEL,
	HEMAIL,
	// HSTI,
	// H01,
	// H02,
	H03,
	H04,
	// H05,
	// H06,
	// H07,
	HNAMEAG,
	HTINAG
} from '../_xmlConstId';
import { COMMON } from '../_decl_subsections_id';
import { GI_1A, GI_1B, GI_1C } from '../_declaretion_answer_id';
import { B1A } from '../../_questions_OLD';
import { RADIO, INPUT, SELECT } from '../../elementTypes';

export const common = {
	id: COMMON,
	srsName: 'common',
	title: 'Загальні відомості',
	quiz_answers: [B1A],
	questions: [
		{
			title: 'Тип декларації',
			answers: [
				{
					xmlId: HZ,
					id: GI_1A,
					name: 'decType',
					title: 'звітна',
					element: RADIO,
					siblingChildren: [HZYP],
					siblings: [HZN, HZU]
				},
				{
					xmlId: HZN,
					id: GI_1B,
					name: 'decType',
					title: 'звітна нова',
					element: RADIO,
					siblingChildren: [HZYP],
					siblings: [HZ, HZU]
				},
				{
					xmlId: HZU,
					id: GI_1C,
					name: 'decType',
					title: 'уточнююча',
					element: RADIO,
					siblingChildren: [HZY],
					siblings: [HZ, HZN]
				}
			]
		},
		{
			title: 'Податковий (звітний) період',
			title_v2: 'Звітний (податковий) період, що уточнюється',
			variants: [HZU],
			answers: [
				{
					xmlId: HZY,
					xmlId_v2: HZYP,
					title: 'рік',
					element: INPUT,
					variants: [HZ, HZN],
					variants_v2: [HZU]
				},
				{
					xmlId: HZKV,
					xmlId_v2: HZKVP,
					title: 'квартал',
					element: SELECT,
					variants: [HZ, HZN],
					variants_v2: [HZU]
				}
			]
		},
		// {
		// 	title: 'Звітний (податковий) період, що уточнюється',
		// 	variants: [HZU],
		// 	answers: [
		// 		{
		// 			xmlId: HZYP,
		// 			title: 'рік',
		// 			element: INPUT,
		// 			value: ''
		// 		},
		// 		{
		// 			xmlId: HZKVP,
		// 			title: 'квартал',
		// 			element: SELECT
		// 		}
		// 	]
		// },
		{
			title: 'ПІБ та реєстраційний номер платника податку',
			answers: [
				{
					xmlId: HNAME,
					title: 'ПІП',
					element: INPUT,
					helper: "Введіть прізвище, ім'я, по батькові платника податку"
				},
				{
					xmlId: HTIN,
					title: 'ІПН',
					element: INPUT,
					helper:
						'Реєстраційний номер картки платника податку або паспортні данні (серія та номер) для осіб які відмовились від його отримання'
				}
				// {
				// 	xmlId: HTIN,
				// 	title:
				// 		'Серія (за наявності) та номер паспорта (для фізичних осіб, які через свої релігійні переконання відмовляються від прийняття реєстраційного номера облікової картки платника податків та офіційно повідомили про це відповідний контролюючий орган і мають відмітку',
				// 	element: INPUT
				// }
			]
		},
		{
			title: 'Податкова адреса (місце проживання) платника податку',
			answers: [
				{
					// xmlId: HREG, //! LOOK
					title: 'введіть адресу:',
					element: INPUT
				},
				{
					xmlId: HREG,
					title: 'область:',
					element: INPUT
				},
				{
					xmlId: HRAJ,
					title: 'район:',
					element: INPUT
				},
				{
					xmlId: HCITY,
					title: 'місто (селище, село)',
					element: INPUT
				},
				{
					xmlId: HSTREET,
					title: 'вулиця',
					element: INPUT
				},
				{
					xmlId: HBUILD,
					title: 'номер будинку',
					element: INPUT
				},
				{
					xmlId: HCORP,
					title: 'корпус',
					element: INPUT
				},
				{
					xmlId: HAPT,
					title: 'номер квартири',
					element: INPUT
				},
				{
					xmlId: HZIP,
					title: 'Поштовий індекс',
					element: INPUT
				},
				{
					xmlId: HTEL,
					title: 'Контактні телефони',
					element: INPUT
				},
				{
					xmlId: HEMAIL,
					title: 'Електронна адреса',
					element: INPUT
				}
			]
		},
		{
			title: 'Декларація заповнена',
			answers: [
				{
					xmlId: H03,
					title: 'Cамостійно платником податку',
					name: 'decWrite',
					element: RADIO,
					siblingChildren: [HNAMEAG, HTINAG],
					siblings: [H04]
				},
				{
					xmlId: H04,
					title: 'Уповноваженою на це особою',
					name: 'decWrite',
					element: RADIO,
					siblings: [H03],

					subquestion: [
						{
							xmlId: HNAMEAG,
							title: 'ПІБ',
							element: INPUT,
							parent: H03,
							helper: "Введіть прізвище, ім'я, по батькові уповноваженої особи"
						},
						{
							xmlId: HTINAG,
							title: 'Номер картки',
							element: INPUT,
							parent: H03,
							helper:
								'Введіть реєстраційний номер облікової картки платника податку уповноваженої особи. Серія (за наявності) та номер паспорта (для фізичних осіб, які через свої релігійні переконання відмовляються від прийняття реєстраційного номера облікової картки платника податків та офіційно повідомили про це відповідний контролюючий орган і мають відмітку'
						}
						// {
						// 	xmlId: HTINAG,
						// 	title:
						// 		'серія (за наявності) та номер паспорта (для фізичних осіб, які через свої релігійні переконання відмовляються від прийняття реєстраційного номера облікової картки платника податків та офіційно повідомили про це відповідний контролюючий орган і мають відмітку',
						// 	element: INPUT
						// }
					]
				}
			]
		}
	]
};
