import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

import hoc from '../../../HOC/hoc';
import LeftSideAdvantages from '../components/LeftSideAdvantages';
import GoogleAuthContainer from '../../../components/containers/GoogleAuthContainer';
import RegistrationForm from './RegistrationForm';

// import GoogleButtonComponent from '../../../components/Auth/google_button';
import Modal from '../../../components/Modal';
import './styles.scss';

class Registration extends Component {
	state = {
		loading: false,
		open: false,
		formdata: {
			email: {
				element: 'input',
				value: '',
				config: {
					name: 'email_input',
					type: 'text',
					label: 'Електорнна пошта'
				},
				validation: {
					required: true,
					email: true
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
			password: {
				element: 'input',
				value: '',
				config: {
					name: 'password_input',
					type: 'password',
					label: 'Пароль'
				},
				validation: {
					required: true,
					password: true
				},
				valid: false,
				touched: false,
				validationMessage: ''
			},
			agree: {
				element: 'checkbox',
				value: false,
				config: {
					name: 'agree_input',
					type: 'checkbox',
					label: 'Згоден з умовами оферти'
				},
				validation: {
					required: true
				},
				valid: false,
				validationMessage: ''
			}
		}
	};

	componentDidMount() {
		localStorage.clear();
	}

	handleChange = element => {
		const target = element.event.target;
		const newFormData = { ...this.state.formdata };
		const newElement = { ...newFormData[element.id] };
		const value = newElement.config.type === 'checkbox' ? target.checked : target.value;
		newElement.value = value;

		if (element.blur) {
			let validData = this.validate(newElement);
			newElement.valid = validData[0];
			newElement.validationMessage = validData[1];
		}

		newElement.touched = element.blur;
		newFormData[element.id] = newElement;

		this.setState({
			formdata: newFormData
		});
	};

	validate = element => {
		let error = [true, ''];

		if (element.validation.email) {
			const valid = /\S+@\S+\.\S+/.test(element.value);
			const message = `${!valid ? 'Не дійсний адреса електронної пошти' : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if (element.validation.password) {
			const valid = element.value.length > 9;
			const message = `${!valid ? 'Пароль повинен бути більше 9 символів' : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if (element.validation.required) {
			const valid = element.value.trim() !== '';
			const message = `${!valid ? "Це поле обов'язкове" : ''}`;
			error = !valid ? [valid, message] : error;
		}
		return error;
	};

	handleSubmit = event => {
		event.preventDefault();
	};

	handleOpenModal = () => {
		this.setState(prevState => (prevState.open = !prevState.open));
	};

	handleSignUp = (values, setSubmitting) => {
		// TODO: add request
		console.log('values  ', values);
		setSubmitting(false);
	};

	render() {
		return (
			<Grid
				item
				style={{ margin: 'auto', minHeight: '100%' }}
				container
				direction="column"
				justify="center"
				alignItems="center"
				className="right-side-block"
			>
				<RegistrationForm onClickLabelCheckbox={this.handleOpenModal} handleSignUp={this.handleSignUp} />
				<p className="or">або</p>
				<div className="social-enter">
					<GoogleAuthContainer />
					{/* <GoogleButtonComponent /> */}
				</div>
				<Modal open={this.state.open} close={this.handleOpenModal} />
			</Grid>
		);
	}
}

export default hoc(LeftSideAdvantages)(Registration);
