const B2BA = 'B2BA',
	B2BB = 'B2BB',
	B2C = 'B2C',
	B2D = 'B2D',
	B4AB1 = 'B4AB1',
	B5AA = 'B5AA',
	B5AB = 'B5AB',
	B5В = 'B5В',
	B5СA = 'B5СA',
	B5CB = 'B5CB',
	B5D = 'B5D',
	B5EA = 'B5EA',
	B5EB = 'B5EB',
	B5FA = 'B5FA',
	B5FB = 'B5FB',
	B5G = 'B5G',
	B5HA = 'B5HA',
	B5HB = 'B5HB',
	B5IA = 'B5IA',
	B5IB = 'B5IB',
	B6AA = 'B6AA',
	B6AB = 'B6AB',
	B6BA = 'B6BA',
	B6BB = 'B6BB',
	B7A = 'B7A',
	B7B = 'B7B',
	B7CA = 'B7CA',
	B8A = 'B8A',
	B10AA = 'B10AA',
	B10AB = 'B10AB',
	B10AC = 'B10AC',
	B10AD = 'B10AD',
	B10AE = 'B10AE',
	B10AF = 'B10AF',
	B10AG = 'B10AG',
	B10AH = 'B10AH',
	B10AI = 'B10AI',
	B10AJ = 'B10AJ',
	B10AK = 'B10AK',
	B11A = 'B11A',
	B12A = 'B12A',
	B12B = 'B12B',
	B12C = 'B12C',
	B12D = 'B12D',
	B12EA = 'B12EA',
	B12EB = 'B12EB',
	B12F = 'B12F',
	B12G = 'B12G',
	B12H = 'B12H',
	B12I = 'B12I';

export const documents = [
	{
		id: B2BA,
		document: 'книга обліку доходів та витрат та інші документи',
		link: '#'
	},
	{
		id: B2BB,
		document: 'книга обліку доходів та витрат та інші документи'
	},
	{
		id: B2C,
		document:
			'податкова декларація платника єдиного податку-фізичної особи-підприємця; податкова звітність за формою 1ДФ; звітність з єдиного соціального внеску; книга обліку доходів та витрат та інші документи',
		link: '#'
	},
	{
		id: B2D,
		document:
			'для подальшого заповнення декларації Вам будуть потрібні такі документи стосовно Вашої незалежної професійної діяльності, як приватного нотаріуса, адвоката, арбітражного керуючого (розпорядника майна, ліквідатора), аудитора, бухгалтера, оцінщика, інженера або архітектора, особи, зайнятої релігійною (місіонерською) діяльністю, літературного діяча, артиста, художника, науковця, викладача, лікаря або іншого фахівця',
		link: '#'
	},
	{
		id: B4AB1,
		document:
			'договір лізингу транспорту/обладнання; договір оренди та/або суборенди майна; виписка з банківського рахунку, на який зараховувалися оплата; документ (квитанція банку тощо), який підтверджує сплату податку – податку на доходи фізичних осіб, військового збору, та інші документи, документи, що підтверджують отримання коштів (виписка з банківського рахунку, на який зараховувалися виплати за лізинг, оренду, суборенду, найм тощо)',
		link: '#'
	},
	{
		id: B5AA,
		document:
			'довідки про доходи, отримані від Роботодавців, у яких Ви працювали упродовж звітного року та інші документи. Звертаємо Вашу увагу, що Роботодавець у довідці про доходи повинен вказати окремо види доходу (заробітна плата, разова матеріальна допомога та інше), які Вам виплачувалися упродовж звітного року, та окремо сплачені податки (єдиний соціальний внесок, податок на доходи фізичних осіб, військовий збір) з цих доходів',
		link: '#'
	},
	{
		id: B5AB,
		document:
			'довідка про доходи від Роботодавця, у якого Ви працювали за межами України, та інші документи; документ, що підтверджує сплату податків за межами України; декларація фізичної особи про доходи, яку Ви подавали за межами України; інші документи',
		link: '#'
	},
	{
		id: B5В,
		document:
			'довідки про доходи, отримані від Роботодавців, у яких Ви працювали упродовж звітного року та інші документи. Звертаємо Вашу увагу, що Роботодавець у довідці про доходи повинен вказати окремо види доходу (заробітна плата, разова матеріальна допомога та інше), які Вам виплачувалися упродовж звітного року, та окремо сплачені податки (єдиний соціальний внесок, податок на доходи фізичних осіб, військовий збір) з цих доходів',
		link: '#'
	},
	{
		id: B5СA,
		document:
			'договори цивільно-правового характеру; акти наданих послуг або виконаних робіт; довідка від Роботодавця, у якого Ви працювали за договором; інші документи (банківська виписка тощо), які підтверджують отримання Вами виплат за виконані роботи або надані послуги за договорами цивільно-правового характеру, та інші документи.'
	},
	{
		id: B5CB,
		document:
			'договори цивільно-правового характеру, укладені з іноземними компаніями, та/або акти виконаних робіт або наданих послуг з іноземними компаніями;  документ, що підтверджує сплату податків за межами України; декларація фізичної особи про доходи, яку Ви подавали за межами України; інші документи'
	},
	{
		id: B5D,
		document:
			'довідка з навчального закладу про виплачену стипендію або виписка з банківського рахунку, на який зараховується стипендія, та інші документи'
	},
	{
		id: B5EA,
		document:
			'довідки з Пенсійного фонду про доходи пенсіонера протягом звітного року або виписка з банківського рахунку, на який зараховується пенсія, та інші документи'
	},
	{
		id: B5EB,
		document:
			'документ, що підтверджує отримання доходів та  сплату податків за межами України; декларація фізичної особи про доходи, яку Ви подавали за межами України; інші документи'
	},
	{
		id: B5FA,
		document:
			'довідки з банку, кредитної спілки, фінансової компанії або іншої фінансової установи про нараховані відсотки. Звертаємо Вашу увагу, що в довідках має бути відображена загальна сума нарахованих у звітному податковому періоді доходів та загальна сума утриманого з них податку'
	},
	{
		id: B5FB,
		document:
			'документ, що підтверджує отримання доходів та  сплату податків за межами України; декларація фізичної особи про доходи, яку Ви подавали за межами України; інші документи'
	},
	{
		id: B5G,
		document: 'довідки про виплату дивідендів, рішення акціонерів про виплату дивідендів та інші документи'
	},
	{
		id: B5HA,
		document:
			'договір використання прав інтелектуальної власності; документи, що підтверджують отримання коштів (виписка з банківського рахунку тощо), та інші документи, договір на користування або надання права використання будь-яких авторських прав на твори літератури або мистецтва (включаючи кінематографічні фільми і фільми або плівки для радіомовлення чи телебачення); документи, що підтверджують отримання коштів (виписка з банківського рахунку тощо), та інші документи'
	},
	{
		id: B5HB,
		document:
			'договір використання прав інтелектуальної власності; документи, що підтверджують отримання коштів (виписка з банківського рахунку тощо), та інші документи, договір на користування або надання права використання будь-яких авторських прав на твори літератури або мистецтва (включаючи кінематографічні фільми і фільми або плівки для радіомовлення чи телебачення); документи, що підтверджують отримання коштів (виписка з банківського рахунку тощо), та інші документи, документ, що підтверджує сплату податків за межами України; декларація фізичної особи про доходи, яку Ви подавали за межами України; інші документи'
	},
	{
		id: B5IA,
		document:
			'документи, що підтверджують отримання коштів (виписка з банківського рахунку тощо), та інші документи'
	},
	{
		id: B5IB,
		document:
			'документ, що підтверджує отримання аліментів від батьків-нерезидентів; документ, що підтверджує сплату податків за межами України; декларація фізичної особи про доходи, яку Ви подавали за межами України; інші документи'
	},
	{
		id: B6AA,
		document:
			'договір купівлі-продажу майна;договір купівлі-продажу майнових прав; договір про відступлення майнових прав; документ (квитанція банку тощо), який підтверджує сплату податку – податку на доходи фізичних осіб, військового збору, та інші документи'
	},
	{
		id: B6AB,
		document:
			'договір купівлі-продажу майна; договір купівлі-продажу майнових прав; договір про відступлення майнових прав; документ, що підтверджує сплату податків за межами України; декларація фізичної особи про доходи, яку Ви подавали за межами України; інші документи'
	},
	{
		id: B6BA,
		document:
			'свідоцтво про право на спадщину; договір дарування; документ (квитанція банку тощо), який підтверджує сплату податку – податку на доходи фізичних осіб, військового збору, та інші документи'
	},
	{
		id: B6BB,
		document:
			'свідоцтво про право на спадщину; договір дарування; документ, що підтверджує сплату податків за межами України; декларація фізичної особи про доходи, яку Ви подавали за межами України; інші документи'
	},
	{
		id: B7A,
		document:
			'свідоцтво про право на спадщину; договір міни;договір купівлі-продажу корпоративних прав; документи, що підтверджують отримання коштів (виписка з банківського рахунку тощо); документ (квитанція банку тощо), який підтверджує сплату податку – податку на доходи фізичних осіб, військового збору, та інші документи'
	},
	{
		id: B7B,
		document:
			'договори купівлі-продажу цінних паперів; звіти брокерів або біржові звіти;виписки з рахунків у цінних паперах зберігачів/депозитарних установ; оригінали або належним чином оформлені копії первинних документів, що підтверджують оплату ЦП (банківські документи – у разі безготівкової оплати, корінець прибуткового ордеру – у разі оплати готівкою, звіт брокера); договори комісії, укладений з торговцем ЦП (винагорода /комісія торговцю ЦП); первинні документи, які є документальним підтвердженням додаткових фактично понесених витрат на придбання інвестиційного активу (сплата біржового збору, оплата брокерських послуг, оплата послуг зберігача/депозитарної установи (реєстратора) та банку); документи (квитанції банків тощо), які підтверджують сплату податку – податку на доходи фізичних осіб, військового збору, та інші документи'
	},
	{
		id: B7CA,
		document:
			'договори купівлі-продажу цінних паперів; виписки з рахунків у цінних паперах зберігача/депозитарної установи;оригінали або належним чином оформлені копії первинних документів, що підтверджують оплату ЦП (банківські документи – у разі безготівкової оплати, корінець прибуткового ордеру – у разі оплати готівкою, звіт брокера); договори комісії, укладені з торговцем ЦП (винагорода /комісія торговцю ЦП); первинні документи, які є документальним підтвердженням додаткових фактично понесених витрат на придбання інвестиційного активу (оплата брокерських послуг, оплата послуг зберігача/депозитарної установи (реєстратора) та банку); документи (квитанції банків тощо), які підтверджують сплату податку – податку на доходи фізичних осіб, військового збору, та інші документи'
	},
	{
		id: B8A,
		document:
			"договори страхування довічних пенсій та недержавного пенсійного забезпечення, договори пенсійного вкладу, договори довірчого управління, укладені з учасниками фондів банківського управління, договори страхування додаткової пенсії; договори медичного страхування; договори страхування здоров'я на випадок хвороби; договори страхування залізничного транспорту; договори страхування наземного транспорту (крім залізничного);договори страхування повітряного транспорту; договори страхування водного транспорту;договори страхування вантажів та багажу (вантажобагажу); договори страхування від вогневих ризиків та ризиків стихійних явищ;договори страхування майна; договори страхування цивільної відповідальності власників наземного транспорту (включаючи відповідальність перевізника); документи, що підтверджують здійснення виплати страхового відшкодування на Вашу користь (банківська виписка тощо);документи, що підтверджують здійснення страхових внесків за договорами страхування (квитанція тощо); довідки від страхових компаній та інші документи"
	},
	{
		id: B10AA,
		document: 'довідка або рішення контролюючого органу; документи, що підтверджують Ваш дохід, та інші документи'
	},
	{
		id: B10AB,
		document: 'цивільно-правовий договір та інші документи'
	},
	{
		id: B10AC,
		document:
			'довідка про отримання виграшу, призу або подарунка від організатора лотереї, змагань тощо та інші документи'
	},
	{
		id: B10AD,
		document: 'рішення суду та інші документи'
	},
	{
		id: B10AE,
		document:
			'договір відступлення права вимоги; договір про розірвання участі у фонді фінансування будівництва; довідка від управителя про вихід з фонду фінансування будівництва; документи, що підтверджують отримання виплати (банківська виписка тощо), та інші документи'
	},
	{
		id: B10AF,
		document:
			'рішення суду; документи, що підтверджують отримання виплати (банківська виписка тощо), та інші документи'
	},
	{
		id: B10AG,
		document:
			'договір або додаткова угода до кредитного договору;повідомлення від кредитора про прощення боргу та інші документи'
	},
	{
		id: B10AH,
		document:
			'довідка про отримання благодійної допомоги; документи, що підтверджують отримання благодійної допомоги (банківська виписка тощо), довідка від державного органу про отримання допомоги; виписка з банківського рахунку, на який була здійснена виплата коштів та інші документи'
	},
	{
		id: B10AI,
		document:
			'договір; квитанція або акт від покупця брухту (вторинної сировини, побутових відходів та ін.); документи, що підтверджують отримання виплати коштів за брухт (банківська виписка тощо), та інші документи'
	},
	{
		id: B10AJ,
		document:
			'договір та квитанція від покупця продукції; виписка з банківського рахунку, на який була здійснена виплата коштів за сільськогосподарську продукцію; довідка про самостійне вирощування, розведення, відгодовування продукції тваринництва;довідка про наявність земельних ділянок та інші документи, довідка від державного органу про отримання допомоги'
	},
	{
		id: B10AK,
		document:
			'документи, які підтверджують отримання доходу у вигляді додаткового блага (фіскальні чеки, договори оренди, квитанції та інші документи)'
	},
	{
		id: B11A,
		document:
			'документи, що підтверджують отримання доходів та  сплату податків за межами України; декларація фізичної особи про доходи, яку Ви подавали за межами України; інші документи'
	},
	{
		id: B12A,
		document:
			'довідка про отриману заробітну плату за звітний рік; паспорт (обов’язково повинна бути відмітка про реєстрацію в позичальника нерухомості, на купівлю якої було оформлено кредит);довідка про присвоєння ідентифікаційного податкового номера;кредитний договір;договір іпотеки, завірений нотаріально;документи на нерухомість (договір купівлі-продажу);первинні платіжні документи банку (квитанції або меморіальні ордери), що підтверджують щомісячну оплату відсотків за кредитом'
	},
	{
		id: B12B,
		document:
			'договір про пожертвування; квитанція про внесення пожертвування та/або благодійного внеску або платіжне доручення, або прибутковий касовий ордер, або довідка роботодавця про відрахування внесків із заробітної плати; при передачі майна – договір дарування (нотаріально посвідчений); акт приймання-передачі майна; витяг про включення організації, до якої було здійснено пожертвування, до реєстру неприбуткових організацій та установ, квитанція про внесення пожертвування та/або благодійного внеску або платіжне доручення, або прибутковий касовий ордер, або довідка роботодавця про відрахування внесків із заробітної плати;при передачі майна – договір дарування (нотаріально посвідчений); акт приймання-передачі майна; витяг про включення організації, до якої було здійснено пожертвування, до реєстру неприбуткових організацій та установ'
	},
	{
		id: B12C,
		document:
			'договір на навчання, укладений з навчальним закладом, платіжне доручення на безготівкове перерахування коштів або квитанція банку чи відділення зв’язку про перерахування готівки; довідка роботодавця про перерахування коштів, який за довіреністю працівника перераховував з його заробітної плати суму на оплату навчання;документ, що підтверджує перший ступінь споріднення з особою, навчання якої оплачував платник податків, який претендує на податкову знижку'
	},
	{
		id: B12D,
		document:
			'платіжні розрахункові документи, що підтверджують сплату коштів за лікування на користь закладів охорони здоров’я; квитанції або платіжне доручення, або прибутковий касовий ордер, або довідка від Роботодавця, який за дорученням працівника частину його заробітної плати перерахував на сплату за лікування за звітний рік'
	},
	{
		id: B12EA,
		document:
			'договори довгострокового страхування життя укладені з страховиками резидентами; договори недержавного пенсійного забезпечення; договори пенсійного внеску; пенсійні контракти з недержавними пенсійним фондом; платіжні розрахункові документи, що підтверджують сплату таких сум;квитанції про сплату за звітний рік або платіжне доручення, або прибутковий касовий ордер, або довідка від Роботодавця, який за дорученням працівника частину його заробітної плати перерахував на сплату таких внесків'
	},
	{
		id: B12EB,
		document:
			'договори довгострокового страхування життя зі страховиками-резидентами; договори недержавного пенсійного забезпечення; договори пенсійного внеску; пенсійні контракти з недержавними пенсійним фондом; договори про відкриття банківських пенсійних депозитних рахунків; платіжні розрахункові документи, що підтверджують сплату таких сум; квитанції про сплату за звітний рік або платіжне доручення, або прибутковий касовий ордер, або довідка від Роботодавця, який за дорученням працівника частину його заробітної плати перерахував на сплату таких внесків'
	},
	{
		id: B12F,
		document:
			'квитанції про оплату вартості або платіжне доручення, або прибутковий касовий ордер (у документах мають бути зазначені реквізити медичного закладу, отримана процедура штучного запліднення і дата оплати послуги); документи про оплату вартості державних послуг, пов’язаних з усиновленням дитини, включаючи оплату державного мита'
	},
	{
		id: B12G,
		document:
			'документ, що підтверджує право власності на транспортний засіб; платіжні та розрахункові документи; сертифікат відповідності для переобладнаного автотранспорту; копія свідоцтва про реєстрацію транспорту (техпаспорт)'
	},
	{
		id: B12H,
		document:
			'платіжні та розрахункові документи, зокрема квитанції, фіскальні або товарні чеки, прибуткові касові ордери, копії договорів, що ідентифікують продавця товарів (робіт, послуг) і їх покупця (отримувача); У зазначених документах обов’язково потрібно відобразити вартість таких товарів (робіт, послуг) і термін їх продажу (виконання, надання)'
	},
	{
		id: B12I,
		document: 'договір оренди та/або суборенди майна; документи, що підтверджують сплату коштів'
	}
];
