import { connect } from 'react-redux';

import IPA from '../subsections/IPA';
import { getIpaData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.IPA.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getIpaData
};

const IPAContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(IPA);

export default IPAContainer;
