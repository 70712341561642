import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const firstLevelQuestions = [
	'C1A',
	'C1B',
	'C1C',
	'C1D',
	'C1E',
	'C1F',
	'C1G',
	'C1H',
	'C1I',
	'C1J',
	'C1K',
	'C1L',
	'C1M',
	'C1N',
	'C1O',
	'C1P',
	'C1Q',
	'C1R',
	'C1S',
	'C1T',
	'C1U',
	'C1V',
	'C1W',
	'C2',
	'C2A',
	'C2B'
];
// const secondLevelQuestion = ['C1KA', 'C1KB', 'C1KC', 'C1KD', 'C1KE'];
const R0109G3 = ['C1AA', 'C1DA', 'C1EAAA', 'C1EABA', 'C1MA', 'C2A'];
const R0113G3 = [
	'C1BA',
	'C1CA',
	'C1DA',
	'C1EAAA',
	'C1EABA',
	'C1FA',
	'C1GA',
	'C1HA',
	'C1IA',
	'C1JA',
	'C1KAAAA',
	'C1KAABA',
	'C1KBAAA',
	'C1KBABA',
	'C1KCAAA',
	'C1KCABA',
	'C1KDAAA',
	'C1KDABA',
	'C1KEA',
	'C1LA',
	'C1MA',
	'C1NA',
	'C1OAA',
	'C1OBA',
	'C1PA',
	'C1QA',
	'C1RA',
	'C1SA',
	'C1TA',
	'C1UA',
	'C1VA',
	'C1WA'
];
const idCheckboxForRemoveData = [
	'C1A',
	'C1B',
	'C1C',
	'C1D',
	'C1F',
	'C1G',
	'C1H',
	'C1I',
	'C1J',
	'C1L',
	'C1M',
	'C1N',
	'C1P',
	'C1OA',
	'C1Q',
	'C1R',
	'C1S',
	'C1T',
	'C1U',
	'C1V',
	'C1W',
	'C1KAAA',
	'C1KAAB',
	'C1KBAA',
	'C1KBAB',
	'C1KCAA',
	'C1KCAB',
	'C1KDAA',
	'C1KDAB',
	'C1KE',
	'C2'
];

const initialAnswers = {
	C1A: false,
	C1AA: '', //! R0109G3 18 1.5  cam

	C1B: false,
	C1BA: '', // R0113G3 0 0

	C1C: false,
	C1CA: '', // R0113G3 0 0

	C1D: false,
	C1DA: '', // ![R0109G3, R0113G3] 18 1.5 cam

	C1E: false,
	C1EA_g: null,
	C1EAAA: '', // ![R0109G3, R0113G3] 18 1.5
	C1EABA: '', // ![R0109G3, R0113G3] 18 1.5

	C1F: false,
	C1FA: '', // R0113G3 0 0

	C1G: false,
	C1GA: '', // R0113G3 0 0

	C1H: false,
	C1HA: '', // R0113G3 0 0

	C1I: false,
	C1IA: '', // R0113G3 0 0

	C1J: false,
	C1JA: '', // R0113G3 0 0

	C1K: false,

	C1KA: false,
	C1KAAA: false,
	C1KAAAA: '', // R0113G3 0 0
	C1KAAB: false,
	C1KAABA: '', // R0113G3 18 1.5 cam

	C1KB: false,
	C1KBAA: false,
	C1KBAAA: '', // R0113G3 0 0
	C1KBAB: false,
	C1KBABA: '', // R0113G3 18 1.5 cam

	C1KC: false,
	C1KCAA: false,
	C1KCAAA: '', // R0113G3 0 0
	C1KCAB: false,
	C1KCABA: '', // R0113G3 18 1.5 cam

	C1KD: false,
	C1KDAA: false,
	C1KDAAA: '', // R0113G3
	C1KDAB: false,
	C1KDABA: '', // R0113G3 cam

	C1KE: false,
	C1KEA: '', // R0113G3

	C1L: false,
	C1LA: '', // R0113G3

	C1M: false,
	C1MA: '', //! [R0113G3, R0109G3] 18 1.5

	C1N: false,
	C1NA: '', // R0113G3

	C1O: false,
	C1OA: false,
	C1OAA: '', // R0113G3

	C1OB: false,
	C1OBA: '', // R0113G3

	C1P: false,
	C1PA: '', // R0113G3

	C1Q: false,
	C1QA: '', // R0113G3

	C1R: false,
	C1RA: '', // R0113G3

	C1S: false,
	C1SA: '', // R0113G3

	C1T: false,
	C1TA: '', // R0113G3

	C1U: false,
	C1UA: '', // R0113G3

	C1V: false,
	C1VA: '', // R0113G3

	C1W: false,
	C1WA: '', // R0113G3

	C2: false,
	C2_g: null,
	C2AA: '' //! R0109G3 18 1.5 cam
};
const initialData = {
	R0109G3: {
		a: {
			C1AA: 0,
			C1DA: 0,
			C1EAAA: 0,
			C1EABA: 0,
			C1MA: 0,
			C2AA: 0
		},
		q: {
			C1AA: null,
			C1DA: null,
			C1EAAA: null,
			C1EABA: null,
			C1MA: null,
			C2AA: null
		}
	},
	R0113G3: {
		a: {
			C1BA: 0,
			C1CA: 0,
			C1DA: 0,
			C1EAAA: 0,
			C1EABA: 0,
			C1FA: 0,
			C1GA: 0,
			C1HA: 0,
			C1IA: 0,
			C1JA: 0,
			C1KAAAA: 0,
			C1KAABA: 0,
			C1KBAAA: 0,
			C1KBABA: 0,
			C1KCAAA: 0,
			C1KCABA: 0,
			C1KDAAA: 0,
			C1KDABA: 0,
			C1KEA: 0,
			C1LA: 0,
			C1MA: 0,
			C1NA: 0,
			C1OAA: 0,
			C1OBA: 0,
			C1PA: 0,
			C1QA: 0,
			C1RA: 0,
			C1SA: 0,
			C1TA: 0,
			C1UA: 0,
			C1VA: 0,
			C1WA: 0
		},
		q: {
			C1BA: null,
			C1CA: null,
			C1DA: null,
			C1EAAA: null,
			C1EABA: null,
			C1FA: null,
			C1GA: null,
			C1HA: null,
			C1IA: null,
			C1JA: null,
			C1KAAAA: null,
			C1KAABA: null,
			C1KBAAA: null,
			C1KBABA: null,
			C1KCAAA: null,
			C1KCABA: null,
			C1KDAAA: null,
			C1KDABA: null,
			C1KEA: null,
			C1LA: null,
			C1MA: null,
			C1NA: null,
			C1OAA: null,
			C1OBA: null,
			C1PA: null,
			C1QA: null,
			C1RA: null,
			C1SA: null,
			C1TA: null,
			C1UA: null,
			C1VA: null,
			C1WA: null
		}
	}
};

const Charity = props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (R0109G3.includes(id)) {
						newData.R0109G3.a[id] = value;
					}
					if (R0113G3.includes(id)) {
						newData.R0113G3.a[id] = value;
					}
					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;
					if (value === 'C1EAA' || value === 'C1EAB') {
						newAnswers[`${value}A`] = '';
						newData.R0109G3.a[`${value}A`] = 0;
						newData.R0113G3.a[`${value}A`] = 0;
					}
					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];

					if (!newAnswers[value] && idCheckboxForRemoveData.includes(value)) {
						if (R0109G3.includes(`${value}A`)) {
							newData.R0109G3.a[`${value}A`] = 0;
						}
						if (R0113G3.includes(`${value}A`)) {
							newData.R0113G3.a[`${value}A`] = 0;
						}

						newAnswers[`${value}A`] = '';
					}
					if (value === 'C1K') {
						newAnswers.C1KA = false;
						newAnswers.C1KAAA = false;
						newAnswers.C1KAAAA = '';
						newAnswers.C1KAAB = false;
						newAnswers.C1KAABA = '';

						newAnswers.C1KB = false;
						newAnswers.C1KBAA = false;
						newAnswers.C1KBAAA = '';
						newAnswers.C1KBAB = false;
						newAnswers.C1KBABA = '';

						newAnswers.C1KC = false;
						newAnswers.C1KCAA = false;
						newAnswers.C1KCAAA = '';
						newAnswers.C1KCAB = false;
						newAnswers.C1KCABA = '';

						newAnswers.C1KD = false;
						newAnswers.C1KDAA = false;
						newAnswers.C1KDAAA = '';
						newAnswers.C1KDAB = false;
						newAnswers.C1KDABA = '';

						newAnswers.C1KE = false;
						newAnswers.C1KEA = '';

						newData.R0113G3.a.C1KAAAA = 0;
						newData.R0113G3.a.C1KAABA = 0;
						newData.R0113G3.a.C1KBAAA = 0;
						newData.R0113G3.a.C1KBABA = 0;
						newData.R0113G3.a.C1KCAAA = 0;
						newData.R0113G3.a.C1KCABA = 0;
						newData.R0113G3.a.C1KDAAA = 0;
						newData.R0113G3.a.C1KDABA = 0;
						newData.R0113G3.a.C1KEA = 0;
					}
					if (value === 'C1E') {
						newAnswers.C1EA_g = null;
						newAnswers.C1EAAA = '';
						newAnswers.C1EAAA = '';
						newAnswers.C1EABA = '';
						newData.R0109G3.a.C1EAAA = 0;
						newData.R0109G3.a.C1EABA = 0;
						newData.R0113G3.a.C1EAAA = 0;
						newData.R0113G3.a.C1EABA = 0;
					}
					if (value === 'C1O') {
						newData.R0113G3.a.C1OAA = 0;
						newData.R0113G3.a.C1OBA = 0;
						newAnswers.C1OA = false;
						newAnswers.C1OB = false;
						newAnswers.C1OAA = '';
						newAnswers.C1OBA = '';
					}

					break;
				default:
					break;
			}
			save('c', { answers: newAnswers, data: newData });
			props.getCData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title || firstLevelQuestions.includes(item.id)) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (answers.C1EA_g === item.parent) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (answers.C1K && item.parent === 'C1K') {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (answers.C1K && answers.C1KA && item.parent === 'C1KA') {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (answers.C1K && answers.C1KB && item.parent === 'C1KB') {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (answers.C1K && answers.C1KC && item.parent === 'C1KC') {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (answers.C1K && answers.C1KD && item.parent === 'C1KD') {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (answers.C1K && answers.C1KE && item.parent === 'C1KE') {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (answers.C1O && item.parent === 'C1O') {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}

			if (answers.C2_g === item.parent) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('c')) {
			const { data, answers } = load('c');
			setAnswers(answers);
			setData(data);
			props.getCData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getCData(data);
	return (
		<div className="C-wrap">
			{renderQuestion}
			<div className="btn-C-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
};

export default Charity;
