import { connect } from 'react-redux';

import Property from '../subsections/Property';
import {
	getDeclarations,
	getPropertySubquestions,
	addProperty,
	deleteProperty,
	getPropertyData
} from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.P.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getDeclarations,
	getPropertySubquestions,
	addProperty,
	deleteProperty,
	getPropertyData
};

const DeclarationContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Property);

export default DeclarationContainer;
