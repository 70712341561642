import { R0106G3, R021G3 } from '../_xmlConstId';

import { OFI_1 } from '../_declaretion_answer_id';
import { B11A } from '../../_questions_OLD';
import { INPUT, SELECT, DATE } from '../../elementTypes';

import countries from '../../../data/countries.json';
import currency from '../../../data/currencies.json';

export const OFI = [
	{
		id: OFI_1,
		title:
			'Вкажіть суму доходів, яку Ви отримали протягом звітного року за кордоном, перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
		xmlId: R0106G3,
		helpers: [
			"Підказка 1. Ви можете зменшити річне податкового зобов'язання на суму податків, сплачених за кордоном згідно з нормами міжнародних договорів про уникнення подвійного оподаткування.",
			'Підказка 2. У разі можливості неоднозначного трактування норм ПКУ рекомендуємо звернутися за індивідуальною податковою консультацією до контролюючого органу.',
			'Підказка 3. У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою).'
		],
		quizOpen: [B11A],
		answers: [
			{
				id: 'OFI_1A',
				title: 'Kраїнa',
				xmlId: R0106G3,
				name: 'country_name',
				element: SELECT,
				options: countries
			},
			{
				id: 'OFI_1B',
				title: 'Валюта',
				xmlId: R0106G3,
				name: 'currency_name',
				element: SELECT,
				options: currency
			},
			{
				id: 'OFI_1C',
				title: 'Дата',
				xmlId: R0106G3,
				name: 'currency_date',
				element: DATE
			},
			{
				id: 'OFI_1D',
				title:
					'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
				element: INPUT,
				name: 'revenue',
				placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R0106G3
			},
			{
				id: 'OFI_1E',
				title:
					'Вкажіть суму  сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
				element: INPUT,
				name: 'tax',
				placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R021G3
			}
		]
	}
];
