import { S } from '../../../data/declaration/sub-sections/S';
import { GET_S_DATA } from '../../actions/declaration_action';

const initialState = {
	questions: { ...S },
	subId: null,
	data: {}
};

const s = (state = initialState, action) => {
	switch (action.type) {
		case GET_S_DATA:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default s;
