import React from 'react';

import { Grid } from '@material-ui/core';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';

import InputTextField from '../../../components/InputTextField';
import CheckboxField from '../../../components/CheckboxField';
import { StyledButton } from '../../../components/Button/Button';

const RegistrationFormShema = yup.object().shape({
	email: yup
		.string()
		.email('Не дійсний адреса електронної пошти')
		.required("Це поле обов'язкове"),
	password: yup
		.string()
		.min(9, 'Пароль повинен бути більше 9 символів')
		.required("Це поле обов'язкове"),
	isAgree: yup.bool().oneOf([true], "Це поле обов'язкове")
});

const RegistrationForm = props => {
	const { handleSubmit, isValid, isSubmitting, onClickLabelCheckbox } = props;
	return (
		<div className="form form__registration">
			<h1 className="h1">Реєстрація</h1>
			<Grid container direction="column" justify="center">
				<Field component={InputTextField} label="Електорнна пошта" type="text" name="email" />
				<Field component={InputTextField} label="Пароль" type="password" name="password" />
				<Field
					component={CheckboxField}
					className="checkbox_input"
					color="default"
					label="Згоден з умовами оферти"
					onClickLabel={onClickLabelCheckbox}
					name="isAgree"
					classNameWrapper="registration-agree-wrapper"
				/>
			</Grid>
			<StyledButton
				disabled={!isValid || isSubmitting}
				variant="contained"
				type="submit"
				className="registration-btn"
				onClick={handleSubmit}
			>
				Завершити
			</StyledButton>
		</div>
	);
};

export default withFormik({
	mapPropsToValues: () => ({
		email: '',
		password: '',
		isAgree: false
	}),
	validationSchema: RegistrationFormShema,
	handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
		await props.handleSignUp(values, setSubmitting, setErrors);
	}
})(RegistrationForm);
