// Take a look at the very bottom of this file re F01002. We are trying to resolve issues with circ references using some
// stupid hacks :)
let main_doc_definition = {};

const resolve_ref_to_main_doc_definition = () => {
	return main_doc_definition;
};

export const F01212 = {
	meta: {
		NUM: '2',
		TYPE: '1'
	},
	head: {
		fixed: {
			C_DOC: 'F01',
			C_DOC_SUB: '212',
			C_DOC_VER: '10'
		},
		dynamic: {
			C_DOC_TYPE: 0,
			C_DOC_CNT: 1,
			C_DOC_STAN: 1,

			TIN: null,
			C_REG: null,
			C_RAJ: null,
			C_STI_ORIG: null,
			PERIOD_TYPE: null,
			PERIOD_MONTH: null,
			PERIOD_YEAR: null,

			D_FILL: null
		}
	},
	body: {
		HTIN: null,
		HZ: null,
		HZY: null,
		HZYP: null,
		T1RXXXXG2: null,
		T1RXXXXG3S: null,
		T1RXXXXG4: null,
		T1RXXXXG5: null,
		T1RXXXXG6: null,
		R001G4: null,
		R001G5: null,
		R001G6: null,
		R002G6: null,
		R003G6: null,
		R031G6: null,
		R032G6: null,
		R004G6: null,
		R041G6: null,
		R042G6: null,
		R005G6: null,
		R051G6: null,
		R052G6: null,
		HBOS: null
	},
	linked_docs: [
		{
			type: 'F01002',
			ref: resolve_ref_to_main_doc_definition()
		}
	]
};

export const F01214 = {
	meta: {
		NUM: '3',
		TYPE: '1'
	},
	head: {
		fixed: {
			C_DOC: 'F01',
			C_DOC_SUB: '214',
			C_DOC_VER: '10'
		},
		dynamic: {
			C_DOC_TYPE: 0,
			C_DOC_CNT: 1,
			C_DOC_STAN: 1,

			TIN: null,
			C_REG: null,
			C_RAJ: null,
			C_STI_ORIG: null,
			PERIOD_TYPE: null,
			PERIOD_MONTH: null,
			PERIOD_YEAR: null,

			D_FILL: null
		}
	},
	body: {
		HTIN: null,
		HZ: null,
		HZN: null,
		HZU: null,
		HZY: null,
		HZYP: null,
		R01G4: null,
		R01G5: null,
		R01G6: null,
		R01G7: null,
		R01G8: null,
		R01G9: null,

		T1RXXXXG2S: null,
		T1RXXXXG3S: null,
		T1RXXXXG4: null,
		T1RXXXXG5: null,
		T1RXXXXG6: null,
		T1RXXXXG7: null,
		T1RXXXXG8: null,
		T1RXXXXG9: null,

		R0221G3: null,
		R0221G4: null,
		R0221G5: null,

		R0222G3: null,
		R0222G4: null,
		R0222G5: null,

		R0223G3: null,
		R0223G4: null,
		R0223G5: null,

		R0224G3: null,
		R0224G4: null,
		R0224G5: null,

		R022G5: null,

		R011G3: null,
		R012G3: null,
		R013G3: null,
		R014G3: null,
		R015G3: null,

		R021G3: null,
		R022G3: null,
		R023G3: null,

		T4RXXXXG2: null,
		T4RXXXXG3S: null,
		T4RXXXXG4S: null,
		T4RXXXXG5: null,
		T4RXXXXG6: null,
		T4RXXXXG7: null,
		T4RXXXXG8: null,
		T4RXXXXG9: null,

		R04G5: null,
		R04G6: null,
		R04G7: null,
		R04G8: null,
		R04G9: null,

		R031G3: null,
		R041G3: null,
		HBOS: null
	},
	linked_docs: [
		{
			type: 'F01002',
			ref: resolve_ref_to_main_doc_definition()
		}
	]
};

export const F01002 = {
	meta: {
		NUM: '1',
		TYPE: '2'
	},
	head: {
		fixed: {
			C_DOC: 'F01',
			C_DOC_SUB: '002',
			C_DOC_VER: '10'
		},
		dynamic: {
			// Для первого поданного (отчётного) документа значение данного элемента равняется 0. Для каждого последующего
			// нового отчётного (уточняющего) документа этого же типа для данного отчётного периода значение увеличивается
			// на единицу
			//
			//type="xs:nonNegativeInteger"
			C_DOC_TYPE: 0,

			// Номер документа в периоде. Дополняется слева нулями до 7 знаков.
			//
			//type="xs:nonNegativeInteger"
			C_DOC_CNT: 1,

			// 1-отчётный документ, 2-новый отчётный документ ,3-уточняющий документ
			//
			//type="DGSTAN"
			C_DOC_STAN: 1,

			TIN: null,

			// Код области
			//
			//type="DGsti"
			C_REG: null,

			// Код района
			//
			//type="DGsti"
			C_RAJ: null,

			// Код инспекции, в которую подаётся оригинал документа
			//
			//type="DGc_dpi"
			C_STI_ORIG: null,

			// Код отчётного периода (1-месяц, 2-квартал, 3-полугодие, 4-девять мес., 5-год).
			//
			//type="DGPType"
			PERIOD_TYPE: null,

			// Отчётным месяцем считается последний месяц в отчётном периоде (для месяцев - порядковый номер месяца, для
			// квартала - 3,6,9,12 месяц, полугодия - 6 и 12, для года - 12)
			//
			//type="DGMonth"
			PERIOD_MONTH: null,

			// Отчётный год	Формат YYYY
			//
			//type="DGYear"
			PERIOD_YEAR: null,

			// Дата заполнения документ Формат ddmmyyyy
			D_FILL: null
		}
	},
	linked_docs: [
		{
			type: 'F01212',
			ref: F01212
		},
		{
			type: 'F01214',
			ref: F01214
		}
	],
	body: {
		HZ: null,
		HZN: null,
		HZU: null,

		HZY: null,

		HZYP: null,

		HNAME: null,
		HTIN: null,

		HREG: null,
		HRAJ: null,
		HCITY: null,
		HSTREET: null,
		HBUILD: null,
		HCORP: null,
		HAPT: null,
		HZIP: null,
		HTEL: null,
		HEMAIL: null,

		HSTI: null,

		H01: null,
		H02: null,
		H03: null,
		H04: null,

		H05: null,
		H06: null,
		H07: null,

		HNAMEAG: null,
		HTINAG: null,

		R010G3: null,
		R010G4: null,
		R010G5: null,
		R010G6: null,
		R010G7: null,

		R0101G3: null,
		R0101G4: null,
		R0101G5: null,
		R0101G6: null,
		R0101G7: null,

		R0102G3: null,
		R0102G4: null,
		R0102G5: null,
		R0102G6: null,
		R0102G7: null,

		R0103G3: null,
		R0103G4: null,
		R0103G5: null,
		R0103G6: null,
		R0103G7: null,

		R0104G3: null,
		R0104G4: null,
		R0104G5: null,
		R0104G6: null,
		R0104G7: null,

		R0105G3: null,
		R0105G4: null,
		R0105G5: null,
		R0105G6: null,
		R0105G7: null,

		R0106G2S: null,
		R0106G3: null,
		R0106G4: null,
		R0106G5: null,
		R0106G6: null,
		R0106G7: null,

		R0107G3: null,
		R0107G4: null,
		R0107G5: null,
		R0107G6: null,
		R0107G7: null,

		R0108G3: null,
		R0108G4: null,
		R0108G5: null,
		R0108G6: null,
		R0108G7: null,

		R0109G3: null,
		R0109G4: null,
		R0109G5: null,
		R0109G6: null,
		R0109G7: null,

		R01091G3: null,
		R01091G4: null,
		R01091G5: null,
		R01091G6: null,
		R01091G7: null,

		R011G3: null,
		R0111G3: null,
		R0112G3: null,
		R0113G3: null,

		R012G3: null,

		R013G1: null,
		R013G2: null,
		R013G3: null,
		R013G4: null,
		R013G5: null,
		R013G6: null,
		R013G7: null,
		R013G8: null,

		R014G3: null,
		R015G3: null,
		R016G3: null,
		R017G3: null,

		R018G3: null,
		R019G3: null,
		R020G3: null,
		R021G3: null,

		R0221G3: null,
		R0222G3: null,

		R0231G3: null,

		R024G3: null,
		R024G4: null,
		R025G3: null,
		R025G4: null,

		R0261G3: null,
		R0261G4: null,
		R0262G3: null,
		R0262G4: null,

		R027G3: null,
		R027G4: null,
		R028G3: null,
		R028G4: null,

		HBANKACC: null,
		HBANKNAME: null,
		HMFO: null,

		T1RXXXXG2: [],
		T1RXXXXG3S: [],
		T1RXXXXG4: [],
		T1RXXXXG5: [],
		T1RXXXXG6: [],
		T1RXXXXG7: [],

		HJ1: null,
		T2RXXXXG2S: [],

		HJ2: null,
		T3RXXXXG2S: [],

		HD1: null,
		HD2: null,
		HBOS: null,
		HFILL: null
	}
};

main_doc_definition = Object.assign(main_doc_definition, F01002);

export const doc_definition = F01002;
