import React, { useState, useEffect, useCallback } from 'react';
import QuestionBlock from './components/QuestionBlock';

import NextButton from '../../components/NextButton';

import { load, save } from '../../../../utils/localStorage';

import './styles.scss';

const initialAnswers = {
	ID1: null, //?
	ID1B: null, //?
	ID1BB: null, //? R0106G3
	ID1BA: '', //? R0105G3
	ID1BBA: '', //? R0106G3
	ID1BBB: '', //? R0106G3
	ID1BBC: '', //? R0106G3
	ID1BBD: '', //? R0106G3
	ID1BBE: '', //? R021G3
	ID1AA: '', //? R0105G3
	ID2: null, //?
	ID2AA: '', //? R0105G3
	ID3A: false, //?
	ID3AA: '', //? R0105G3
	ID3B: false,
	ID3BA: '', //? R0105G3
	ID3C: false, //?
	ID3CA: '', //? R0105G3
	ID3D: false, //?
	ID3DA: '', //? R0105G3
	ID3E: false, //?
	ID3EA: '', //? R0105G3
	ID3F: false, //?
	ID3FA: '', //? R0105G3
	ID4: null, //?
	ID4AA: '' //? R0105G3
};

const initialData = {
	R0105G3: {
		q: {
			ID1BA: null,
			ID1AA: null,
			ID2AA: null,
			ID3AA: null,
			ID3BA: null,
			ID3CA: null,
			ID3DA: null,
			ID3EA: null,
			ID3FA: null,
			ID4AA: null
		},
		a: {
			ID1BA: 0, //* 18 1.5 самостоятельно
			ID1AA: 0, //* 18 1.5 самостоятельно
			ID2AA: 0, //* 0 0 самостоятельно
			ID3AA: 0, //* 5 1.5 самостоятельно
			// ID3AA: 0, //* 0 0 самостоятельно
			ID3BA: 0, //* 5 1.5 самостоятельно
			// ID3BA: 0, //* 0 0 самостоятельно
			ID3CA: 0, //* 5 1.5 самостоятельно
			ID3DA: 0, //* 5 1.5
			ID3EA: 0, //* 5 1.5
			// ID3EA: 0, //* 0 0 B3A B3B
			ID3FA: 0, //* 0 0  самостоятельно
			ID4AA: 0 //* 18 1.5
		}
	},
	R0106G3: {
		q: { ID1BB: null },
		a: {
			ID1BB: [
				//* 18 1.5
				{
					country_name: '',
					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0,
					tax: 0
				}
			]
		}
	},
	R021G3: {
		q: { ID1BBE: null },
		a: { ID1BBE: 0 }
	}
};

const ID = props => {
	const [data, setData] = useState(initialData);
	const [answers, setAnswers] = useState(initialAnswers);
	// const [answers, setAnswers] = useState(mock);

	useEffect(() => {
		if (load('id')) {
			let { data, answers } = load('id');
			if (data && answers) {
				setAnswers(answers);
				setData(data);
				props.getIdData(data);
			}
		}
	}, []);

	const handleChangeSelect = ({ target, currentTarget: { dataset } }) => {
		let newData = { ...data };
		let newAnswers = { ...answers };
		newAnswers[dataset.id] = target.value;
		newData.R0106G3.a.ID1BB[0][dataset.name] = target.value;
		setData(newData);
		setAnswers({ ...answers, ...newAnswers });
		save('id', { data: newData, answers: newAnswers });
	};

	const handleChange = ({ currentTarget }) => {
		const { value, name, id, type, labels } = currentTarget;
		let newData = { ...data };
		let newAnswers = { ...answers };

		// const parent = labels[0] && labels[0].attributes['data-parent'] && labels[0].attributes['data-parent'].value;

		switch (type) {
			case 'text':
				if (['ID1BBC', 'ID1BBD'].includes(id)) {
					if (id === 'ID1BBC') {
						newData[name]['a']['ID1BB'][0]['currency_date'] = value;
					}
					// if (id === 'ID1BBE') {
					// 	newData[name]['a']['ID1BB'][0]['revenue'] = value;
					// }
					if (id === 'ID1BBD') {
						newData[name]['a']['ID1BB'][0]['revenue'] = value;
					}
				} else {
					if (newData[name]) {
						newData[name]['a'][id] = value;
					}
				}

				// newAnswers[`${id}_input`] = value;
				newAnswers[`${id}`] = value;

				break;

			case 'checkbox':
				newAnswers[value] = !newAnswers[value];
				// Очистка подвопроса при изменение ответа Answers
				if (!newAnswers[value]) {
					newAnswers[`${value}A`] = '';
					newData[id]['a'][`${value}A`] = 0;
				}

				break;

			case 'radio': {
				const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
				// очистка данных при изменение ответа
				const children =
					labels[0].attributes['data-sibchildren'] && labels[0].attributes['data-sibchildren'].value;

				if (children) {
					let childs = children.split(',');
					childs.forEach(item => {
						newAnswers[item] = '';
						if (item === 'ID1BA') {
							// Проверка для удаления из правильного ключа так как в ответе ключ не соответствует соседнему элементу
							newData['R0105G3']['a'][item] = 0;
						} else if (item === 'ID1BBC' || item === 'ID1BBD') {
							newData['R0106G3']['a'].ID1BB[0].country_name = '';
							newData['R0106G3']['a'].ID1BB[0].currency_date = '';
							newData['R0106G3']['a'].ID1BB[0].currency_name = '';
							newData['R0106G3']['a'].ID1BB[0].revenue = 0;
							newData.R021G3.a.ID1BBE = 0;
						} else if (item === 'ID1BBE') {
							newData['R021G3']['q'][item] = 0;
						} else {
							if (newData[name]) {
								newData[name]['a'][item] = 0; //! Check why this value take data
							}
						}
					});
				}

				newAnswers[groupName] = value;

				if (id !== 'ID1BB') {
					newData[name]['q'][id] = true;
					// newData[name]['a'][id] = true;
				}

				break;
			}
			default:
				break;
		}
		save('id', { data: newData, answers: newAnswers });
		props.getIdData(newData);
		setData(newData);
		setAnswers({ ...newAnswers });
	};

	const handleChangeDate = useCallback(
		key => e => {
			let newData = { ...data };
			let newAnswers = { ...answers };

			const date = new Date(e._d);

			newAnswers[key] = date.getTime(); //! change answer
			newData.R0106G3.a[key.slice(0, -1)][0].currency_date = date.getTime(); //! change data

			setData(newData);
			setAnswers(newAnswers);
			props.getIdData(newData);
			save('id', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const handleClickNextButton = () => props.getIdData(data);

	const { subsection } = props;
	return (
		<div className="ID-wrap">
			<div className="ID-content-wrap">
				<QuestionBlock
					subsection={subsection}
					state={{ data, answers }}
					change={handleChange}
					changeSelect={handleChangeSelect}
					handleChangeDate={handleChangeDate}
				/>

				{/* <Button variant="contained"> Додати</Button> */}
			</div>
			<NextButton link={props.nextSubSection} click={handleClickNextButton} />
		</div>
	);
};

export default ID;
