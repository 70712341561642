import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const R0113G3 = ['Int1CA'];
const R0109G3 = ['Int1BA', 'Int1DA', 'Int1EA'];

const initialAnswers = {
	Int1A: false,
	Int1AA: '',
	Int1AB: '',
	Int1AC: '',
	Int1AD: '',
	Int1AE: '',

	Int1B: false,
	Int1BA: '', // R0109G3

	Int1C: false,
	Int1CA: '', // R0113G3

	Int1D: false,
	Int1DA: '', // R0109G3

	Int1E: false,
	Int1EA: '' // R0109G3
};
const initialData = {
	R0106G3: {
		a: {
			Int1A: [
				{
					country_name: '',
					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0,
					tax: 0
				}
			]
		},
		q: { Int1A: null }
	},
	R0109G3: {
		a: { Int1BA: 0, Int1DA: 0, Int1EA: 0 },
		q: { Int1BA: null, Int1DA: null, Int1EA: null }
	},
	R0113G3: {
		a: { Int1CA: 0 },
		q: { Int1CA: null }
	},
	R021G3: {
		a: { Int1AE: 0 },
		q: { Int1AE: null }
	}
};

const Int = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, name, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (R0109G3.includes(id)) {
						newData.R0109G3.a[id] = value;
					}
					if (R0113G3.includes(id)) {
						newData.R0113G3.a[id] = value;
					}
					if (name === 'tax') {
						newData.R021G3.a[id] = value;
					}
					if (name === 'revenue') {
						newData.R0106G3.a.Int1A[0][name] = value;
					}
					if (name === 'currency_date') {
						newData.R0106G3.a.Int1A[0][name] = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;
					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];

					if (!newAnswers[value]) {
						newAnswers[`${value}A`] = '';
						newData[id]['a'][`${value}A`] = 0;
					}

					break;

				default:
					break;
			}
			save('int', { answers: newAnswers, data: newData });
			props.getIntData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			let newData = { ...data };
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;
			newData.R0106G3.a.Int1A[0][dataset.name] = target.value;
			setData(newData);
			setAnswers({ ...answers, ...newAnswers });
			props.getIntData(newData);
			save('int', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const handleChangeDate = useCallback(
		key => e => {
			let newData = { ...data };
			let newAnswers = { ...answers };

			const date = new Date(e._d);

			newAnswers[key] = date.getTime(); //! change answer
			newData.R0106G3.a[key.slice(0, -1)][0].currency_date = date.getTime(); //! change data

			setData(newData);
			setAnswers(newAnswers);
			props.getIntData(newData);
			save('int', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			return (
				<Question
					key={i}
					item={item}
					state={{ data, answers }}
					change={handleChange}
					changeSelect={handleChangeSelect}
					handleChangeDate={handleChangeDate}
				/>
			);
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('int')) {
			const { data, answers } = load('int');
			setAnswers(answers);
			setData(data);
			props.getIntData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getIntData(data);
	return (
		<div className="Int-wrap">
			{renderQuestion}
			<div className="btn-Int-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default Int;
