import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const initialAnswers = {
	R1A: '',
	R1BA: '',
	R1BB: '',
	R1BC: '',
	R1BD: '',
	R1BE: ''
};
const initialData = {
	R0106G3: {
		a: {
			R1B: [
				{
					country_name: '',
					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0,
					tax: 0
				}
			]
		},
		q: { R1B: null }
	},
	R0109G3: {
		a: { R1A: 0 },
		q: { R1A: null }
	},
	R021G3: {
		a: { R1BE: 0 },
		q: { R1BE: null }
	}
};

const Royalty = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, name, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if ('R1A' === id) {
						newData.R0109G3.a[id] = value;
					}
					if (name === 'currency_date') {
						newData.R0106G3.a.R1B[0][name] = value;
					}
					if (name === 'tax') {
						newData.R021G3.a[id] = value;
					}
					if (name === 'revenue') {
						newData.R0106G3.a.R1B[0][name] = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;

					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];

					break;

				default:
					break;
			}
			save('r', { answers: newAnswers, data: newData });
			props.getRData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);

	const handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			let newData = { ...data };
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;
			newData.R0106G3.a.R1B[0][dataset.name] = target.value;
			setData(newData);
			setAnswers({ ...answers, ...newAnswers });
			props.getRData(newData);
			save('r', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const handleChangeDate = useCallback(
		key => e => {
			let newData = { ...data };
			let newAnswers = { ...answers };

			const date = new Date(e._d);

			newAnswers[key] = date.getTime(); //! change answer
			newData.R0106G3.a[key.slice(0, -1)][0].currency_date = date.getTime(); //! change data

			setData(newData);
			setAnswers(newAnswers);
			props.getRData(newData);
			save('r', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			return (
				<Question
					key={i}
					item={item}
					state={{ data, answers }}
					change={handleChange}
					changeSelect={handleChangeSelect}
					handleChangeDate={handleChangeDate}
				/>
			);
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('r')) {
			const { data, answers } = load('r');
			setAnswers(answers);
			setData(data);
			props.getRData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getRData(data);

	return (
		<div className="R-wrap">
			{renderQuestion}
			<div className="btn-next-R-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default Royalty;
