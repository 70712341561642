import findIndex from 'lodash/findIndex';
import flatten from 'lodash/flatten';
export const checkAnswerToShowHideQuestion = (checkQuestionId, questions) => {
	let showQuestion = false;
	let indexOfQuestion = findIndex(flatten(questions), item => item.id === checkQuestionId);
	let answerGroup = flatten(questions)[indexOfQuestion].group;
	return answer => {
		let answers = Object.keys(answer).filter(key => answer[key]);

		if (answers.includes(answerGroup)) {
			showQuestion = answers.includes(answerGroup);
		}

		return { indexOfQuestion, showQuestion };
	};
};
