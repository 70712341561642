import { useState } from 'react';

const initialState = {
	T1RXXXXG2: '',
	T1RXXXXG3S: '',
	T1RXXXXG4: '',
	T1RXXXXG5: '',
	T1RXXXXG6: '',
	T1RXXXXG7: ''
};

export const useForms = (state = initialState, cb = () => {}) => {
	const [answers, setAnswers] = useState(state);

	const _handleChange = ({ currentTarget }) => {
		const { value, id, type, labels } = currentTarget;
		let newAnswers = { ...answers };
		switch (type) {
			case 'text':
				newAnswers[`${id}`] = value;
				break;

			case 'radio': {
				const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
				newAnswers[groupName] = value;

				break;
			}
			case 'checkbox':
				newAnswers[value] = !newAnswers[value];
				break;

			default:
				break;
		}
		setAnswers(newAnswers);
		cb(newAnswers);
	};

	const _handleChangeSelect = ({ target, currentTarget: { dataset } }) => {
		let newAnswers = { ...answers };
		newAnswers[dataset.id] = target.value;
		setAnswers({ ...answers, ...newAnswers });
		cb(newAnswers);
	};

	return {
		state,
		_handleChange,
		_handleChangeSelect
	};
};

export default useForms;
