import { R010G6, R010G7, R017G3 } from './index';

export const R018G3 = function(formValues) {
	return R010G6(formValues);
};

export const R019G3 = function(formValues) {
	return R017G3(formValues);
};

export const R020G3 = function(formValues) {
	return parseFloat(formValues['R020G3']);
};

// Сума податків, сплачених за кордоном, на яку зменшується сума річного податкового зобовязання (але не більше значення рядка 18)
export const R021G3 = function(formValues) {
	let value = parseFloat(formValues['R021G3']);
	let R18 = R018G3(formValues);

	return value < R18 ? value : R18;
};

// додатне значення - (рядок 18 - рядок 19 - рядок 20 - рядок 21)
export const R0221G3 = function(formValues) {
	let value = R018G3(formValues) - R019G3(formValues) - R020G3(formValues) - R021G3(formValues);

	return value > 0 ? value : 0;
};

// від'ємне значення - (рядок 18 - рядок 19 - рядок 20 - рядок 21)
export const R0222G3 = function(formValues) {
	let value = R018G3(formValues) - R019G3(formValues) - R020G3(formValues) - R021G3(formValues);

	return value < 0 ? value * -1 : 0;
};

// (графа 7 рядка 10)
export const R0231G3 = function(formValues) {
	return R010G7(formValues);
};
