import React, { Component } from 'react';

export default class htmlData1212 extends Component {
	constructor(props) {
		super(props);
		this.userData = this.props.userData;
	}

	render() {
		return (
			<div className="chat-container doc-preview">
				<table width="100%">
					<tbody>
						<tr>
							<td className="main_wrap">
								<div className="item">F0121210</div>
							</td>
						</tr>
					</tbody>
				</table>

				<table width="100%" className="main_wrap text_center">
					<tbody>
						<tr>
							<td width="100%" style={{ padding: 0 }}>
								<table width="100%" className="main_wrap text_center table_border_none">
									<tbody>
										<tr>
											<td width="37%" className="border_1">
												&nbsp;
												<br />
												Відмітка про одержання
												<br />
												(штамп контролюючого органу, дата, вхідний номер)
												<br />
												&nbsp;
											</td>
											<td width="28%" />
											<td width="35%" rowSpan="2" className={'text_left'}>
												Додаток <b>Ф1</b>
												<br />
												до податкової декларації про майновий
												<br />
												стан і доходи
											</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td>&nbsp;</td>
										</tr>
										<tr>
											<td colSpan="3">
												<h3>
													РОЗРАХУНОК
													<br />
													податкових зобов'язань з податку на доходи фізичних осіб та
													військового збору
													<br />з доходів, отриманих від операцій з інвестиційними активами
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<table width="100%" className="border_1 main_wrap text_center ">
					<tbody>
						<tr>
							<td width="100%" style={{ padding: '5px' }}>
								<table
									width="100%"
									cellSpacing="3"
									className="main_wrap text_center table_border_none"
									style={{ borderCollapse: 'separate' }}
								>
									<tbody>
										<tr>
											<td width="70%" className="text_left">
												Реєстраційний номер облікової картки платника податків або серія (за
												наявності) та номер
											</td>
											<td width="30%" className="border">
												<div className="item">{this.cell('HTIN')}</div>
											</td>
										</tr>
										<tr>
											<td colSpan="2" className="text_left">
												паспорта (для фізичних осіб, які через свої релігійні переконання
												відмовляються від прийняття реєстраційного номера облікової картки
												платника податків та офіційно повідомили про це відповідний контролюючий
												орган і мають відмітку у паспорті)
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<table width="100%" className="main_wrap text_center border_1">
					<tbody>
						<tr>
							<td width="100%">
								<table
									width="100%"
									cellSpacing="2"
									className="main_wrap text_center table_border_none"
									style={{ borderCollapse: 'separate' }}
								>
									<tbody>
										<tr>
											<td width="44%" colSpan="6" className="text_left">
												Тип декларації:
											</td>
											<td width="22%" colSpan="2" className="text_left">
												Звітний (податковий) період:
											</td>
											<td width="34%" colSpan="2" className="text_left">
												Звітний (податковий) період, що уточнюється:
											</td>
										</tr>
										<tr>
											<td width="4%" className="border">
												<div className="item input_item ">{this.cell('HZ')}</div>
											</td>
											<td width="8%" className="text_left">
												Звітна
											</td>
											<td width="4%" className="border">
												<div className="item input_item ">{this.cell('HZN')}</div>
											</td>
											<td width="12%" className="text_left">
												Звітна нова
											</td>
											<td width="4%" className="border">
												<div className="item input_item ">{this.cell('HZU')}</div>
											</td>
											<td width="12%" className="text_left">
												Уточнююча
											</td>

											<td width="10%" className="border">
												<div className="item">{this.cell('HZY')}</div>
											</td>
											<td width="12%" className="text_left">
												рік
											</td>

											<td width="10%" className="border">
												<div className="item">{this.cell('HZYP')}</div>
											</td>
											<td width="24%" className="text_left">
												рік
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<table width="100%" className="main_wrap text_center border_1">
					<tbody>
						<tr>
							<td width="100%" colSpan="6">
								<b>І. РОЗРАХУНОК ІНВЕСТИЦІЙНОГО ПРИБУТКУ</b>
							</td>
						</tr>
						<tr>
							<td width="5%">
								№<br />
								з/п
							</td>
							<td width="7%">
								Вид
								<br />
								інвести-
								<br />
								ційних
								<br />
								активів*
							</td>
							<td width="47%">Найменування та характеристика</td>
							<td width="13%">Сума доходу, отриманого від продажу інвестиційного активу (грн, коп.)</td>
							<td width="11%">Сума витрат на придбання інвестиційного активу (грн, коп.)</td>
							<td width="17%">
								Фінансовий результат операцій з інвестиційними активами (інвестиційний прибуток (+) або
								інвестиційний збиток (‐)) (грн, коп.)
							</td>
						</tr>
						<tr>
							<td>1</td>
							<td>2</td>
							<td>3</td>
							<td>4</td>
							<td>5</td>
							<td>6</td>
						</tr>
						<tr>
							<td rowSpan="2">1</td>
							<td colSpan="5" style={{ padding: 0 }}>
								<table width="100%" className="main_wrap text_center table_1_1 border_1">
									<tbody>
										{Array.isArray(this.getValue('T1RXXXXG2')) &&
											this.getValue('T1RXXXXG2').map((val, idx) => {
												return (
													<tr key={idx}>
														<td width="7.2%" className={'text_center'}>
															<div className="item ">{this.pureCell(val)}</div>
														</td>
														<td width="49.6%">
															<div className="item">
																{this.pureCell(this.getValue('T1RXXXXG3S')[idx])}
															</div>
														</td>
														<td width="13.7%" className={'text_center'}>
															<div className="item">
																{this.pureCell(this.getValue('T1RXXXXG4')[idx])}
															</div>
														</td>
														<td width="11.7%" className={'text_center'}>
															<div className="item">
																{this.pureCell(this.getValue('T1RXXXXG5')[idx])}
															</div>
														</td>
														<td width="17.8%" className={'text_center'}>
															<div className="item">
																{this.pureCell(this.getValue('T1RXXXXG6')[idx])}
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</td>
						</tr>
						<tr>
							<td colSpan="2" className="text_left">
								<b>УСЬОГО</b>
							</td>
							<td>
								<div className="item">{this.cell('R001G4')}</div>
							</td>
							<td>
								<div className="item">{this.cell('R001G5')}</div>
							</td>
							<td>
								<div className="item">{this.cell('R001G6')}</div>
							</td>
						</tr>
						<tr>
							<td>2</td>
							<td colSpan="4" className="text_left">
								Сума від'ємного значення загального фінансового результату операцій з інвестиційними
								активами попереднього звітного періоду
							</td>
							<td>
								<div className="item">{this.cell('R002G6')}</div>
							</td>
						</tr>
						<tr>
							<td>3</td>
							<td colSpan="4" className="text_left">
								<b>Загальний фінансовий результат операцій з інвестиційними активами</b>
								<br />
								<i>(графа 6 "УСЬОГО" рядка 1 ‐ рядок 2)</i>
							</td>
							<td>
								<div className="item">{this.cell('R003G6')}</div>
							</td>
						</tr>
						<tr>
							<td>3.1</td>
							<td colSpan="4" className="text_left">
								Додатне значення рядка 3 (інвестиційний прибуток)
							</td>
							<td>
								<div className="item">{this.cell('R031G6')}</div>
							</td>
						</tr>
						<tr>
							<td>3.2</td>
							<td colSpan="4" className="text_left">
								Від'ємне значення рядка 3 (інвестиційний збиток), значення вказується без знака "‐"
							</td>
							<td>
								<div className="item">{this.cell('R032G6')}</div>
							</td>
						</tr>
					</tbody>
				</table>
				<table width="100%" className="main_wrap text_center border_1">
					<tbody>
						<tr>
							<td width="5%">Код рядка</td>
							<td width="78%">
								<b>
									ІІ. ПОДАТКОВІ ЗОБОВ'ЯЗАННЯ З ПОДАТКУ НА ДОХОДИ ФІЗИЧНИХ ОСІБ /<br />
									ВІЙСЬКОВОГО ЗБОРУ ВІД ОПЕРАЦІЙ З ІНВЕСТИЦІЙНИМИ АКТИВАМИ
								</b>
							</td>
							<td width="17%">
								<b>
									Сума
									<br />
									(грн, коп.)
								</b>
							</td>
						</tr>
						<tr>
							<td>4</td>
							<td className="text_left">
								<b>Сума податку на доходи фізичних осіб, у тому числі:</b>
								<br />
								<i>(рядок 3.1 × на ставку податку 18%)</i>
							</td>
							<td>
								<div className="item">{this.cell('R004G6')}</div>
							</td>
						</tr>
						<tr>
							<td>4.1</td>
							<td className="text_left">утриманого (сплаченого) податковим агентом</td>
							<td>
								<div className="item">{this.cell('R041G6')}</div>
							</td>
						</tr>
						<tr>
							<td>4.2</td>
							<td className="text_left">
								яка підлягає сплаті платником податку самостійно до бюджету
								<br />
								<i>(додатне значення (рядок 4 ‐ рядок 4.1))</i>
							</td>
							<td>
								<div className="item">{this.cell('R042G6')}</div>
							</td>
						</tr>
						<tr>
							<td>5</td>
							<td className="text_left">
								<b>Сума військового збору, у тому числі:</b>
								<br />
								<i>(рядок 3.1 × на ставку збору 1,5 %)</i>
							</td>
							<td>
								<div className="item">{this.cell('R005G6')}</div>
							</td>
						</tr>
						<tr>
							<td>5.1</td>
							<td className="text_left">утриманого (сплаченого) податковим агентом</td>
							<td>
								<div className="item">{this.cell('R051G6')}</div>
							</td>
						</tr>
						<tr>
							<td>5.2</td>
							<td className="text_left">
								яка підлягає сплаті платником податків самостійно до бюджету за результатами звітного
								року
								<br />
								<i>(додатне значення (рядок 5 ‐ рядок 5.1))</i>
							</td>
							<td>
								<div className="item">{this.cell('R052G6')}</div>
							</td>
						</tr>
					</tbody>
				</table>

				<br />
				<br />
				<table width="100%" className="main_wrap table_border_none">
					<tbody>
						<tr>
							<td width="35%">
								<b>*Перелік операцій з інвестиційними активами</b>
							</td>
							<td width="65%">1 – корпоративні права (інші ніж цінні папери)</td>
						</tr>
						<tr>
							<td />
							<td>2 – цінні папери чи деривативи, що перебувають в обігу на фондовій біржі;</td>
						</tr>
						<tr>
							<td />
							<td>3 – цінні папери чи деривативи, що перебувають в обігу не на фондовій біржі.</td>
						</tr>
					</tbody>
				</table>

				<br />
				<br />
				<table width="100%" className="main_wrap text_center table_border_none">
					<tbody>
						<tr>
							<td width="27%" className="text_left">
								<b>Фізична особа – платник податку</b>
							</td>
							<td width="73%" colSpan="4" />
						</tr>
						<tr>
							<td width="27%" className="text_left">
								<b>або уповноважена особа</b>
							</td>
							<td width="2%" />
							<td width="27%" className="border_bottom" />
							<td width="2%" />
							<td width="42%" className="border_bottom">
								<div className="item">{this.cell('HBOS')}</div>
							</td>
						</tr>
						<tr>
							<td />
							<td />
							<td>(підпис</td>
							<td />
							<td>(ініціали та прізвище</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}

	cell = (key, label = false, className = '') => {
		let val = this.getValue(key);

		return this.buildCell(val, label, className);
	};

	pureCell = (value, label = false) => {
		let val = this.prepareValue(value);
		return this.buildCell(val, label);
	};

	buildCell = (val, label, extClassName = '') => {
		let className = 'inner-cell ' + extClassName;
		if (val === '__empty__') {
			val = <span>&nbsp;&nbsp;</span>;
			className = 'inner-cell-empty';
		}

		return (
			<div className="grid-x ">
				<div className={'cell ' + className}>{val}</div>
				{label && <div className={'cell f-10 inner-cell-label'}>{label}</div>}
			</div>
		);
	};

	prepareValue(val) {
		if (typeof val === 'boolean') {
			val = val === true ? 'X' : '__empty__';
		} else if (val === null || (typeof val === 'string' && val.trim() === '')) {
			val = '__empty__';
		}
		return val;
	}

	getValue = key => {
		let val = '__empty__';

		if (this.getUserData().hasOwnProperty(key)) {
			val = this.getUserData()[key];
			val = this.prepareValue(val);
		}

		return val;
	};

	getUserData = () => {
		return this.userData;
	};
}
