import React, { useEffect, useRef } from 'react';

const Step = props => {
	const touch = useRef(false);

	useEffect(() => {
		if (props.index === props.activeIndex) {
			touch.current = true;
		}
	}, [props.activeIndex]);

	let inData;
	let disabled;

	if (props.data) {
		const regex = new RegExp(`${props.step[0]['id']}`, 'i');
		const arrayCheckboxTruthAnswers = Object.keys(props.data).reduce((acc, val) => {
			if (props.data[val] === true) {
				acc.push(val);
			}
			return acc;
		}, []);

		let ids = [];

		props.step.map(obj => {
			if (obj.id && obj.hasOwnProperty('id')) {
				ids.push(obj.id);
			}
			if (obj.answers && obj.hasOwnProperty('answers')) {
				obj.answers.map(answObj => {
					if (answObj.id && answObj.hasOwnProperty('id')) {
						ids.push(answObj.id);
					}
					return answObj;
				});
			}
			return obj;
		});

		inData =
			Object.values(props.data).some(item => regex.test(item)) ||
			arrayCheckboxTruthAnswers.some(item => regex.test(item)) ||
			ids.some(item => props.data[item]);
		disabled = props.step[0] && props.step[0].disabled;
	}

	return (
		<>
			<div
				className="stepper-icon"
				onClick={() => {
					if (!props.step[0].disabled) {
						props.getIndex(props.index);
					}
				}}
			>
				<img
					className="stepper-icon__passive"
					src={
						props.index === props.activeIndex
							? '/img/oval-blue-active.svg'
							: (touch.current && (props.index !== props.activeIndex && inData)) || inData
							? '/img/done.svg'
							: props.error || (touch.current && !inData && !disabled)
							? '/img/alert.svg'
							: disabled || (disabled && touch.current)
							? '/img/oval.svg'
							: '/img/oval-blue-passive.svg'
					}
					alt="passive"
				/>
			</div>
		</>
	);
};

export default Step;
