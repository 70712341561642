const falseIfEmpty = function(value) {
	return value ? value : false;
};

export const HZYP = function(formValues) {
	return formValues['HZU'] ? formValues['HZYP'] : false;
};

export const HZKVP = function(formValues) {
	return formValues['HZU'] ? formValues['HZKVP'] : false;
};

export const HD1 = function(formValues) {
	return formValues['HD1'] !== undefined ? formValues['HD1'] : true;
};

export const HD2 = function(formValues) {
	return formValues['HD2'] !== undefined ? formValues['HD2'] : true;
};

export const HNAMEAG = function(formValues) {
	return formValues['HD4'] ? formValues['HNAMEAG'] : formValues['HNAME'];
};

export const HTINAG = function(formValues) {
	return formValues['HD4'] ? formValues['HTINAG'] : formValues['HTIN'];
};

export const HCORP = function(formValues) {
	return falseIfEmpty(formValues['HCORP']);
};

export const HREG = function(formValues) {
	return falseIfEmpty(formValues['HREG']);
};

export const HRAJ = function(formValues) {
	return falseIfEmpty(formValues['HRAJ']);
};

export const HBUILD = function(formValues) {
	return falseIfEmpty(formValues['HBUILD']);
};

export const HAPT = function(formValues) {
	return falseIfEmpty(formValues['HAPT']);
};

export const HZIP = function(formValues) {
	return falseIfEmpty(formValues['HZIP']);
};

export const HTEL = function(formValues) {
	return falseIfEmpty(formValues['HTEL']);
};

export const HEMAIL = function(formValues) {
	return falseIfEmpty(formValues['HEMAIL']);
};

export const HBANKACC = function(formValues) {
	return falseIfEmpty(formValues['HBANKACC']);
};

export const HBANKNAME = function(formValues) {
	return falseIfEmpty(formValues['HBANKNAME']);
};

export const HMFO = function(formValues) {
	return falseIfEmpty(formValues['HMFO']);
};
