import isArray from 'lodash/isArray';

import { form_data_to_linked_doc_converter } from '../../misc/misc';
import { precisionRound } from '../../../types/index';

export const R0104G3 = function(formValues) {
	return form_data_to_linked_doc_converter('F01002', formValues, 'F01212')['R031G6'];
};

// Сума податку утриманого (сплаченого) податковим агентом
export const R0104G4 = function(formValues) {
	//return parseFloat(formValues['R0104G4']);
	return form_data_to_linked_doc_converter('F01002', formValues, 'F01212')['R041G6'];
};

// Сума збору утриманого (сплаченого) податковим агентом
export const R0104G5 = function(formValues) {
	//return parseFloat(formValues['R0104G5']);
	return form_data_to_linked_doc_converter('F01002', formValues, 'F01212')['R051G6'];
};

// Сума податку що підлягає сплаті самостійно
export const R0104G6 = function(formValues) {
	return form_data_to_linked_doc_converter('F01002', formValues, 'F01212')['R042G6'];
};

// Сума збору що підлягає сплаті самостійно
export const R0104G7 = function(formValues) {
	return form_data_to_linked_doc_converter('F01002', formValues, 'F01212')['R052G6'];
};

// Сума податку що підлягає сплаті самостійно
export const R0104G6_partials = function(formValues) {
	return Object.keys(formValues['R0104G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.map(key => {
			let revenues = [];
			let expenses = [];
			let keyValue = formValues['R0104G3']['a'][key];

			if (isArray(keyValue)) {
				revenues = keyValue.map(fieldsSet => parseFloat(fieldsSet['T1RXXXXG4']));
				expenses = keyValue.map(fieldsSet => parseFloat(fieldsSet['T1RXXXXG5']));
			}

			let incomes = revenues.map((value, index) => {
				let result = value - expenses[index];
				return result < 0 ? 0 : result;
			});

			let taxes = incomes.map(income => {
				return (income / 100) * 18;
			});

			let personalTaxes = taxes.map((tax, index) => {
				let agent_tax =
					formValues[`R0104G4_${key}`][index] != null
						? parseFloat(formValues[`R0104G4_${key}`][index].value)
						: 0;
				agent_tax = isNaN(agent_tax) ? 0 : agent_tax;

				let personal_tax = tax - agent_tax;

				return {
					value: personal_tax < 0 ? 0 : precisionRound(personal_tax, 2)
				};
			});

			return {
				[`R0104G6_${key}`]: personalTaxes
			};
		})
		.reduce((acc, obj) => {
			return { ...acc, ...obj };
		}, {});
};

// Сума податку що підлягає сплаті самостійно
export const R0104G7_partials = function(formValues) {
	return Object.keys(formValues['R0104G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.map(key => {
			let revenues = [];
			let expenses = [];
			let keyValue = formValues['R0104G3']['a'][key];

			if (isArray(keyValue)) {
				revenues = keyValue.map(fieldsSet => parseFloat(fieldsSet['T1RXXXXG4']));
				expenses = keyValue.map(fieldsSet => parseFloat(fieldsSet['T1RXXXXG5']));
			}

			let incomes = revenues.map((value, index) => {
				let result = value - expenses[index];
				return result < 0 ? 0 : result;
			});

			let fees = incomes.map(income => {
				return (income / 100) * 1.5;
			});

			let personalFees = fees.map((fee, index) => {
				let agent_fee =
					formValues[`R0104G5_${key}`][index] != null
						? parseFloat(formValues[`R0104G5_${key}`][index].value)
						: 0;
				agent_fee = isNaN(agent_fee) ? 0 : agent_fee;

				let personal_fee = fee - agent_fee;

				return {
					value: personal_fee < 0 ? 0 : precisionRound(personal_fee, 2)
				};
			});

			return {
				[`R0104G7_${key}`]: personalFees
			};
		})
		.reduce((acc, obj) => {
			return { ...acc, ...obj };
		}, {});
};
