import { R0109G3, R0113G3 } from '../_xmlConstId';
import { WP1A, WP1B } from '../_declaretion_answer_id';
import { B10AC } from '../../_questions_OLD';
import { INPUT, RADIO } from '../../elementTypes';

export const WP = [
	{
		id: 'WP1',
		title:
			'Оберіть форму, в якій Ви отримали виграші (призи) у лотерею чи в інші розіграші, у букмекерському парі, у парі тоталізатора, призи та виграші, одержані за перемогу та/або участь в спортивних змаганнях, у тому числі у більярдному спорті',
		xmlId: R0109G3,
		answers: [
			{
				id: WP1A,
				title: 'Грошова форма',
				xmlId: R0109G3,
				element: RADIO,
				name: 'WP1',
				quizOpen: B10AC
			},
			{
				id: WP1B,
				title: 'Негрошова форма',
				xmlId: R0109G3,
				element: RADIO,
				name: 'WP1',
				quizOpen: B10AC
			}
		]
	},
	{
		parent: WP1A,
		id: 'WP1AA',
		// title:
		// 	'Оберіть форму, в якій Ви отримали виграші (призи) у лотерею чи в інші розіграші, у букмекерському парі, у парі тоталізатора, призи та виграші, одержані за перемогу та/або участь в спортивних змаганнях, у тому числі у більярдному спорті',
		element: INPUT,
		name: 'WP1AA',
		placeholder: 'Вкажіть суму виграшу або призу',
		xmlId: R0109G3
	},
	{
		parent: WP1B,
		id: 'WP1BA',
		// title:
		// 	'Оберіть форму, в якій Ви отримали виграші (призи) у лотерею чи в інші розіграші, у букмекерському парі, у парі тоталізатора, призи та виграші, одержані за перемогу та/або участь в спортивних змаганнях, у тому числі у більярдному спорті',
		element: INPUT,
		name: 'WP1BA',
		placeholder: 'Вкажіть суму виграшу або призу',
		xmlId: [R0109G3, R0113G3]
	}
];
