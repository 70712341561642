import { OTI } from '../../../data/declaration/sub-sections/OTI';
import { GET_OTI_DATA } from '../../actions/declaration_action';

const initialState = {
	questions: { ...OTI },
	subId: null,
	data: {}
};

const oti = (state = initialState, action) => {
	switch (action.type) {
		case GET_OTI_DATA:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default oti;
