import React, { Fragment } from 'react';

import NewStepperCheckBox from '../NewStepperCheckBox';
import NewDatePickerField from '../NewDatePickerField';

const NewStepperCheckBoxDateField = ({
	field: { name, value, onChange, onBlur },
	id,
	question,
	values,
	errors,
	setFieldValue,
	setFieldError,
	stepperCurrent,
	...props
}) => {
	return (
		<Fragment>
			<NewStepperCheckBox
				name={name}
				id={id}
				value={name}
				checked={value}
				onChange={onChange}
				onBlur={onBlur}
				{...props}
			/>
			{values[question.parent].includes(question.name) && (
				<NewDatePickerField
					name={question.name}
					formikError={errors[question.name]}
					setFieldValue={setFieldValue}
					setFieldError={setFieldError}
					value={values[question.name] || null}
					stepperCurrent={stepperCurrent}
				/>
			)}
		</Fragment>
	);
};

export default NewStepperCheckBoxDateField;
