import React from 'react';
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

const DatePicker = props => {
	return (
		<MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'} moment={moment}>
			<InlineDatePicker
				// style={{ width: '160px' }}
				keyboard
				value={props.answer[props.item.id]}
				onChange={props.handleChangeDate}
				format="DD.MM.YYYY"
				mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
			/>
		</MuiPickersUtilsProvider>
	);
};

export default DatePicker;
