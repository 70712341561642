import { connect } from 'react-redux';

import Common from '../subsections/Common/Common';

import { getCommonData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.Common,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
	// subsection: getCurrentSuSection(state)
});

const mapDispatchToProps = {
	getCommonData
};

const CommonContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Common);

export default CommonContainer;
