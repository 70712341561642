import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const NewStepperCheckBox = ({ id, name, checked, onChange, value, label, onBlur }) => {
	return (
		<FormControlLabel
			control={
				<Checkbox
					id={id}
					name={name}
					checked={checked}
					onChange={onChange}
					onBlur={onBlur}
					value={value}
					color="default"
				/>
			}
			label={label}
		/>
	);
};

export default NewStepperCheckBox;
