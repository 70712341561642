import React from 'react';
import Icon from 'react-eva-icons';

import { StyledButton as Button } from '../../../../components/Button/Button';
import './styles.scss';

const ButtonAddRemove = props => {
	if (props.delete) {
		return (
			<div className="icon-block" onClick={props.removeBlock}>
				<Icon
					name="trash-outline"
					fill="#FF0000"
					size="medium" // small, medium, large, xlarge
					animation={{
						// type: 'pulse', // zoom, pulse, shake, flip
						hover: true,
						infinite: false
					}}
				/>
			</div>
		);
	}

	if (props.add) {
		return (
			<div className="add-button-wrap">
				<Button className="add-button" variant="contained" onClick={props.addBlock}>
					<div className="add-button__text">Додати інші об'єкти</div>
					<div className="add-button__circle">
						<Icon
							name="plus-circle-outline"
							fill="#4ca34c"
							size="medium" // small, medium, large, xlarge
							animation={{
								// type: 'pulse', // zoom, pulse, shake, flip
								hover: true,
								infinite: false
							}}
						/>
					</div>
				</Button>
			</div>
		);
	}

	return null;
};

export default ButtonAddRemove;
