import React from 'react';

import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';

import MomentUtils from '@date-io/moment';

const NewYearPicker = ({ name, label, style, onChange, onBlur, error, value, helperText, onError }) => {
	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<DatePicker
				name={name}
				views={['year']}
				label={label}
				keyboard
				style={style}
				error={error}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				helperText={helperText}
				onError={onError}
			/>
		</MuiPickersUtilsProvider>
	);
};

export default NewYearPicker;
