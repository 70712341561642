// Общее Common
const GI_1A = 'GI_1A',
	GI_1B = 'GI_1B',
	GI_1C = 'GI_1C',
	GI_2A = 'GI_2A',
	GI_2B = 'GI_2B',
	GI_3A = 'GI_3A',
	GI_3B = 'GI_3B',
	GI_4A = 'GI_4A',
	GI_4B = 'GI_4B',
	GI_4C = 'GI_4C',
	GI_5AA = 'GI_5AA',
	GI_5AB = 'GI_5AB',
	GI_5AC = 'GI_5AC',
	GI_5AD = 'GI_5AD',
	GI_5AE = 'GI_5AE',
	GI_5AF = 'GI_5AF',
	GI_5AG = 'GI_5AG',
	GI_5BA = 'GI_5BA',
	GI_5BB = 'GI_5BB',
	GI_5BC = 'GI_5BC',
	GI_6 = 'GI_6',
	GI_7A = 'GI_7A',
	GI_7B = 'GI_7B',
	GI_8A = 'GI_8A',
	GI_8B = 'GI_8B',
	GI_8C = 'GI_8C';

export {
	GI_1A,
	GI_1B,
	GI_1C,
	GI_2A,
	GI_2B,
	GI_3A,
	GI_3B,
	GI_4A,
	GI_4B,
	GI_4C,
	GI_5AA,
	GI_5AB,
	GI_5AC,
	GI_5AD,
	GI_5AE,
	GI_5AF,
	GI_5AG,
	GI_5BA,
	GI_5BB,
	GI_5BC,
	GI_6,
	GI_7A,
	GI_7B,
	GI_8A,
	GI_8B,
	GI_8C
};

// Майно	Property
const P1A = 'P1A',
	P1B = 'P1B',
	P1C = 'P1C',
	P1D = 'P1D',
	P1E = 'P1E',
	P1F = 'P1F',
	P1G = 'P1G',
	P1H = 'P1H',
	P1I = 'P1I',
	P1J = 'P1J',
	P1K = 'P1K',
	P1L = 'P1L',
	P2A = 'P2A',
	P2B = 'P2B',
	P3 = 'P3',
	P4 = 'P4',
	P5 = 'P5',
	P6A = 'P6A',
	P6B = 'P6B';

const P1 = 'P1',
	P2 = 'P2',
	P6 = 'P6';

export { P1, P2, P6 };
export { P1A, P1B, P1C, P1D, P1E, P1F, P1G, P1H, P1I, P1J, P1K, P1L, P2A, P2B, P3, P4, P5, P6A, P6B };

// Виплати, пов'язані з трудовими відносинами	Wages
// const W1A = 'W1A',
// 	W1B = 'W1B',
// 	W2A = 'W2A',
// 	W3 = 'W3',
// 	W4 = 'W4',
// 	W5A = 'W5A',
// 	W5AA = 'W5AA',
// 	W5AB = 'W5AB',
// 	W5B = 'W5B',
// 	W6A = 'W6A',
// 	W6B = 'W6B',
// 	W7A = 'W7A',
// 	W7B = 'W7B';

// export { W1A, W1B, W2A, W3, W4, W5A, W5AA, W5AB, W5B, W6A, W6B, W7A, W7B };

const W1A = 'W1A',
	W1B = 'W1B',
	W1BA = 'W1BA',
	W1BB = 'W1BB',
	W1BC = 'W1BC',
	W1BD = 'W1BD',
	W1BE = 'W1BE',
	W2A = 'W2A',
	W3 = 'W3', //
	W4 = 'W4', //
	W5AA = 'W5AA', //
	W5AB = 'W5AB', //
	W3A = 'W3A',
	W4A = 'W4A',
	W4AA = 'W4AA',
	W4AAA = 'W4AAA',
	W4AAB = 'W4AAB',
	W5 = 'W5',
	W5A = 'W5A',
	W5B = 'W5B',
	W5C = 'W5C',
	W5D = 'W5D',
	W5E = 'W5E',
	W6 = 'W6',
	W6A = 'W6A',
	W6AA = 'W6AA',
	W6B = 'W6B',
	W6C = 'W6C',
	W6D = 'W6D',
	W6E = 'W6E',
	W7 = 'W7',
	W7A = 'W7A',
	W7AA = 'W7AA',
	W7B = 'W7B',
	W8 = 'W8',
	W8A = 'W8A',
	W8AA = 'W8AA',
	W8B = 'W8B';

export {
	W1A,
	W1B,
	W1BA,
	W1BB,
	W1BC,
	W1BD,
	W1BE,
	W2A,
	W3, //
	W3A,
	W4, //
	W5AA, //
	W5AB, //
	W4A,
	W4AA,
	W4AAA,
	W4AAB,
	W5,
	W5A,
	W5B,
	W5C,
	W5D,
	W5E,
	W6,
	W6A,
	W6AA,
	W6B,
	W6C,
	W6D,
	W6E,
	W7,
	W7A,
	W7AA,
	W7B,
	W8,
	W8A,
	W8AA,
	W8B
};

// Income from inheritance and donation ID
const ID1 = 'ID1',
	ID1A = 'ID1A',
	ID1B = 'ID1B',
	ID1BA = 'ID1BA',
	ID1BB = 'ID1BB',
	ID1BBA = 'ID1BBA',
	ID1BBB = 'ID1BBB',
	ID1BBC = 'ID1BBC',
	ID1BBD = 'ID1BBD',
	ID1BBE = 'ID1BBE',
	ID1AA = 'ID1AA',
	ID2 = 'ID2',
	ID2A = 'ID2A',
	ID2B = 'ID2B',
	ID2AA = 'ID2AA',
	ID3 = 'ID3',
	ID3A = 'ID3A',
	ID3AA = 'ID3AA',
	ID3B = 'ID3B',
	ID3BA = 'ID3BA',
	ID3C = 'ID3C',
	ID3CA = 'ID3CA',
	ID3D = 'ID3D',
	ID3DA = 'ID3DA',
	ID3E = 'ID3E',
	ID3EA = 'ID3EA',
	ID3F = 'ID3F',
	ID3FA = 'ID3FA',
	ID4 = 'ID4',
	ID4A = 'ID4A',
	ID4B = 'ID4B',
	ID4AA = 'ID4AA',
	ID4AAA = 'ID4AAA';

export {
	ID1,
	ID1A,
	ID1B,
	ID1BA,
	ID1BB,
	ID1BBA,
	ID1BBB,
	ID1BBC,
	ID1BBD,
	ID1BBE,
	ID1AA,
	ID2,
	ID2A,
	ID2B,
	ID2AA,
	ID3,
	ID3A,
	ID3AA,
	ID3B,
	ID3BA,
	ID3C,
	ID3CA,
	ID3D,
	ID3DA,
	ID3E,
	ID3EA,
	ID3F,
	ID3FA,
	ID4,
	ID4A,
	ID4B,
	ID4AA,
	ID4AAA
};

const PENALTY1 = 'PENALTY1',
	PENALTY2 = 'PENALTY2',
	PENALTY3 = 'PENALTY3';

export { PENALTY1, PENALTY2, PENALTY3 };

const OTI_1 = 'OTI_1',
	OTI_2A = 'OTI_2A',
	OTI_2B = 'OTI_2B',
	OTI_3 = 'OTI_3',
	OTI_4 = 'OTI_4',
	OTI_5 = 'OTI_5',
	OTI_6 = 'OTI_6',
	OTI_7 = 'OTI_7';

export { OTI_1, OTI_2A, OTI_2B, OTI_3, OTI_4, OTI_5, OTI_6, OTI_7 };

const OFI_1 = 'OFI_1';

export { OFI_1 };

const Disc1 = 'Disc1',
	Disc2 = 'Disc2',
	Disc3 = 'Disc3',
	Disc4 = 'Disc4',
	Disc4B = 'Disc4B',
	Disc5 = 'Disc5',
	Disc6 = 'Disc6',
	Disc7 = 'Disc7',
	Disc8 = 'Disc8';

export { Disc1, Disc2, Disc3, Disc4, Disc4B, Disc5, Disc6, Disc7, Disc8 };

const WP1A = 'WP1A',
	WP1B = 'WP1B';

export { WP1A, WP1B };

const C1 = 'C1',
	C1A = 'C1A',
	C1AA = 'C1AA',
	C1B = 'C1B',
	C1BA = 'C1BA',
	C1C = 'C1C',
	C1CA = 'C1CA',
	C1D = 'C1D',
	C1DA = 'C1DA',
	C1E = 'C1E',
	C1EA = 'C1EA',
	C1EAA = 'C1EAA',
	C1EAAA = 'C1EAAA',
	C1EAB = 'C1EAB',
	C1EABA = 'C1EABA',
	C1F = 'C1F',
	C1FA = 'C1FA',
	C1G = 'C1G',
	C1GA = 'C1GA',
	C1H = 'C1H',
	C1HA = 'C1HA',
	C1I = 'C1I',
	C1IA = 'C1IA',
	C1J = 'C1J',
	C1JA = 'C1JA',
	C1K = 'C1K',
	C1KA = 'C1KA',
	C1KB = 'C1KB',
	C1KC = 'C1KC',
	C1KD = 'C1KD',
	C1KE = 'C1KE',
	C1KEA = 'C1KEA',
	C1KAA = 'C1KAA',
	C1KAAA = 'C1KAAA',
	C1KAAAA = 'C1KAAAA',
	C1KAAB = 'C1KAAB',
	C1KAABA = 'C1KAABA',
	C1KBA = 'C1KBA',
	C1KBAA = 'C1KBAA',
	C1KBAAA = 'C1KBAAA',
	C1KBAB = 'C1KBAB',
	C1KBABA = 'C1KBABA',
	C1KCA = 'C1KCA',
	C1KCAA = 'C1KCAA',
	C1KCAAA = 'C1KCAAA',
	C1KCAB = 'C1KCAB',
	C1KCABA = 'C1KCABA',
	C1KDA = 'C1KDA',
	C1KDAA = 'C1KDAA',
	C1KDAAA = 'C1KDAAA',
	C1KDAB = 'C1KDAB',
	C1KDABA = 'C1KDABA',
	C1L = 'C1L',
	C1LA = 'C1LA',
	C1M = 'C1M',
	C1MA = 'C1MA',
	C1N = 'C1N',
	C1NA = 'C1NA',
	C1O = 'C1O',
	C1OA = 'C1OA',
	C1OAA = 'C1OAA',
	C1OB = 'C1OB',
	C1OBA = 'C1OBA',
	C1P = 'C1P',
	C1PA = 'C1PA',
	C1Q = 'C1Q',
	C1QA = 'C1QA',
	C1R = 'C1R',
	C1RA = 'C1RA',
	C1S = 'C1S',
	C1SA = 'C1SA',
	C1T = 'C1T',
	C1TA = 'C1TA',
	C1U = 'C1U',
	C1UA = 'C1UA',
	C1V = 'C1V',
	C1VA = 'C1VA',
	C1W = 'C1W',
	C1WA = 'C1WA',
	C2 = 'C2',
	C2A = 'C2A';

export {
	C1,
	C1A,
	C1AA,
	C1B,
	C1BA,
	C1C,
	C1CA,
	C1D,
	C1DA,
	C1E,
	C1EA,
	C1EAA,
	C1EAAA,
	C1EAB,
	C1EABA,
	C1F,
	C1FA,
	C1G,
	C1GA,
	C1H,
	C1HA,
	C1I,
	C1IA,
	C1J,
	C1JA,
	C1K,
	C1KA,
	C1KB,
	C1KC,
	C1KD,
	C1KE,
	C1KEA,
	C1KAA,
	C1KAAA,
	C1KAAAA,
	C1KAAB,
	C1KAABA,
	C1KBA,
	C1KBAA,
	C1KBAAA,
	C1KBAB,
	C1KBABA,
	C1KCA,
	C1KCAA,
	C1KCAAA,
	C1KCAB,
	C1KCABA,
	C1KDA,
	C1KDAA,
	C1KDAAA,
	C1KDAB,
	C1KDABA,
	C1L,
	C1LA,
	C1M,
	C1MA,
	C1N,
	C1NA,
	C1O,
	C1OA,
	C1OAA,
	C1OB,
	C1OBA,
	C1P,
	C1PA,
	C1Q,
	C1QA,
	C1R,
	C1RA,
	C1S,
	C1SA,
	C1T,
	C1TA,
	C1U,
	C1UA,
	C1V,
	C1VA,
	C1W,
	C1WA,
	C2,
	C2A
};

const IP1 = 'IP1',
	IP1A = 'IP1A',
	IP1AA = 'IP1AA',
	IP1AAA = 'IP1AAA',
	IP1AAB = 'IP1AAB',
	IP1AAAA = 'IP1AAAA',
	IP1AAAB = 'IP1AAAB',
	IP1AABA = 'IP1AABA',
	IP1B = 'IP1B',
	IP1BA = 'IP1BA',
	IP1BAA = 'IP1BAA',
	IP1BAB = 'IP1BAB',
	IP1BAAA = 'IP1BAAA',
	IP1BABA = 'IP1BABA',
	IP1BAAAA = 'IP1BAAAA',
	IP1BABAA = 'IP1BABAA',
	IP1C = 'IP1C',
	IP1CA = 'IP1CA',
	IP1CAA = 'IP1CAA',
	IP1D = 'IP1D',
	IP1DA = 'IP1DA',
	IP1DAA = 'IP1DAA',
	IP1DAAA = 'IP1DAAA',
	IP1DAB = 'IP1DAB',
	IP1DABA = 'IP1DABA',
	IP1DAAAA = 'IP1DAAAA',
	IP1DABAA = 'IP1DABAA',
	IP1E = 'IP1E',
	IP1EA = 'IP1EA',
	IP1EAA = 'IP1EAA',
	IP1F = 'IP1F',
	IP1FA = 'IP1FA',
	IP1FAA = 'IP1FAA',
	IP1G = 'IP1G',
	IP1GA = 'IP1GA',
	IP1GAA = 'IP1GAA',
	IP1H = 'IP1H',
	IP1HA = 'IP1HA',
	IP1HAA = 'IP1HAA',
	IP1J = 'IP1J',
	IP1JA = 'IP1JA',
	IP1JAA = 'IP1JAA',
	IP1K = 'IP1K',
	IP1KA = 'IP1KA',
	IP1KAA = 'IP1KAA',
	IP1L = 'IP1L',
	IP1LA = 'IP1LA',
	IP1LAA = 'IP1LAA',
	IP1M = 'IP1M',
	IP1MA = 'IP1MA',
	IP1MAA = 'IP1MAA',
	IP1N = 'IP1N',
	IP1NA = 'IP1NA',
	IP1NAA = 'IP1NAA',
	IP1NAAA = 'IP1NAAA',
	IP1NAAAA = 'IP1NAAAA',
	IP1NAB = 'IP1NAB',
	IP1NABA = 'IP1NABA',
	IP1NABAA = 'IP1NABAA',
	IP1P = 'IP1P',
	IP1PA = 'IP1PA',
	IP1PAA = 'IP1PAA',
	IP1PAB = 'IP1PAB',
	IP2 = 'IP2',
	IP2A = 'IP2A',
	IP2AA = 'IP2AA',
	IP2AAA = 'IP2AAA',
	IP2AB = 'IP2AB',
	IP2B = 'IP2B',
	IP2BA = 'IP2BA',
	IP2C = 'IP2C',
	IP2CA = 'IP2CA',
	IP2CB = 'IP2CB',
	IP2CC = 'IP2CC',
	IP2CD = 'IP2CD';

export {
	IP1,
	IP1A,
	IP1AA,
	IP1AAA,
	IP1AAB,
	IP1AAAA,
	IP1AAAB,
	IP1AABA,
	IP1B,
	IP1BA,
	IP1BAA,
	IP1BAB,
	IP1BAAA,
	IP1BABA,
	IP1BAAAA,
	IP1BABAA,
	IP1C,
	IP1CA,
	IP1CAA,
	IP1D,
	IP1DA,
	IP1DAA,
	IP1DAAA,
	IP1DAB,
	IP1DABA,
	IP1DAAAA,
	IP1DABAA,
	IP1E,
	IP1EA,
	IP1EAA,
	IP1F,
	IP1FA,
	IP1FAA,
	IP1G,
	IP1GA,
	IP1GAA,
	IP1H,
	IP1HA,
	IP1HAA,
	IP1J,
	IP1JA,
	IP1JAA,
	IP1K,
	IP1KA,
	IP1KAA,
	IP1L,
	IP1LA,
	IP1LAA,
	IP1M,
	IP1MA,
	IP1MAA,
	IP1N,
	IP1NA,
	IP1NAA,
	IP1NAAA,
	IP1NAAAA,
	IP1NAB,
	IP1NABA,
	IP1NABAA,
	IP1P,
	IP1PA,
	IP1PAA,
	IP1PAB,
	IP2,
	IP2A,
	IP2AA,
	IP2AAA,
	IP2AB,
	IP2B,
	IP2BA,
	IP2C,
	IP2CA,
	IP2CB,
	IP2CC,
	IP2CD
};

const R1A = 'R1A',
	R1B = 'R1B',
	R1BA = 'R1BA',
	R1BB = 'R1BB',
	R1BC = 'R1BC',
	R1BD = 'R1BD',
	R1BE = 'R1BE';

export { R1A, R1B, R1BA, R1BB, R1BC, R1BD, R1BE };

const ALIM1 = 'ALIM1',
	ALIM1A = 'ALIM1A',
	ALIM2 = 'ALIM2',
	ALIM2A = 'ALIM2A',
	ALIM2B = 'ALIM2B',
	ALIM2C = 'ALIM2C',
	ALIM2D = 'ALIM2D',
	ALIM2E = 'ALIM2E';

export { ALIM1, ALIM1A, ALIM2, ALIM2A, ALIM2B, ALIM2C, ALIM2D, ALIM2E };

const S1 = 'S1',
	S1A = 'S1A',
	S1AA = 'S1AA',
	S1B = 'S1B',
	S1BA = 'S1BA',
	S1C = 'S1C',
	S1CA = 'S1CA',
	S1D = 'S1D',
	S1DA = 'S1DA';

export { S1, S1A, S1AA, S1B, S1BA, S1C, S1CA, S1D, S1DA };

const Lease1 = 'Lease1',
	Lease1A = 'Lease1A',
	Lease1AA = 'Lease1AA',
	Lease1B = 'Lease1B',
	Lease1BA = 'Lease1BA',
	Lease2 = 'Lease2',
	Lease2A = 'Lease2A',
	Lease2B = 'Lease2B',
	Lease2C = 'Lease2C',
	Lease2D = 'Lease2D',
	Lease2E = 'Lease2E';

export { Lease1, Lease1A, Lease1AA, Lease1B, Lease1BA, Lease2, Lease2A, Lease2B, Lease2C, Lease2D, Lease2E };

const Int1 = 'Int1',
	Int1A = 'Int1A',
	Int1AA = 'Int1AA',
	Int1AB = 'Int1AB',
	Int1AC = 'Int1AC',
	Int1AD = 'Int1AD',
	Int1AE = 'Int1AE',
	Int1B = 'Int1B',
	Int1BA = 'Int1BA',
	Int1C = 'Int1C',
	Int1CA = 'Int1CA',
	Int1D = 'Int1D',
	Int1DA = 'Int1DA',
	Int1E = 'Int1E',
	Int1EA = 'Int1EA';

export {
	Int1,
	Int1A,
	Int1AA,
	Int1AB,
	Int1AC,
	Int1AD,
	Int1AE,
	Int1B,
	Int1BA,
	Int1C,
	Int1CA,
	Int1D,
	Int1DA,
	Int1E,
	Int1EA
};

const D1 = 'D1',
	D1A = 'D1A',
	D1AA = 'D1AA',
	D1B = 'D1B',
	D2 = 'D2',
	D2A = 'D2A',
	D2B = 'D2B',
	D2BA = 'D2BA',
	D3 = 'D3',
	D3A = 'D3A',
	D3AA = 'D3AA',
	D3AB = 'D3AB',
	D3AC = 'D3AC',
	D3AD = 'D3AD',
	D3AE = 'D3AE',
	D3B = 'D3B',
	D3BA = 'D3BA',
	D3C = 'D3C',
	D3CA = 'D3CA';

export { D1, D1A, D1AA, D1B, D2, D2A, D2B, D2BA, D3, D3A, D3AA, D3AB, D3AC, D3AD, D3AE, D3B, D3BA, D3C, D3CA };

const AddB = 'AddB',
	AddB1 = 'AddB1',
	AddB1A = 'AddB1A',
	AddB2 = 'AddB2',
	AddB2A = 'AddB2A',
	AddB3 = 'AddB3',
	AddB3A = 'AddB3A',
	AddB4 = 'AddB4',
	AddB4A = 'AddB4A',
	AddB5 = 'AddB5',
	AddB5A = 'AddB5A',
	AddB6 = 'AddB6',
	AddB6A = 'AddB6A',
	AddB7 = 'AddB7',
	AddB7A = 'AddB7A',
	AddB8 = 'AddB8',
	AddB8A = 'AddB8A',
	AddB9 = 'AddB9',
	AddB9A = 'AddB9A',
	AddB10 = 'AddB10',
	AddB10A = 'AddB10A',
	AddB11 = 'AddB11',
	AddB11A = 'AddB11A',
	AddB12 = 'AddB12',
	AddB12A = 'AddB12A',
	AddB13 = 'AddB13',
	AddB13A = 'AddB13A',
	AddB14 = 'AddB14',
	AddB14A = 'AddB14A';

export {
	AddB,
	AddB1,
	AddB1A,
	AddB2,
	AddB2A,
	AddB3,
	AddB3A,
	AddB4,
	AddB4A,
	AddB5,
	AddB5A,
	AddB6,
	AddB6A,
	AddB7,
	AddB7A,
	AddB8,
	AddB8A,
	AddB9,
	AddB9A,
	AddB10,
	AddB10A,
	AddB11,
	AddB11A,
	AddB12,
	AddB12A,
	AddB13,
	AddB13A,
	AddB14,
	AddB14A
};

const ONTI1 = 'ONTI1',
	ONTI1A = 'ONTI1A',
	ONTI1B = 'ONTI1B',
	ONTI1AA = 'ONTI1AA',
	ONTI1AB = 'ONTI1AB',
	ONTI1AC = 'ONTI1AC',
	ONTI2 = 'ONTI2',
	ONTI3 = 'ONTI3',
	ONTI3A = 'ONTI3A',
	ONTI3B = 'ONTI3B',
	ONTI3AA = 'ONTI3AA',
	ONTI4 = 'ONTI4',
	ONTI4A = 'ONTI4A',
	ONTI4B = 'ONTI4B',
	ONTI4AA = 'ONTI4AA',
	ONTI5 = 'ONTI5',
	ONTI5A = 'ONTI5A',
	ONTI5B = 'ONTI5B',
	ONTI5AA = 'ONTI5AA',
	ONTI6 = 'ONTI6',
	ONTI6A = 'ONTI6A',
	ONTI6B = 'ONTI6B',
	ONTI6AA = 'ONTI6AA',
	ONTI7 = 'ONTI7',
	ONTI7A = 'ONTI7A',
	ONTI7B = 'ONTI7B',
	ONTI7AA = 'ONTI7AA',
	ONTI8 = 'ONTI8',
	ONTI8A = 'ONTI8A',
	ONTI8B = 'ONTI8B',
	ONTI8AA = 'ONTI8AA',
	ONTI9 = 'ONTI9',
	ONTI9A = 'ONTI9A',
	ONTI9B = 'ONTI9B',
	ONTI9AA = 'ONTI9AA',
	ONTI10 = 'ONTI10',
	ONTI10A = 'ONTI10A',
	ONTI10AA = 'ONTI10AA',
	ONTI10B = 'ONTI10B',
	ONTI10BA = 'ONTI10BA',
	ONTI10C = 'ONTI10C',
	ONTI10CA = 'ONTI10CA',
	ONTI10D = 'ONTI10D',
	ONTI10DA = 'ONTI10DA',
	ONTI11 = 'ONTI11',
	ONTI11A = 'ONTI11A',
	ONTI11B = 'ONTI11B',
	ONTI11AA = 'ONTI11AA',
	ONTI12 = 'ONTI12',
	ONTI12A = 'ONTI12A',
	ONTI12B = 'ONTI12B',
	ONTI12AA = 'ONTI12AA',
	ONTI13 = 'ONTI13',
	ONTI13A = 'ONTI13A',
	ONTI13B = 'ONTI13B',
	ONTI13AA = 'ONTI13AA',
	ONTI14 = 'ONTI14',
	ONTI14A = 'ONTI14A',
	ONTI14B = 'ONTI14B',
	ONTI14AA = 'ONTI14AA',
	ONTI15 = 'ONTI15',
	ONTI15A = 'ONTI15A',
	ONTI15B = 'ONTI15B',
	ONTI15AA = 'ONTI15AA',
	ONTI16 = 'ONTI16',
	ONTI16A = 'ONTI16A',
	ONTI16B = 'ONTI16B',
	ONTI16AA = 'ONTI16AA',
	ONTI17 = 'ONTI17',
	ONTI17A = 'ONTI17A',
	ONTI17B = 'ONTI17B',
	ONTI17AA = 'ONTI17AA';

export {
	ONTI1,
	ONTI1A,
	ONTI1B,
	ONTI1AA,
	ONTI1AB,
	ONTI1AC,
	ONTI2,
	ONTI3,
	ONTI3A,
	ONTI3B,
	ONTI3AA,
	ONTI4,
	ONTI4A,
	ONTI4B,
	ONTI4AA,
	ONTI5,
	ONTI5A,
	ONTI5B,
	ONTI5AA,
	ONTI6,
	ONTI6A,
	ONTI6B,
	ONTI6AA,
	ONTI7,
	ONTI7A,
	ONTI7B,
	ONTI7AA,
	ONTI8,
	ONTI8A,
	ONTI8B,
	ONTI8AA,
	ONTI9,
	ONTI9A,
	ONTI9B,
	ONTI9AA,
	ONTI10,
	ONTI10A,
	ONTI10AA,
	ONTI10B,
	ONTI10BA,
	ONTI10C,
	ONTI10CA,
	ONTI10D,
	ONTI10DA,
	ONTI11,
	ONTI11A,
	ONTI11B,
	ONTI11AA,
	ONTI12,
	ONTI12A,
	ONTI12B,
	ONTI12AA,
	ONTI13,
	ONTI13A,
	ONTI13B,
	ONTI13AA,
	ONTI14,
	ONTI14A,
	ONTI14B,
	ONTI14AA,
	ONTI15,
	ONTI15A,
	ONTI15B,
	ONTI15AA,
	ONTI16,
	ONTI16A,
	ONTI16B,
	ONTI16AA,
	ONTI17,
	ONTI17A,
	ONTI17B,
	ONTI17AA
};

const SalePro1 = 'SalePro1',
	SalePro1A = 'SalePro1A',
	SalePro1AA = 'SalePro1AA',
	SalePro1B = 'SalePro1B',
	SalePro1BA = 'SalePro1BA',
	SalePro1CA = 'SalePro1CA',
	SalePro1C = 'SalePro1C',
	SalePro2 = 'SalePro2',
	SalePro2A = 'SalePro2A',
	SalePro2AA = 'SalePro2AA',
	SalePro2B = 'SalePro2B',
	SalePro2BA = 'SalePro2BA',
	SalePro2C = 'SalePro2C',
	SalePro2CA = 'SalePro2CA',
	SalePro2D = 'SalePro2D',
	SalePro2DA = 'SalePro2DA',
	SalePro3 = 'SalePro3',
	SalePro3A = 'SalePro3A',
	SalePro3B = 'SalePro3B',
	SalePro3C = 'SalePro3C',
	SalePro3D = 'SalePro3D',
	SalePro3E = 'SalePro3E',
	SalePro4 = 'SalePro4',
	SalePro4А = 'SalePro4А',
	SalePro4AA = 'SalePro4AA',
	SalePro4B = 'SalePro4B',
	SalePro3AA = 'SalePro3AA';

export {
	SalePro1,
	SalePro1A,
	SalePro1AA,
	SalePro1B,
	SalePro1BA,
	SalePro1CA,
	SalePro1C,
	SalePro2,
	SalePro2A,
	SalePro2AA,
	SalePro2B,
	SalePro2BA,
	SalePro2C,
	SalePro2CA,
	SalePro2D,
	SalePro2DA,
	SalePro3,
	SalePro3A,
	SalePro3B,
	SalePro3C,
	SalePro3D,
	SalePro3E,
	SalePro4,
	SalePro4А,
	SalePro4AA,
	SalePro4B,
	SalePro3AA
};

const InvestP1 = 'InvestP1',
	InvestP1A = 'InvestP1A',
	InvestP1AA = 'InvestP1AA',
	InvestP1AB = 'InvestP1AB',
	InvestP1AC = 'InvestP1AC',
	InvestP1B = 'InvestP1B',
	InvestP1BA = 'InvestP1BA',
	InvestP1BB = 'InvestP1BB',
	InvestP1BC = 'InvestP1BC',
	InvestP1C = 'InvestP1C',
	InvestP1CA = 'InvestP1CA',
	InvestP1CB = 'InvestP1CB',
	InvestP1CC = 'InvestP1CC',
	InvestP1D = 'InvestP1D',
	InvestP1DA = 'InvestP1DA',
	InvestP1DB = 'InvestP1DB',
	InvestP1DC = 'InvestP1DC',
	InvestP1E = 'InvestP1E',
	InvestP1EA = 'InvestP1EA',
	InvestP1EB = 'InvestP1EB',
	InvestP1EC = 'InvestP1EC',
	InvestP1F = 'InvestP1F',
	InvestP1FA = 'InvestP1FA',
	InvestP1FB = 'InvestP1FB',
	InvestP1G = 'InvestP1G',
	InvestP1GA = 'InvestP1GA',
	InvestP1H = 'InvestP1H',
	InvestP1HA = 'InvestP1HA',
	InvestP1I = 'InvestP1I',
	InvestP1J = 'InvestP1J',
	InvestP1K = 'InvestP1K',
	InvestP1L = 'InvestP1L',
	InvestP1LA = 'InvestP1LA',
	InvestP1LB = 'InvestP1LB',
	InvestP1LAA = 'InvestP1LAA',
	InvestP1M = 'InvestP1M',
	InvestP1MA = 'InvestP1MA',
	InvestP1MB = 'InvestP1MB',
	InvestP1MAA = 'InvestP1MAA';

export {
	InvestP1,
	InvestP1A,
	InvestP1AA,
	InvestP1AB,
	InvestP1AC,
	InvestP1B,
	InvestP1BA,
	InvestP1BB,
	InvestP1BC,
	InvestP1C,
	InvestP1CA,
	InvestP1CB,
	InvestP1CC,
	InvestP1D,
	InvestP1DA,
	InvestP1DB,
	InvestP1DC,
	InvestP1E,
	InvestP1EA,
	InvestP1EB,
	InvestP1EC,
	InvestP1F,
	InvestP1FA,
	InvestP1FB,
	InvestP1G,
	InvestP1GA,
	InvestP1H,
	InvestP1HA,
	InvestP1I,
	InvestP1J,
	InvestP1K,
	InvestP1L,
	InvestP1LA,
	InvestP1LB,
	InvestP1LAA,
	InvestP1M,
	InvestP1MA,
	InvestP1MB,
	InvestP1MAA
};

const IPA1 = 'IPA1',
	IPA2 = 'IPA2',
	IPA3 = 'IPA3',
	IPA4 = 'IPA4',
	IPA5 = 'IPA5',
	IPA6 = 'IPA6',
	IPA7 = 'IPA7';

export { IPA1, IPA2, IPA3, IPA4, IPA5, IPA6, IPA7 };

const EntreprI1 = 'EntreprI1',
	EntreprI2 = 'EntreprI2',
	EntreprI3 = 'EntreprI3',
	EntreprI4 = 'EntreprI4',
	EntreprI5 = 'EntreprI5',
	EntreprI5A = 'EntreprI5A',
	EntreprI5B = 'EntreprI5B',
	EntreprI5C = 'EntreprI5C',
	EntreprI6 = 'EntreprI6',
	EntreprI6A = 'EntreprI6A',
	EntreprI6AA = 'EntreprI6AA',
	EntreprI6AB = 'EntreprI6AB',
	EntreprI6AC = 'EntreprI6AC',
	EntreprI6AD = 'EntreprI6AD',
	EntreprI6B = 'EntreprI6B',
	EntreprI6C = 'EntreprI6C',
	EntreprI6D = 'EntreprI6D',
	EntreprI6E = 'EntreprI6E',
	EntreprI7 = 'EntreprI7';

export {
	EntreprI1,
	EntreprI2,
	EntreprI3,
	EntreprI4,
	EntreprI5,
	EntreprI5A,
	EntreprI5B,
	EntreprI5C,
	EntreprI6,
	EntreprI6A,
	EntreprI6AA,
	EntreprI6AB,
	EntreprI6AC,
	EntreprI6AD,
	EntreprI6B,
	EntreprI6C,
	EntreprI6D,
	EntreprI6E,
	EntreprI7
};

const EI_TAI1 = 'EI_TAI1',
	EI_TAI2 = 'EI_TAI2',
	EI_TAI3 = 'EI_TAI3',
	EI_TAI4 = 'EI_TAI4',
	EI_TAI5 = 'EI_TAI5',
	EI_TAI5A = 'EI_TAI5A',
	EI_TAI5B = 'EI_TAI5B',
	EI_TAI5C = 'EI_TAI5C',
	EI_TAI6 = 'EI_TAI6',
	EI_TAI6A = 'EI_TAI6A',
	EI_TAI6AA = 'EI_TAI6AA',
	EI_TAI6AB = 'EI_TAI6AB',
	EI_TAI6AC = 'EI_TAI6AC',
	EI_TAI6AD = 'EI_TAI6AD',
	EI_TAI6B = 'EI_TAI6B',
	EI_TAI6C = 'EI_TAI6C',
	EI_TAI6D = 'EI_TAI6D',
	EI_TAI6E = 'EI_TAI6E',
	EI_TAI7 = 'EI_TAI7';

export {
	EI_TAI1,
	EI_TAI2,
	EI_TAI3,
	EI_TAI4,
	EI_TAI5,
	EI_TAI5A,
	EI_TAI5B,
	EI_TAI5C,
	EI_TAI6,
	EI_TAI6A,
	EI_TAI6AA,
	EI_TAI6AB,
	EI_TAI6AC,
	EI_TAI6AD,
	EI_TAI6B,
	EI_TAI6C,
	EI_TAI6D,
	EI_TAI6E,
	EI_TAI7
};
