import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Layout from './HOC/Layout';

// CONTAINERS
import { LoginContainer } from './pages/containers/LoginContainer';
import Declaration from './pages/declaration';
// import DeclarationContainer from './pages/containers/DeclarationContainer';
// import QuizContainer from './pages/containers/QuizContainer'; // FIXME: need delete after change quiz page

// new routes (Valera)
import NewQuizPage from './pages/quiz/NewQuizPage';
import NewCreateDecl from './pages/quiz/NewCreateDecl';

import Preview from './pages/declaration/components/PreviewDeclaration';

import { Registration, Forgot, NewPassword } from './pages/auth';
import Documents from './pages/documents';
import DocumentList from './pages/documents/documents_list';
import Kb from './pages/kb';

class Routes extends Component {
	render() {
		return (
			<Layout>
				<Switch>
					<Route path="/" exact component={Registration} />
					<Route path="/login" exact component={LoginContainer} />
					<Route path="/login/fogot" exact component={Forgot} />
					<Route path="/login/fogot/new-password" exact component={NewPassword} />

					<Route path="/documents/create-new" component={NewCreateDecl} />
					<Route path="/documents/quiz" component={NewQuizPage} />
					<Route path="/documents/docs-list" component={DocumentList} />

					<Route path="/documents/declaration" exact component={Declaration} />
					<Route path="/documents/declaration/preview" component={Preview} />
					<Route path="/documents/declaration/:subsection" component={Declaration} />

					<Route path="/documents" exact component={Documents} />
					<Route path="/kb" exact component={Kb} />
					<Route path="/profile" exact component={Documents} />
					<Redirect to="/" />
				</Switch>
			</Layout>
		);
	}
}

export default Routes;
