import { connect } from 'react-redux';

import InvestP from '../subsections/InvestP';
import { getInvestPData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.INVESTP.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getInvestPData
};

const InvestPContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvestP);

export default InvestPContainer;
