import React, { Component } from 'react';
import { connect } from 'react-redux';
import hoc from '../../HOC/hoc';
import Search from './components/search';
import Panel from './components/Panels';
import { getKbList } from '../../store/actions/kb_actions';

import './styles.scss';

class Kb extends Component {
	componentDidMount() {
		this.props.dispatch(getKbList());
	}

	render() {
		return (
			<div className="kb-container">
				<h3 className="page-title page-title_kb title-shadow">Список термінів</h3>
				<Search />
				{this.props.kbList && this.props.kbList.map((item, i) => <Panel kb={item} key={i} />)}
			</div>
		);
	}
}

export default connect(state => ({ kbList: state.kb.kbList }))(hoc(null)(Kb));
