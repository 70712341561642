import { connect } from 'react-redux';

import Wages from '../subsections/Wages';

import { getDeclarations, getWagesData, setStepIndex } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.W.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName),
	currentStepIndex: state.declaration.currentStepIndex
});

const mapDispatchToProps = {
	getDeclarations,
	getWagesData,
	setStepIndex
};

const WContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Wages);

export default WContainer;
