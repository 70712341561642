import React, { useState, useEffect, Fragment } from 'react';
import QuestionBlock from './components/QuestionBlock';

import NextButton from '../../components/NextButton';
import AddButton from '../../components/ButtonAddRemove';
import Stepper from '../../containers/StepperContainer';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const initialAnswersState = {
	Disc1: [{ apartment_area: '', expenses: '' }],
	Disc2: [{ expenses: '' }],
	Disc3: [{ expenses: '' }],
	Disc4: [{ expenses: '' }],
	Disc4B: [{ expenses: '', family: '' }],
	Disc5: [{ expenses: '' }],
	Disc6: [{ expenses: '' }],
	Disc7: [{ expenses: '' }],
	Disc8: [{ expenses: '' }]
};
const initialDataState = {
	R013G1: false, //Disc1
	R013G2: false, //Disc2
	R013G3: false, //Disc3
	R013G4: false, //?Disc4  Disc4B
	R013G5: false, //Disc4B
	R013G6: false, //Disc5
	R013G7: false, //Disc6
	R013G8: false, //Disc7
	R014G3: {
		q: {
			Disc1: { sub_q: { Disc1: false } },
			Disc2: { sub_q: { Disc2: false } },
			Disc3: { sub_q: { Disc3: false } },
			Disc4: { sub_q: { Disc4: false } },
			Disc4B: { sub_q: { Disc4B: false } },
			Disc5: { sub_q: { Disc5: false } },
			Disc6: { sub_q: { Disc6: false } },
			Disc7: { sub_q: { Disc7: false } },
			Disc8: { sub_q: { Disc8: false } }
		},
		a: {
			Disc1: { apartment_area: 0, expenses: 0 },
			Disc2: { expenses: 0 },
			Disc3: { expenses: 0 },
			Disc4: { expenses: 0 },
			Disc4B: { expenses: 0, family: 0 },
			Disc5: { expenses: 0 },
			Disc6: { expenses: 0 },
			Disc7: { expenses: 0 },
			Disc8: { expenses: 0 }
		}
	}
};

const Disc = props => {
	const [data, setData] = useState(initialDataState);
	const [answers, setAnswers] = useState(initialAnswersState);

	const getAnswers = () => () => {
		const newAnswers = { ...answers };
		const newData = { ...data };
		// const keys = Object.keys(answer);

		// прописывается логика добавления ответов в дату для дальнейшего расчета в декларации

		setAnswers(newAnswers);
		setData(newData);
		props.getDiscData({ data: newData, answers: newAnswers });
		save('disc', { answers: newAnswers, data: newData });
	};

	const addBlock = state => {
		let newAnswers = { ...answers };
		const keys = Object.keys(state);
		keys.map(key => {
			if (key === 'Disc1') {
				newAnswers[key].push({ apartment_area: '', expenses: '' });
			} else if (key === 'Disc4B') {
				newAnswers[key].push({ expenses: '', family: '' });
			} else {
				newAnswers[key].push({ expenses: '' });
			}
			return key;
		});
		setAnswers(newAnswers);
		save('disc', { answers: newAnswers, data });
	};

	const removeBlock = index => state => {
		let newAnswers = { ...answers };
		const keys = Object.keys(state);
		keys.map(key => {
			newAnswers[key].splice(index, 1);
			return key;
		});
		setAnswers(newAnswers);
		save('disc', { answers: newAnswers, data });
	};

	useEffect(() => {
		if (load('disc')) {
			const { data, answers } = load('disc');
			setAnswers(answers);
			setData(data);
			props.getDiscData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getOfiData(data);

	return (
		<div className="disc-wrap">
			<Stepper steps={props.subsection} activeIndex={props.currentStepIndex} />

			{Object.values(props.subsection[props.currentStepIndex]).map((question, i) => {
				return (
					<Fragment key={i}>
						{answers[question.id].map((state, i) => (
							<QuestionBlock
								key={i}
								index={i}
								question={question}
								state={{ [question.id]: state }}
								getAnswers={getAnswers(i)}
								removeBlock={removeBlock(i)}
							/>
						))}
						<AddButton
							add
							addBlock={() => addBlock({ [question.id]: initialAnswersState[question.id][0] })}
						/>
					</Fragment>
				);
			})}

			<div className="wrap-btn-next">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
};

export default Disc;
