import { connect } from 'react-redux';

import Stepper from '../../../components/Stepper';

import { setStepIndex } from '../../../store/actions/declaration_action';

const mapStateToProps = state => ({
	data: state.declaration.answers
});

const mapDispatchToProps = { getIndex: setStepIndex };

const StepperContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Stepper);

export default StepperContainer;
