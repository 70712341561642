import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const SelectForm = ({ item, change, answer }) => {
	return (
		<FormControl style={{ width: '100%' }}>
			<InputLabel htmlFor="age-simple">{item.palaceholder}</InputLabel>
			<Select
				value={answer[item.id]}
				onChange={e => change(e)}
				inputProps={{
					name: `${item.name}`,
					id: `${item.xmlId}`
				}}
			>
				{item.options &&
					item.options.map((option, i) => (
						<MenuItem
							key={i}
							value={option.value}
							name={option.title}
							data-xml={item.xmlId}
							data-id={item.id}
							data-name={item.name}
						>
							{option.title}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
};

export default SelectForm;
