import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const firstLevelQuestions = ['SalePro1A', 'SalePro1B', 'SalePro1C', 'SalePro2A', 'SalePro2B', 'SalePro2C', 'SalePro2D'];
const R0112G3 = ['SalePro1AA', 'SalePro2AA'];
const R0102G3 = ['SalePro1AA', 'SalePro1BA', 'SalePro1CA', 'SalePro2AA', 'SalePro2BA', 'SalePro2CA', 'SalePro2DA'];
const R020G3 = ['SalePro4AA'];

const initialAnswers = {
	SalePro1A: false,
	SalePro1AA: '', // R0112G3, R0102G3
	SalePro1B: false,
	SalePro1BA: '', //R0102G3
	SalePro1C: false,
	SalePro1CA: '', //R0102G3

	SalePro2A: false,
	SalePro2AA: '', // [R0102G3, R0112G3]
	SalePro2B: false,
	SalePro2BA: '', // R0102G3
	SalePro2C: false,
	SalePro2CA: '', // R0102G3
	SalePro2D: false,
	SalePro2DA: '', //R0102G3

	SalePro3A: '', //R0106G3
	SalePro3B: '', //R0106G3
	SalePro3C: '', //R0106G3
	SalePro3D: '', //R0106G3
	SalePro3E: '', //R021G3

	SalePro4А: '',
	SalePro4_g: null,
	SalePro4AA: '' //R020G3
};
const initialData = {
	R0106G3: {
		a: {
			SalePro3: [
				{
					country_name: '',

					currency_name: '',
					revenue: 0,
					currency_rate: '',
					currency_date: '',
					revenue_currency: 0,
					tax: 0
				}
			]
		},
		q: { SalePro3: null }
	},
	R0112G3: {
		a: {
			SalePro1AA: 0,
			SalePro2AA: 0
		},
		q: {
			SalePro1AA: null,
			SalePro2AA: null
		}
	},
	R0102G3: {
		a: {
			SalePro1AA: 0,
			SalePro1BA: 0,
			SalePro1CA: 0,
			SalePro2AA: 0,
			SalePro2BA: 0,
			SalePro2CA: 0,
			SalePro2DA: 0
		},
		q: {
			SalePro1AA: null,
			SalePro1BA: null,
			SalePro1CA: null,
			SalePro2AA: null,
			SalePro2BA: null,
			SalePro2CA: null,
			SalePro2DA: null
		}
	},
	R021G3: {
		a: { SalePro3E: 0 },
		q: { SalePro3E: null }
	},
	R020G3: 0
};

const SalePro = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, name, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (R0112G3.includes(id)) {
						newData.R0112G3.a[id] = value;
					}
					if (R0102G3.includes(id)) {
						newData.R0102G3.a[id] = value;
					}
					if (R020G3.includes(id)) {
						newData.R020G3 = value;
					}
					if (name === 'tax') {
						newData.R021G3.a[id] = value;
					}
					if (name === 'revenue') {
						newData.R0106G3.a.SalePro3[0][name] = value;
					}
					if (name === 'currency_date') {
						newData.R0106G3.a.SalePro3[0][name] = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;
					const children =
						labels[0].attributes['data-sibchildren'] && labels[0].attributes['data-sibchildren'].value;

					if (children) {
						let childs = children.split(',');
						childs.forEach(item => {
							newAnswers[item] = '';
							if (R0102G3.includes(item)) {
								newData.R0102G3['a'][item] = 0;
							}
							if (R0112G3.includes(item)) {
								newData.R0112G3['a'][item] = 0;
							}
						});
					}

					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];

					break;

				default:
					break;
			}
			save('salePro', { answers: newAnswers, data: newData });
			props.getSaleProData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);
	const handleChangeSelect = useCallback(
		({ target, currentTarget: { dataset } }) => {
			let newData = { ...data };
			let newAnswers = { ...answers };
			newAnswers[dataset.id] = target.value;
			newData.R0106G3.a.SalePro3[0][dataset.name] = target.value;
			setData(newData);
			setAnswers({ ...answers, ...newAnswers });
			props.getSaleProData(newData);
			save('salePro', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const handleChangeDate = useCallback(
		key => e => {
			let newData = { ...data };
			let newAnswers = { ...answers };

			const date = new Date(e._d);

			newAnswers[key] = date.getTime(); //! change answer
			newData.R0106G3.a[key.slice(0, -1)][0].currency_date = date.getTime(); //! change data

			setData(newData);
			setAnswers(newAnswers);
			props.getSaleProData(newData);
			save('salePro', { data: newData, answers: newAnswers });
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title || firstLevelQuestions.includes(item.id)) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			}
			if (answers.SalePro4_g === item.parent) {
				return (
					<Question
						key={i}
						item={item}
						state={{ data, answers }}
						change={handleChange}
						changeSelect={handleChangeSelect}
						handleChangeDate={handleChangeDate}
					/>
				);
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('salePro')) {
			const { data, answers } = load('salePro');
			setAnswers(answers);
			setData(data);
			props.getSaleProData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getSaleProData(data);
	return (
		<div className="IP-wrap">
			{renderQuestion}
			<div className="btn-IP-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default SalePro;
