import { R0106G3, R0109G3, R021G3 } from '../_xmlConstId';
import {
	D1,
	D1A,
	D1AA,
	D1B,
	D2,
	D2A,
	D2B,
	D2BA,
	D3,
	D3A,
	D3AA,
	D3AB,
	D3AC,
	D3AD,
	D3AE,
	D3B,
	D3BA,
	D3C,
	D3CA
} from '../_declaretion_answer_id';
import { B5D } from '../../_questions_OLD';
import { INPUT, CHECKBOX, SELECT, DATE } from '../../elementTypes';

import countries from '../../../data/countries.json';
import currency from '../../../data/currencies.json';

export const D = [
	{
		// MAIN QUESTION
		id: D1,
		title: 'Оберіть джерело, за яким Ви отримували проценти або дисконтний дохід',
		quizOpen: [B5D],
		question_title: true
	},
	{
		parent: D1,
		id: D1A,
		title:
			'у вигляді акцій (часток, паїв), емітованих юридичною особою – резидентом, з подальшим їх зарахуванням до статутного фонду емітента за умови, що таке нарахування жодним чином не змінює пропорцій (часток) участі всіх акціонерів (власників) у статутному фонді емітента',
		name: D1A,
		element: CHECKBOX,
		answers: [
			{
				id: D1AA,
				xmlId: R0109G3,
				title: 'Вкажіть номінальну вартість нарахованих дивідендів',
				name: D1AA,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: D1,
		id: D1B,
		title: 'у вигляді грошових коштів',
		name: D1B,
		element: CHECKBOX
	},

	{
		// MAIN QUESTION
		id: D2,
		title: 'Оберіть вид цінних паперів, дивіденди від яких Ви отримували',
		quizOpen: [B5D],
		question_title: true
	},

	{
		parent: D2,
		id: D2A,
		title: 'звичайні акції та/або інвестиційні сертифікати, корпоративні права ',
		name: D1A,
		element: CHECKBOX
	},
	{
		parent: D2,
		id: D2B,
		title:
			'акції або інші корпоративні права, що мають статус привілейованих та/або передбачають виплату фіксованого розміру дивідендів',
		name: D2B,
		element: CHECKBOX,
		answers: [
			{
				id: D2BA,
				xmlId: R0109G3,
				title: 'Вкажіть суму нарахованих дивідендів',
				name: D2BA,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},

	{
		// MAIN QUESTION
		id: D3,
		title: 'Оберіть тип підприємства, цінними паперами якого Ви володієте',
		quizOpen: [B5D],
		question_title: true
	},

	{
		parent: D3,
		id: D3A,
		title:
			'нерезидент України',
		name: D3A,
		helpers: [
			"Підказка 1. Ви можете зменшити річне податкового зобов'язання на суму податків, сплачених за кордоном згідно з нормами міжнародних договорів про уникнення подвійного оподаткування.",
			'Підказка 2. У разі можливості неоднозначного трактування норм ПКУ рекомендуємо звернутися за індивідуальною податковою консультацією до контролюючого органу',
			'Підказка 3. У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою)'
		],
		element: CHECKBOX,
		answers: [
			{
				parent: D3A,
				id: D3AA,
				title: 'Kраїнa',
				xmlId: R0106G3,
				name: 'country_name',
				element: SELECT,
				options: countries
			},
			{
				parent: D3,
				id: D3AB,
				title: 'Валюта',
				xmlId: R0106G3,
				name: 'currency_name',
				element: SELECT,
				options: currency
			},
			{
				parent: D3A,
				id: D3AC,
				title: 'Дата',
				xmlId: R0106G3,
				name: 'currency_date',
				element: DATE
			},
			{
				parent: D3A,
				id: D3AD,
				title:
					'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
				element: INPUT,
				name: 'revenue',
				placeholder: 'Введіть суму (грн., коп.)',
				xmlId: R0106G3
			},
			{
				parent: D3A,
				id: D3AE,
				title:
					'Вкажіть суму  сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
				element: INPUT,
				name: 'tax',
				placeholder: 'НАЛОГ',
				// placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R021G3
			}
		]
	},

	{
		parent: D3,
		id: D3B,
		title:
			'інститут спільного інвестування або суб\'єкти господарювання резиденти України, які не є платниками податку на прибуток підприємств',
		name: D3B,
		element: CHECKBOX,
		answers: [
			{
				id: D3BA,
				xmlId: R0109G3,
				title: 'Вкажіть номінальну вартість нарахованих дивідендів',
				name: D3BA,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		id: D3C,
		title:
			'резидент України – платник податку на прибуток підприємств',
		name: D3C,
		element: CHECKBOX,
		answers: [
			{
				id: D3CA,
				xmlId: R0109G3,
				title: 'Вкажіть номінальну вартість нарахованих дивідендів',
				name: D3CA,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	}
];
