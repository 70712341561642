import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import htmlGenerator from '../../../../utils/html/htmlGenerator';
import { report, data } from '../../../../data/mochData/Decl';

import './styles.scss';

const PreviewDeclaration = props => {
	const [tab, setTab] = useState(0);

	const calculateData = props.data[0] && {
		...props.dataCommon,
		...props.dataP,
		...props.dataQuiz,
		...props.dataPenalty,
		...props.data[0]
	};

	const getReport = () => {
		const Report = htmlGenerator
			.fetchDoc(report)
			// .setUserData({ ...data, ...props.dataQuiz, ...getDataFromSubSections() }, false)
			.setUserData({ ...data, ...calculateData }, false)
			.prepare()
			.open();

		return <Report />;
	};

	const getF0121210 = () => {
		const Report = htmlGenerator
			.fetchDoc('F0121210')
			// .setUserData({ ...data, ...props.dataQuiz, ...getDataFromSubSections() }, false)
			.setUserData({ ...props.data[1] }, false)
			.prepare()
			.open();

		return <Report />;
	};
	const getF0121410 = () => {
		const Report = htmlGenerator
			.fetchDoc('F0121410')
			// .setUserData({ ...data, ...props.dataQuiz, ...getDataFromSubSections() }, false)
			.setUserData({ ...props.data[2] }, false)
			.prepare()
			.open();

		return <Report />;
	};

	const handleChange = (event, tab) => setTab(tab);

	return (
		<div>
			<h1>Декларацiя</h1>
			<Tabs indicatorColor="primary" textColor="primary" value={tab} onChange={handleChange}>
				<Tab disableRipple label="F0100210" />
				{props.data[1] && <Tab disableRipple label="F0121210" />}
				{props.data[2] && <Tab disableRipple label="F0121410" />}
			</Tabs>

			{tab === 0 && getReport()}
			{props.data[1] ? tab === 1 && getF0121210() : null}
			{!props.data[1] && props.data[2] ? tab === 1 && getF0121410() : null}
			{props.data[1] && props.data[2] ? tab === 2 && getF0121410() : null}
		</div>
	);
};

export default PreviewDeclaration;
