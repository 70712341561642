import { connect } from 'react-redux';

import Lease from '../subsections/Lease';
import { getLeaseData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.LEASE.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getLeaseData
};

const LeaseContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Lease);

export default LeaseContainer;
