import React, { Fragment } from 'react';

import Step from './Step';

import './styles.scss';

const Stepper = props => {
	let steps = props.steps || [];
	return (
		<div className="stepper-block">
			{steps.map((item, i) => {
				return (
					<Fragment key={i}>
						<Step
							data={props.data || {}}
							index={i}
							step={item}
							activeIndex={props.activeIndex}
							getIndex={props.getIndex}
						/>
						{i < steps.length - 1 && <div className="horizontal-line" />}
					</Fragment>
				);
			})}
		</div>
	);
};

export default Stepper;
