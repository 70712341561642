import { precisionRound } from '../../index';

export const R01091G3 = function(formValues) {
	return Object.keys(formValues['R01091G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.reduce((acc, key) => {
			let curr = parseFloat(formValues['R01091G3']['a'][key]);
			return acc + curr;
		}, 0);
};

// Сума податку утриманого (сплаченого) податковим агентом
export const R01091G4 = function(formValues) {
	return Object.keys(formValues['R01091G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = parseFloat(formValues[`R01091G4_${key}`]);

			return acc + tax;
		}, 0);
};

// Сума збору утриманого (сплаченого) податковим агентом
export const R01091G5 = function(formValues) {
	return Object.keys(formValues['R01091G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = parseFloat(formValues[`R01091G5_${key}`]);

			return acc + tax;
		}, 0);
};

// Сума податку що підлягає сплаті самостійно
export const R01091G6_partials = function(formValues) {
	return Object.keys(formValues['R01091G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R01091G6_${key}_override`]) {
				tax = parseFloat(formValues[`R01091G6_${key}`]);
			} else {
				let curr = parseFloat(formValues['R01091G3']['a'][key]);

				tax += (curr * 18) / 100;
			}

			acc[`R01091G6_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума податку що підлягає сплаті самостійно
export const R01091G6 = function(formValues) {
	let partials = R01091G6_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};

// Сума збору що підлягає сплаті самостійно
export const R01091G7_partials = function(formValues) {
	return Object.keys(formValues['R01091G3']['a'])
		.filter(key => {
			return key !== '0';
		})
		.reduce((acc, key) => {
			let tax = 0;

			if (formValues[`R01091G7_${key}_override`]) {
				tax = parseFloat(formValues[`R01091G7_${key}`]);
			} else {
				let curr = parseFloat(formValues['R01091G3']['a'][key]);

				tax += (curr * 1.5) / 100;
			}

			acc[`R01091G7_${key}`] = precisionRound(tax, 2);

			return acc;
		}, {});
};

// Сума збору що підлягає сплаті самостійно
export const R01091G7 = function(formValues) {
	let partials = R01091G7_partials(formValues);

	return Object.keys(partials)
		.map(key => {
			return partials[key];
		})
		.reduce((acc, tax) => {
			return acc + tax;
		}, 0);
};
