import { declarationSubSections } from './declaration';

export const sectionsNav = [
	{
		id: 'B',
		name: 'quiz',
		title: 'Анкета',
		link: '/documents/quiz'
	},
	{
		id: 'docs',
		name: 'docs-list',
		title: 'Перелік документів',
		link: '/documents/docs-list'
	},
	{
		id: 'decl',
		name: 'declaration',
		title: 'Декларація',
		link: '/documents/declaration/common',
		has_sub_sections: true,
		sub_sections: [...declarationSubSections]
	}
];
