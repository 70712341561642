import {
	SalePro1,
	SalePro1A,
	SalePro1AA,
	SalePro1B,
	SalePro1BA,
	SalePro1CA,
	SalePro1C,
	SalePro2,
	SalePro2A,
	SalePro2AA,
	SalePro2B,
	SalePro2BA,
	SalePro2C,
	SalePro2CA,
	SalePro2D,
	SalePro2DA,
	SalePro3,
	SalePro3A,
	SalePro3B,
	SalePro3C,
	SalePro3D,
	SalePro3E,
	SalePro4,
	SalePro4А,
	SalePro4AA,
	SalePro4B
} from '../_declaretion_answer_id';
import {
	R0102G3,
	R0112G3,
	R0106G3,
	R020G3, //! LOOK
	R021G3 //! LOOK
} from '../_xmlConstId';
import { B6AA, B6AB, B1A, B1B } from '../../_questions_OLD';
import { INPUT, RADIO, CHECKBOX, SELECT, DATE } from '../../elementTypes';

import countries from '../../../data/countries.json';
import currency from '../../../data/currencies.json';

export const SalePro = [
	{
		id: SalePro1,
		title: 'Ви отримували доходи від продажу (обміну) об’єктів рухомого майна в Україні упродовж звітного року',
		name: SalePro1,
		quizOpen: [B6AA],
		question_title: true,
		ss: [
			'Підказка! Перевірте на сайті Міністерства економічного розвитку і торгівлі, чи вартість транспортного засобу, який Ви продали, є вищою (або рівною) середньоринкової (http://www.me.gov.ua/Vehicles/CalculatePrice?lang=uk-UA)'
		]
	},
	{
		parent: SalePro1,
		id: SalePro1A,
		title: 'не більше одного об’єкта рухомого майна у вигляді легкового автомобіля та/або мотоцикла та/або мопеда',
		name: SalePro1A,
		quizOpen: [B1A, B1B],
		element: CHECKBOX,
		answers: [
			{
				id: SalePro1AA,
				xmlId: [R0112G3, R0102G3],
				title:
					"Вкажіть дані (сума угоди) з договорів купівлі/продажу або міни об'єктів рухомого майна (коштовностей, картин, антикваріату тощо), які Ви укладали упродовж звітного року",
				name: SalePro1AA,
				element: INPUT,
				placeholder: 'Введіть суму'
			}
		]
	},
	{
		parent: SalePro1,
		id: SalePro1B,
		title: "двох або більше об'єктів рухомого майна у вигляді легкового автомобіля та/або мотоцикла та/або мопеда",
		name: SalePro1B,
		quizOpen: [B1A, B1B],
		element: CHECKBOX,
		answers: [
			{
				id: SalePro1BA,
				xmlId: R0102G3,
				title:
					"Вкажіть дані (сума угоди) з договорів купівлі/продажу або міни об'єктів рухомого майна (коштовностей, картин, антикваріату тощо), які Ви укладали упродовж звітного року.",
				name: SalePro1BA,
				element: INPUT,
				placeholder: 'Введіть суму'
			}
		]
	},
	{
		parent: SalePro1,
		id: SalePro1C,
		title:
			"інших об'єктів рухомого майна, зокрема предметів антикваріату або витворів мистецтва; природного дорогоцінного каміння чи дорогоцінних металів, прикрас з використанням дорогоцінних металів та/або природного дорогоцінного каміння; будь-якого приладдя для транспортних засобів; інші види рухомого майна",
		name: SalePro1C,
		quizOpen: [B1A, B1B],
		element: CHECKBOX,
		answers: [
			{
				id: SalePro1CA,
				xmlId: R0102G3,
				title:
					"Вкажіть дані (сума угоди) з договорів купівлі/продажу або міни об'єктів рухомого майна (коштовностей, картин, антикваріату тощо), які Ви укладали упродовж звітного року",
				name: SalePro1CA,
				element: INPUT,
				placeholder: 'Введіть суму'
			}
		]
	},
	{
		id: SalePro2,
		title:
			'Ви отримували доходи від продажу (обміну) нерухомого майна та об’єктів незавершеного будівництва в Україні упродовж звітного року',
		name: SalePro2,
		quizOpen: [B6AA],
		question_title: true,
		helpers: [
			"Підказка 1. Об’єкти нерухомого майна – житловий будинок, квартира або їх частина, кімната, садовий (дачний) будинок (включаючи земельну ділянку, на якій розташовані такі об'єкти, а також господарсько-побутові споруди та будівлі, розташовані на такій земельній ділянці), а також земельні ділянки, що не перевищують норми безоплатної передачі, визначені статтею 121 Земельного кодексу України залежно від її призначення.",
			'Підказка 2. Норми безоплатної передачі земельних ділянок громадянам (ст. 121 Земельного кодексу України) Громадяни України мають право на безоплатну передачу їм земельних ділянок із земель державної або комунальної власності в таких розмірах: для ведення фермерського господарства - в розмірі земельної частки (паю), визначеної для членів сільськогосподарських підприємств, розташованих на території сільської, селищної, міської ради, де знаходиться фермерське господарство; для ведення особистого селянського господарства – не більше 2,0 гектара;  для ведення садівництва – не більше 0,12 гектара;  для будівництва і обслуговування житлового будинку, господарських будівель і споруд (присадибна ділянка) у селах – не більше 0,25 гектара, у селищах – не більше 0,15 гектара, у містах – не більше 0,10 гектара; для індивідуального дачного будівництва – не більше 0,10 гектара; для будівництва індивідуальних гаражів – не більше 0,01 гектара.'
		]
	},
	{
		parent: SalePro2,
		id: SalePro2A,
		title:
			'нерухомого майна, яке продавалось вперше упродовж звітного року та перебувало у власності більше трьох років та/або було успадковане',
		name: SalePro2A,
		quizOpen: [B1A, B1B],
		element: CHECKBOX,
		answers: [
			{
				id: SalePro2AA,
				xmlId: [R0102G3, R0112G3],
				title:
					'Вкажіть дані (сума угоди) з договорів купівлі/продажу або міни нерухомого майна, які Ви укладали протягом звітного року',
				name: SalePro2AA,
				element: INPUT,
				placeholder: 'Введіть суму'
			}
		]
	},
	{
		parent: SalePro2,
		id: SalePro2B,
		title: 'нерухомого майна (крім успадкованого), яке перебувало у власності менше трьох років',
		name: SalePro2B,
		quizOpen: [B1A, B1B],
		element: CHECKBOX,
		answers: [
			{
				id: SalePro2BA,
				xmlId: R0102G3,
				title:
					'Вкажіть дані (сума угоди) з договорів купівлі/продажу або міни нерухомого майна, які Ви укладали протягом звітного року',
				name: SalePro2BA,
				element: INPUT,
				placeholder: 'Введіть суму'
			}
		]
	},
	{
		parent: SalePro2,
		id: SalePro2C,
		title: 'більше ніж від одного об’єкта нерухомості, яке перебувало у Вашій власності понад три роки',
		name: SalePro2C,
		quizOpen: [B1A, B1B],
		element: CHECKBOX,
		answers: [
			{
				id: SalePro2CA,
				xmlId: R0102G3,
				title:
					'Вкажіть дані (сума угоди) з договорів купівлі/продажу або міни нерухомого майна, які Ви укладали протягом звітного року',
				name: SalePro2CA,
				element: INPUT,
				placeholder: 'Введіть суму'
			}
		]
	},
	{
		parent: SalePro2,
		id: SalePro2D,
		title: 'об’єктів незавершеного будівництва',
		name: SalePro2D,
		quizOpen: [B1A, B1B],
		element: CHECKBOX,
		answers: [
			{
				id: SalePro2DA,
				xmlId: R0102G3,
				title:
					'Вкажіть дані (сума угоди) з договорів купівлі/продажу або міни нерухомого майна, які Ви укладали протягом звітного року',
				name: SalePro2DA,
				element: INPUT,
				placeholder: 'Введіть суму'
			}
		]
	},
	{
		id: SalePro3,
		title:
			'Вкажіть обсяг доходів отриманих, від продажу (обміну) об’єктів рухомого/нерухомого майна за межами Україні, перерахований у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
		name: SalePro3,
		quizOpen: [B6AB],
		question_title: true,
		answers: [
			{
				parent: SalePro3,
				id: SalePro3A,
				title: 'Kраїнa',
				xmlId: R0106G3,
				name: 'country_name',
				element: SELECT,
				options: countries
			},
			{
				parent: SalePro3,
				id: SalePro3B,
				title: 'Валюта',
				xmlId: R0106G3,
				name: 'currency_name',
				element: SELECT,
				options: currency
			},
			{
				parent: SalePro3,
				id: SalePro3C,
				title: 'Дата',
				xmlId: R0106G3,
				name: 'currency_date',
				element: DATE
			},
			{
				parent: SalePro3,
				id: SalePro3D,
				title:
					'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
				element: INPUT,
				name: 'revenue',
				placeholder: 'Введіть суму (грн., коп.)',
				xmlId: R0106G3
			},
			{
				parent: SalePro3,
				id: SalePro3E,
				// title:
				// 	'Вкажіть суму  сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
				element: INPUT,
				name: 'tax',
				placeholder: 'НАЛОГ',
				// placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R021G3
			}
			// {
			// 	id: SalePro3A,
			// 	xmlId: R0106G3,
			// 	title: 'Оберіть країну-походження доходів та її валюту',
			// 	name: SalePro3A,
			// 	element: SELECT,
			// 	placeholder: 'Країна'
			// },
			// {
			// 	id: SalePro3B,
			// 	xmlId: R0106G3,
			// 	// title: 'Оберіть країну-походження доходів та її валюту',
			// 	name: SalePro3B,
			// 	element: SELECT,
			// 	placeholder: 'Валюта'
			// },
			// {
			// 	id: SalePro3C,
			// 	xmlId: R0102G3, //! LOOK
			// 	title:
			// 		'Вкажіть суму сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
			// 	name: SalePro3C,
			// 	element: INPUT,
			// 	placeholder: 'Введіть суму'
			// }
		]
	},
	{
		id: SalePro4,
		title:
			"Чи мала місце сплата ПДФО з доходів від купівлі-продажу, міни об'єкта нерухомості, а нотаріальна дії щодо посвідчення відповідних договорів не відбулася?",
		quizOpen: [B6AA],
		question_title: true,
		answers: [
			{
				id: SalePro4А,
				title: 'Так',
				element: RADIO,
				name: 'SalePro4_g'
			},
			{
				id: SalePro4B,
				title: 'Ні',
				element: RADIO,
				name: 'SalePro4_g'
			}
		]
	},
	{
		parent: SalePro4А,
		id: SalePro4AA,
		xmlId: R020G3,
		title: 'Вкажіть суму сплаченого податку',
		name: SalePro4AA,
		element: INPUT,
		placeholder: 'Введіть суму'
	}
];
