import React from 'react';

import RadioInput from '../RadioInput';

const RadioInputField = ({ field: { value, onChange, onBlur, name }, id, ...props }) => {
	return (
		<RadioInput
			name={name}
			id={id}
			value={id}
			checked={id === value}
			onChange={onChange}
			onBlur={onBlur}
			{...props}
		/>
	);
};

export default RadioInputField;
