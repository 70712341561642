import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { calculateDeclaration } from '../../../../store/actions/declaration_action';

import { StyledButton as Button } from '../../../../components/Button/Button';

import './style.scss';

const mapDispatchToProps = { calculateDeclaration };

const ButtonCreateDeclaration = ({ calculateDeclaration }) => {
	return (
		<Link to="/documents/declaration/preview" onClick={calculateDeclaration}>
			<Button className="create">Сформувати декларацію</Button>
		</Link>
	);
};

const ButtonCreateDeclarationContainer = connect(
	null,
	mapDispatchToProps
)(ButtonCreateDeclaration);

export default ButtonCreateDeclarationContainer;
