import React, { Fragment } from 'react';

import { Field } from 'formik';

import NewStepperCheckBox from '../NewStepperCheckBox';
// import NewStepperCheckboxGroup from '../NewStepperCheckboxGroup'; // FIXME: leave if need checkox group
import RadioGroup from '../RadioGroup';
import RadioInputField from '../RadioInputField';

const NewStepperCheckBoxSubField = ({
	field: { name, value, onChange, onBlur },
	id,
	question,
	stepperCurrent,
	values,
	errors,
	// setFieldValue,
	// setFieldTouched, FIXME: leave if need checkox group
	...props
}) => {
	return (
		<Fragment>
			<NewStepperCheckBox
				name={name}
				id={id}
				value={name}
				checked={value}
				onChange={onChange}
				onBlur={onBlur}
				{...props}
			/>
			{values[question.parent].includes(question.subInSub ? question.subInSub : question.name) && (
				<RadioGroup
					classNameTitle="question-title sub-title"
					id={question.name}
					label={question.title}
					value={values[question.name]}
					error={errors[question.name]}
					touched={stepperCurrent.isTouched}
				>
					{/* <NewStepperCheckboxGroup
					classNameTitle="question-title sub-title"
					id={question.name}
					label={question.title}
					value={values[question.name]}
					error={errors[question.name]}
					touched={stepperCurrent.isTouched}
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					FIXME: if need checkboxses is sub_sub leave
				> */}
					{question.answers.map(answer => {
						return (
							<Field
								key={answer.code}
								component={RadioInputField}
								name={question.name}
								id={answer.code}
								label={answer.text}
								answer={answer}
							/>
						);
					})}
				</RadioGroup>
			)}
		</Fragment>
	);
};

export default NewStepperCheckBoxSubField;
