import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const firstLevelQuestions = [
	'AddB1',
	'AddB2',
	'AddB3',
	'AddB4',
	'AddB5',
	'AddB6',
	'AddB7',
	'AddB8',
	'AddB9',
	'AddB10',
	'AddB11',
	'AddB12',
	'AddB13',
	'AddB14'
];
const R0113G3 = [
	'AddB2A',
	'AddB5A',
	'AddB6A',
	'AddB7A',
	'AddB8A',
	'AddB10A',
	'AddB11A',
	'AddB12A',
	'AddB12B',
	'AddB13A',
	'AddB14A'
];
const R0109G3 = ['AddB1A', 'AddB3A', 'AddB4A', 'AddB5A', 'AddB9A', 'AddB12A', 'AddB12B', 'AddB14A'];

const initialAnswers = {
	AddB1: false,
	AddB1A: '', // R0109G3
	AddB2: false,
	AddB2A: '', //! R0111G3
	AddB3: false,
	AddB3A: '', // R0109G3
	AddB4: false,
	AddB4A: '', // R0109G3
	AddB5: false,
	AddB5A: '', // R0109G3 R0111G3 // самостоятельно
	AddB6: false,
	AddB6A: '', //! R0111G3
	AddB7: false,
	AddB7A: '', //! R0111G3
	AddB8: false,
	AddB8A: '', //! R0111G3
	AddB9: false,
	AddB9A: '', // R0109G3
	AddB10: false,
	AddB10A: '', //! R0111G3
	AddB11: false,
	AddB11A: '', //! R0111G3
	AddB12: false,
	AddB12A: '', // R0109G3 R0111G3
	AddB12B: '', // R0109G3 R0111G3
	AddB13: false,
	AddB13A: '', //! R0111G3
	AddB14: false,
	AddB14A: '' // R0109G3 R0111G3
};
const initialData = {
	R0109G3: {
		a: {
			AddB1A: 0,
			AddB3A: 0,
			AddB4A: 0,
			AddB5A: 0,
			AddB9A: 0,
			AddB12A: 0,
			AddB12B: 0,
			AddB14A: 0
		},
		q: {
			AddB1A: null,
			AddB3A: null,
			AddB4A: null,
			AddB5A: null,
			AddB9A: null,
			AddB12A: null,
			AddB14A: null
		}
	},
	R0113G3: {
		a: {
			AddB2A: 0,
			AddB5A: 0,
			AddB6A: 0,
			AddB7A: 0,
			AddB8A: 0,
			AddB10A: 0,
			AddB11A: 0,
			AddB12A: 0,
			AddB12B: 0,
			AddB13A: 0,
			AddB14A: 0
		},
		q: {
			AddB2A: null,
			AddB5A: null,
			AddB6A: null,
			AddB7A: null,
			AddB8A: null,
			AddB10A: null,
			AddB11A: null,
			AddB12A: null,
			AddB13A: null,
			AddB14A: null
		}
	}
};

const AddB = React.memo(props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	const handleChange = useCallback(
		({ currentTarget }) => {
			const { value, id, type, labels } = currentTarget;
			let newData = { ...data };
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					if (R0109G3.includes(id)) {
						newData.R0109G3.a[id] = value;
					}
					if (R0113G3.includes(id)) {
						newData.R0113G3.a[id] = value;
					}

					newAnswers[`${id}`] = value;
					break;

				case 'radio': {
					const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
					newAnswers[groupName] = value;

					break;
				}
				case 'checkbox':
					newAnswers[value] = !newAnswers[value];

					if (!newAnswers[value]) {
						newAnswers[`${value}A`] = '';
						if (['AddB5', 'AddB12', 'AddB14'].includes(value)) {
							newData.R0109G3.a[`${id}A`] = 0;
							newData.R0113G3.a[`${id}A`] = 0;

							if (value === 'AddB12') {
								newData.R0109G3.a[`${id}B`] = 0;
								newData.R0113G3.a[`${id}B`] = 0;
								newAnswers[`${value}B`] = '';
							}

							break;
						}
						newData[id]['a'][`${value}A`] = 0;
					}

					break;

				default:
					break;
			}
			save('addb', { answers: newAnswers, data: newData });
			props.getAddBData(newData);
			setData(newData);
			setAnswers(newAnswers);
		},
		[answers, setAnswers, data, setData]
	);

	const renderQuestion = useMemo(() => {
		return Object.values(props.subsection).map((item, i) => {
			if (item.question_title || firstLevelQuestions.includes(item.id)) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			}
			if (
				answers.IP1AA_g === item.parent ||
				item.parent === answers.IP1BA_g ||
				item.parent === answers.IP1DA_g ||
				item.parent === answers.IP1NA_g
			) {
				return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
			} else {
				return null;
			}
		});
	}, [answers, setAnswers, data, setData]);

	useEffect(() => {
		if (load('addb')) {
			const { data, answers } = load('addb');
			setAnswers(answers);
			setData(data);
			props.getAddBData(data);
		}
	}, []);

	const handleClickNextButton = () => props.getAddBData(data);
	return (
		<div className="AddB-wrap">
			{renderQuestion}
			<div className="btn-AddB-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
});

export default AddB;
