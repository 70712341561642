import React, { Component } from 'react';

export default class htmlData210 extends Component {
    constructor(props) {
        super(props);
        this.userData = this.props.userData;
        this.partial = this.props.partial&&this.props.partial===true;
    }

    render () {
        return (
            <div className="chat-container doc-preview">

            <table width="100%" >
                <tbody>
                <tr>
                    <td className="main_wrap"  >
                        <div className="item" >F0100210</div>
                    </td>
                </tr>
                </tbody>
            </table>

            <table width="100%" className=" main_wrap text_center">
                <tbody>
                <tr>
                    <td width="100%" style={{padding: 0}} >
                        <table width="100%" className="main_wrap text_center table_border_none">
                            <tbody>
                            <tr>
                                <td width="37%" className="border_1" >&nbsp;<br />Відмітка про одержання<br />(штамп
                                    контролюючого органу, дата, вхідний номер)<br />&nbsp;</td>
                                <td width="28%" ></td>
                                <td width="35%" rowSpan="2" className={'text_left'} >ЗАТВЕРДЖЕНО<br />Наказ
                                    Міністерства фінансів України<br />02 жовтня 2015 року № 859<br />(у редакції наказу
                                        Міністерства фінансів України<br />від 06.06.2017 року № 556)</td>
                            </tr>
                            <tr>
                                <td >&nbsp;</td>
                                <td >&nbsp;</td>
                            </tr>
                            <tr>
                                <td colSpan="3" ><h3 >ПОДАТКОВА ДЕКЛАРАЦІЯ<br />ПРО
                                    МАЙНОВИЙ СТАН І ДОХОДИ</h3></td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width="100%" className="border_1 main_wrap text_center">
                <tbody>
                <tr>
                    <td width="100%" colSpan="4" ><b >І. ЗАГАЛЬНІ ВІДОМОСТІ</b></td>
                </tr>
                <tr>
                    <td width="3%">1</td>
                    <td width="37%">
                        <table width="100%" cellSpacing="2" className="main_wrap text_center table_border_none"
                               style={{borderCollapse: 'separate'}}>
                            <tbody>
                            <tr>
                                <td width="100%" colSpan="6"  >Тип декларації:</td>
                            </tr>
                            <tr>
                                <td width="8%" className="border" >
                                    <div className="item input_item">{this.cell('HZ')}
                                    </div>
                                </td>
                                <td width="18%"  >Звітна</td>
                                <td width="8%" className="border">
                                    <div className="item input_item">{this.cell('HZN')}
                                    </div>
                                </td>
                                <td width="29%" >Звітна нова</td>
                                <td width="8%" className="border">
                                    <div className="item input_item">{this.cell('HZU')}
                                    </div>
                                </td>
                                <td width="29%"  >Уточнююча</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td width="3%" >2</td>
                    <td width="57%" >
                        <table width="100%" cellSpacing="2" className="main_wrap text_center table_border_none"
                               style={{borderCollapse: 'separate'}} >
                            <tbody>
                            <tr>
                                <td width="45%" colSpan="4"  >Звітний (податковий) період:
                                </td>
                                <td width="55%" colSpan="4" >Звітний (податковий) період, що уточнюється:
                                </td>
                            </tr>
                            <tr>
                                <td width="10%" className="border" >
                                    <div className="item">{this.cell('HZY')}</div>
                                </td>
                                <td width="10%" >рік</td>
                                <td width="5%" className="border" >&nbsp;</td>
                                <td width="20%"  >квартал<sup>1</sup></td>
                                <td width="10%" className="border">
                                    <div className="item">{this.cell('HZP')}</div>
                                </td>
                                <td width="10%" >рік</td>
                                <td width="5%" className="border">&nbsp;</td>
                                <td width="30%" >квартал<sup>1</sup></td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width="100%" className="border_1 main_wrap text_center">
                <tbody>
                <tr>
                    <td width="100%" colSpan="2" ><b >Інформація про платника податку</b></td>
                </tr>
                <tr>
                    <td width="3%">3</td>
                    <td width="97%" style={{padding: '0'}}>
                        <table width="100%" className="main_wrap text_center table_border_none">
                            <tbody>
                            <tr>
                                <td width="100%" colSpan="3" style={{padding: '5px'}} >
                                    <table width="100%" className="main_wrap text_center" cellSpacing="3">
                                        <tbody>
                                        <tr>
                                            <td width="33%" className={'text_left'}><b >Прізвище, ім'я, по батькові
                                                платника податку:</b></td>
                                            <td width="67%" colSpan="2" className="border_bottom"><b>
                                                <div className="item" >{this.cell('HNAME')}</div>
                                            </b></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td width="70%"   className={'text_left'}>Реєстраційний номер облікової картки
                                    платника податку
                                </td>
                                <td width="29%" className="border">
                                    <div className="item">{this.cell('HTIN')}</div>
                                </td>
                                <td width="1%"></td>
                            </tr>
                            <tr>
                                <td colSpan="2"   className={'text_left'}>або серія та/або номер паспорта (для
                                    фізичних осіб, які через свої релігійні переконання відмовляються від прийняття
                                    реєстраційного<br />номера облікової картки платника податків та офіційно повідомили
                                        про це відповідний контролюючий орган і мають відмітку у паспорті)</td>
                                <td width="2%"></td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width="100%" className="border_1 main_wrap text_center">
                <tbody>
                <tr>
                    <td width="3%" >4</td>
                    <td width="57%" style={{padding: '0'}}>
                        <table width="100%" className="main_wrap table_border_none">
                            <tbody>
                            <tr>
                                <td width="98%" colSpan="6"   className={'text_left'}><b >Податкова
                                    адреса (місце проживання) платника податку:</b></td>
                                <td width="2%"></td>
                            </tr>
                            <tr>
                                <td width="26%"   className={'text_left'}>Область:</td>
                                <td width="72%" colSpan="5" className="border_bottom">
                                    <div className="item">{this.cell('HREG')}</div>
                                </td>
                                <td ></td>
                            </tr>
                            <tr>
                                <td   className={'text_left'}>Район:</td>
                                <td colSpan="5" className="border_bottom" >
                                    <div className="item">{this.cell('HRAJ')}</div>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className={'text_left'}>Місто (селище, село):</td>
                                <td colSpan="5" className="border_bottom" >
                                    <div className="item">{this.cell('HCITY')}</div>
                                </td>
                                <td ></td>
                            </tr>
                            <tr>
                                <td className={'text_left'}>Вулиця:</td>
                                <td colSpan="5" className="border_bottom">
                                    <div className="item">{this.cell('HSTREET')}</div>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td width="26%"  >Номер будинку:</td>
                                <td width="10%">
                                    <div className="item">{this.cell('HBUILD')}
                                    </div>
                                </td>
                                <td width="15%" >Корпус:</td>
                                <td width="10%">
                                    <div className="item">{this.cell('HCORP')}</div>
                                </td>
                                <td width="27%" >Номер квартири:</td>
                                <td width="10%">
                                    <div className="item">{this.cell('HAPT')}</div>
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td width="40%" style={{padding: '0'}}>
                        <table width="100%" className="main_wrap text_center table_border_none">
                            <tbody>
                            <tr>
                                <td width="37%" className={'text_left'} ><b>Поштовий індекс:</b></td>
                                <td width="60%" className="border_bottom">
                                    <div className="item">{this.cell('HZIP')}</div>
                                </td>
                                <td width="3%"></td>
                            </tr>
                            <tr>
                                <td   className={'text_left'}>Контактні телефони:<sup>2</sup></td>
                                <td className="border_bottom">
                                    <div className="item">{this.cell('HTEL')}</div>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td   className={'text_left'}>Електронна адреса:<sup>2</sup></td>
                                <td className="border_bottom">
                                    <div className="item">{this.cell('HEMAIL')}</div>
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width="100%" className="border_1 main_wrap text_center">
                <tbody>
                <tr>
                    <td width="3%">5</td>
                    <td width="97%">
                        <table width="100%" className="main_wrap text_center table_border_none">
                            <tbody>
                            <tr>
                                <td width="49%" className={'text_left'}><b >Найменування контролюючого органу, до
                                    якого подається декларація:</b></td>
                                <td width="51%">
                                    <div className="item">{this.cell('HSTI')}</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width="100%" className="border_1 main_wrap text_center">
                <tbody>
                <tr>
                    <td width="3%" className={'text_center'}>6</td>
                    <td width="37%" >
                        <table width="100%" cellSpacing="2" className="main_wrap table_border_none"
                               style={{borderCollapse: 'separate'}} >
                            <tbody>
                            <tr>
                                <td width="100%" colSpan="6" ><b >Резидентський статус
                                    платника податку:</b></td>
                            </tr>
                            <tr>
                                <td width="8%" className="border" >
                                    <div className="item input_item">{this.cell('H01')}</div>
                                </td>
                                <td width="42%"  >резидент</td>
                                <td width="8%" className="border" >
                                    <div className="item input_item" >{this.cell('H02')}</div>
                                </td>
                                <td width="42%"  >нерезидент</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                    <td width="3%"  className={'text_center'}>7</td>
                    <td width="57%">
                        <table width="100%" cellSpacing="2" className="main_wrap table_border_none"
                               style={{borderCollapse: 'separate'}}>
                            <tbody>
                            <tr>
                                <td width="100%" colSpan="6" ><b>Декларація заповнена:</b></td>
                            </tr>
                            <tr>
                                <td width="5%" className="border">
                                    <div className="item input_item">{this.cell('H03')}
                                    </div>
                                </td>
                                <td width="45%" >самостійно платником податку</td>
                                <td width="5%" className="border">
                                    <div className="item input_item">{this.cell('H04')}
                                    </div>
                                </td>
                                <td width="45%" >уповноваженою на це особою</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width="100%" className="border_1 main_wrap">
                <tbody>
                <tr>
                    <td width="3%"  className={'text_center'}>8</td>
                    <td width="97%"  style={{padding: 0}}>
                        <table width="100%" cellSpacing="0" className="main_wrap"
                                >
                            <tbody>
                            <tr>
                                <td width="21%" ><b >Категорія
                                    платника:</b></td>
                                <td width="3%" className="border" >
                                    <div className="item input_item">{this.cell('H05')}
                                    </div>
                                </td>
                                <td width="15%"  >громадянин</td>
                                <td width="3%" className="border" >
                                    <div className="item input_item">{this.cell('H06')}
                                    </div>
                                </td>
                                <td width="23%"  >особа, яка провадить незалежну професійну діяльність</td>
                                <td width="3%" >&nbsp;</td>
                                <td width="14%"  >підприємець</td>
                                <td width="3%" className="border">&nbsp;</td>
                                <td width="36%" >особа, яка припинила підприємницьку діяльність протягом звітного року<sup>3</sup> датою</td>
                                <td width="10%" className="border" >&nbsp;</td>

                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width="100%" className="border_1 main_wrap ">
                <tbody>
                <tr>
                    <td width="100%" colSpan="2"  className={'text_center'}><b >Інформація про особу, уповноважену
                        на заповнення декларації</b></td>
                </tr>
                <tr>
                    <td width="3%" className={'text_center'}>9</td>
                    <td width="97%" style={{padding: '0'}}>
                        <table width="100%" className="main_wrap table_border_none">
                            <tbody>
                            <tr>
                                <td width="100%" colSpan="3" style={{padding: '5px'}} >
                                    <table width="100%" className="main_wrap text_center" cellSpacing="3"
                                           >
                                        <tbody>
                                        <tr>
                                            <td width="36%"  ><b >Прізвище,
                                                ім'я, по батькові уповноваженої особи:</b></td>
                                            <td width="64%" colSpan="2" className="border_bottom" >
                                                <div className="item" >{this.cell('HNAMEAG')}</div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td width="70%"  >Реєстраційний номер облікової картки
                                    платника податку уповноваженої особи
                                </td>
                                <td width="29%" className="border">
                                    <div className="item">{this.cell('HTINAG')}</div>
                                </td>
                                <td width="1%"></td>
                            </tr>
                            <tr>
                                <td colSpan="2"  >або серія та/або номер паспорта (для
                                    фізичних осіб, які через свої релігійні переконання відмовляються від прийняття
                                    реєстраційного<br />номера облікової картки платника податків та офіційно повідомили
                                        про це відповідний контролюючий орган і мають відмітку у паспорті)</td>
                                <td width="2%"></td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width="100%" className="border_1 main_wrap ">
                <tbody>
                <tr>
                    <td width="5%" rowSpan="3" className={'text_center'}>Код рядка</td>
                    <td width="45%" rowSpan="3"  className={'text_center'}><b >ІІ. ДОХОДИ, ЯКІ ВКЛЮЧАЮТЬСЯ ДО
                        ЗАГАЛЬНОГО РІЧНОГО ОПОДАТКОВУВАНОГО ДОХОДУ</b></td>
                    <td width="9%" rowSpan="3" className={'text_center'}>Сума доходів<br />(грн, коп.)</td>
                    <td width="41%" colSpan="4" className={'text_center'}>Сума податку/збору (грн, коп.)</td>
                </tr>
                <tr>
                    <td width="16%" colSpan="2" className={'text_center'}>утриманого (сплаченого)<br />податковим агентом</td>
                    <td width="16%" colSpan="2" className={'text_center'}>що підлягає сплаті самостійно</td>
                </tr>
                <tr>
                    <td width="9%" className={'text_center'}>податок на доходи фізичних осіб</td>
                    <td width="7%" className={'text_center'}>військовий збір</td>
                    <td width="9%" className={'text_center'}>податок на доходи фізичних осіб</td>
                    <td width="7%" className={'text_center'}>військовий збір</td>
                </tr>
                <tr>
                    <td  className={'text_center'}>1</td>
                    <td className={'text_center'}>2</td>
                    <td className={'text_center'}>3</td>
                    <td className={'text_center'}>4</td>
                    <td className={'text_center'}>5</td>
                    <td className={'text_center'}>6</td>
                    <td className={'text_center'}>7</td>
                </tr>
                <tr>
                    <td  className={'text_center'}>10</td>
                    <td  ><b >Загальна сума доходів, які включаються до
                        загального річного оподатковуваного доходу, в тому числі <i>(рядок 10.1 + рядок 10.2 + рядок
                            10.3 + рядок 10.4 + рядок 10.5 + рядок 10.6 + рядок 10.7 + рядок 10.8 + рядок 10.9):</i></b>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R010G3')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R010G4')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R010G5')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R010G6')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R010G7')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>10.1</td>
                    <td  >Дохід, нарахований (виплачений, наданий) у формі заробітної плати,
                        інших заохочуваль- них та компенсаційних виплат або інших виплат і винагород, які нараховані
                        (виплачені, надані) у зв'язку з трудовими відносинами та за цивільно-правовими договорами
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0101G3')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0101G4')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0101G5')}</div>
                    </td>
                    <td className={'text_center'}>X</td>
                    <td className={'text_center'}>X</td>
                </tr>
                <tr>
                    <td className={'text_center'}>10.2</td>
                    <td  >Доходи від операцій з продажу (обміну) об'єктів рухомого та/або
                        нерухомого майна
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0102G3')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0102G4')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0102G5')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0102G6')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0102G7')}</div>
                    </td>
                </tr>
                <tr >
                    <td className={'text_center'}>10.3</td>
                    <td  >Дохід від надання майна в лізинг, оренду (суборенду), житловий
                        найм (піднайм)<sup>4</sup></td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0103G3')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0103G4')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0103G5')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0103G6')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0103G7')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>10.4</td>
                    <td >Інвестиційний прибуток (додаток Ф1)</td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0104G3')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0104G4')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0104G5')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0104G6')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0104G7')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>10.5</td>
                    <td >Вартість успадкованого чи отриманого у дарунок майна</td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0105G3')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0105G4')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0105G5')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0105G6')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0105G7')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>10.6</td>
                    <td >Доходи, отримані з джерел за межами України <sup>5</sup>(<div className="item" style={{display: 'inline-block'}}>{this.cell('R0106G2S')}</div>)*</td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0106G3')}</div>
                    </td>
                    <td className={'text_center'}>X</td>
                    <td className={'text_center'}>X</td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0106G6')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0106G7')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>10.7</td>
                    <td  >Чистий оподатковуваний дохід, отриманий фізичною особою –
                        підприємцем від провадження господарської діяльності, крім осіб, що обрали спрощену систему
                        оподаткування (додаток Ф2)
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0107G3')}</div>
                    </td>
                    <td className={'text_center'}>X</td>
                    <td className={'text_center'}>X</td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0107G6')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0107G7')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>10.8</td>
                    <td  >Чистий оподатковуваний дохід, отриманий фізичною особою, яка
                        провадить незалежну професійну діяльність (додаток Ф2)
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0108G3')}</div>
                    </td>
                    <td className={'text_center'}>X</td>
                    <td className={'text_center'}>X</td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0108G6')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0108G7')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>10.9</td>
                    <td >Інші доходи, у т.ч.</td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0109G3')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0109G4')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0109G5')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0109G6')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0109G7')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>10.9.1</td>
                    <td  >у вигляді додаткового блага (прощений (анульований) борг за
                        кредитом, що отриманий на придбання житла (іпотечний кредит))
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R01091G3')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R01091G4')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R01091G5')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R01091G6')}</div>
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R01091G7')}</div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="7"  >&nbsp;&nbsp;&nbsp;&nbsp;* Назва країни, з якої
                        отримано іноземні доходи, та назва валюти.
                    </td>
                </tr>
                </tbody>
            </table>
            <table width="100%" className="border_1 main_wrap">
                <tbody>
                <tr>
                    <td width="5%" className={'text_center'}>Код рядка</td>
                    <td width="84%" className={'text_center'}><b >ІІІ. ДОХОДИ, ЯКІ НЕ ВКЛЮЧАЮТЬСЯ ДО ЗАГАЛЬНОГО РІЧНОГО
                        ОПОДАТКОВУВАНОГО ДОХОДУ</b></td>
                    <td width="11%" className={'text_center'}>Сума доходів (грн, коп.)</td>
                </tr>
                <tr>
                    <td className={'text_center'}>1</td>
                    <td  className={'text_center'}>2</td>
                    <td className={'text_center'}>3</td>
                </tr>
                <tr>
                    <td className={'text_center'}>11</td>
                    <td ><b>Доходи, які не включаються до розрахунку загального річного оподатковуваного
                        доходу, у тому числі: <i>(рядок 11.1 + рядок 11.2 + рядок 11.3)</i></b></td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R011G3')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>11.1</td>
                    <td >Доходи, отримані від провадження господарської діяльності за спрощеною системою
                        оподаткування протягом звітного (податкового) року
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0111G3')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>11.2</td>
                    <td >Доходи від операцій з продажу (обміну) об'єктів рухомого та/або нерухомого майна
                    </td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0112G3')}</div>
                    </td>
                </tr>
                <tr>
                    <td className={'text_center'}>11.3</td>
                    <td >Інші доходи, що не підлягають оподаткуванню</td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R0113G3')}</div>
                    </td>
                </tr>
                </tbody>
            </table>

            <table width="100%" className="border_1 main_wrap ">
                <tbody>
                <tr>
                    <td width="5%" className={'text_center'}>Код рядка</td>
                    <td width="84%" className={'text_center'}><b>ІV. ЗАГАЛЬНА СУМА РІЧНОГО ДОХОДУ</b></td>
                    <td width="11%" className={'text_center'}>Сума доходів (грн, коп.)</td>
                </tr>
                <tr>
                    <td className={'text_center'}>12</td>
                    <td ><b >Загальна сума річного доходу (рядок 10.1 + рядок 10.2 + рядок
                        10.3 + графа 6 "УСЬОГО" рядка 1 додатка Ф1 + рядок 10.5 + рядок 10.6 + "УСЬОГО" графи 9 розділу
                        І додатка Ф2 + "УСЬОГО" графи 9 розділу ІV додатка Ф2 + рядок 10.9 + рядок 11)</b></td>
                    <td className={'text_center'}>
                        <div className="item">{this.cell('R012G3')}</div>
                    </td>
                </tr>
                </tbody>
            </table>

            {this.partial===false && this.renderLastPart()}

        </div>
        )
    }



    renderLastPart = () => {
        return (<div>
                    <table width="100%" className="border_1 main_wrap " style={{pageBreakBefore: 'always'}}>
                        <tbody>
                        <tr>
                            <td width="5%" className={'text_center'}>Код рядка</td>
                            <td width="95%" colSpan="17" className={'text_center'}><b >V. РОЗРАХУНОК СУМИ ПОДАТКУ, НА ЯКУ ЗМЕНШУЮТЬСЯ
                                ПОДАТКОВІ ЗОБОВ ´ЯЗАННЯ З ПОДАТКУ НА ДОХОДИ ФІЗИЧНИХ ОСІБ У ЗВ ´ЯЗКУ З ВИКОРИСТАННЯМ ПРАВА НА
                                ПОДАТКОВУ ЗНИЖКУ ЗГІДНО ЗІ СТАТТЕЮ 166 РОЗДІЛУ IV ПОДАТКОВОГО КОДЕКСУ УКРАЇНИ</b> (грн, коп.)
                            </td>
                        </tr>
                        <tr>
                            <td width="5%" className={'text_center'}>13</td>
                            <td width="7%">Категорії витрат</td>
                            <td width="8%">п.п. 166.3.1 п. 166.3 ст. 166 ПКУ</td>
                            <td width="3%" className={'text_center'}>
                                <div className="item input_item">{this.cell('R013G1')}</div>
                            </td>
                            <td width="8%">п.п. 166.3.2 п. 166.3 ст. 166 ПКУ</td>
                            <td width="3%" className={'text_center'}>
                                <div className="item input_item">{this.cell('R013G2')} </div>
                            </td>
                            <td width="8%">п.п. 166.3.3 п. 166.3 ст. 166 ПКУ</td>
                            <td width="3%" className={'text_center'}>
                                <div className="item input_item">{this.cell('R013G3')} </div>
                            </td>
                            <td width="8%">п.п. 166.3.4 п.166.3 ст. 166 ПКУ <sup>6</sup></td>
                            <td width="3%" className={'text_center'}>
                                <div className="item input_item">{this.cell('R013G4')} </div>
                            </td>
                            <td width="8%">п.п. 166.3.5 п. 166.3 ст. 166 ПКУ</td>
                            <td width="3%" className={'text_center'}>
                                <div className="item input_item">{this.cell('R013G5')} </div>
                            </td>
                            <td width="8%">п.п. 166.3.6 п. 166.3 ст. 166 ПКУ</td>
                            <td width="3%" className={'text_center'}>
                                <div className="item input_item">{this.cell('R013G6')} </div>
                            </td>
                            <td width="8%">п.п. 166.3.7 п. 166.3 ст. 166 ПКУ</td>
                            <td width="3%" className={'text_center'}>
                                <div className="item input_item">{this.cell('R013G7')} </div>
                            </td>
                            <td width="8%">п.п. 166.3.8 п. 166.3 ст. 166 ПКУ</td>
                            <td width="3%" className={'text_center'}>
                                <div className="item input_item">{this.cell('R013G8')} </div>
                            </td>
                        </tr>
                        <tr>
                            <td width="5%" className={'text_center'}>14</td>
                            <td width="84%" colSpan="15" >Загальна сума фактично здійснених протягом звітного
                                (податкового) року витрат у граничних розмірах, дозволених до включення до податкової знижки
                                відповідно до статті 166 розділу IV Податкового кодексу України (далі - ПКУ)
                            </td>
                            <td width="11%" colSpan="2" className={'text_center'}>
                                <div className="item">{this.cell('R014G3')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>15</td>
                            <td colSpan="15" >Сума нарахованої заробітної плати, зменшена на суму обов'язкових
                                страхових внесків до недержавного пенсійного фонду, які відповідно до закону сплачуються за
                                рахунок заробітної плати, а також на суму податкової соціальної пільги за її наявності
                            </td>
                            <td colSpan="2" className={'text_center'}>
                                <div className="item">{this.cell('R015G3')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>16</td>
                            <td colSpan="15" >Сума (вартість) витрат платника податку – резидента, дозволених до
                                включення до податкової знижки з урахуванням обмеження, встановленого підпунтком 166.4.2 пункту
                                166.4 статті 166 розділу IV ПКУ <i>(рядок 14, але не більше рядка 15)</i></td>
                            <td colSpan="2" className={'text_center'}>
                                <div className="item">{this.cell('R016G3')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>17</td>
                            <td colSpan="15" >Сума податку, на яку зменшуються податкові зобов'язання у зв'язку з
                                використанням права на податкову знижку <i>(значення вказується без знака "‐") (графа 4 рядка
                                    10.1 ‐ (рядок 15 - рядок 16) × на ставку податку, визначену пунктом 167.1 статті 167 розділу
                                    IV ПКУ)</i></td>
                            <td colSpan="2" className={'text_center'}>
                                <div className="item">{this.cell('R017G3')}</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table width="100%" className="border_1 main_wrap ">
                        <tbody>
                        <tr>
                            <td width="5%" className={'text_center'}>Код рядка</td>
                            <td width="84%"  className={'text_center'}><b>VI. ПОДАТКОВІ ЗОБОВ'ЯЗАННЯ З ПОДАТКУ НА ДОХОДИ ФІЗИЧНИХ ОСІБ /
                                ВІЙСЬКОВОГО ЗБОРУ</b></td>
                            <td width="11%" className={'text_center'}>Сума (грн, коп.)</td>
                        </tr>
                            <tr>
                                <td className={'text_center'}>18</td>
                                <td >Загальна сума податкових зобов'язань з податку на доходи фізичних осіб <i>(графа 6
                                рядка 10)</i></td>
                                <td className={'text_center'}>
                                <div className="item">{this.cell('R018G3')}</div>
                        </td>
                        </tr>
                            <tr>
                                <td className={'text_center'}>19</td>
                                <td >Сума податку, на яку зменшуються податкові зобов'язання з податку на доходи
                                    фізичних осіб у зв'язку з використанням права на податкову знижку <i>(рядок 17)</i></td>
                                <td className={'text_center'}>
                                    <div className="item">{this.cell('R019G3')}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className={'text_center'}>20</td>
                                <td  >Сума податку на доходи фізичних осіб, що підлягає поверненню у
                                    разі невчинення нотаріальної дії щодо посвідчення договору купівлі- продажу, міни об'єкта
                                    нерухомості відповідно до пункту 172.6 статті 172 розділу IV ПКУ
                                </td>
                                <td className={'text_center'}>
                                    <div className="item">{this.cell('R020G3')}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className={'text_center'}>21</td>
                                <td >Сума податків, сплачених за кордоном, на яку зменшується сума річного податкового
                                    зобов'язання згідно з підпунктом 170.11.2 пункту 170.11 статті 170 розділу IV ПКУ <i>(але не
                                        більше значення рядка 18)</i></td>
                                <td className={'text_center'}>
                                    <div className="item">{this.cell('R021G3')}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className={'text_center'}>22</td>
                                <td ><b>Розрахунки з бюджетом з податку на доходи фізичних осіб:</b></td>
                                <td className={'text_center'}>X</td>
                            </tr>
                            <tr>
                                <td className={'text_center'}>22.1</td>
                                <td >Сума податкових зобов'язань з податку на доходи фізичних осіб, що підлягає сплаті
                                    до бюджету самостійно платником податків за результатами звітного (податкового) року (додатне
                                    значення <i>(рядок 18 - рядок 19 - рядок 20 - рядок 21))</i></td>
                                <td className={'text_center'}>
                                    <div className="item">{this.cell('R0221G3')}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className={'text_center'}>22.2</td>
                                <td >Сума податку на доходи фізичних осіб, що підлягає поверненню з бюджету за
                                    результатами звітного (податкового) року (від'ємне значення <i >(рядок 18 -
                                        рядок 19 - рядок 20 - рядок 21))</i></td>
                                <td className={'text_center'}>
                                    <div className="item">{this.cell('R0222G3')}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className={'text_center'}>23</td>
                                <td  ><b >Розрахунки з бюджетом з військового збору:</b>
                                </td>
                                <td className={'text_center'}>X</td>
                            </tr>
                            <tr>
                                <td className={'text_center'}>23.1</td>
                                <td >Сума податкових зобов'язань з військового збору, що підлягає сплаті до бюджету
                                    самостійно платником податків за результатами звітного <i>(податкового) року (графа 7 рядка
                                        10)</i></td>
                                <td className={'text_center'}>
                                    <div className="item">{this.cell('R0231G3')}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%" className="border_1 main_wrap ">
                        <tbody>
                        <tr>
                            <td width="5%" rowSpan="2" className={'text_center'}>Код рядка</td>
                            <td width="73%" rowSpan="2" className={'text_center'}><b>VII. РОЗРАХУНОК ПОДАТКОВИХ ЗОБОВ'ЯЗАНЬ У ЗВ'ЯЗКУ З ВИПРАВЛЕННЯМ
                                САМОСТІЙНО ВИЯВЛЕНИХ ПОМИЛОК У ПОПЕРЕДНІХ ЗВІТНИХ ПЕРІОДАХ</b></td>
                            <td width="22%" colSpan="2" className={'text_center'}>Сума (грн, коп.)</td>
                        </tr>
                        <tr>
                            <td width="11%" className={'text_center'}>податок на доходи фізичних осіб</td>
                            <td width="11%" className={'text_center'}>військовий збір</td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>24</td>
                            <td >Сума податку та/або збору, які підлягали перерахуванню до бюджету або поверненню,
                                за даними звітного (податкового) періоду, в якому виявлена помилка
                            </td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R024G3')}</div>
                            </td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R024G4')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>25</td>
                            <td >Уточнені суми податкових зобов'язань або сума до повернення за звітний (податковий)
                                період, у якому виявлена помилка
                            </td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R025G3')}</div>
                            </td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R025G4')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>26</td>
                            <td ><b>Розрахунки у зв'язку з виправленням помилки:</b></td>
                            <td colSpan="2" className={'text_center'}>X</td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>26.1</td>
                            <td >Збільшення суми, яка підлягала перерахуванню до бюджету <i>(рядок 25 ‐ рядок 24,
                                якщо рядок 25 &gt; рядка 24)</i></td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R0261G3')}</div>
                            </td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R0261G4')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>26.2</td>
                            <td  >Зменшення суми, яка підлягала перерахуванню до бюджету <i>(рядок
                                25 ‐ рядок 24, якщо рядок 25 &lt; рядка 24) (значення вказується без знака "‐")</i></td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R0262G3')}</div>
                            </td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R0262G4')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>27</td>
                            <td  >Сума штрафу, яка нарахована платником податку самостійно у зв'язку
                                з виправленням помилки, <div className="item" style={{display: 'inline-block'}}>
                                    {this.cell('R027G2')}
                                </div> % <i>(рядок 26.1 × 3 або 5 %)</i></td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R027G3')}</div>
                            </td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R027G4')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>28</td>
                            <td >Сума пені, яка нарахована платником податку самостійно відповідно до підпункту
                                129.1.3 пункту 129.1 статті 129 ПКУ
                            </td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R028G3')}</div>
                            </td>
                            <td className={'text_center'}>
                                <div className="item">{this.cell('R028G4')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3" className={'text_center'}><b>Реквізити банківського рахунку для перерахування коштів у разі повернення надміру
                                утриманих (сплачених) сум податку</b></td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>29</td>
                            <td colSpan="3" style={{padding: '0'}}>
                                <table width="100%" className="main_wrap table_border_none">
                                    <tbody>
                                    <tr>
                                        <td width="100%" colSpan="3" >Реквізити
                                            банківського рахунку для перерахування коштів у разі повернення надміру утриманих
                                            (сплачених) сум податку, у тому числі при застосуванні права на податкову
                                            знижку:</td>
                                    </tr>
                                    <tr>
                                        <td width="20%"  >Номер рахунку:</td>
                                        <td width="79%" className="border_bottom">
                                            <div className="item">{this.cell('HBANKACC')}
                                            </div>
                                        </td>
                                        <td width="1%"></td>
                                    </tr>
                                    <tr>
                                        <td  >Найменування банку:</td>
                                        <td className="border_bottom">
                                            <div className="item">{this.cell('HBANKNAME')}</div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td  >МФО банку:</td>
                                        <td  style={{padding: '5px'}}>
                                            <table width="30%" className="main_wrap text_center" cellSpacing="3">
                                                <tbody>
                                                <tr>
                                                    <td width="100%" className="border">
                                                        <div className="item">{this.cell('HMFO')}</div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table width="100%" className="border_1 main_wrap table_1">
                        <tbody>
                        <tr>
                            <td width="100%" colSpan="7"  className={'text_center'}><b >ВІДОМОСТІ ПРО ВЛАСНЕ НЕРУХОМЕ
                                (РУХОМЕ) МАЙНО ТА/АБО МАЙНО, ЯКЕ НАДАЄТЬСЯ В ОРЕНДУ (СУБОРЕНДУ)</b></td>
                        </tr>
                        <tr>
                            <td width="5%"  className={'text_center'}>Код рядка</td>
                            <td width="8%"  className={'text_center'}>Категорії<br />об'єктів **</td>
                            <td width="44%"  className={'text_center'}>Місцезнаходження об'єкта нерухомого майна (країна, адреса) або
                            марка (модель) рухомого майна
                            </td>
                            <td width="11% className={'text_center'}">Рік набуття у власність/ рік випуску (для рухомого майна)</td>
                            <td width="10%" className={'text_center'}>Загальна площа нерухомого майна (кв. м)</td>
                            <td width="10%" className={'text_center'}>Частка в загальній площі нерухомого майна</td>
                            <td width="12%" className={'text_center'}>Відмітка про надання майна в оренду (суборенду), житловий найм
                            (піднайм) ***</td>
                        </tr>
                        <tr>
                            <td className={'text_center'}>1</td>
                            <td className={'text_center'}>2</td>
                            <td  className={'text_center'}>3</td>
                            <td className={'text_center'}>4</td>
                            <td className={'text_center'}>5</td>
                            <td className={'text_center'}>6</td>
                            <td className={'text_center'}>7</td>
                        </tr>

                        {Array.isArray(this.getValue('T1RXXXXG2')) && this.getValue('T1RXXXXG2').map((val, idx)=>{
                            return (
                                <tr key={idx}>
                                    <td className={'text_center'}>{idx+1}</td>
                                    <td  className={'text_center'}>
                                        <div className="item " >{this.pureCell(val)}</div>
                                    </td>
                                    <td >
                                        <div className="item">{this.pureCell(this.getValue('T1RXXXXG3S')[idx])}</div>
                                    </td>
                                    <td className={'text_center'}>
                                        <div className="item">{this.pureCell(this.getValue('T1RXXXXG4')[idx])}</div>
                                    </td>
                                    <td className={'text_center'}>
                                        <div className="item">{this.pureCell(this.getValue('T1RXXXXG5')[idx])}</div>
                                    </td>
                                    <td className={'text_center'}>
                                        <div className="item">{this.pureCell(this.getValue('T1RXXXXG6')[idx])}</div>
                                    </td>
                                    <td className={'text_center'}>
                                        <div className="item input_item">{this.pureCell(this.getValue('T1RXXXXG7')[idx])}</div>
                                    </td>
                                </tr>
                            )
                        })}

                        <tr >
                            <td colSpan="7"  >** КАТЕГОРІЇ ОБ'ЄКТІВ
                                : 1 – земельні ділянки; 2 – житлові будинки; 3 – квартири; 4 – садові (дачні) будинки; 5 –
                                гаражі; 6 – водойми; 7- автомобілі легкові; 8 – автомобілі вантажні (спеціальні); 9 – водні
                                транспортні засоби; 10 – повітряні судна; 11 – мотоцикли (мопеди); 12 – інше нерухоме (рухоме)
                                майно.<br />*** При заповненні вказується позначка (х).</td>
                        </tr>
                    </tbody>
                    </table>
                    <table width="100%" className="border_1 main_wrap table_2">
                        <tbody>
                        <tr >
                            <td width="5%"  className={'text_center'}>
                                <div className="item input_item">{this.cell('HJ1')} </div>
                            </td>
                            <td width="95%"  className={'text_center'}><b >Доповнення до податкової декларації
                                (заповнюється і подається відповідно до пункту 46.4 статті 46 глави 2 розділу II ПКУ)</b></td>
                            </tr>
                            <tr >
                                <td  >№ з/п</td>
                                <td  className={'text_center'}>Зміст доповнення</td>
                            </tr>

                            {Array.isArray(this.getValue('T2RXXXXG2S')) && this.getValue('T2RXXXXG2S').map((val, idx)=>{
                                return (
                                <tr key={idx}>
                                    <td   className={'text_center'}>
                                        <div className="item">{idx+1}</div>
                                    </td>
                                    <td   >
                                        <div className="item" >{this.pureCell(val)}</div>
                                    </td>
                                </tr>
                                )}
                            )}


                        </tbody>
                    </table>
                    <table width="100%" className="border_1 main_wrap  table_3">
                        <tbody>
                            <tr >
                                <td width="5%"  className={'text_center'}>
                                    <div className="item" >{this.cell('HJ2')} </div>
                                </td>
                                <td width="95%"  className={'text_center'}><b >Доповнення до податкової декларації (подається
                                    відповідно до підпункту "д" 164.2.17 пункту 164.2 статті 164 розділу IV ПКУ)</b></td>
                            </tr>
                            <tr >
                            <td >№ з/п</td>
                            <td  className={'text_center'}>Зміст доповнення</td>
                            </tr>
                            {Array.isArray(this.getValue('T3RXXXXG2S')) && this.getValue('T3RXXXXG2S').map((val, idx)=>{
                                return (
                                <tr key={idx}>
                                    <td   className={'text_center'}>
                                        <div className="item">{idx+1}</div>

                                    </td>
                                    <td >
                                        <div className="item" >{this.pureCell(val)}</div>
                                    </td>
                                </tr>
                                )}
                            )}
                        </tbody>
                    </table>

                    <br /><br />
                    <table width="100%" className="main_wrap">
                        <tbody>
                        <tr >
                            <td>
                                <table width="100%" className="main_wrap table_border_none">
                                    <tbody>
                                    <tr>
                                        <td width="25%">Дата подання декларації:</td>
                                        <td width="25%" className="border text_center">
                                            <div className="item">{this.cell('HFILL')}</div>
                                        </td>
                                        <td width="100%"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td width="100%" colSpan="3">Інформація, наведена в декларації, додатках і
                                            доповненнях до декларації, є достовірною.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <br /><br />
                                <table width="100%" className="main_wrap table_border_none">
                                    <tbody>
                                    <tr>
                                        <td width="20%" >Фізична особа – платник податку</td>
                                        <td width="80%" colSpan="4"></td>
                                    </tr>
                                    <tr>
                                        <td width="20%" >або уповноважена особа</td>
                                        <td width="3%"></td>
                                        <td width="30%" className="border_bottom"></td>
                                        <td width="3%"></td>
                                        <td width="44%" className="border_bottom">
                                            <div className="item">{this.cell('HBOS')}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><font size="-1">(підпис)</font></td>
                                        <td></td>
                                        <td><font size="-1">(ініціали та прізвище)</font></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <br /><br />
                    <table width="100%" className="main_wrap table_border_none" >
                        <tbody>
                            <tr>
                                <td width="100%" ><font size="-1"><sup>1</sup> Заповнюється у разі подання
                                    декларації фізичною особою – підприємцем за результатами звітного кварталу, в якому
                                    розпочата господарська діяльність або відбувся перехід на загальну систему
                                    оподаткування.</font></td>
                            </tr>
                            <tr>
                                <td width="100%"><font size="-1"><sup>2</sup> Заповнюється за бажанням платника
                                    податку.</font></td>
                            </tr>
                            <tr>
                                <td width="100%"><font size="-1"><sup>3</sup> Заповнюється для фізичних осіб - підприємців,
                                    які припинили підприємницьку діяльність протягом звітного (податкового) року</font></td>
                            </tr>
                            <tr>
                                <td width="100%"><font size="-1" ><sup>4</sup> У разі отримання таких
                                    доходів заповнюється графа 7 розділу відомостей про власне нерухоме (рухоме) майно
                                    та/або майно, яке надається в оренду (суборенду).</font></td>
                            </tr>
                            <tr>
                                <td width="100%"><font size="-1" ><sup>5</sup> Перераховуються у гривні за
                                    валютним курсом Національного банку України, що діє на момент нарахування (отримання)
                                    таких доходів.</font></td>
                            </tr>
                            <tr>
                                <td width="100%"><font size="-1" ><sup>6</sup> Набирає чинності з 01 січня
                                    року, наступного за роком, у якому набере чинності закон про загальнообов'язкове
                                    державне соціальне медичне страхування.</font></td>
                            </tr>
                        </tbody>
                    </table>

                    <br /><br />
                    <table width="100%" cellPadding="1" className="border_1 main_wrap text_center" >
                        <tbody>
                        <tr>
                            <td width="100%" colSpan="4">Ця частина декларації заповнюється посадовими особами
                            контролюючого органу
                            </td>
                        </tr>
                        <tr>
                            <td width="5%"></td>
                            <td width="95%" colSpan="3" >Відмітка про внесення даних до електронної
                            бази податкової звітності «___» ___________ 20___ року
                            </td>
                        </tr>
                        <tr>
                            <td width="100%" colSpan="4">&nbsp;</td>
                        </tr>
                        <tr>
                            <td colSpan="4"><font size="-1">(посадова особа контролюючого органу (підпис, ініціали
                                та прізвище))</font></td>
                        </tr>
                        <tr>
                            <td colSpan="4">За результатами камеральної перевірки декларації (потрібне позначити):
                            </td>
                        </tr>
                        <tr>
                            <td width="5%"></td>
                            <td width="30%" >порушень (помилок) не виявлено</td>
                            <td width="5%"></td>
                            <td width="60%" >складено акт від «___» ________ 20___ року №__________</td>
                        </tr>
                        <tr>
                            <td colSpan="2" >«____» _____________ 20___ року</td>
                            <td colSpan="2"
                                >_____________________________________________________________<br /><font
                                size="-1" >(посадова особа контролюючого органу (підпис, ініціали та
                                прізвище))</font>
                            </td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        )
    }



    cell = (key, label = false) => {
        let val = this.getValue(key);

        return this.buildCell(val, label);
    };

    pureCell = (value, label = false) => {
        let val = this.prepareValue(value);
        return this.buildCell(val, label);
    };

    buildCell = (val, label) => {
        let className = 'inner-cell';
        if (val === '__empty__'){
            val = <span>&nbsp;&nbsp;</span>;
            className = 'inner-cell-empty';
        }

        return (
            <div className="grid-x ">
                <div className={'cell '+className}>{val}</div>
                {label && <div className={'cell f-10 inner-cell-label'}>{label}</div>}
            </div>
        )
    }

    prepareValue(val){
        if(typeof(val) === "boolean"){
            val = val===true?'X':'__empty__'
        } else if (val === null || (typeof(val) === "string" && val.trim() === '')){
            val = '__empty__';
        }
        return val;
    }

    getValue = (key) => {
        let val = '__empty__';

        if (this.getUserData().hasOwnProperty(key)){
            val = this.getUserData()[key];
            val = this.prepareValue(val);
        }

        return val;
    }

    getUserData = ()=>{
        return this.userData;
    };


}