import React from 'react';
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers';
import moment from 'moment';
import 'moment/locale/ru';
import MomentUtils from '@date-io/moment';
import { H01, H02, H06, H05 } from './declaration/_xmlConstId';
/**
 * answer code what we can get
 */
const B1A = 'B1A',
	B1B = 'B1B',
	B2A = 'B2A',
	B2BA = 'B2BA',
	B2BB = 'B2BB',
	B2C = 'B2C',
	B2D = 'B2D',
	B3A = 'B3A',
	B3B = 'B3B',
	B3C = 'B3C',
	B3D = 'B3D',
	B3E = 'B3E',
	B4A = 'B4A',
	B4B = 'B4B',
	B4AA = 'B4AA',
	B4AB = 'B4AB',
	B5AA = 'B5AA',
	B5AB = 'B5AB',
	B5B = 'B5B',
	B5CA = 'B5CA',
	B5CB = 'B5CB',
	B5D = 'B5D',
	B5EA = 'B5EA',
	B5EB = 'B5EB',
	B5FA = 'B5FA',
	B5FB = 'B5FB',
	B5G = 'B5G',
	B5HA = 'B5HA',
	B5HB = 'B5HB',
	B5IA = 'B5IA',
	B5IB = 'B5IB',
	B6AA = 'B6AA',
	B6AB = 'B6AB',
	B6B = 'B6B',
	B7A = 'B7A',
	B7B = 'B7B',
	B7C = 'B7C',
	B8A = 'B8A',
	B8B = 'B8B',
	B10A = 'B10A',
	B10AA = 'B10AA',
	B10AB = 'B10AB',
	B10AC = 'B10AC',
	B10AD = 'B10AD',
	B10AE = 'B10AE',
	B10AF = 'B10AF',
	B10AG = 'B10AG',
	B10AH = 'B10AH',
	B10AI = 'B10AI',
	B10AJ = 'B10AJ',
	B10AK = 'B10AK',
	B10AL = 'B10AL',
	B10AМ = 'B10AМ',
	B10AN = 'B10AN',
	B10B = 'B10B',
	B11A = 'B11A',
	B11B = 'B11B',
	B12A = 'B12A',
	B12B = 'B12B',
	B12C = 'B12C',
	B12D = 'B12D',
	B12EA = 'B12EA',
	B12EB = 'B12EB',
	B12F = 'B12F',
	B12G = 'B12G',
	B12H = 'B12H',
	B12I = 'B12I';

/**
 * questions code
 */
const B1 = 'B1',
	B2 = 'B2',
	B3 = 'B3',
	B4 = 'B4',
	B5 = 'B5',
	B6 = 'B6',
	B7 = 'B7',
	B8 = 'B8',
	// B9 = 'B9',
	B10 = 'B10',
	B11 = 'B11',
	B12 = 'B12',
	B13 = 'B13';

const RADIO = 'radio';
const CHECKBOX = 'checkbox';

/**Quiz questions */
export const questions = [
	{
		id: B1,
		title: 'Оберіть свій резидентський статус',
		element: RADIO,
		group: B1,
		answers: [
			{
				xmlId: H01,
				siblingXmlId: H02,
				id: B1A,
				title: 'резидент',
				type: RADIO,
				question: B1,
				variant: null,
				has_sub_question: false
			},
			{
				xmlId: H02,
				siblingXmlId: H01,
				id: B1B,
				title: 'нерезидент',
				type: RADIO,
				question: B1,
				variant: null,
				has_sub_question: false
			}
		]
	},
	{
		id: B2,
		title: 'Оберіть категорію, до якої Ви себе відносите?',
		element: CHECKBOX,
		group: B2,
		answers: [
			{
				xmlId: H05,
				id: B2A,
				title: 'фізична особа-громадянин',
				type: CHECKBOX,
				variant: null,
				question: B2,
				has_sub_question: false
			},
			{
				id: B2BA,
				title: 'фізична особа-підприємець на загальній системі оподаткування',
				type: CHECKBOX,
				variant: null,
				question: B2,
				has_sub_question: false
			},
			{
				id: B2BB,
				title: 'фізична особа-підприємець, яка припинила підприємницьку діяльність упродовж звітного року',
				type: CHECKBOX,
				variant: null,
				question: B2,
				has_sub_question: false,
				input(change, date) {
					// moment.locale('ru');
					return (
						<MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'} moment={moment}>
							<InlineDatePicker
								style={{ width: '160px' }}
								keyboard
								value={date}
								onChange={change}
								format="DD.MM.YYYY"
								mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
							/>
						</MuiPickersUtilsProvider>
					);
				} //! --- DATEPICKER
			},
			{
				id: B2C,
				title: 'фізична особа-підприємець на спрощеній системі оподаткування',
				type: CHECKBOX,
				variant: null,
				question: B2,
				has_sub_question: false
			},
			{
				xmlId: H06,
				id: B2D,
				title: 'фізична особа, яка провадить незалежну професійну діяльність',
				type: CHECKBOX,
				variant: null,
				question: B2,
				has_sub_question: false
			}
		]
	},
	{
		id: B3,
		title: 'Чи маєте Ви спеціальний статус?',
		element: RADIO,
		group: B3,
		answers: [
			{
				id: `${B3}_1`,
				title: 'Так',
				question: B3,
				variant: [B3A, B3B, B3C, B3D],
				type: RADIO,
				has_sub_question: true
			},
			{
				id: B3E,
				title: 'Ні',
				question: B3,
				variant: null,
				// variant: [`${B3}_1`],
				type: RADIO,
				has_sub_question: false
			}
		],
		sub_question: {
			id: `${B3}_1`,
			title: 'Оберіть Ваш спеціальний статус',
			group: `${B3}_1`,
			element: CHECKBOX,
			answers: [
				{
					id: B3A,
					title: 'інвалід І групи',
					question: `${B3}_1`,
					type: CHECKBOX,
					siblings: [B3B, B3C, B3D],
					variant: null,
					has_sub_question: false,
					answer: true
				},
				{
					id: B3B,
					title: 'дитина-сирота або дитина, позбавлена батьківського піклування',
					question: `${B3}_1`,
					type: CHECKBOX,
					siblings: [B3A, B3C, B3D],
					variant: null,
					has_sub_question: false,
					answer: true
				},
				{
					id: B3C,
					title: 'дитина-інвалід',
					question: `${B3}_1`,
					type: CHECKBOX,
					siblings: [B3A, B3B, B3D],
					variant: null,
					has_sub_question: false,
					answer: true
				},
				{
					id: B3D,
					title: 'внутрішньопереміщена особа',
					question: `${B3}_1`,
					type: CHECKBOX,
					siblings: [B3A, B3B, B3C],
					variant: null,
					has_sub_question: false,
					answer: true
				}
			]
		}
	},
	{
		id: B4,
		title: 'Ви володієте нерухомим або рухомим майном?',
		element: RADIO,
		group: B4,
		answers: [
			{
				id: B4A,
				title: 'Так',
				question: B4,
				type: RADIO,
				variant: [B4AA, B4AB],
				has_sub_question: true
			},
			{
				id: B4B,
				title: 'Ні',
				question: B4,
				type: RADIO,
				variant: null,
				has_sub_question: false
			}
		],
		sub_question: {
			title: 'Чи надавали Ви Ваше майно в лізинг, оренду (суборенду), житловий найм (піднайм)?',
			group: B4A,
			element: RADIO,
			variant: [B4AA, B4AB],
			answers: [
				{
					id: B4AA,
					title: 'Так',
					type: RADIO,
					question: B4A,
					open: true,
					variant: null,
					has_sub_question: false
				},
				{
					id: B4AB,
					title: 'Ні',
					type: RADIO,
					question: B4A,
					open: true,
					variant: null,
					has_sub_question: false
				}
			]
		}
	},
	{
		id: B5,
		title: 'Оберіть види доходів, які Ви отримували упродовж звітного року',
		element: CHECKBOX,
		group: B5,
		answers: [
			{
				id: `${B5}_1`,
				title: 'заробітна плата та інші доходи, отримані від Роботодавця',
				type: CHECKBOX,
				question: B5,
				variant: [B5AA, B5AB],
				has_sub_question: true,
				sub_question: {
					title: 'Оберіть країну-джерело походження доходів',
					group: `${B5}_1`,
					element: CHECKBOX,
					answers: [
						{
							id: B5AA,
							title: 'Україна',
							type: CHECKBOX,
							question: `${B5}_1`,
							variant: null,
							siblings: [B5AB],
							has_sub_question: false
						},
						{
							id: B5AB,
							title: 'інші країни',
							type: CHECKBOX,
							question: `${B5}_1`,
							variant: null,
							siblings: [B5AA],
							has_sub_question: false
						}
					]
				}
			},
			{
				id: B5B,
				title: 'нецільова (разова) матеріальна допомога від Роботодавця в Україні',
				type: CHECKBOX,
				question: B5,
				variant: null,
				has_sub_question: false
			},
			{
				id: `${B5}_2`,
				title: 'дохід від виконання робіт або надання послуг за договорами цивільно-правового характеру',
				type: CHECKBOX,
				question: B5,
				variant: [B5CA, B5CB],
				has_sub_question: true,
				sub_question: {
					title: 'Оберіть країну-джерело походження доходів',
					group: `${B5}_2`,
					element: CHECKBOX,
					answers: [
						{
							id: B5CA,
							title: 'Україна',
							type: CHECKBOX,
							question: `${B5}_2`,
							siblings: [B5CB],
							variant: null,
							has_sub_question: false
						},
						{
							id: B5CB,
							title: 'інші країни',
							type: CHECKBOX,
							question: `${B5}_2`,
							siblings: [B5CA],
							variant: null,
							has_sub_question: false
						}
					]
				}
			},
			{
				id: B5D,
				title: 'стипендія (державна премія, щомісячна довічна плата)',
				type: CHECKBOX,
				question: B5,
				variant: null,
				has_sub_question: false
			},
			{
				id: `${B5}_3`,
				title: 'пенсія або довічне грошове утримання',
				type: CHECKBOX,
				question: B5,
				variant: [B5EA, B5EB],
				has_sub_question: true,
				sub_question: {
					title: 'Оберіть країну-джерело походження доходів',
					group: `${B5}_3`,
					element: CHECKBOX,
					answers: [
						{
							id: B5EA,
							title: 'Україна',
							type: CHECKBOX,
							question: `${B5}_3`,
							siblings: [B5EB],
							variant: null,
							has_sub_question: false
						},
						{
							id: B5EB,
							title: 'інші країни',
							type: CHECKBOX,
							question: `${B5}_3`,
							siblings: [B5EA],
							variant: null,
							has_sub_question: false
						}
					]
				}
			},
			{
				id: `${B5}_4`,
				title: 'проценти',
				type: CHECKBOX,
				question: B5,
				variant: [B5FA, B5FB],
				has_sub_question: true,
				sub_question: {
					title: 'Оберіть країну-джерело походження доходів',
					group: `${B5}_4`,
					element: CHECKBOX,
					answers: [
						{
							id: B5FA,
							title: 'Україна',
							type: CHECKBOX,
							question: `${B5}_4`,
							siblings: [B5FB],
							variant: null,
							has_sub_question: false
						},
						{
							id: B5FB,
							title: 'інші країни',
							type: CHECKBOX,
							question: `${B5}_4`,
							siblings: [B5FA],
							variant: null,
							has_sub_question: false
						}
					]
				}
			},
			{
				id: B5G,
				title: 'дивіденди',
				type: CHECKBOX,
				question: B5,
				variant: null,
				has_sub_question: false
			},
			{
				id: `${B5}_5`,
				title: "від продажу об'єктів майнових і немайнових прав, зокрема інтелектуальної власності та роялті",
				type: CHECKBOX,
				question: B5,
				variant: [B5HA, B5HB],
				has_sub_question: true,
				sub_question: {
					title: 'Оберіть країну-джерело походження доходів',
					group: `${B5}_5`,
					element: CHECKBOX,
					answers: [
						{
							id: B5HA,
							title: 'Україна',
							type: CHECKBOX,
							question: `${B5}_5`,
							siblings: [B5HB],
							variant: null,
							has_sub_question: false
						},
						{
							id: B5HB,
							title: 'інші країни',
							type: CHECKBOX,
							question: `${B5}_5`,
							siblings: [B5HA],
							variant: null,
							has_sub_question: false
						}
					]
				}
			},
			{
				id: `${B5}_6`,
				title: 'аліменти',
				type: CHECKBOX,
				question: B5,
				variant: [B5IA, B5IB],
				has_sub_question: true,
				sub_question: {
					title: '',
					group: `${B5}_6`,
					element: CHECKBOX,
					answers: [
						{
							id: B5IA,
							title: 'Україна',
							type: CHECKBOX,
							question: `${B5}_6`,
							siblings: [B5IB],
							variant: null,
							has_sub_question: false
						},
						{
							id: B5IB,
							title: 'інші країни',
							type: CHECKBOX,
							question: `${B5}_6`,
							siblings: [B5IA],
							variant: null,
							has_sub_question: false
						}
					]
				}
			}
		]
	},
	{
		id: B6,
		title: 'Оберіть вид операцій, які Ви здійснювали з майном упродовж звітного року',
		element: CHECKBOX,
		group: B6,
		answers: [
			{
				id: `${B6}_1`,
				title: 'операції з продажу (обміну)',
				type: CHECKBOX,
				question: B6,
				variant: [B6AA, B6AB],
				has_sub_question: true,
				sub_question: {
					title: 'Оберіть країну в якій здійснювалась операція',
					group: `${B6}_1`,
					element: CHECKBOX,
					answers: [
						{
							id: B6AA,
							title: 'Україна',
							type: CHECKBOX,
							question: `${B6}_1`,
							siblings: [B6AB],
							variant: null,
							has_sub_question: false
						},
						{
							id: B6AB,
							title: 'інші країни',
							type: CHECKBOX,
							question: `${B6}_1`,
							siblings: [B6AA],
							variant: null,
							has_sub_question: false
						}
					]
				}
			},
			{
				id: B6B,
				title: 'отримання спадку (дарунку)',
				type: CHECKBOX,
				question: B6,
				variant: null,
				has_sub_question: false
			}
		]
	},
	{
		id: B7,
		title:
			'Оберіть вид операцій, які Ви здійснювали з корпоративними правами, цінними паперами, деривативами в Україні упродовж звітного року',
		element: CHECKBOX,
		group: B7,
		answers: [
			{
				id: B7A,
				title: 'операції з корпоративними правами',
				type: CHECKBOX,
				question: B7,
				variant: null,
				has_sub_question: false
			},
			{
				id: B7B,
				title:
					'операції з цінними паперами чи деривативами, що перебувають / перебували в обігу на фондовій біржі',
				type: CHECKBOX,
				question: B7,
				variant: null,
				has_sub_question: false
			},
			{
				id: B7C,
				title:
					'операції з цінними паперами чи деривативами, що не перебувають / перебували в обігу на фондовій біржі',
				type: CHECKBOX,
				question: B7,
				variant: null,
				has_sub_question: false
			}
		]
	},
	{
		id: B8,
		title:
			'Чи отримували Ви страхові виплати та/або здійснювали внески за договорами страхування та накопичення упродовж звітного року?',
		element: RADIO,
		group: B8,
		answers: [
			{
				id: B8A,
				title: 'так',
				type: RADIO,
				question: B8,
				variant: null,
				has_sub_question: false
			},
			{
				id: B8B,
				title: 'ні',
				type: RADIO,
				question: B8,
				variant: null,
				has_sub_question: false
			}
		]
	},
	{
		id: B10,
		title: 'Чи отримували Ви інші доходи з джерелом походження з України протягом звітного року',
		element: RADIO,
		group: B10,
		answers: [
			{
				id: B10A,
				title: 'Так',
				question: B3,
				variant: [
					B10AA,
					B10AB,
					B10AC,
					B10AD,
					B10AE,
					B10AF,
					B10AG,
					B10AH,
					B10AI,
					B10AJ,
					B10AK,
					B10AL,
					B10AМ,
					B10AN
				],
				type: RADIO,
				has_sub_question: true
			},
			{
				id: B3E,
				title: 'Ні',
				question: B3,
				variant: null,
				type: RADIO,
				has_sub_question: false
			}
		],
		sub_question: {
			id: B10A,
			// title:
			// 	'Чи отримували Ви інші доходи з джерелом походження з України протягом звітного року',
			element: CHECKBOX,
			group: B10A,
			answers: [
				{
					id: B10AA,
					title:
						'оподаткований дохід (прибуток), виявлений Вами або нарахований контролюючим органом і не включений до розрахунку загальних оподатковуваних доходів минулих податкових періодів',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AB,
					title:
						'дохід у вигляді несплаченої заборгованості перед юридичною та/або фізичною особою або фізичною особою-підприємцем у сумі понад 881 грн за розрахунками за укладеним цивільно-правовим договором, за яким закінчився строк позовної давності у 2018 році',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AC,
					title: 'дохід у вигляді виграшів та призів',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AD,
					title:
						'дохід у вигляді коштів або майна, що класифіковані як хабар або як викрадений чи знайдений скарб, не зданий державі згідно з законом, і такі факти визнані рішенням суду',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AE,
					title:
						'дохід у вигляді коштів внаслідок відмови або відступлення права вимоги за договором про участь у фонді фінансування будівництва',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AF,
					title:
						'дохід у вигляді неустойки (штрафів, пені), відшкодування матеріальної або немайнової (моральної) шкоди',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AG,
					title:
						'дохід у вигляді суми Вашого боргу, прощеного (анульованого) кредитором за його самостійним рішенням, яке не пов’язане з процедурою банкрутства, до закінчення строку позовної давності',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AH,
					title: 'дохід у вигляді допомоги (виплати, компенсації, відшкодування)',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AI,
					title:
						'дохід, отриманий Вами за зданий (проданий) брухт дорогоцінних металів, вторинну сировину, побутові відходи, брухт чорних металів, брухт кольорових металів, крім доходу, тощо',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AJ,
					title: 'дохід від продажу власної сільськогосподарської продукції',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AK,
					title: 'дохід, отриманий платником податку як додаткове благо',
					helpers: [
						"ПІДКАЗКА:. Додаткове благо - кошти, матеріальні чи нематеріальні цінності, послуги, інші види доходу, що виплачуються (надаються) платнику податку Роботодавцем, якщо такий дохід не є заробітною платою та не пов'язаний з виконанням обов'язків трудового найму або не є винагородою за цивільно-правовими договорами (угодами), укладеними з таким платником податку (пп.14.1.47 п. 14.1 ст. 14 ПКУ)"
					],
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AL,
					title:
						' дохід у вигляді позитивної різниці між сумою коштів, витрачених на придбання права вимоги за депозитом (вкладом) та коштів, отриманих у результаті реалізації права вимоги такого депозиту (вкладу)',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AМ,
					title: 'інший вид оподатковуваних доходів',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				},
				{
					id: B10AN,
					title: 'інший вид неоподатковуваних доходів',
					type: CHECKBOX,
					question: B10,
					variant: null,
					has_sub_question: false
				}
			]
		}
	},
	{
		id: B11,
		title: 'Чи отримували Ви доходи з джерелом походження за межами України упродовж звітного року?',
		element: RADIO,
		helpers: [
			'ПІДКАЗКА: за виключенням заробітної плати, спадщини від нерезидентів, пенсії, оренди, процентів, дивідендів від нерезидентів, роялті, аліментів, доходів від продажу(обміну) нерухомого майна'
		],
		group: B11,
		answers: [
			{
				id: B11A,
				title: 'так',
				type: RADIO,
				question: B11,
				variant: null,
				has_sub_question: false
			},
			{
				id: B11B,
				title: 'ні',
				type: RADIO,
				question: B11,
				variant: null,
				has_sub_question: false
			}
		]
	},
	{
		id: B12,
		title: 'Ви сплачували такі платежі у звітному році',
		element: CHECKBOX,
		group: B1A,
		answers: [
			{
				id: B12A,
				title: 'проценти за користування іпотечним житловим кредитом (не більше ніж одним). ',
				type: CHECKBOX,
				helpers: [
					'ПІДКАЗКА: На це запитання потрібно відповідати ствердно, якщо об’єкт житлової іпотеки (придбаний, збудований) використовується як Ваше основне місце реєстрації згідно з паспортом'
				],
				question: B12,
				variant: null,
				has_sub_question: false
			},
			{
				id: B12B,
				title: 'пожертвування або благодійні внески неприбутковим організаціям',
				type: CHECKBOX,
				question: B12,
				variant: null,
				has_sub_question: false
			},
			{
				id: B12C,
				title: 'кошти за здобуття освіти Вами або членом Вашої сім’ї першого ступеня споріднення',
				type: CHECKBOX,
				question: B12,
				variant: null,
				helpers: [
					'ПІДКАЗКА 1: Мається на увазі дошкільної, позашкільної, загальної середньої, професійної (професійно-технічної) та вищої освіти',
					'ПІДКАЗКА 2: На це запитання потрібно відповідати ствердно, якщо витрати понесені за власне навчання та/або навчання працездатних дітей віком до 23 років та/або непрацездатних дітей незалежно від віку, які не отримують заробітну плату',
					'ПІДКАЗКА 3: Окрім коштів за навчання на військовій кафедрі'
				],
				has_sub_question: false
			},
			{
				id: B12D,
				title: 'кошти на користь закладів охорони здоров’я',
				type: CHECKBOX,
				question: B12,
				variant: null,
				has_sub_question: false
			},
			{
				id: `${B12}_1`,
				title:
					'на сплату страхових платежів, пенсійних внесків та внесків на банківський пенсійний депозитний рахунок',
				type: CHECKBOX,
				question: B12,
				variant: [B12EA, B12EB],
				has_sub_question: true,
				sub_question: {
					title: '',
					group: `${B12}_1`,
					element: CHECKBOX,
					answers: [
						{
							id: B12EA,
							title:
								'згідно з Вашими договорами довгострокового страхування життя та/або недержавного пенсійного страхування або внески на Ваш банківський пенсійний депозитний рахунок',
							type: CHECKBOX,
							question: `${B12}_1`,
							siblings: [B12EB],
							variant: null,
							has_sub_question: false
						},
						{
							id: B12EB,
							title:
								' згідно з договорами довгострокового страхування життя та/або недержавного пенсійного страхування або внески на банківський пенсійний депозитний рахунок членів сім’ї першого ступеня споріднення',
							type: CHECKBOX,
							question: `${B12}_1`,
							siblings: [B12EA],
							variant: null,
							helpers: [
								'ПІДКАЗКА: До членів сім’ї першого ступеня споріднення належать батьки, чоловік або дружина, діти такої фізичної особи, у тому числі усиновлені'
							],
							has_sub_question: false
						}
					]
				}
			},
			{
				id: B12F,
				title:
					'на оплату вартості допоміжних репродуктивних технологій або вартості державних послуг, пов’язаних з усиновленням дитини, включаючи сплату державного мита',
				type: CHECKBOX,
				question: B12,
				variant: null,
				has_sub_question: false
			},
			{
				id: B12G,
				title: 'на переобладнання транспортного засобу для переходу на альтернативні види палива.',
				type: CHECKBOX,
				question: B12,
				variant: null,
				helpers: [
					'ПІДКАЗКА:. До альтернативних видів палива відносять паливо моторне сумішеве, біоетанол, біодизель, стиснений або скраплений газ, інші види біопалива.'
				],
				has_sub_question: false
			},
			{
				id: B12H,
				title:
					'на будівництво (придбання), у тому числі на погашення пільгового іпотечного житлового кредиту, наданого на придбання такого доступного житла та процентів за ним',
				type: CHECKBOX,
				question: B12,
				variant: null,
				has_sub_question: false
			},
			{
				id: B12I,
				title: 'орендну плату за договором оренди житла (квартири, будинку).',
				type: CHECKBOX,
				question: B12,
				variant: null,
				helpers: [
					'ПІДКАЗКА: 1. На це запитання потрібно відповідати ствердно, якщо у Вас та/або членів сім’ї першого та другого ступенів споріднення не має у власності придатної для проживання житлової нерухомості, розташованої поза межами тимчасово окупованої території України та Ви не отримуєте передбачених законодавством України бюджетних виплат для покриття витрат на проживання.',
					'ПІДКАЗКА: 2. Розмір такої знижки не може перевищувати (у розрахунку на календарний рік) 30 розмірів мінімальної заробітної плати, встановленої на 1 січня звітного (податкового) року.'
				],
				has_sub_question: false
			}
		]
	},
	{
		id: B13,
		title:
			'Чи виявляли Ви самостійно помилки у поданих деклараціях про майновий стан і доходи у попередніх звітних періодах?',
		element: RADIO,
		group: B13,
		answers: [
			{
				id: `${B13}_1`,
				title: 'Так',
				type: RADIO,
				question: B13,
				variant: null,
				has_sub_question: false
			},
			{
				id: `${B13}_2`,
				title: 'Ні',
				type: RADIO,
				question: B13,
				variant: null,
				has_sub_question: false
			}
		]
	}
];

export {
	B13,
	B12,
	B1A,
	B1B,
	B2A,
	B2BA,
	B2BB,
	B2C,
	B2D,
	B3A,
	B3B,
	B3C,
	B3D,
	B3E,
	B4A,
	B4B,
	B4AA,
	B4AB,
	B5AA,
	B5AB,
	B5B,
	B5CA,
	B5CB,
	B5D,
	B5EA,
	B5EB,
	B5FA,
	B5FB,
	B5G,
	B5HA,
	B5HB,
	B5IA,
	B5IB,
	B6AA,
	B6AB,
	B6B,
	B7A,
	B7B,
	B7C,
	B8A,
	B8B,
	B10A,
	B10B,
	B10AA,
	B10AB,
	B10AC,
	B10AD,
	B10AE,
	B10AF,
	B10AG,
	B10AH,
	B10AI,
	B10AJ,
	B10AK,
	B10AL,
	B10AМ,
	B10AN,
	B11A,
	B11B,
	B12A,
	B12B,
	B12C,
	B12D,
	B12EA,
	B12EB,
	B12F,
	B12G,
	B12H,
	B12I
};
