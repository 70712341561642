import { R0101G4, R0101G3 } from './index';

export const R013G1 = function(formValues) {
	return Boolean(formValues['R013G1']);
};

export const R013G2 = function(formValues) {
	return Boolean(formValues['R013G2']);
};

export const R013G3 = function(formValues) {
	return Boolean(formValues['R013G3']);
};

export const R013G4 = function(formValues) {
	return Boolean(formValues['R013G4']);
};

export const R013G5 = function(formValues) {
	return Boolean(formValues['R013G5']);
};

export const R013G6 = function(formValues) {
	return Boolean(formValues['R013G6']);
};

export const R013G7 = function(formValues) {
	return Boolean(formValues['R013G7']);
};

export const R013G8 = function(formValues) {
	return Boolean(formValues['R013G8']);
};

export const R014G3 = function(formValues) {
	//return parseFloat(formValues['R014G3']);

	return Object.keys(formValues['R014G3']['a']).reduce((acc, key, i) => {
		let all_options_was_checked =
			formValues[`R013G${i + 1}`] &&
			Object.keys(formValues['R014G3']['q'][key]['sub_q'])
				.map(i => {
					return formValues['R014G3']['q'][key]['sub_q'][i];
				})
				.every(option_value => Boolean(option_value) === true);

		let allowedExpenses = 0;
		let actualExpenses = all_options_was_checked
			? parseFloat(formValues['R014G3']['a'][key]['expenses'])
			: parseFloat(formValues['R014G3']['a'][key]['expenses']) || 0;
		let coef = 1;
		if (all_options_was_checked) {
			switch (key) {
				case 'Disc1': {
					let apartment_area = parseFloat(formValues['R014G3']['a'][key]['apartment_area']);
					coef = apartment_area > 100 ? 100 / apartment_area : coef;
					break;
				}
				case 'Disc2':
					coef = 0.4;
					break;

				case 'Disc4':
					coef = 1;
					actualExpenses = actualExpenses > 2470 ? 2470 : actualExpenses;
					break;

				case 'Disc4B': {
					coef = 1;
					let count_famaly = parseFloat(formValues['R014G3']['a'][key]['family']);
					actualExpenses =
						actualExpenses > (2470 / 2) * count_famaly ? (2470 / 2) * count_famaly : actualExpenses;
					break;
				}
				case 'Disc5':
					coef = 1 / 3;
					break;
				default:
					break;
			}
		}
		allowedExpenses = actualExpenses * coef;

		return acc + allowedExpenses;
	}, 0);
};
// export const R014G3 = function(formValues) {
// 	//return parseFloat(formValues['R014G3']);

// 	return Object.keys(formValues['R014G3']['a']).reduce((acc, key, i) => {
// 		let all_options_was_checked =
// 			formValues[`R013G${i + 1}`] &&
// 			Object.keys(formValues['R014G3']['q'][key]['sub_q'])
// 				.map(i => {
// 					return formValues['R014G3']['q'][key]['sub_q'][i];
// 				})
// 				.every(option_value => Boolean(option_value) == true);
// 		let allowedExpenses = 0;
// 		let actualExpenses = all_options_was_checked ? parseFloat(formValues['R014G3']['a'][key]['expenses']) : 0;
// 		let coef = 1;

// 		if (all_options_was_checked) {
// 			switch (key) {
// 				case 'Disc1': {
// 					let apartment_area = parseFloat(formValues['R014G3']['a'][key]['apartment_area']);
// 					coef = apartment_area > 100 ? 100 / apartment_area : coef;
// 					break;
// 				}
// 				case 'Disc2':
// 					coef = 0.04;
// 					break;
// 				case 'Disc3':
// 				case 'Disc4':
// 				case 'Disc4B':
// 				case 'Disc5':
// 				case 'Disc6':
// 				case 'Disc7':
// 				case 'Disc8':
// 					break;
// 			}
// 		}

// 		allowedExpenses = actualExpenses * coef;

// 		return acc + allowedExpenses;
// 	}, 0);
// };

export const R015G3 = function(formValues) {
	// let value = parseFloat(formValues['R015G3']);
	// let calculated = R0101G3(formValues);

	// if (value) return value;

	// return calculated;
	return R0101G3(formValues);
};

export const R016G3 = function(formValues) {
	let expenses = R014G3(formValues);
	let salary = R015G3(formValues);

	return expenses < salary ? expenses : salary;
};

export const R017G3 = function(formValues) {
	// (графа 4 рядка 10.1 ‐ (рядок 15 - рядок 16) × на ставку податку(18%)
	// (значення вказується без знака "‐")
	//
	// из декларации формула:                                   C18=C16-(C15-C17)*0.18
	// в то время как таблице клиента формула "перевернутая":   C18=(C15-C17)*0.18-C16

	let operand_2 = ((R015G3(formValues) - R016G3(formValues)) * 18) / 100;

	if (operand_2 <= 0) {
		return 0;
	}

	let operand_1 = R0101G4(formValues);

	return operand_1 - operand_2;
};
