import React, { Fragment } from 'react';

import ActiveStep from '../../images/oval-blue-active.svg';
import PassiveStep from '../../images/oval-blue-passive.svg';
import DoneStep from '../../images/done.svg';
import ErrorsStep from '../../images/alert.svg';
import DisabledStep from '../../images/oval.svg';

const NewStepper = ({ steps, activeStep, handleClickStep }) => {
	const _setImage = (stepId, isDisabled, activeStep, isTouched, isValid) => {
		if (isDisabled) {
			return DisabledStep;
		} else if (stepId === activeStep) {
			return ActiveStep;
		} else if (isValid) {
			return DoneStep;
		} else if (isTouched && !isValid) {
			return ErrorsStep;
		} else {
			return PassiveStep;
		}
	};

	return (
		<div className="stepper-block">
			{steps.map((step, i) => {
				return (
					<Fragment key={step.id}>
						<div
							className="stepper-icon"
							onClick={() => {
								if (!step.isDisabled && step.id !== activeStep) {
									handleClickStep(step.id);
								}
							}}
						>
							<img
								className="stepper-icon__passive"
								src={_setImage(step.id, step.isDisabled, activeStep, step.isTouched, step.isValid)}
								alt="passive"
							/>
						</div>
						{i < steps.length - 1 && <div className="horizontal-line" />}
					</Fragment>
				);
			})}
		</div>
	);
};

export default NewStepper;
