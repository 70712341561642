import React from 'react';
import { Grid } from '@material-ui/core';
import Icon from 'react-eva-icons';
import { Link } from 'react-router-dom';

import { StyledButton } from '../Button/Button';

import './style.scss';

const Card = () => {
	return (
		<Grid container className="card" alignItems="center">
			<div className="document-left-side">
				<Grid item>
					<Link to="/" className="card_link">
						<h3 className="card_title">Звітна декларація за 2017 рік</h3>
					</Link>
					<div className="card-status">
						<div className="card-status__icon">
							<Icon
								className="card_status__icon"
								name="alert-triangle-outline"
								fill="#f4c010"
								size="medium" // small, medium, large, xlarge
								animation={{
									// type: 'pulse', // zoom, pulse, shake, flip
									hover: true,
									infinite: false
								}}
							/>
						</div>
						<div className="card-status__text">Готова для відправлення</div>
					</div>
					{/* <StyledButton className="card_button" variant="contained">
						Відправити
					</StyledButton> */}
				</Grid>
				<Grid item className="card_actions">
					<Link to="/" className="card_action_link">
						<Icon
							name="download-outline"
							fill="#abbdd3"
							size="medium" // small, medium, large, xlarge
							animation={{
								// type: 'pulse', // zoom, pulse, shake, flip
								hover: true,
								infinite: false
							}}
						/>
					</Link>
					<Link to="/" className="card_action_link">
						<Icon
							name="edit-outline"
							fill="#abbdd3"
							size="medium" // small, medium, large, xlarge
							animation={{
								// type: 'pulse', // zoom, pulse, shake, flip
								hover: true,
								infinite: false
							}}
						/>
					</Link>
					<Link to="/" className="card_action_link">
						<Icon
							name="trash-outline"
							fill="#abbdd3"
							size="medium" // small, medium, large, xlarge
							animation={{
								// type: 'pulse', // zoom, pulse, shake, flip
								hover: true,
								infinite: false
							}}
						/>
					</Link>
				</Grid>
			</div>
			<StyledButton className="send-icon-btn" variant="contained">
				<Icon
					name="paper-plane-outline"
					fill="#4ca34c"
					size="medium" // small, medium, large, xlarge
					animation={{
						// type: 'pulse', // zoom, pulse, shake, flip
						hover: true,
						infinite: false
					}}
				/>
			</StyledButton>
		</Grid>
	);
};

export default Card;
