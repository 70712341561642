import { connect } from 'react-redux';

import ONTI from '../subsections/ONTI';
import { getOntiData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.ONTI.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getOntiData
};

const ONTIContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ONTI);

export default ONTIContainer;
