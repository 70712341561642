import {
	GOOGLE_SIGN_IN_SUCCESS,
	GOOGLE_SIGN_IN_FAIL,
	GOOGLE_SIGN_OUT_SUCCESS,
	GOOGLE_SIGN_OUT_FAIL
} from '../actions/user_actions';

const initialState = {
	isSignIn: false,
	initial_login: true,
	user: null,
	authenticated_by: '',
	profile: null,
	profile_form: {},
	docs: {
		count: 0
	}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GOOGLE_SIGN_IN_SUCCESS:
			return {
				...state,
				isSignIn: true,
				some: action.payload
			};

		case GOOGLE_SIGN_IN_FAIL:
			return {
				...state,
				isSignIn: false
			};

		case GOOGLE_SIGN_OUT_SUCCESS:
			return {
				...state
			};

		case GOOGLE_SIGN_OUT_FAIL:
			return {
				...state
			};

		default:
			return state;
	}
}
