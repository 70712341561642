import { GET_LEFT_NAVIGATION, SET_CURRENT_SUBSECTION_INDEX } from '../actions/nav_actions';
import { declarationSubSections } from '../../data/declaration';
import { sectionsNav } from '../../data/navigation';
import { intersection, flattenDeep, compact, uniq } from 'lodash';

const initialState = {
	loading: false,
	navSections: sectionsNav,
	navSubSections: declarationSubSections,
	currentSubSectionIndex: 0,
	result: null
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_LEFT_NAVIGATION:
			return {
				...state,
				navigation: action.payload
			};

		case SET_CURRENT_SUBSECTION_INDEX:
			return {
				...state,
				currentSubSectionIndex: action.payload
			};

		default:
			return state;
	}
}

export function getActualNavigation(answersData, subNavigation) {
	let actualNavigation;
	let answers;

	if (answersData) {
		answers = uniq(
			Object.keys(answersData)
				.filter(key => (answersData[key] ? key : null))
				.reduce((acc, key) => {
					if (answersData[key] === true) {
						acc.push(key);
					} else {
						acc.push(answersData[key]);
					}

					return acc;
				}, [])
		);
	}

	if (answers && answers.length) {
		answers = flattenDeep(answers).filter(element => typeof element === 'string');

		if (subNavigation) {
			actualNavigation = subNavigation.map((subNav, i) => {
				let hasSubSection = intersection(subNav.quiz_answers, answers);

				if (hasSubSection.length) {
					subNav = { ...subNav, index: i };

					return subNav;
				}

				return null;
			});
			return compact(actualNavigation);
		}
	}
	return subNavigation;
}
