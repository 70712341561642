import React from 'react';

import { TextField } from '@material-ui/core';

const InputComponent = ({
	error,
	label,
	onChange,
	value,
	disabled,
	id,
	name,
	onInput,
	defaultValue,
	type,
	rows,
	multiline,
	...props
}) => {
	return (
		<TextField
			error={error}
			label={label}
			onChange={onChange}
			value={value}
			disabled={disabled}
			id={id}
			name={name}
			onInput={onInput}
			defaultValue={defaultValue}
			type={type}
			rows={rows}
			multiline={multiline}
			{...props}
		/>
	);
};

export default InputComponent;
