import { R0109G3, R0106G3, R021G3 } from '../_xmlConstId';
import { R1A, R1B, R1BA, R1BB, R1BC, R1BD, R1BE } from '../_declaretion_answer_id';
import { B5HA, B5HB } from '../../_questions_OLD';
import { INPUT, SELECT, DATE } from '../../elementTypes';

import countries from '../../../data/countries.json';
import currency from '../../../data/currencies.json';

export const R = [
	{
		// 1 INPUT
		id: R1A,
		title:
			'Вкажіть суму отриманих  в Україні упродовж звітного року доходів від продажу власних та успадкованих об’єктів  майнових і немайнових прав, зокрема інтелектуальної власності та роялті',
		name: R1A,
		element: INPUT,
		xmlId: R0109G3,
		placeholder: 'Вкажіть суму',
		quizOpen: [B5HA],
		helpers: ['Підказка 1: До таких об\'єктів відносяться: об’єкти майнових і немайнових прав, права інтелектуальної (промислової) власності та прирівняні до них права, авторські винагороди, інші виплати за надання права на користування або розпорядження іншим особам нематеріальними активами (творами науки, мистецтва, літератури або іншими нематеріальними активами), об’єкти права інтелектуальної промислової власності та прирівняні до них права (роялті)'],
		question_title: true
	},
	{
		// 2 INPUT
		id: R1B,
		title:
			'Вкажіть суму отриманих  за кордоном доходів від продажу власних та успадкованих об’єктів  майнових і немайнових прав, зокрема інтелектуальної власності та роялті перерахованих у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів упроводж звітного року',
		name: R1B,
		// element: INPUT,
		placeholder: 'Вкажіть суму',
		xmlId: R0106G3,
		quizOpen: B5HB,
		question_title: true,
		helpers: [
			'Підказка 1: До таких об\'єктів відносяться: об’єкти майнових і немайнових прав, права інтелектуальної (промислової) власності та прирівняні до них права, авторські винагороди, інші виплати за надання права на користування або розпорядження іншим особам нематеріальними активами (творами науки, мистецтва, літератури або іншими нематеріальними активами), об’єкти права інтелектуальної промислової власності та прирівняні до них права (роялті)',
			"Підказка 2. Ви можете зменшити річне податкового зобов'язання на суму податків, сплачених за кордоном згідно з нормами міжнародних договорів про уникнення подвійного оподаткування.",
			'Підказка 3. У разі можливості неоднозначного трактування норм ПКУ рекомендуємо звернутися за індивідуальною податковою консультацією до контролюючого органу',
			'Підказка 4. У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою)'
		],
		answers: [
			{
				parent: R1B,
				id: R1BA,
				title: 'Kраїнa',
				xmlId: R0106G3,
				name: 'country_name',
				element: SELECT,
				options: countries
			},
			{
				parent: R1B,
				id: R1BB,
				title: 'Валюта',
				xmlId: R0106G3,
				name: 'currency_name',
				element: SELECT,
				options: currency
			},
			{
				parent: R1B,
				id: R1BC,
				title: 'Дата',
				xmlId: R0106G3,
				name: 'currency_date',
				element: DATE
			},
			{
				parent: R1B,
				id: R1BD,
				title:
					'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
				element: INPUT,
				name: 'revenue',
				placeholder: 'Введіть суму (грн., коп.)',
				xmlId: R0106G3
			},
			{
				parent: R1B,
				id: R1BE,
				// title:
				// 	'Вкажіть суму  сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
				element: INPUT,
				name: 'tax',
				placeholder: 'НАЛОГ',
				// placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R021G3
			}
		]
	}
];
