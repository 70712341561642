import React, { Fragment } from 'react';

import Input from './FormFields/Input';
import Radio from './FormFields/Radio';
import Checkbox from './FormFields/Checkbox';
import Select from './FormFields/Select';

import FormHelperText from '@material-ui/core/FormHelperText';

const Question = ({ item, state, change, changeSelect }) => {
	const renderFormFields = item => {
		switch (item.element) {
			case 'input':
				return <Input item={item} answer={state.answers} change={change} />;

			case 'radio':
				return <Radio item={item} answer={state.answers} change={change} />;

			case 'checkbox':
				return <Checkbox item={item} answer={state.answers} change={change} />;

			case 'select':
				return <Select item={item} answer={state.answers} change={changeSelect} />;

			default:
				break;
		}
	};

	const renderAnswers = answers => {
		return answers.map((answer, i) => {
			return (
				<Fragment key={i}>
					{renderFormFields(answer)}
					{answer.subsection && state.answers[answer.id] && renderFormFields(answer.subsection)}
				</Fragment>
			);
		});
	};

	return (
		<div className='WP-blocks-wrap'>
			{item.title && <h3 className="page-title page-title-WP">{item.title}</h3>}
			{/* <h3 className="page-title page-title-WP">{item.title}</h3> */}
			{item.helpers && item.helpers.map((helper, i) => <FormHelperText key={i}>{helper}</FormHelperText>)}
			{item.answers && renderAnswers(item.answers)}
			{!item.answers && renderFormFields(item)}
		</div>
	);
};

export default Question;
