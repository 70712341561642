import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

import hoc from '../../../HOC/hoc';
import LeftSideAdvantages from '../components/LeftSideAdvantages';
import ForgotPasswordForm from './forms/ForgotPasswordForm';

class Forgot extends Component {
	state = {
		loading: false,
		formdata: {
			email: {
				element: 'input',
				value: '',
				config: {
					name: 'email_input',
					type: 'text',
					label: 'Електорнна пошта'
				},
				validation: {
					required: true,
					email: true
				},
				valid: false,
				touched: false,
				validationMessage: ''
			}
		}
	};

	handleChange = element => {
		const target = element.event.target;
		const newFormData = { ...this.state.formdata };
		const newElement = { ...newFormData[element.id] };
		const value = newElement.config.type === 'checkbox' ? target.checked : target.value;
		newElement.value = value;

		if (element.blur) {
			let validData = this.validate(newElement);
			newElement.valid = validData[0];
			newElement.validationMessage = validData[1];
		}

		newElement.touched = element.blur;
		newFormData[element.id] = newElement;

		this.setState({
			formdata: newFormData
		});
	};

	validate = element => {
		let error = [true, ''];

		if (element.validation.email) {
			const valid = /\S+@\S+\.\S+/.test(element.value);
			const message = `${!valid ? 'Не дійсний адреса електронної пошти' : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if (element.validation.password) {
			const valid = element.value.length > 9;
			const message = `${!valid ? 'Пароль повинен бути більше 9 символів' : ''}`;
			error = !valid ? [valid, message] : error;
		}

		if (element.validation.required) {
			const valid = element.value.trim() !== '';
			const message = `${!valid ? "Це поле обов'язкове" : ''}`;
			error = !valid ? [valid, message] : error;
		}
		return error;
	};

	handleSubmit = event => {
		event.preventDefault();
	};

	handleSendEmail = (values, setSubmitting) => {
		// TODO: edd request
		console.log('handleSendEmail  ', values);
		setSubmitting(false);
	};

	render() {
		return (
			<Grid
				container
				alignItems="center"
				justify="center"
				className="container forgot-form-block"
				style={{ minHeight: '100%' }}
			>
				<Grid container direction="column" justify="center" alignItems="center">
					<ForgotPasswordForm handleSendEmail={this.handleSendEmail} />
					{/* <form className="form form__login" onSubmit={this.handleSubmit}>
						<h1 className="h1">Забули пароль?</h1>
						<p className="sub-title">Введіть електронну пошту, щоб змінити пароль</p>
						<Grid container direction="column" justify="center">
							<FormField formdata={this.state.formdata.email} id="email" change={this.handleChange} />
							<StyledButton className="fogot-btn" variant="contained" type="submit">
								Відправити
							</StyledButton>
						</Grid>
					</form> */}
				</Grid>
			</Grid>
		);
	}
}

export default hoc(LeftSideAdvantages)(Forgot);
