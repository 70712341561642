import {
	InvestP1,
	InvestP1A,
	InvestP1AA,
	InvestP1AB,
	InvestP1AC,
	InvestP1B,
	InvestP1BA,
	InvestP1BB,
	InvestP1BC,
	InvestP1C,
	InvestP1CA,
	InvestP1CB,
	InvestP1CC,
	InvestP1D,
	InvestP1DA,
	InvestP1DB,
	InvestP1DC,
	InvestP1E,
	InvestP1EA,
	InvestP1EB,
	InvestP1EC,
	InvestP1F,
	InvestP1FA,
	// InvestP1FB,
	InvestP1G,
	InvestP1GA,
	InvestP1H,
	InvestP1HA,
	InvestP1I,
	InvestP1J,
	InvestP1K,
	InvestP1L,
	InvestP1LA,
	InvestP1LB,
	InvestP1LAA,
	InvestP1M,
	InvestP1MA,
	InvestP1MB,
	InvestP1MAA
} from '../_declaretion_answer_id';
import { R0113G3, R0109G3 } from '../_xmlConstId';
import { B7A, B7B, B7C } from '../../_questions_OLD';
import { INPUT, CHECKBOX, RADIO } from '../../elementTypes';

export const InvestP = [
	{
		// MAIN QUESTION
		id: InvestP1,
		title: 'У звітному році Ви отримували такі інвестиційні доходи',
		name: InvestP1,
		quizOpen: B7A,
		question_title: true,
		helpers: [
			'ПІДКАЗКА:  До розрахунку загального фінансового результату операцій з інвестиційними активами включається дохід, отриманий платником податку протягом звітного податкового року від продажу інвестиційних активів, що перевищує суму місячного прожиткового мінімуму для працездатної особи, помноженого на 1,4 та округленого до найближчих 10 грн (пп. 170.2.8, абзац «а»). У іншому разі повинна висвічуватись підказка: Доходи та витрати на придбання таких інвестиційних активів не включаються до розрахунку загального фінансового результату операцій з інвестиційними активами'
		],
		answers: []
	},
	{
		// 1 CB
		parent: InvestP1,
		id: InvestP1A,
		title: 'від операцій з корпоративними правами (іншими ніж цінні папери)',
		element: CHECKBOX,
		// name: InvestP1A,
		answers: [
			{
				// 1 CB > 1 INPUT
				id: InvestP1AA,
				title:
					'Вкажіть найменування та характеристику інвестиційного активу за прикладом 100 % ТОВ "Престиж" (код ЄДРПОУ 22334455)',
				// xmlId: R0104G3,
				xmlId: 'T1RXXXXG3S',
				element: INPUT,
				// name: InvestP1AA,
				placeholder: 'Найменування та характеристика'
			},
			{
				// 1 CB > 2 INPUT
				id: InvestP1AB,
				title:
					'Вкажіть суму доходу, отриманого від продажу інвестиційного активу з урахуванням курсової різниці (за наявності)',
				// xmlId: R0104G3,
				xmlId: 'T1RXXXXG4',
				element: INPUT,
				// name: InvestP1AB,
				helpers: [
					'ПІДКАЗКА:  Під продажем розуміють: обмін інвестиційного активу на інший; зворотний викуп або погашення його емітентом, який належав платнику податку; повернення платнику податку коштів або майна (майнових прав), попередньо внесених ним до статутного капіталу емітента корпоративних прав, у разі виходу такого платника податку з числа засновників (учасників) такого емітента чи ліквідації такого емітента'
				],
				placeholder: 'Вкажіть суму доходу (грн, коп.)'
			},
			{
				// 1 CB > 3 INPUT
				id: InvestP1AC,
				title: 'Вкажіть суму витрат на придбання інвестиційного активу',
				// xmlId: R0104G3,
				xmlId: 'T1RXXXXG5',
				element: INPUT,
				// name: InvestP1AC,
				placeholder: 'Вкажіть суму (грн, коп.)'
			}
		]
	},
	{
		// 2 CB
		parent: InvestP1,
		id: InvestP1B,
		title:
			'у разі припинення членства у господарських товариствах у розмірі, що перевищує розмір (суму, вартість) пайового внеску у звітному році',
		element: CHECKBOX,
		// name: InvestP1B,
		answers: [
			{
				// 2 CB > 1 INPUT
				id: InvestP1BA,
				title:
					'Вкажіть найменування та характеристику інвестиційного активу за прикладом 100 % ТОВ "Престиж" (код ЄДРПОУ 22334455)',
				xmlId: 'T1RXXXXG3S',
				// xmlId: R0104G3,
				element: INPUT,
				// name: InvestP1BA,
				placeholder: 'Найменування та характеристика'
			},
			{
				// 2 CB > 2 INPUT
				id: InvestP1BB,
				title:
					'Вкажіть суму доходу, отриманого від продажу інвестиційного активу з урахуванням курсової різниці (за наявності)',
				xmlId: 'T1RXXXXG4',
				// xmlId: R0104G3,
				element: INPUT,
				// name: InvestP1BB,
				helpers: [
					'ПІДКАЗКА:  Під продажем розуміють: обмін інвестиційного активу на інший; зворотний викуп або погашення його емітентом, який належав платнику податку; повернення платнику податку коштів або майна (майнових прав), попередньо внесених ним до статутного капіталу емітента корпоративних прав, у разі виходу такого платника податку з числа засновників (учасників) такого емітента чи ліквідації такого емітента'
				],
				placeholder: 'Вкажіть суму доходу (грн, коп.)'
			},
			{
				// 2 CB > 3 INPUT
				id: InvestP1BC,
				title: 'Вкажіть суму витрат на придбання інвестиційного активу',
				xmlId: 'T1RXXXXG5',
				// xmlId: R0104G3,
				element: INPUT,
				// name: InvestP1BC,
				placeholder: 'Вкажіть суму (грн, коп.)'
			}
		]
	},
	{
		// 3 CB
		parent: InvestP1,
		id: InvestP1C,
		title: 'у разі припинення членства у виробничому сільськогосподарському кооперативі',
		element: CHECKBOX,
		// name: InvestP1C,
		answers: [
			{
				// 3 CB > 1 INPUT
				id: InvestP1CA,
				title:
					'Вкажіть найменування та характеристику інвестиційного активу за прикладом 100 % ТОВ "Престиж" (код ЄДРПОУ 22334455)',
				xmlId: 'T1RXXXXG3S',
				// xmlId: [R0104G3, R0113G3],
				element: INPUT,
				// name: InvestP1CA,
				placeholder: 'Найменування та характеристика'
			},
			{
				// 3 CB > 2 INPUT
				id: InvestP1CB,
				title:
					'Вкажіть суму доходу, отриманого від продажу інвестиційного активу з урахуванням курсової різниці (за наявності)',
				// xmlId: [R0104G3, R0113G3],
				xmlId: 'T1RXXXXG4',
				element: INPUT,
				// name: InvestP1CB,
				helpers: [
					'ПІДКАЗКА:  Під продажем розуміють: обмін інвестиційного активу на інший; зворотний викуп або погашення його емітентом, який належав платнику податку; повернення платнику податку коштів або майна (майнових прав), попередньо внесених ним до статутного капіталу емітента корпоративних прав, у разі виходу такого платника податку з числа засновників (учасників) такого емітента чи ліквідації такого емітента'
				],
				placeholder: 'Вкажіть суму доходу (грн, коп.)'
			},
			{
				// 3 CB > 3 INPUT
				id: InvestP1CC,
				title: 'Вкажіть суму витрат на придбання інвестиційного активу',
				xmlId: 'T1RXXXXG5',
				// xmlId: [R0104G3, R0113G3],
				element: INPUT,
				// name: InvestP1CC,
				placeholder: 'Вкажіть суму (грн, коп.)'
			}
		]
	},
	{
		// 4 CB
		parent: InvestP1,
		id: InvestP1D,
		title:
			'від операцій з цінними паперами чи деривативами, що перебувають в обігу на фондовій біржі у звітному році',
		element: CHECKBOX,
		// name: InvestP1D,
		quizOpen: B7B,
		answers: [
			{
				// 4 CB > 1 INPUT
				id: InvestP1DA,
				title:
					'Вкажіть найменування та характеристику інвестиційного активу за прикладом 100 % ТОВ "Престиж" (код ЄДРПОУ 22334455)',
				xmlId: 'T1RXXXXG3S',
				// xmlId: R0104G3,
				element: INPUT,
				// name: InvestP1DA,
				placeholder: 'Найменування та характеристика'
			},
			{
				// 4 CB > 2 INPUT
				id: InvestP1DB,
				title:
					'Вкажіть суму доходу, отриманого від продажу інвестиційного активу з урахуванням курсової різниці (за наявності)',
				xmlId: 'T1RXXXXG4',
				// xmlId: R0104G3,
				element: INPUT,
				// name: InvestP1DB,
				helpers: [
					'ПІДКАЗКА:  Під продажем розуміють: обмін інвестиційного активу на інший; зворотний викуп або погашення його емітентом, який належав платнику податку; повернення платнику податку коштів або майна (майнових прав), попередньо внесених ним до статутного капіталу емітента корпоративних прав, у разі виходу такого платника податку з числа засновників (учасників) такого емітента чи ліквідації такого емітента'
				],
				placeholder: 'Вкажіть суму доходу (грн, коп.)'
			},
			{
				// 4 CB > 3 INPUT
				id: InvestP1DC,
				title: 'Вкажіть суму витрат на придбання інвестиційного активу',
				xmlId: 'T1RXXXXG5',
				// xmlId: R0104G3,
				element: INPUT,
				// name: InvestP1DC,
				placeholder: 'Вкажіть суму (грн, коп.)'
			}
		]
	},
	{
		// 5 CB
		parent: InvestP1,
		id: InvestP1E,
		title:
			'від операцій з цінними паперами чи деривативами, що не перебувають в обігу на фондовій біржі у звітному році',
		element: CHECKBOX,
		// name: InvestP1E,
		quizOpen: B7C,
		answers: [
			{
				// 5 CB > 1 INPUT
				id: InvestP1EA,
				title:
					'Вкажіть найменування та характеристику інвестиційного активу за прикладом 100 % ТОВ "Престиж" (код ЄДРПОУ 22334455)',
				xmlId: 'T1RXXXXG3S',
				// xmlId: R0104G3,
				element: INPUT,
				// name: InvestP1EA,
				placeholder: 'Найменування та характеристика'
			},
			{
				// 5 CB > 2 INPUT
				id: InvestP1EB,
				title:
					'Вкажіть суму доходу, отриманого від продажу інвестиційного активу з урахуванням курсової різниці (за наявності)',
				xmlId: 'T1RXXXXG4',
				// xmlId: R0104G3,
				element: INPUT,
				// name: InvestP1EB,
				helpers: [
					'ПІДКАЗКА:  Під продажем розуміють: обмін інвестиційного активу на інший; зворотний викуп або погашення його емітентом, який належав платнику податку; повернення платнику податку коштів або майна (майнових прав), попередньо внесених ним до статутного капіталу емітента корпоративних прав, у разі виходу такого платника податку з числа засновників (учасників) такого емітента чи ліквідації такого емітента'
				],
				placeholder: 'Вкажіть суму доходу (грн, коп.)'
			},
			{
				// 5 CB > 3 INPUT
				id: InvestP1EC,
				title: 'Вкажіть суму витрат на придбання інвестиційного активу',
				xmlId: 'T1RXXXXG5',
				// xmlId: R0104G3,
				element: INPUT,
				// name: InvestP1EC,
				placeholder: 'Вкажіть суму (грн, коп.)'
			}
		]
	},
	{
		// 6 CB
		parent: InvestP1,
		id: InvestP1F,
		title:
			'від операцій з борговими зобов’язаннями Національного банку України та з державними цінними паперами, емітованими центральним органом виконавчої влади, що забезпечує формування та реалізує державну фінансову політику, з урахуванням курсових різниць',
		element: CHECKBOX,
		// name: InvestP1F,
		answers: [
			{
				// 6 CB > 1 INPUT
				id: InvestP1FA,
				title:
					'Вкажіть суму доходу, отриманого від продажу інвестиційного активу з урахуванням курсової різниці (за наявності)',
				xmlId: R0109G3,
				element: INPUT,
				name: InvestP1FA,
				helpers: [
					'ПІДКАЗКА:  Під продажем розуміють: обмін інвестиційного активу на інший; зворотний викуп або погашення його емітентом, який належав платнику податку; повернення платнику податку коштів або майна (майнових прав), попередньо внесених ним до статутного капіталу емітента корпоративних прав, у разі виходу такого платника податку з числа засновників (учасників) такого емітента чи ліквідації такого емітента.'
				],
				placeholder: 'Вкажіть суму доходу (грн, коп.)'
			}
			// {
			// 	// 6 CB > 2 INPUT
			// 	id: InvestP1FB,
			// 	title: 'Вкажіть суму витрат на придбання інвестиційного активу',
			// 	xmlId: R0109G3,
			// 	element: INPUT,
			// 	name: InvestP1FB,
			// 	placeholder: 'Вкажіть суму (грн, коп.)'
			// }
		]
	},
	{
		// 7 CB
		parent: InvestP1,
		id: InvestP1G,
		title:
			'внаслідок відчуження акцій (інших корпоративних прав), одержаних у власність у процесі приватизації в обмін на приватизаційні компенсаційні сертифікати, безпосередньо отримані Вами як компенсація суми внеску до установ Ощадного банку СРСР або до установ державного страхування СРСР, або в обмін на приватизаційні сертифікати, отримані Вами відповідно до закону, а також дохід внаслідок відчуження земельних ділянок сільськогосподарського призначення, земельних часток (паїв) за нормами безоплатної передачі та майнових паїв, безпосередньо отриманих у власність у процесі приватизації',
		element: CHECKBOX,
		// name: InvestP1G,
		answers: [
			{
				// 7 CB > INPUT
				id: InvestP1GA,
				xmlId: R0113G3,
				element: INPUT,
				name: InvestP1GA,
				placeholder: 'Вкажіть суму доходу (грн, коп.)'
			}
		]
	},
	{
		// 8 CB
		parent: InvestP1,
		id: InvestP1H,
		title:
			'від операцій з конвертації (обміну) цінних паперів за звичайними цінами або за вартістю чистих активів у розрахунку на один цінний папір, які не призводять до відчуження інвестицій та здійснюються з цінними паперами ІСІ, що перебувають в управлінні однієї компанії з управління активами',
		element: CHECKBOX,
		// name: InvestP1H,
		answers: [
			{
				// 8 CB > INPUT
				id: InvestP1HA,
				xmlId: R0113G3,
				element: INPUT,
				name: InvestP1HA,
				placeholder: 'Вкажіть суму доходу (грн, коп.)'
			}
		]
	},
	{
		// 8 INPUT
		parent: InvestP1,
		id: InvestP1I,
		title:
			'Вкажіть суму збитку від операцій з інвестиційними активами за попередній календарний рік у разі наявності',
		// xmlId: , //! LOOK
		element: INPUT,
		name: InvestP1I,
		placeholder: 'Вкажіть суму (грн, коп.)'
	},
	{
		// 9 INPUT
		parent: InvestP1,
		id: InvestP1J,
		title: 'Вкажіть суму ПДФО утриманого (сплаченого) податковим агентом',
		// xmlId: , //! LOOK
		element: INPUT,
		name: InvestP1J,
		placeholder: 'Вкажіть суму (грн, коп.)'
	},
	{
		// 10 INPUT
		parent: InvestP1,
		id: InvestP1K,
		title: 'Вкажіть суму військового збору утриманого (сплаченого) податковим агентом',
		// xmlId: , //! LOOK
		element: INPUT,
		name: InvestP1K,
		placeholder: 'Вкажіть суму (грн, коп.)'
	},
	{
		// 11 RB
		parent: InvestP1,
		id: InvestP1L,
		title:
			'Ви здійснювали майнові та немайнові внески до статутного фонду юридичної особи в обмін на корпоративні права?',
		name: InvestP1L,
		question_title: true,
		answers: [
			{
				id: InvestP1LA,
				// xmlId: R0113G3,
				title: 'Так',
				name: 'InvestP1L_g',
				element: RADIO
			},
			{
				id: InvestP1LB,
				// xmlId: R0113G3,
				title: 'Ні',
				name: 'InvestP1L_g',
				element: RADIO
			}
		]
	},
	{
		// 11 RB > 1 RB
		parent: InvestP1LA,
		id: InvestP1LAA,
		title: 'Вкажіть вартість корпоративних прав, отриманих упродовж звітного року',
		xmlId: R0113G3,
		element: INPUT,
		name: InvestP1LAA,
		placeholder: 'Вкажіть суму (грн, коп.)'
	},
	{
		// 12 RB
		parent: InvestP1,
		id: InvestP1M,
		title:
			'Ви проводили операції з конвертації (обміну) цінних паперів за звичайними цінами або за вартістю чистих активів у розрахунку на один цінний папір, які не призводять до відчуження інвестицій та проводяться з цінними паперами ІСІ, що перебувають в управлінні однієї компанії з управління активами?',
		name: InvestP1M,
		question_title: true,
		answers: [
			{
				id: InvestP1MA,
				// xmlId: R0113G3,
				title: 'Так',
				name: 'InvestP1M_g',
				element: RADIO
			},
			{
				id: InvestP1MB,
				// xmlId: R0113G3,
				title: 'Ні',
				name: 'InvestP1M_g',
				element: RADIO
			}
		]
	},
	{
		// 12 RB > 1 RB > INPUT
		parent: InvestP1MA,
		id: InvestP1MAA,
		title: 'Вкажіть дохід, отриманий упродовж звітного року',
		xmlId: R0113G3,
		element: INPUT,
		name: InvestP1MAA,
		placeholder: 'Вкажіть суму (грн, коп.)'
	}
];
