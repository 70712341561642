import { connect } from 'react-redux';

import SalePro from '../subsections/salePro';
import { getSaleProData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.SALEPRO.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getSaleProData
};

const SaleProContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(SalePro);

export default SaleProContainer;
