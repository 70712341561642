import moment from 'moment';
import {
	W1A,
	// W5A,
	// W5B,
	W1B,
	W2A,
	W4,
	W6A,
	W7A,
	// W5AA,
	// W5AB,
	// ID1,
	// ID1A,
	// ID1B,
	ID1BA,
	ID1BB,
	// ID1BBA,
	// ID1BBB,
	// ID1BBC,
	// ID1BBD,
	ID1BBE,
	ID1AA,
	// ID2,
	// ID2A,
	// ID2B,
	ID2AA,
	// ID3,
	// ID3A,
	ID3AA,
	// ID3B,
	ID3BA,
	// ID3C,
	ID3CA,
	// ID3D,
	ID3DA,
	// ID3E,
	ID3EA,
	// ID3F,
	ID3FA,
	// ID4,
	// ID4A,
	// ID4B,
	ID4AA,
	OTI_1,
	OTI_2A,
	OTI_2B,
	OTI_3,
	OTI_4,
	OTI_5,
	OTI_6,
	OTI_7,
	IP2C,
	ALIM2,
	ALIM2E,
	W3A
	// W4A
} from '../../../../data/declaration/_declaretion_answer_id';

// import { store } from '../../../../../app';

const get_form_data_definition = function() {
	const form_data_definition = {
		_type: 'F01002',
		_revision: '10',
		_revision_versions: {
			'10': 0
		},

		_profile_answers: null,

		HZ: true,
		HZN: false,
		HZU: false,

		HZY: moment(new Date())
			.subtract(1, 'years')
			.format('YYYY'),

		HZYP: moment(new Date())
			.subtract(1, 'years')
			.format('YYYY'),

		HNAME: '',
		//"HNAME": store.getState().user.authenticated_by == "acsk" ? store.getState().user.profile.fullname : '',
		HTIN: '',
		//"HTIN": store.getState().user.authenticated_by == "acsk" ? store.getState().user.profile.EDRPOUCode : '',

		HREG: '',
		HRAJ: '',
		HCITY: '',
		HSTREET: '',
		HBUILD: '',
		HCORP: '',
		HAPT: '',
		HZIP: '',
		HTEL: '',
		//"HTEL": store.getState().user.authenticated_by == "acsk" ? store.getState().user.profile.phone : '',
		HEMAIL: '',

		HSTI: '',
		C_STI_ORIG: '',
		C_REG: '',
		C_RAJ: '',

		H01: false,
		H02: false,

		H03: true,
		H04: false,

		H05: false,
		H06: false,
		H07: false,

		HNAMEAG: '',
		//"HNAMEAG": store.getState().user.authenticated_by == "acsk" ? store.getState().user.profile.fullname : '',
		HTINAG: '',
		//"HTINAG": store.getState().user.authenticated_by == "acsk" ? store.getState().user.profile.EDRPOUCode : '',

		R010G3: 0,
		R010G4: 0,
		R010G5: 0,
		R010G6: 0,
		R010G7: 0,

		R0101G3: {
			q: {
				[W1A]: null,
				[W3A]: null,
				W4AA: null,
				W4AB: null
			},
			a: {
				[W1A]: 0,
				[W3A]: 0,
				W4AA: 0, // оплата самостоятельно
				W4AB: 0 // оплата агентом
			}
		},
		R0101G4: 0,
		R0101G4_override: false,

		R0101G5: 0,
		R0101G5_override: false,

		R0101G6: 0,
		R0101G7: 0,

		R0102G3: {
			a: {
				SalePro1AA: 0,
				SalePro1BA: 0,
				SalePro1CA: 0,
				SalePro2AA: 0,
				SalePro2BA: 0,
				SalePro2CA: 0,
				SalePro2DA: 0
			},
			q: {
				SalePro1AA: null,
				SalePro1BA: null,
				SalePro1CA: null,
				SalePro2AA: null,
				SalePro2BA: null,
				SalePro2CA: null,
				SalePro2DA: null
			}
		},
		R0102G4: 0,
		R0102G5: 0,

		R0102G6: 0,
		R0102G6_override: 0,
		R0102G7: 0,
		R0102G7_override: 0,

		R0103G3: {
			q: {
				Lease1AA: null,
				Lease1BA: null
			},
			a: {
				Lease1AA: 0,
				Lease1BA: 0
			}
		},
		R0103G4: 0,
		R0103G5: 0,

		R0103G6: 0,
		R0103G6_override: false,
		R0103G7: 0,
		R0103G7_override: false,

		R0104G3: {
			q: {
				'0': null,
				'1': null,
				'2': null,
				'3': null,
				'4': null
			},
			a: {
				'0': 0
				// InvestP1A: [
				// 	{
				// 		T1RXXXXG2: '1',
				// 		T1RXXXXG3S: '', // їмя активу
				// 		T1RXXXXG4: 0, // доходи
				// 		T1RXXXXG5: 0 // витрати
				// 	}
				// ],
				// InvestP1B: [
				// 	{
				// 		T1RXXXXG2: '1',
				// 		T1RXXXXG3S: '', // їмя активу
				// 		T1RXXXXG4: 0, // доходи
				// 		T1RXXXXG5: 0 // витрати
				// 	}
				// ],
				// InvestP1C: [
				// 	{
				// 		T1RXXXXG2: '1',
				// 		T1RXXXXG3S: '', // їмя активу
				// 		T1RXXXXG4: 0, // доходи
				// 		T1RXXXXG5: 0 // витрати
				// 	}
				// ],
				// InvestP1D: [
				// 	{
				// 		T1RXXXXG2: '2',
				// 		T1RXXXXG3S: '', // їмя активу
				// 		T1RXXXXG4: 0, // доходи
				// 		T1RXXXXG5: 0 // витрати
				// 	}
				// ],
				// InvestP1E: [
				// 	{
				// 		T1RXXXXG2: '3',
				// 		T1RXXXXG3S: '', // їмя активу
				// 		T1RXXXXG4: 0, // доходи
				// 		T1RXXXXG5: 0 // витрати
				// 	}
				// ]
				// Можливі данні для колонки T1RXXXXG2:
				//  1 – корпоративні права (інші ніж цінні папери);
				//  2 – цінні папери чи деривативи, що перебувають в обігу на фондовій біржі;
				//  3 – цінні папери чи деривативи, що перебувають в обігу не на фондовій біржі.
				// '-'– інше
				// '1': [
				// 	{
				// 		T1RXXXXG2: '1',
				// 		T1RXXXXG3S: '', // їмя активу
				// 		T1RXXXXG4: 0, // доходи
				// 		T1RXXXXG5: 0 // витрати
				// 	}
				// ],
				// '2': [
				// 	{
				// 		T1RXXXXG2: '2',
				// 		T1RXXXXG3S: '', // їмя активу
				// 		T1RXXXXG4: 0, // доходи
				// 		T1RXXXXG5: 0 // витрати
				// 	}
				// ],
				// '3': [
				// 	{
				// 		T1RXXXXG2: '-',
				// 		T1RXXXXG3S: '', // їмя активу
				// 		T1RXXXXG4: 0, // доходи
				// 		T1RXXXXG5: 0 // витрати
				// 	}
				// ],
				// '4': [
				// 	{
				// 		T1RXXXXG2: '3',
				// 		T1RXXXXG3S: '', // їмя активу
				// 		T1RXXXXG4: 0, // доходи
				// 		T1RXXXXG5: 0 // витрати
				// 	}
				// ]
			}
		},
		R0104G4: 0,
		R0104G5: 0,

		R0104G6: 0,
		R0104G6_override: false,
		R0104G7: 0,
		R0104G7_override: false,

		R0105G3: {
			q: {
				[ID1BA]: null,
				[ID1AA]: null,
				[ID2AA]: null,
				[ID3AA]: null,
				[ID3BA]: null,
				[ID3CA]: null,
				[ID3DA]: null,
				[ID3EA]: null,
				[ID3FA]: null,
				[ID4AA]: null
			},
			a: {
				[ID1BA]: 0,
				[ID1AA]: 0,
				[ID2AA]: 0,
				[ID3AA]: 0,
				[ID3BA]: 0,
				[ID3CA]: 0,
				[ID3DA]: 0,
				[ID3EA]: 0,
				[ID3FA]: 0,
				[ID4AA]: 0
			}
		},
		R0105G4: 0,
		R0105G5: 0,

		R0105G6: 0,
		R0105G6_override: false,
		R0105G7: 0,
		R0105G7_override: false,

		R0106G2S: '',
		R0106G3: {
			q: {
				W5: null,
				[W1B]: null,
				[ID1BB]: null,
				[IP2C]: null
			},
			a: {
				W5: [
					{
						country_name: '',
						currency_name: '',
						revenue: 0,
						currency_rate: '',
						currency_date: '',
						revenue_currency: 0,
						tax: 0
					}
				],
				[W1B]: [
					{
						country_name: '',
						currency_name: '',
						revenue: 0,
						currency_rate: '',
						currency_date: '',
						revenue_currency: 0,
						tax: 0
					}
				],
				[ID1BB]: [
					{
						country_name: '',
						currency_name: '',
						revenue: 0,
						currency_rate: '',
						currency_date: '',
						revenue_currency: 0,
						tax: 0
					}
				],
				[IP2C]: [
					{
						country_name: '',
						currency_name: '',
						revenue: 0,
						currency_rate: '',
						currency_date: '',
						revenue_currency: 0,
						tax: 0
					}
				],
				[ALIM2]: [
					{
						country_name: '',
						currency_name: '',
						revenue: 0,
						currency_rate: '',
						currency_date: '',
						revenue_currency: 0,
						tax: 0
					}
				]
			}
		},
		R0106G4: 0,
		R0106G5: 0,
		R0106G6: 0,
		R0106G6_override: false,
		R0106G7: 0,
		R0106G7_override: false,

		R0107G3: {
			q: {
				extra_1: true, // R012G3 from F01214
				extra_2: true, // R013G3 from F01214
				extra_3: true, // R022G3 from F01214

				'1': null
			},
			a: {
				extra_1: 0,
				extra_2: 0,
				extra_3: 0,

				'1': [
					{
						T1RXXXXG2S: '',
						T1RXXXXG3S: '',
						T1RXXXXG4: 0, // revenue
						T1RXXXXG5: 0, // expenses 'type 1'
						T1RXXXXG6: 0, // expenses 'type 2'
						T1RXXXXG7: 0, // expenses 'type 3'
						T1RXXXXG8: {
							// expenses 'type 4'
							A1: {
								start_value: 0,
								end_value: 0
							},
							A2: {
								start_value: 0,
								end_value: 0
							},
							A3: {
								start_value: 0,
								end_value: 0
							},
							A4: {
								start_value: 0,
								end_value: 0
							}
						},
						T1RXXXXG8_present: false,
						T1RXXXXG9: 0 // profit
					}
				]
			}
		},
		R0107G4: 0,
		R0107G5: 0,
		R0107G6: 0,
		R0107G7: 0,

		R0108G3: {
			q: {
				'0': true,
				'1': null
			},
			a: {
				'0': 0,
				'1': [
					{
						T4RXXXXG2: '',
						T4RXXXXG3S: '',
						T4RXXXXG4S: '',
						T4RXXXXG5: 0, // revenue
						T4RXXXXG6: 0, // expenses 'type 1'
						T4RXXXXG7: 0, // expenses 'type 2'
						T4RXXXXG8: 0, // expenses 'type 3'
						T4RXXXXG9: 0 // profit
					}
				]
			}
		},
		R0108G4: 0,
		R0108G5: 0,
		R0108G6: 0,
		R0108G7: 0,

		R0109G3: {
			q: {
				[W2A]: null,
				[OTI_1]: null,
				[OTI_2A]: null,
				[OTI_2B]: null,
				[OTI_3]: null,
				[OTI_4]: null,
				[OTI_5]: null,
				[OTI_6]: null,
				[OTI_7]: null,
				[OTI_1]: null,
				[OTI_2A]: null,
				[OTI_2B]: null,
				[OTI_3]: null,
				[OTI_4]: null,
				[OTI_5]: null,
				[OTI_6]: null,
				[OTI_7]: null,
				WP1AA: null,
				WP1BA: null,
				IP1AAAB: null,
				IP1AABA: null,
				IP1BABAA: null,
				IP1CAA: null,
				IP1DAAAA: null,
				IP1EAA: null,
				IP1FAA: null,
				IP1JAA: null,
				IP1NABAA: null,
				IP1PAB: null,
				IP2AB: null,
				// C
				C1AA: null,
				C1DA: null,
				C1EAAA: null,
				C1EABA: null,
				C1MA: null,
				C2A: null
			},
			a: {
				[W2A]: 0,

				[OTI_1]: 0,
				[OTI_2A]: 0,
				[OTI_2B]: 0,
				[OTI_3]: 0,
				[OTI_4]: 0,
				[OTI_5]: 0,
				[OTI_6]: 0,
				[OTI_7]: 0,
				[OTI_1]: 0,
				[OTI_2A]: 0,
				[OTI_2B]: 0,
				[OTI_3]: 0,
				[OTI_4]: 0,
				[OTI_5]: 0,
				[OTI_6]: 0,
				[OTI_7]: 0,

				WP1AA: 0,
				WP1BA: 0,

				IP1AAAB: 0,
				IP1AABA: 0,
				IP1BABAA: 0,
				IP1CAA: 0,
				IP1DAAAA: 0,
				IP1EAA: 0,
				IP1FAA: 0,
				IP1JAA: 0,
				IP1NABAA: 0,
				IP1PAB: 0,
				IP2AB: 0,

				C1AA: 0,
				C1DA: 0,
				C1EAAA: 0,
				C1EABA: 0,
				C1MA: 0,
				C2A: 0
			}
		},
		R0109G4: 0,
		R0109G4_override: 0,
		R0109G5: 0,
		R0109G5_override: 0,

		R0109G6: 0,
		// R0109G6_override: 0,
		R0109G7: 0,
		// R0109G7_override: 0,

		R01091G3: {
			q: {
				'1': null
			},
			a: {
				'1': 0
			}
		},
		R01091G4: 0,
		R01091G5: 0,

		R01091G6: 0,
		R01091G6_override: 0,
		R01091G7: 0,
		R01091G7_override: 0,

		R011G3: 0,

		R0111G3: {
			q: {
				[W2A]: null,
				[W4]: null,
				[W6A]: null,
				[W7A]: null
			},
			a: {
				[W2A]: 0,
				[W4]: 0,
				[W6A]: 0,
				[W7A]: 0
			}
		},

		R0112G3: {
			q: {
				'1': null,
				'2': null
			},
			a: {
				'1': 0,
				'2': 0
			}
		},

		R0113G3: {
			q: {
				W2A: null,
				W3B: null,
				W4A: null,
				W6AA: null,
				W7AA: null,

				WP1BA: null,

				IP1AAAA: null,
				IP1AAAB: null,
				IP1BAAAA: null,
				IP1DABAA: null,
				IP1GAA: null,
				IP1HAA: null,
				IP1KAA: null,
				IP1LAA: null,
				IP1MAA: null,
				IP1NAAAA: null,
				IP1PAA: null,
				IP2AAA: null,
				IP2BA: null,

				C1BA: null,
				C1CA: null,
				C1DA: null,
				C1EAAA: null,
				C1EABA: null,
				C1FA: null,
				C1GA: null,
				C1HA: null,
				C1IA: null,
				C1JA: null,
				C1KAAAA: null,
				C1KAABA: null,
				C1KBAAA: null,
				C1KBABA: null,
				C1KCAAA: null,
				C1KCABA: null,
				C1KDAAA: null,
				C1KDABA: null,
				C1KEA: null,
				C1LA: null,
				C1MA: null,
				C1NA: null,
				C1OAA: null,
				C1OBA: null,
				C1PA: null,
				C1QA: null,
				C1RA: null,
				C1SA: null,
				C1TA: null,
				C1UA: null,
				C1VA: null,
				C1WA: null,

				ALIM1A: null,
				S1BA: null,
				S1CA: null,
				S1DA: null
			},
			a: {
				W2A: 0,
				W3B: 0,
				W4A: 0,
				W6AA: 0,
				W7AA: 0,

				WP1BA: 0,

				IP1AAAA: 0,
				IP1AAAB: 0,
				IP1BAAAA: 0,
				IP1DABAA: 0,
				IP1GAA: 0,
				IP1HAA: 0,
				IP1KAA: 0,
				IP1LAA: 0,
				IP1MAA: 0,
				IP1NAAAA: 0,
				IP1PAA: 0,
				IP2AAA: 0,
				IP2BA: 0,

				C1BA: 0,
				C1CA: 0,
				C1DA: 0,
				C1EAAA: 0,
				C1EABA: 0,
				C1FA: 0,
				C1GA: 0,
				C1HA: 0,
				C1IA: 0,
				C1JA: 0,
				C1KAAAA: 0,
				C1KAABA: 0,
				C1KBAAA: 0,
				C1KBABA: 0,
				C1KCAAA: 0,
				C1KCABA: 0,
				C1KDAAA: 0,
				C1KDABA: 0,
				C1KEA: 0,
				C1LA: 0,
				C1MA: 0,
				C1NA: 0,
				C1OAA: 0,
				C1OBA: 0,
				C1PA: 0,
				C1QA: 0,
				C1RA: 0,
				C1SA: 0,
				C1TA: 0,
				C1UA: 0,
				C1VA: 0,
				C1WA: 0,

				ALIM1A: 0,

				S1BA: 0,
				S1CA: 0,
				S1DA: 0
			}
		},

		R012G3: 0,

		R013G1: false,
		R013G2: false,
		R013G3: false,
		R013G4: false,
		R013G5: false,
		R013G6: false,
		R013G7: false,
		R013G8: false,

		R014G3: {
			q: {
				Disc1: {
					sub_q: {
						apartment_area: false,
						expenses: false
					}
				},
				Disc2: {
					sub_q: {
						expenses: false
					}
				},
				Disc3: {
					sub_q: {
						expenses: false
					}
				},
				Disc4: {
					sub_q: {
						expenses: false
					}
				},
				Disc4B: {
					sub_q: {
						expenses: false,
						family: false
					}
				},
				Disc5: {
					sub_q: {
						expenses: false
					}
				},
				Disc6: {
					sub_q: {
						expenses: false
					}
				},
				Disc7: {
					sub_q: {
						expenses: false
					}
				},
				Disc8: {
					sub_q: {
						expenses: false
					}
				}
			},
			a: {
				Disc1: {
					apartment_area: 0,
					expenses: 0
				},
				Disc2: {
					expenses: 0
				},
				Disc3: {
					expenses: 0
				},
				Disc4: {
					expenses: 0
				},
				Disc4B: {
					expenses: 0,
					family: 0
				},
				Disc5: {
					expenses: 0
				},
				Disc6: {
					expenses: 0
				},
				Disc7: {
					expenses: 0
				},
				Disc8: {
					expenses: 0
				}
			}
		},

		R015G3: 0,
		R016G3: 0,
		R017G3: 0,

		R018G3: 0,
		R019G3: 0,
		R020G3: 0,
		// R021G3: 0,
		R021G3: {
			q: {
				[ID1BBE]: null,
				[IP2C]: null,
				[ALIM2E]: null,
				Int1AE: null
			},
			a: {
				[ID1BBE]: 0,
				[IP2C]: 0,
				[ALIM2E]: 0,
				Int1AE: 0
			}
		},

		R0221G3: 0,
		R0222G3: 0,

		R0231G3: 0,

		R024G3: 0,
		R024G4: 0,
		R025G3: 0,
		R025G4: 0,

		R0261G3: 0,
		R0261G4: 0,
		R0262G3: 0,
		R0262G4: 0,

		R027G3: 0,
		R027G4: 0,
		R028G3: 0,
		R028G4: 0,

		HBANKACC: '',
		HBANKNAME: '',
		HMFO: '',

		T1RXXXXG2: [],
		T1RXXXXG3S: [],
		T1RXXXXG4: [],
		T1RXXXXG5: [],
		T1RXXXXG6: [],
		T1RXXXXG7: [],

		HJ1: false,
		HJ2: false,
		T2RXXXXG2S: [],
		T3RXXXXG2S: [],

		HD1: true,
		HD2: true,
		HFILL: moment(new Date()).format('DD/MM/YYYY'),

		HBOS: ''
		//HBOS: store.getState().user.authenticated_by == "acsk" ? store.getState().user.profile.fullname : '',
	};

	['R0101G4', 'R0101G5'].forEach(field => {
		Object.keys(form_data_definition['R0101G3']['a'])
			.filter(key => {
				return key !== '0';
			})
			.forEach(key => {
				//"R0101G4_1" = 0,
				//"R0101G4_1_override" = false,
				form_data_definition[`${field}_${key}`] = 0;
				form_data_definition[`${field}_${key}_override`] = false;
			});
	});

	['R0102G4', 'R0102G5', 'R0102G6', 'R0102G7'].forEach(field => {
		Object.keys(form_data_definition['R0102G3']['a'])
			.filter(key => {
				return key !== '0';
			})
			.forEach(key => {
				form_data_definition[`${field}_${key}`] = 0;

				if (['R0102G4', 'R0102G5'].indexOf(field) > -1) {
					return;
				}

				form_data_definition[`${field}_${key}_override`] = false;
			});
	});

	['R0103G4', 'R0103G5', 'R0103G6', 'R0103G7'].forEach(field => {
		Object.keys(form_data_definition['R0103G3']['a'])
			.filter(key => {
				return key !== '0';
			})
			.forEach(key => {
				form_data_definition[`${field}_${key}`] = 0;

				if (['R0103G4', 'R0103G5'].indexOf(field) > -1) {
					return;
				}

				form_data_definition[`${field}_${key}_override`] = false;
			});
	});

	['R0104G4', 'R0104G5', 'R0104G6', 'R0104G7'].forEach(field => {
		Object.keys(form_data_definition['R0104G3']['a'])
			.filter(key => {
				return key !== '0';
			})
			.forEach(key => {
				let fieldsObj = {
					value: 0
				};

				form_data_definition[`${field}_${key}`] = [fieldsObj];
			});
	});

	['R0105G4', 'R0105G5', 'R0105G6', 'R0105G7'].forEach(field => {
		Object.keys(form_data_definition['R0105G3']['a'])
			.filter(key => {
				return key !== '0';
			})
			.forEach(key => {
				form_data_definition[`${field}_${key}`] = 0;

				if (['R0105G4', 'R0105G5'].indexOf(field) > -1) {
					return;
				}

				form_data_definition[`${field}_${key}_override`] = false;
			});
	});

	['R0106G6', 'R0106G7'].forEach(field => {
		Object.keys(form_data_definition['R0106G3']['a'])
			.filter(key => {
				return key !== '0';
			})
			.forEach(key => {
				let fieldsObj = {
					value: 0,
					override: false
				};

				form_data_definition[`${field}_${key}`] = [fieldsObj];
			});
	});

	['R0109G4', 'R0109G5', 'R0109G6', 'R0109G7'].forEach(field => {
		Object.keys(form_data_definition['R0109G3']['a'])
			.filter(key => {
				return key !== '0';
			})
			.forEach(key => {
				form_data_definition[`${field}_${key}`] = 0;

				if (['R0109G4', 'R0109G5'].indexOf(field) > -1) {
					return;
				}

				form_data_definition[`${field}_${key}_override`] = false;
			});
	});

	['R01091G4', 'R01091G5', 'R01091G6', 'R01091G7'].forEach(field => {
		Object.keys(form_data_definition['R01091G3']['a'])
			.filter(key => {
				return key !== '0';
			})
			.forEach(key => {
				form_data_definition[`${field}_${key}`] = 0;

				if (['R01091G4', 'R01091G5'].indexOf(field) > -1) {
					return;
				}

				form_data_definition[`${field}_${key}_override`] = false;
			});
	});

	return form_data_definition;
};

export { get_form_data_definition };
