import { R0109G3, R0113G3 } from '../_xmlConstId';
import { S1, S1A, S1AA, S1B, S1BA, S1C, S1CA, S1D, S1DA } from '../_declaretion_answer_id';
import { B5D } from '../../_questions_OLD';
import { INPUT, CHECKBOX } from '../../elementTypes';

export const S = [
	{
		// S1 QUESTION
		id: S1,
		title:
			'Оберіть вид стипендії (державної премії, щомісячної довічної плати), яку Ви отримували упродовж звітного року',
		name: S1,
		quizOpen: [B5D],
		question_title: true,
		answers: []
	},
	{
		// S1 QUESTION > S1A
		id: S1A,
		title:
			"стипендії (включаючи суму її індексації, нараховану відповідно до закону), яка виплачується з бюджету учню, студенту, курсанту військових навчальних закладів, ординатору, аспіранту або ад'юнкту",
		name: S1A,
		element: CHECKBOX,
		answers: [
			{
				// S1 QUESTION > S1A > INPUT
				id: S1AA,
				title: 'Вкажіть суми отриманої стипендії за рік',
				name: S1AA,
				xmlId: [R0109G3, R0113G3],
				placeholder: 'Вкажіть суму',
				element: INPUT
			}
		]
	},
	{
		// S1 QUESTION > S1B
		id: S1B,
		title:
			'державні премії України або стипендії України, призначені законом, постановами Верховної Ради України, указами Президента України, винагороди спортсменам-чемпіонам України, призерам спортивних змагань міжнародного рівня, у тому числі спортсменам-особам з інвалідністю, а також державні нагороди чи винагороди від імені України, крім тих, що виплачуються коштами чи іншим майном, Нобелівські чи Абелівські премії',
		name: S1B,
		element: CHECKBOX,
		answers: [
			{
				// S1 QUESTION > S1B > INPUT
				id: S1BA,
				title:
					'Вкажіть суми нарахованих/виплачених коштів відповідно до довідок, розрахункових документів установ та організацій, що здійснювали такі виплати',
				name: S1BA,
				xmlId: R0113G3,
				placeholder: 'Вкажіть суму',
				element: INPUT
			}
		]
	},
	{
		// S1 QUESTION > S1C
		id: S1C,
		title:
			'щомісячну довічна плата з Державного бюджету України за звання дійсного члена та члена-кореспондента (академіка)  Національної академії наук України, Української академії аграрних наук, Академії медичних наук України, Академії педагогічних наук України, Академії правових наук України та Академії мистецтв України',
		name: S1C,
		element: CHECKBOX,
		answers: [
			{
				// S1 QUESTION > S1C > INPUT
				id: S1CA,
				title: 'Вкажіть суму коштів, яку Ви отримали упродовж звітного року',
				name: S1CA,
				xmlId: R0113G3,
				placeholder: 'Вкажіть суму',
				element: INPUT
			}
		]
	},
	{
		// S1 QUESTION > S1D
		id: S1D,
		title: 'премія переможцю Міжнародного конкурсу з української мови імені Петра Яцика',
		name: S1D,
		element: CHECKBOX,
		answers: [
			{
				// S1 QUESTION > S1D > INPUT
				id: S1DA,
				title: 'Вкажіть суму премії',
				name: S1DA,
				xmlId: R0113G3,
				placeholder: 'Вкажіть суму',
				element: INPUT
			}
		]
	}
];
