import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import hoc from '../../HOC/hoc';
import Card from '../../components/Card';
import { StyledButton } from '../../components/Button/Button';

import './styles.scss';

class Documents extends Component {
	state = {
		isOpen: true
	};
	render() {
		return (
			<div className="documents">
				<Grid className="documents-title-block">
					<Grid item>
						<h3 className="documents-title-block__title">Список сформованих декларацій</h3>
					</Grid>
					<Grid item>
						<Link to="/documents/create-new">
							<StyledButton variant="contained">Створити декларацію</StyledButton>
						</Link>
					</Grid>
				</Grid>
				<ul className="planning_list">
					<li className="panning_item">
						<Card />
					</li>
					<li className="panning_item">
						<Card />
					</li>
					<li className="panning_item">
						<Card />
					</li>
				</ul>
			</div>
		);
	}
}

export default hoc(null)(Documents);
