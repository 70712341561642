import { combineReducers } from 'redux';
import findIndex from 'lodash/findIndex';
import dropWile from 'lodash/dropWhile';

import { declarationSubSections } from '../../data/declaration';
import {
	GET_CURRENT_DECLARATION_INDEX,
	GET_DECLARATIONS,
	GET_DECLARATION,
	GET_ALL_DATA,
	CALCULATE_DECLARATION,
	SET_STEP_INDEX,
	GET_DECLARATION_ANSWERS
} from '../actions/declaration_action';
import { SET_CURRENT_SUBSECTION_INDEX } from '../actions/nav_actions';

// import { doc_definition } from '../../pages/declaration/types/F01002/doc_definition';
import { get_form_data_definition } from '../../pages/declaration/types/F01002/form_data';

import ID from './decl_subsectons/id_reducer';
import P from './decl_subsectons/p_reducer';
import W from './decl_subsectons/w_reducer';
import Common from './decl_subsectons/common_reducer';
import OFI from './decl_subsectons/ofi_reducer';
import OTI from './decl_subsectons/oti_reducer';
import Penalty from './decl_subsectons/penalty_reducer';
import Disc from './decl_subsectons/disc_reducer';
import WP from './decl_subsectons/wp_reducer';
import IP from './decl_subsectons/ip_reducer';
import C from './decl_subsectons/c_reducer';
import R from './decl_subsectons/r_reducer';
import ALIM from './decl_subsectons/alim_reducer';
import LEASE from './decl_subsectons/lease_reducer';
import S from './decl_subsectons/s_reducer';
import INT from './decl_subsectons/int_reducer';
import D from './decl_subsectons/d_reducer';
import ADDB from './decl_subsectons/addb_reducer';
import ONTI from './decl_subsectons/onti_reducer';
import SALEPRO from './decl_subsectons/salePro_reducer';
import INVESTP from './decl_subsectons/investp_reducer';
import IPA from './decl_subsectons/ipa_reducer';
import ENTERPRL from './decl_subsectons/enterprl_reducer';
import EITA from './decl_subsectons/eita_reducer';

import * as fromNav from '../reducers/nav_reducer';

const initialStepIndex = 0;

const currentStepIndex = (state = initialStepIndex, action) => {
	switch (action.type) {
		case SET_STEP_INDEX:
			return action.payload;

		default:
			return state;
	}
};

const declaration = (state = {}, action) => {
	switch (action.type) {
		case GET_DECLARATION:
			return {
				...state,
				...action.payload
			};

		case CALCULATE_DECLARATION:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};

const initialCurrentIndexState = 0;

const currentIndex = (state = initialCurrentIndexState, action) => {
	switch (action.type) {
		case GET_CURRENT_DECLARATION_INDEX:
			return {
				...state
			};

		case SET_CURRENT_SUBSECTION_INDEX:
			return action.payload;

		default:
			return state;
	}
};

const initialDeclarationAnswers = {};

const answers = (state = initialDeclarationAnswers, action) => {
	switch (action.type) {
		case GET_DECLARATION_ANSWERS:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};

const data = (state = { ...get_form_data_definition() }, action) => {
	switch (action.type) {
		case GET_ALL_DATA:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};

const subsections = (state = declarationSubSections, action) => {
	switch (action.type) {
		case GET_DECLARATIONS:
			return {
				...state,
				...action.payload
			};

		default:
			return state;
	}
};

// ROOT REDUCER
const declarationReducer = combineReducers({
	answers,
	data,
	declaration,
	subsections,
	currentIndex,
	currentStepIndex,
	Common,
	P,
	W,
	ID,
	OFI,
	OTI,
	Penalty,
	Disc,
	WP,
	IP,
	C,
	R,
	ALIM,
	LEASE,
	S,
	INT,
	D,
	ADDB,
	ONTI,
	SALEPRO,
	INVESTP,
	IPA,
	ENTERPRL,
	EITA
});
export default declarationReducer;

// SELECTORS
export const getSubSection = (state, currentIndex) => state.declaration.subsections[currentIndex];

export const getPropertySubQuestion = (state, subId) =>
	state.declaration.P.question.variants[
		findIndex(state.declaration.P.question.variants, element => element.id === subId)
	];

export const getNextSubssection = (state, currentSubsectionName) => {
	const actualNavigation = fromNav.getActualNavigation(state.quiz.answers, state.navigation.navSubSections);
	const currentIndex = findIndex(actualNavigation, subsection => subsection.srsName === currentSubsectionName);

	if (actualNavigation.length > currentIndex + 1) {
		return actualNavigation[currentIndex + 1];
	}
};

export const getSubSectionDatas = state => {
	return dropWile(Object.values(state), item => !item.data).map(item => item.data);
};
