import * as tableCells from './index';
import * as F1tableCells from '../linked_docs/F01112/fields/calc_rules/index';
import * as F2tableCells from '../linked_docs/F01114//fields/calc_rules/index';

// Загальна сума річного доходу
// Для F01001-09
// (рядок 10.1 + рядок 10.2 + рядок 10.3 + графа 6 "УСЬОГО" рядка 1 додатка Ф1 + рядок 10.5 + рядок 10.6 + "УСЬОГО" графи 8
// розділу І додатка Ф2 + "УСЬОГО" графи 9 розділу ІІІ додатка Ф2 + рядок 10.9 + рядок 11)
// Для F01001-10
// (рядок 10.1 + рядок 10.2 + рядок 10.3 + графа 6 "УСЬОГО" рядка 1 додатка Ф1 + рядок 10.5 + рядок 10.6 + "УСЬОГО" графи 9
// розділу І додатка Ф2 + "УСЬОГО" графи 9 розділу ІV  додатка Ф2 + рядок 10.9 + рядок 11)

export const R012G3 = function(formValues) {
	const cells = [
		'R0101G3',
		'R0102G3',
		'R0103G3',
		'R001G6',
		'R0105G3',
		'R0106G3',
		'R01G9',
		'R04G9',
		'R0109G3',
		'R01091G3',
		'R011G3'
	];

	return cells
		.map(cell => {
			let result = tableCells[cell]
				? tableCells[cell](formValues)
				: F1tableCells[cell]
				? F1tableCells[cell](formValues)
				: F2tableCells[cell]
				? F2tableCells[cell](formValues)
				: 0;

			return result;
		})
		.reduce((acc, cur) => {
			return acc + cur;
		}, 0);
};
