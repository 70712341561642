import React from 'react';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

const IntegrationDownshift = ({ item, answer, change }) => {
	const renderInput = inputProps => {
		const { InputProps, ref, ...other } = inputProps;

		return (
			<TextField
				InputProps={{
					inputRef: ref,
					...InputProps
				}}
				{...other}
			/>
		);
	};

	const renderSuggestion = ({ suggestion, index, itemProps, highlightedIndex, selectedItem }) => {
		const isHighlighted = highlightedIndex === index;
		const isSelected = (selectedItem || '').indexOf(suggestion.name) > -1;

		return (
			<MenuItem
				{...itemProps}
				key={suggestion.name}
				selected={isHighlighted}
				component="div"
				style={{
					fontWeight: isSelected ? 500 : 400
				}}
				id={suggestion.id}
			>
				{suggestion.name}
			</MenuItem>
		);
	};

	const getSuggestions = value => {
		const inputValue = deburr(value.trim()).toLowerCase();
		const inputLength = inputValue.length;
		let count = 0;

		return inputLength === 0
			? []
			: item.options.filter(suggestion => {
					const keep = count < 10 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

					if (keep) {
						count += 1;
					}

					return keep;
			  });
	};
	return (
		<div>
			<Downshift id="downshift" onChange={change} selectedItem={answer[item.id]}>
				{({
					getInputProps,
					getItemProps,
					getMenuProps,
					highlightedIndex,
					inputValue,
					isOpen,
					selectedItem
				}) => (
					<div>
						{renderInput({
							fullWidth: true,
							InputProps: getInputProps({
								placeholder: item.placeholder
								// value: answer[item.id]
							})
						})}
						<div {...getMenuProps()}>
							{isOpen ? (
								<Paper square>
									{getSuggestions(inputValue).map((suggestion, index) =>
										renderSuggestion({
											suggestion,
											index,
											itemProps: getItemProps({ item: suggestion.name }),
											highlightedIndex,
											selectedItem
										})
									)}
								</Paper>
							) : null}
						</div>
					</div>
				)}
			</Downshift>
		</div>
	);
};

export default IntegrationDownshift;
