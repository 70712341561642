import { connect } from 'react-redux';

import { googleSignIn, googleSignOut } from '../../store/actions/user_actions';

import { Login } from '../auth';

const mapStateToProps = state => ({
	user: state.user
});

const mapDispatchToProps = {
	googleSignIn,
	googleSignOut
};

export const LoginContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Login);
