import { connect } from 'react-redux';

import Enterprl from '../subsections/Enterprl';
import { getIpaData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.ENTERPRL.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getIpaData
};

const EnterprlContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Enterprl);

export default EnterprlContainer;
