import {
	ID1,
	ID1A,
	ID1B,
	ID1BA,
	ID1BB,
	ID1BBA,
	ID1BBB,
	ID1BBC,
	ID1BBD,
	ID1BBE,
	ID1AA,
	ID2,
	ID2A,
	ID2B,
	ID2AA,
	ID3,
	ID3A,
	ID3AA,
	ID3B,
	ID3BA,
	ID3C,
	ID3CA,
	ID3D,
	ID3DA,
	ID3E,
	ID3EA,
	ID3F,
	ID3FA,
	ID4,
	ID4A,
	ID4B,
	ID4AA
	// ID4AAA
} from '../_declaretion_answer_id';
import { R0105G3, R0106G3, R021G3 } from '../_xmlConstId';
import { B6B, B1A, B1B, B3A, B3B, B3C } from '../../_questions_OLD';
import { INPUT, RADIO, CHECKBOX, SELECT, DATE } from '../../elementTypes';

import countries from '../../../data/countries.json';
import currency from '../../../data/currencies.json';

export const ID = [
	//! Q1 - ID1
	{
		id: ID1,
		title: 'Оберіть резидентство спадкодавця (дарувальника)',
		xmlId: R0105G3,
		// helpers: [
		// 	'дані (усього отримано доходу) з довідок про доходи фізичної особи, отриманих у  Роботодавців, у яких Ви працювали упродовж звітного року'
		// ],
		quizOpen: B6B,
		answers: [
			{
				id: ID1A,
				title: 'резидент(и) України',
				xmlId: R0105G3,
				element: RADIO,
				name: ID1,
				quizOpen: [B1A, B1B],
				sibling_children: [
					ID1BA,
					ID1BB,
					ID1B,
					ID1BBA,
					ID1BBB,
					ID1BBC,
					ID1BBD,
					ID1BBE,
					ID1BBA,
					ID1BBB,
					ID1BBC,
					ID1BBC,
					ID1BBD,
					ID1BBE
					// 'ID1BBC_input',
					// `ID1BBD_input`,
					// `ID1BBE_input`
				] // Очистка соседнего ответа с подвопросами
			},
			{
				id: ID1B,
				title: 'нерезидент(и) України',
				xmlId: R0105G3,
				element: RADIO,
				name: ID1,
				quizOpen: [B1A, B1B],
				sibling_children: [
					ID1BA,
					// 'ID1AA_input',
					ID1AA,
					ID2AA
				] // Очистка соседнего ответа с подвопросами
			}
		]
	},
	{
		parent: ID1B,
		id: ID1B,
		title: 'Оберіть країну-походження доходів та її валюту',
		helpers: [
			"Підказка 1. Ви можете зменшити річне податкового зобов'язання на суму податків, сплачених за кордоном згідно з нормами міжнародних договорів про уникнення подвійного оподаткування.",
			'Підказка 2. У разі можливості неоднозначного трактування норм ПКУ рекомендуємо звернутися за індивідуальною податковою консультацією до контролюючого органу',
			'Підказка 3. У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою)'
		],
		answers: [
			{
				parent: ID1B,
				id: ID1BA,
				title: 'Україна',
				xmlId: R0105G3,
				element: RADIO,
				name: ID1B,
				sibling_children: [
					'ID1BBA',
					'ID1BBB',
					// 'ID1BBC_input',
					'ID1BBC',
					'ID1BBD',
					'ID1BBE'
					// 'ID1BBD_input',
					// 'ID1BBE_input'
				] // Очистка соседнего ответа с подвопросами
			},
			{
				parent: ID1B,
				id: ID1BB,
				title: 'Інші країни',
				xmlId: R0106G3,
				element: RADIO,
				name: ID1B,
				sibling_children: [ID1BA] // Очистка соседнего ответа с подвопросами
			}
		]
	},
	{
		parent: ID1BB,
		id: ID1BB,
		xmlId: R0106G3,
		title: 'Оберіть країну-походження доходів та її валюту',
		answers: [
			{
				parent: ID1BB,
				id: ID1BBA,
				title: 'Kраїнa',
				xmlId: R0106G3,
				name: 'country_name',
				element: SELECT,
				options: countries
			},
			{
				parent: ID1BB,
				id: ID1BBB,
				title: 'Валюта',
				xmlId: R0106G3,
				name: 'currency_name',
				element: SELECT,
				options: currency
			},
			{
				parent: ID1BB,
				id: ID1BBC,
				title: 'Дата',
				xmlId: R0106G3,
				name: 'currency_date',
				element: DATE
			},
			{
				parent: ID1BB,
				id: ID1BBD,
				title:
					'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
				element: INPUT,
				name: 'revenue',
				placeholder: 'Введіть суму (грн., коп.)',
				xmlId: R0106G3
			},
			{
				parent: ID1BB,
				id: ID1BBE,
				// title:
				// 	'Вкажіть суму  сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
				element: INPUT,
				name: 'tax',
				placeholder: 'НАЛОГ',
				// placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R021G3
			}
		]
	},
	{
		parent: ID1BA,
		id: ID1BA,
		title:
			'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину',
		answers: [
			{
				id: ID1BA,
				parent: ID1BA,
				element: INPUT,
				name: '',
				placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R0105G3
			}
		]
	},
	{
		parent: ID1A,
		id: ID1AA,
		title:
			'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину',
		answers: [
			{
				parent: ID1A,
				id: ID1AA,
				element: INPUT,
				name: '',
				placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R0105G3
			}
		]
	},

	//! Q2   ID2

	{
		parent: ID1A,
		id: ID2,
		title: 'Оберіть ступінь споріднення з спадкодавцем (дарувальником). ',
		xmlId: R0105G3,
		answers: [
			{
				parent: ID1A,
				id: ID2A,
				title: 'перший або другий ступінь',
				xmlId: R0105G3,
				element: RADIO,
				name: ID2
			},
			{
				parent: ID1A,
				id: ID2B,
				title: 'інші ступені споріднення, відмінні від першого та другого',
				element: RADIO,
				xmlId: R0105G3,
				name: ID2,
				sibling_children: [ID2A, ID2AA]
			}
		]
	},
	{
		parent: ID2A,
		id: ID2AA,
		title:
			'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину',
		answers: [
			{
				id: ID2AA,
				parent: ID2A,
				element: INPUT,
				name: '',
				placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R0105G3
			}
		]
	},
	//! Q3
	{
		id: ID3,
		title: 'Які об’єкти спадщини (дарування) Ви отримували від спадкодавця (дарувальника) – фізичної особи',
		xmlId: R0105G3,
		// name: ,
		// element: RADIO,
		// placeholder: 'Введіть суму доходу (грн., коп.)',
		answers: [
			{
				id: ID3A,
				title: 'об’єкт нерухомості',
				xmlId: R0105G3,
				element: CHECKBOX,
				// name: ,
				quizOpen: [B3A, B3B, B3C],
				subsection: {
					parent: ID3A,
					id: ID3AA,
					title:
						'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину',
					element: INPUT,
					name: 'ID3A1',
					placeholder: 'Checkbox MONEY',
					xmlId: R0105G3
				}
			},
			{
				id: ID3B,
				title:
					'об’єкт рухомого майна, зокрема предмет антикваріату або витвір мистецтва, дорогоцінне каміння, метали та відповідні прикраси, будь-який транспортний засіб та приладдя до нього та інші види рухомого майна',
				xmlId: R0105G3,
				element: CHECKBOX,
				// name: ,
				quizOpen: [B3A, B3B, B3C],
				subsection: {
					parent: ID3B,
					id: ID3BA,
					title:
						'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину',
					element: INPUT,
					name: 'ID3B1',
					placeholder: 'Checkbox MONEY',
					xmlId: R0105G3
				}
			},
			{
				id: ID3C,
				title:
					'об’єкт комерційної власності, а саме: цінні папери (крім депозитного (ощадного), іпотечного сертифіката), корпоративне право, власність на об’єкт бізнесу як такий, тобто власність на цілісний майновий комплекс, інтелектуальна (промислова) власність або право на отримання доходу від неї, майнові та немайнові права',
				xmlId: R0105G3,
				element: CHECKBOX,
				// name: ,
				subsection: {
					parent: ID3C,
					id: ID3CA,
					title:
						'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину',
					element: INPUT,
					name: 'ID3C',
					placeholder: 'Checkbox MONEY',
					xmlId: R0105G3
				}
			},
			{
				id: ID3D,
				title:
					' сума страхового відшкодування (страхових виплат) за страховими договорами, а також сума, що зберігається відповідно на пенсійному депозитному рахунку, накопичувальному пенсійному рахунку, індивідуальному пенсійному рахунку спадкодавця – учасника накопичувальної системи пенсійного забезпечення',
				xmlId: R0105G3,
				element: CHECKBOX,
				// name: ,
				subsection: {
					parent: ID3D,
					id: ID3DA,
					title:
						'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину',
					element: INPUT,
					name: 'ID3D',
					placeholder: 'Checkbox MONEY',
					xmlId: R0105G3
				}
			},
			{
				id: ID3E,
				title:
					'готівка або кошти, що зберігаються на рахунках спадкодавця, відкритих у банківських і небанківських фінансових установах, у тому числі депозитні (ощадні), іпотечні сертифікати, сертифікати фонду операцій з нерухомістю',
				xmlId: R0105G3,
				element: CHECKBOX,
				// name: ,
				quizOpen: [B3A, B3B],
				subsection: {
					parent: ID3E,
					id: ID3EA,
					title:
						'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину',
					element: INPUT,
					name: 'ID3E1',
					placeholder: 'Checkbox MONEY',
					xmlId: R0105G3
				}
			},
			{
				id: ID3F,
				title:
					'грошові заощадження, поміщені до 02.01.1992 в установах Ощадного банку СРСР та державного страхування СРСР, що діяли та території України, а також державні цінні папери та грошові заощадження (облігації Державної цільової безпроцентної позики 1990 року, облігації Державної внутрішньої виграшної позики 1982 року, державні казначейські зобов’язання СРСР, сертифікати Ощадного банку СРСР) та грошові заощадження громадян України, поміщені в установи Ощадного банку України та колишнього Укрдержстраху упродовж 1992–1994 років, погашення яких не відбулося',
				xmlId: R0105G3,
				element: CHECKBOX,
				// name: ,
				subsection: {
					parent: ID3F,
					id: ID3FA,
					title:
						'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину',
					element: INPUT,
					name: 'ID3F_input',
					placeholder: 'Checkbox MONEY',
					xmlId: R0105G3
				}
			}
		]
	},
	// [ID3A1, ID3D, ID3C, ID3B1, ID3E1, ID3F]

	{
		id: ID4,
		title: 'Чи отримували Ви дарунок від юридичної особи або фізичної особи – підприємця?',
		xmlId: R0105G3,
		// name: ,
		// element: RADIO,
		// placeholder: 'Введіть суму доходу (грн., коп.)',
		answers: [
			{
				id: ID4A,
				title: 'Так',
				xmlId: R0105G3,
				element: RADIO,
				name: ID4
			},
			{
				id: ID4B,
				title: 'Ні',
				xmlId: R0105G3,
				element: RADIO,
				name: ID4,
				sibling_children: [ID4AA]
			}
		]
	},
	{
		parent: ID4A,
		id: ID4AA,
		title:
			'Вкажіть суму оціночну / орієнтовну вартість об’єкта дарування, який Ви отримали протягом звітного періоду',
		xmlid: R0105G3,
		answers: [
			{
				parent: ID4AA,
				id: ID4AA,
				element: INPUT,
				xmlId: R0105G3,
				placeholder: 'MONEY'
			}
		]
	}
];
