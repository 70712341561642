import React from 'react';

import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { sendCreacteNewDeclAction } from '../../../store/actions/newQuizActions';

import hoc from '../../../HOC/hoc';

import NewCreateDeclForm from './NewCreateDeclForm';

const NewCreateDecl = props => {
	const handleCreateNewDecl = async (values, setSubmitting) => {
		const { sendCreacteNewDeclAction, history } = props;
		const response = await sendCreacteNewDeclAction(values, setSubmitting);

		if (response.status === 201) {
			// TODO: change route
			history.push('/documents/quiz');
		}
	};

	const handleBack = () => {
		const { history } = props;
		history.push('/documents');
	};

	return <NewCreateDeclForm handleCreateNewDecl={handleCreateNewDecl} handleBack={handleBack} />;
};

export default compose(
	connect(
		null,
		{
			sendCreacteNewDeclAction
		}
	),
	withRouter,
	hoc(null)
)(NewCreateDecl);

// export default hoc(null)(NewCreateDecl);
