import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

import { LeftSide, RightSide } from '../components/Sides';
import Header from '../components/Header/Header';
import NavigationContainer from '../components/containers/NavigationContainer';

const hoc = (LeftComponent = NavigationContainer) => WrapComponent => {
	return class HOC extends Component {
		render() {
			return (
				<Grid container alignItems="center">
					<Header />

					<LeftSide className="leftSide">{LeftComponent !== null ? <LeftComponent /> : null}</LeftSide>

					<RightSide>
						<WrapComponent {...this.props} />
					</RightSide>
				</Grid>
			);
		}
	};
};

export default hoc;
