import React, { useState, useEffect } from 'react';
import Question from './components/Question';

import NextButton from '../../components/NextButton';

import { save, load } from '../../../../utils/localStorage';

import './styles.scss';

const initialAnswers = {
	WP1: null,
	WP1AA: '',
	WP1BA: ''
};

const initialData = {
	R0109G3: {
		q: { WP1AA: null, WP1BA: null },
		a: { WP1AA: 0, WP1BA: 0 }
	},
	R0113G3: {
		q: { WP1BA: null },
		a: { WP1BA: 0 }
	}
};

const WP = props => {
	const [answers, setAnswers] = useState(initialAnswers);
	const [data, setData] = useState(initialData);

	useEffect(() => {
		if (load('wp')) {
			const { data, answers } = load('wp');
			setAnswers(answers);
			setData(data);
			props.getWpData(data);
		}
	}, []);

	const handleChange = ({ currentTarget }) => {
		const { value, id, type, labels } = currentTarget;
		let newData = { ...data };
		let newAnswers = { ...answers };

		switch (type) {
			case 'text':
				if (id === 'WP1BA') {
					newData.R0113G3.a.WP1BA = value;
				}

				newData.R0109G3.a[id] = value;
				newAnswers[`${id}`] = value;
				break;

			case 'radio': {
				const groupName = labels[0].attributes['data-name'] && labels[0].attributes['data-name'].value;
				// очистка данных при изменение ответа
				if (id === 'WP1A') {
					newAnswers.WP1BA = '';
					newData.R0109G3.a.WP1BA = 0;
					newData.R0113G3.a.WP1BA = 0;
				}
				if (id === 'WP1B') {
					newAnswers.WP1AA = '';
					newData.R0109G3.a.WP1AA = 0;
				}

				newAnswers[groupName] = value;

				break;
			}
			default:
				break;
		}
		save('wp', { answers: newAnswers, data: newData });
		props.getWpData(newData);
		setData(newData);
		setAnswers(prevState => ({ ...prevState, ...newAnswers }));
	};

	const handleClickNextButton = () => props.getWpData(data);

	return (
		<div className="WP-wrap">
			{Object.values(props.subsection).map((item, i) => {
				if (item.id === 'WP1') {
					return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
				}

				if (answers.WP1 === item.parent) {
					return <Question key={i} item={item} state={{ data, answers }} change={handleChange} />;
				} else {
					return null;
				}
			})}
			<div className="btn-next-WP-wrap">
				<NextButton link={props.nextSubSection.srsName} click={handleClickNextButton} />
			</div>
		</div>
	);
};

export default WP;
