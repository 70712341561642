import { connect } from 'react-redux';

import { getActualNavigation } from '../../store/reducers';
import { getLeftNavigation, setCurrentSubsectionIndex } from '../../store/actions/nav_actions';

import Navigation from '../Navigation';

const mapStateToProps = state => {
	const { navSections, currentSubSectionIndex } = state.navigation;

	return {
		navSections,
		navSubSections: getActualNavigation(state),
		currentSubSectionIndex,
		answers: state.quiz.answers
	};
};

const mapDispatchToProps = {
	getLeftNavigation,
	setCurrentSubsectionIndex
};

const NavigationContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Navigation);

export default NavigationContainer;
