export const R0111G3 = function(formValues) {
	return Object.keys(formValues['R0111G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0111G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.reduce((acc, key) => {
			let curr = parseFloat(formValues['R0111G3']['a'][key]);
			return acc + curr;
		}, 0);
};

export const R0112G3 = function(formValues) {
	return Object.keys(formValues['R0112G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0112G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.reduce((acc, key) => {
			let curr = parseFloat(formValues['R0112G3']['a'][key]);
			return acc + curr;
		}, 0);
};

export const R0113G3 = function(formValues) {
	return Object.keys(formValues['R0113G3']['a'])
		.filter(key => {
			// return key != '0' && formValues['R0113G3']['q'][key] == 'yes';
			return key !== '0';
		})
		.reduce((acc, key) => {
			let curr = parseFloat(formValues['R0113G3']['a'][key]);
			let month = parseFloat(formValues['R0109G3']['a']['AddB12B']);

			switch (key) {
				case 'W2A':
					curr = curr <= 2470 ? curr : 2470;
					break;
				case 'IP1AAAB': //0.6
				case 'IP1AABA':
				case 'IP1BABAA':
				case 'IP1CAA':
				case 'IP1DAAAA':
					curr = curr * 0.6;
					break;
				case 'AddB12A':
					curr = curr * month > 11169 * month ? 11169 * month : curr * month;
					break;
				case 'AddB12B':
					curr = 0;
					break;
				case 'AddB5A':
					curr = curr > 930.75 ? 930.75 : curr;
					break;
				case 'AddB14A':
					curr = curr < 18615 ? curr : 0;
					break;
				case 'WP1BA':
					curr = curr < 930.75 ? curr : 930.75;
					break;

				case 'C1BA':
				case 'C1EAAA':
				case 'C1EABA':
					curr = curr > 2470 ? 2470 : curr;
					break;

				case 'C1KAABA':
				case 'C1KBABA':
				case 'C1KCABA':
				case 'C1KDABA':
					curr = curr > 2086500 ? 2086500 : curr;
					break;

				case 'C1MA':
					curr += curr <= 4940 ? curr : 4940;
					break;
				default:
					break;
			}

			return acc + curr;
		}, 0);
};

export const R011G3 = function(formValues) {
	return R0111G3(formValues) + R0112G3(formValues) + R0113G3(formValues);
};
