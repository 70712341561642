import { useState, useCallback, useEffect } from 'react';

const initialState = {
	Disc1: { apartment_area: '', expenses: '' },
	Disc2: { expenses: '' },
	Disc3: { expenses: '' },
	Disc4: { expenses: '' },
	Disc4B: { expenses: '', family: '' },
	Disc5: { expenses: '' },
	Disc6: { expenses: '' },
	Disc7: { expenses: '' },
	Disc8: { expenses: '' }
};

export const useForms = (state = initialState, cb = () => {}) => {
	const [answers, setAnswers] = useState(state);

	const _handleChange = useCallback(
		({ currentTarget }) => {
			const { value, name, id, type } = currentTarget;
			let newAnswers = { ...answers };

			switch (type) {
				case 'text':
					newAnswers[id][name] = value;

					break;
				default:
					break;
			}
			setAnswers(newAnswers);
			cb(newAnswers);
		},
		[answers]
	);

	useEffect(() => {
		setAnswers(state);
	}, [state]);

	return {
		state,
		_handleChange
	};
};

export default useForms;
