import { R0106G3, R0109G3, R0113G3, R021G3 } from '../_xmlConstId';
import {
	Int1,
	Int1A,
	Int1AA,
	Int1AB,
	Int1AC,
	Int1AD,
	Int1AE,
	Int1B,
	Int1BA,
	Int1C,
	Int1CA,
	Int1D,
	Int1DA,
	Int1E,
	Int1EA
} from '../_declaretion_answer_id';
import { B5FA, B5FB, B1B } from '../../_questions_OLD';
import { INPUT, CHECKBOX, SELECT, DATE } from '../../elementTypes';

import countries from '../../../data/countries.json';
import currency from '../../../data/currencies.json';

export const Int = [
	{
		// MAIN QUESTION
		id: Int1,
		title: 'Оберіть джерело, за яким Ви отримували проценти або дисконтний дохід',
		quizOpen: [B5FA],
		question_title: true
	},
	{
		parent: Int1,
		id: Int1A,
		title:
			'депозитний та/або поточний банківський рахунок та/або іменний ощадний сертифікат та/або вклад (депозит) у кредитних спілках за межами України тощо',
		name: Int1A,
		quizOpen: [B5FB],
		helpers: [
			"Підказка 1. Ви можете зменшити річне податкового зобов'язання на суму податків, сплачених за кордоном згідно з нормами міжнародних договорів про уникнення подвійного оподаткування.",
			'Підказка 2. У разі можливості неоднозначного трактування норм ПКУ рекомендуємо звернутися за індивідуальною податковою консультацією до контролюючого органу',
			'Підказка 3. У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою)'
		],
		element: CHECKBOX,
		answers: [
			{
				parent: Int1A,
				id: Int1AA,
				title: 'Kраїнa',
				xmlId: R0106G3,
				name: 'country_name',
				element: SELECT,
				options: countries
			},
			{
				parent: Int1A,
				id: Int1AB,
				title: 'Валюта',
				xmlId: R0106G3,
				name: 'currency_name',
				element: SELECT,
				options: currency
			},
			{
				parent: Int1A,
				id: Int1AC,
				title: 'Дата',
				xmlId: R0106G3,
				name: 'currency_date',
				element: DATE
			},
			{
				parent: Int1A,
				id: Int1AD,
				title:
					'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
				element: INPUT,
				name: 'revenue',
				placeholder: 'Введіть суму (грн., коп.)',
				xmlId: R0106G3
			},
			{
				parent: Int1A,
				id: Int1AE,
				title:
					'Вкажіть суму  сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
				element: INPUT,
				name: 'tax',
				placeholder: 'НАЛОГ',
				// placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R021G3
			}
		]
	},
	{
		parent: Int1,
		id: Int1B,
		title:
			'депозитний та/або поточний банківський рахунок та/або іменний ощадний сертифікат та/або вклад (депозит) у кредитних спілках України тощо',
		name: Int1B,
		element: CHECKBOX,
		xmlId: R0109G3,
		quizOpen: [B1B],
		answers: [
			{
				// FIRST CB > CB > INPUT
				id: Int1BA,
				xmlId: R0109G3,
				// title: '',
				name: Int1BA,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: Int1,
		id: Int1C,
		title:
			'поточний банківський рахунок, за яким на Вашу користь здійснюються виключно виплати заробітної плати, стипендій, пенсій, соціальної допомоги та інших передбачених законом соціальних виплат',
		name: Int1C,
		xmlId: R0113G3,
		element: CHECKBOX,
		answers: [
			{
				id: Int1CA,
				xmlId: R0113G3,
				// title: '',
				name: Int1CA,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: Int1,
		id: Int1D,
		title: "державні цінні папери, емітовані МФУ, боргові зобов'язання НБУ",
		name: Int1D,
		xmlId: R0109G3,
		element: CHECKBOX,
		answers: [
			{
				id: Int1DA,
				xmlId: R0109G3,
				// title: '',
				name: Int1DA,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: Int1,
		id: Int1E,
		title:
			'державні цінні папери або облігації місцевих позик, або боргові цінні папери, виконання зобов’язань за якими забезпечено державними або місцевими гарантіями, якщо такі цінні папери придбані за межами території України через уповноважених агентів - нерезидентів, або позики (кредити або зовнішні запозичення), надані державі або бюджету Автономної Республіки Крим чи міському бюджету, які відображаються в Державному бюджеті України або місцевих бюджетах чи кошторисі Національного банку України, або кредити (позики), надані суб’єктам господарювання, виконання яких забезпечено державними або місцевими гарантіями',
		name: Int1E,
		xmlId: R0109G3,
		element: CHECKBOX,
		answers: [
			{
				id: Int1EA,
				xmlId: R0109G3,
				// title: '',
				name: Int1EA,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	}
];
