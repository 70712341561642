import { ID } from '../../../data/declaration/sub-sections/ID';
import { GET_ID_DATA } from '../../actions/declaration_action';

const initialState = {
	questions: { ...ID },
	subId: null,
	data: {}
};

const id = (state = initialState, action) => {
	switch (action.type) {
		case GET_ID_DATA:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default id;
