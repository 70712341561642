import { connect } from 'react-redux';

import EI_TA from '../subsections/EI_TA';
import { getEITAData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.EITA.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getEITAData
};

const EITAContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(EI_TA);

export default EITAContainer;
