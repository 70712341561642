import { GET_ANSWERS } from './quiz_actions';

export const GET_LEFT_NAVIGATION = 'GET_LEFT_NAVIGATION';

export const getLeftNavigation = () => {
	return dispatch => {
		dispatch({
			type: GET_ANSWERS
		});

		dispatch({
			type: GET_LEFT_NAVIGATION
		});
	};
};

export const SET_CURRENT_SUBSECTION_INDEX = 'SET_CURRENT_SUBSECTION_INDEX';

export const setCurrentSubsectionIndex = index => {
	return {
		type: SET_CURRENT_SUBSECTION_INDEX,
		payload: index
	};
};
