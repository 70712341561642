import isArray from 'lodash/isArray';

export const T4RXXXXG2 = function(formValues) {
	return Object.keys(formValues['R0108G3']['a'])
		.filter(key => key !== '0')
		.map(key => {
			let result = [];
			let keyValue = formValues['R0108G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => fieldsSet['T4RXXXXG2']);
			}
			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T4RXXXXG3S = function(formValues) {
	return Object.keys(formValues['R0108G3']['a'])
		.filter(key => key !== '0')
		.map(key => {
			let result = [];
			let keyValue = formValues['R0108G3']['a'][key];
			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => fieldsSet['T4RXXXXG3S']);
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T4RXXXXG4S = function(formValues) {
	return Object.keys(formValues['R0108G3']['a'])
		.filter(key => key !== '0')
		.map(key => {
			let result = [];
			let keyValue = formValues['R0108G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => fieldsSet['T4RXXXXG4S']);
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T4RXXXXG5 = function(formValues) {
	return Object.keys(formValues['R0108G3']['a'])
		.filter(key => key !== '0')
		.map(key => {
			let result = [];
			let keyValue = formValues['R0108G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => parseFloat(fieldsSet['T4RXXXXG5']));
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T4RXXXXG6 = function(formValues) {
	return Object.keys(formValues['R0108G3']['a'])
		.filter(key => key !== '0')
		.map(key => {
			let result = [];
			let keyValue = formValues['R0108G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => parseFloat(fieldsSet['T4RXXXXG6']));
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T4RXXXXG7 = function(formValues) {
	return Object.keys(formValues['R0108G3']['a'])
		.filter(key => key !== '0')
		.map(key => {
			let result = [];
			let keyValue = formValues['R0108G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => parseFloat(fieldsSet['T4RXXXXG7']));
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T4RXXXXG8 = function(formValues) {
	return Object.keys(formValues['R0108G3']['a'])
		.filter(key => key !== '0')
		.map(key => {
			let result = [];
			let keyValue = formValues['R0108G3']['a'][key];

			if (isArray(keyValue)) {
				result = keyValue.map(fieldsSet => parseFloat(fieldsSet['T4RXXXXG8']));
			}

			return result;
		})
		.reduce((a, b) => {
			return a.concat(b);
		}, []);
};

export const T4RXXXXG9 = function(formValues) {
	let revenues = T4RXXXXG5(formValues);

	let expenses1 = T4RXXXXG6(formValues);
	let expenses2 = T4RXXXXG7(formValues);
	let expenses3 = T4RXXXXG8(formValues);

	return revenues.map((revenue, i) => {
		let profit = revenue - expenses1[i] - expenses2[i] - expenses3[i];

		return profit;
	});
};
