import { R0106G3, R0109G3, R0113G3, R021G3 } from '../_xmlConstId';
import {
	IP1,
	IP1A,
	// IP1AA,
	IP1AAA,
	IP1AAB,
	IP1AAAA,
	IP1AAAB,
	IP1AABA,
	IP1B,
	// IP1BA,
	IP1BAA,
	IP1BAB,
	// IP1BAAA,
	// IP1BABA,
	IP1BAAAA,
	IP1BABAA,
	IP1C,
	// IP1CA,
	IP1CAA,
	IP1D,
	// IP1DA,
	IP1DAA,
	// IP1DAAA,
	IP1DAB,
	// IP1DABA,
	IP1DAAAA,
	IP1DABAA,
	IP1E,
	// IP1EA,
	IP1EAA,
	IP1F,
	// IP1FA,
	IP1FAA,
	IP1G,
	// IP1GA,
	IP1GAA,
	IP1H,
	// IP1HA,
	IP1HAA,
	IP1J,
	// IP1JA,
	IP1JAA,
	IP1K,
	// IP1KA,
	IP1KAA,
	IP1L,
	// IP1LA,
	IP1LAA,
	IP1M,
	// IP1MA,
	IP1MAA,
	IP1N,
	// IP1NA,
	IP1NAA,
	// IP1NAAA,
	IP1NAAAA,
	IP1NAB,
	// IP1NABA,
	IP1NABAA,
	IP1P,
	// IP1PA,
	IP1PAA,
	IP1PAB,
	IP2,
	IP2A,
	IP2AA,
	IP2AAA,
	IP2AB,
	IP2B,
	IP2BA,
	IP2C,
	IP2CA,
	IP2CB,
	IP2CC,
	IP2CD
} from '../_declaretion_answer_id';
import { B5EA, B8A, B5EB } from '../../_questions_OLD';
import { INPUT, RADIO, CHECKBOX, SELECT, DATE } from '../../elementTypes';

import countries from '../../../data/countries.json';
import currency from '../../../data/currencies.json';

export const IP = [
	{
		// MAIN QUESTION
		id: IP1,
		title: 'Оберіть вид страхових виплат за договорами страхування та накопичення',
		name: IP1,
		quizOpen: B5EA,
		question_title: true
	},
	{
		parent: IP1,
		id: IP1A,
		title:
			'одноразова страхова виплата за договором довгострокового страхування життя у разі досягнення Вами певного віку, обумовленого у такому страховому договорі, чи доживання до закінчення строку такого договору',
		name: IP1A,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				id: IP1AAA,
				title: 'Ви є страхувальником',
				name: 'IP1AA_g',
				element: RADIO,
				sibling_children: [IP1AABA],
				helper: [
					'Підказка: страхувальник - юридична або фізична особа, яка вступила в страхові відносини зі страховиком, сплачує страхові внески і претендує на страхове відшкодування збитків за застрахованими подіями'
				]
			},
			{
				id: IP1AAB,
				title: 'Cтрахувальником є інша особа',
				name: 'IP1AA_g',
				element: RADIO,
				sibling_children: [IP1AAAA, IP1AAAB]
			}
		]
	},
	{
		// First CB > First RB > INPUTS QUESTION
		parent: IP1AAA,
		id: IP1AAAA,
		xmlId: R0113G3, //! -LOOK
		title:
			'Вкажіть розмір отриманих/нарахованих на Вашу користь упродовж звітного періоду сум згідно з довідкою від страхової компанії тощо',
		name: IP1A,
		element: INPUT,
		placeholder: 'Введіть суму'
	},
	{
		// First CB > First RB > INPUTS QUESTION
		parent: IP1AAA,
		id: IP1AAAB,
		xmlId: R0109G3, //! -LOOK
		xmlIds: ['R0109G3', 'R0113G3'], //! -LOOK
		title:
			'Вкажіть розмір здійснених особисто Вами внесків за увесь період дії договору згідно з довідкою від страхової компанії тощо',
		name: IP1A,
		element: INPUT,
		placeholder: 'Введіть суму'
	},
	{
		// First CB > Second RB > INPUT QUESTION
		parent: IP1AAB,
		id: IP1AABA,
		xmlId: R0109G3,
		title:
			'Вкажіть розмір отриманих/нарахованих на Вашу користь упродовж звітного періоду сум згідно з довідкою від страхової компанії тощо',
		name: IP1A,
		element: INPUT,
		placeholder: 'Введіть суму',
		quizOpen: B8A
	},
	{
		parent: IP1,
		id: IP1B,
		title: 'одноразова страхова виплата за договором страхування довічних пенсій',
		name: IP1B,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Second CB > 2 RB
				id: IP1BAA,
				title: 'Так',
				name: 'IP1BA_g',
				xmlId: R0113G3,
				element: RADIO,
				sibling_children: [IP1BABAA]
			},
			{
				// Second CB > 2 RB
				id: IP1BAB,
				title: 'Ні',
				name: 'IP1BA_g',
				xmlId: R0109G3,
				element: RADIO,
				sibling_children: [IP1BAAAA]
			}
		]
	},
	{
		//! Second CB > First RB > INPUT
		parent: IP1BAA,
		id: IP1BAAAA,
		xmlId: R0113G3,
		title: 'Вкажіть розмір одноразової страхової виплати упродовж звітного періоду',
		name: IP1B,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)'
	},
	{
		//! Second CB > Second RB > INPUT
		parent: IP1BAB,
		id: IP1BABAA,
		xmlId: R0109G3,
		title: 'Вкажіть розмір одноразової страхової виплати упродовж звітного періоду',
		name: IP1B,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)'
	},
	{
		parent: IP1,
		id: IP1C,
		title: 'виплати пенсії на визначений строк, що проводиться з недержавного пенсійного фонду',
		name: IP1C,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				parent: IP1C,
				// Third CB > INPUT
				id: IP1CAA,
				xmlId: R0109G3,
				title: 'виплати пенсії на визначений строк, що проводиться з недержавного пенсійного фонду ',
				name: IP1C,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: IP1,
		id: IP1D,
		title:
			'регулярні та послідовні виплати (ануїтети) за договором довгострокового страхування життя, пенсійних виплат за договором пенсійного вкладу, пенсійних та цільових виплат, виплат довічної пенсії (довічні ануїтети)',
		name: IP1D,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Fourth CB > 2 RB
				id: IP1DAA,
				title: 'Так',
				name: 'IP1DA_g',
				element: RADIO,
				sibling_children: [IP1DABAA]
			},
			{
				// Fourth CB > 2 RB
				id: IP1DAB,
				title: 'Ні',
				name: 'IP1DA_g',
				element: RADIO,
				sibling_children: [IP1DAAAA]
			}
		]
	},
	{
		// Fourth CB > First RB > INPUT
		parent: IP1DAA,
		id: IP1DAAAA,
		xmlId: R0109G3,
		title: 'Вкажіть суму виплат упродовж звітного періоду',
		name: IP1D,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)'
	},
	{
		// Fourth CB > Second RB > INPUT
		parent: IP1DAB,
		id: IP1DABAA,
		xmlId: R0113G3,
		title: 'Вкажіть суму виплат упродовж звітного періоду',
		name: IP1D,
		element: INPUT,
		placeholder: 'Введіть суму доходу (грн., коп.)'
	},
	{
		parent: IP1,
		id: IP1E,
		title: 'викупна сума у разі дострокового розірвання Вами договору довгострокового страхування життя',
		name: IP1E,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Fifth CB > INPUT
				id: IP1EAA,
				xmlId: R0109G3,
				title: 'Вкажіть викупну суму упродовж звітного періоду',
				name: IP1E,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: IP1,
		id: IP1F,
		title:
			"суми коштів, з якої не було утримано (сплачено) податок та яка виплачується вкладнику з його пенсійного вкладу або рахунка учасника фонду банківського управління у зв'язку з достроковим розірванням договору пенсійного вкладу, договору довірчого управління, договору недержавного пенсійного забезпечення",
		name: IP1F,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Sixth CB > INPUT
				id: IP1FAA,
				xmlId: R0109G3,
				title: 'Вкажіть викупну суму упродовж звітного періоду',
				name: IP1F,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: IP1,
		id: IP1G,
		title:
			'страхові виплати, страхове відшкодування за договором недовгострокового страхування життя від страховика-резидента у разі, якщо Ви дожили до дати чи події або досягли віку, передбаченого таким договором',
		name: IP1G,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Seventh CB > INPUT
				id: IP1GAA,
				xmlId: R0113G3,
				title: 'Вкажіть викупну суму упродовж звітного періоду',
				name: IP1G,
				element: INPUT,
				helpers: [
					'У разі страхового випадку - факт заподіяння шкоди застрахованій особі повинен бути належним чином підтверджений. Якщо застрахована особа помирає, сума страхової виплати, яка належить вигодонабувачам або спадкоємцям, оподатковується за правилами та ставками, встановленими для оподаткування спадщини (вигодонабувач прирівнюється до спадкоємця (з урахуванням особливостей пп. 165.1.43 ПКУ)'
				],
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: IP1,
		id: IP1H,
		title:
			'викупна сума у розмірі, що не перевищує суму внесених страхових платежів за договором страхування життя, іншим, ніж договір довгострокового страхування життя',
		name: IP1H,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Eighth CB > INPUT
				id: IP1HAA,
				xmlId: R0113G3,
				title:
					'Вкажіть суму отриманих коштів  від страховика-резидента в розмірі, що не перевищують суму внесених страхових платежів',
				name: IP1H,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: IP1,
		id: IP1J,
		title:
			'викупна сума від страховика-резидента у розмірі, що перевищує суму внесених страхових платежів за договором страхування життя, іншим, ніж договір довгострокового страхування життя',
		name: IP1J,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Nineth CB > INPUT
				id: IP1JAA,
				xmlId: R0109G3,
				title:
					'Вкажіть суму отриманих коштів  від страховика-резидента в розмірі, що не перевищують суму внесених страхових платежів',
				name: IP1J,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: IP1,
		id: IP1K,
		title:
			'сума страхової виплати за договором довгострокового страхування життя, якщо внаслідок страхового випадку Ви отримали інвалідність I групи',
		name: IP1K,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Tenth CB > INPUT
				id: IP1KAA,
				xmlId: R0113G3,
				title: 'Вкажіть суму страхової виплати упродовж звітного періоду',
				name: IP1K,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: IP1,
		id: IP1L,
		title:
			'сума виплати за договором пенсійного вкладу, виплат з рахунка учасника фонду банківського управління, виплати пенсії на визначений строк, довічної пенсії, якщо Ви  отримали інвалідність I групи',
		name: IP1L,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Eleventh CB > INPUT
				id: IP1LAA,
				xmlId: R0113G3,
				title: 'Вкажіть суму страхової виплати упродовж звітного періоду',
				name: IP1L,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		parent: IP1,
		id: IP1M,
		title:
			'сума одноразової пенсійної виплати учаснику недержавного пенсійного фонду або Накопичувального фонду за рахунок коштів Накопичувального фонду',
		name: IP1M,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Twelfth CB > INPUT
				id: IP1MAA,
				xmlId: R0113G3,
				title: 'Вкажіть грошову суму, сплачену/нараховану на Вашу користь',
				name: IP1M,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		//Thirteenth question
		parent: IP1,
		id: IP1N,
		title: 'виплати на Вашу користь за договорами страхування життя у разі смерті застрахованої особи',
		name: IP1N,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Thirteenth CB > 2 RB
				id: IP1NAA,
				title: 'застрахована особа мала перший ступінь споріднення з Вами',
				name: 'IP1NA_g',
				element: RADIO,
				sibling_children: [IP1NABAA]
			},
			{
				// Thirteenth CB > 2 RB
				id: IP1NAB,
				title: 'застрахована особа мала ступінь споріднення з Вами відмінний від першого',
				name: 'IP1NA_g',
				element: RADIO,
				sibling_children: [IP1NAAAA]
			}
		]
	},
	{
		// Thirteenth CB > First RB > INPUT QUESTION
		parent: IP1NAA,
		id: IP1NAAAA,
		xmlId: R0113G3,
		title: 'Вкажіть суму коштів отриманих коштів',
		name: IP1N,
		element: INPUT,
		placeholder: 'Введіть суму (грн., коп.)'
	},
	{
		// Thirteenth CB > INPUT QUESTION
		parent: IP1NAB,
		id: IP1NABAA,
		xmlId: R0109G3,
		title: 'Вкажіть суму коштів отриманих коштів',
		name: IP1N,
		element: INPUT,
		placeholder: 'Введіть суму (грн., коп.)'
	},
	{
		parent: IP1,
		id: IP1P,
		title:
			'страхові виплати, страхове відшкодування за договорами, іншими ніж страхування життя від страховика-резидента (наприклад, страхування майна, цивільної відповідальності тощо)',
		name: IP1P,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// Fourteenth CB > INPUTS QUESTION
				id: IP1PAA,
				xmlId: R0113G3,
				title: 'Вкажіть суму страхової виплати упродовж звітного періоду в межах зазначених обмежень',
				name: IP1P,
				element: INPUT,
				placeholder: 'Введіть суму (грн., коп.)'
			},
			{
				// Fourteenth CB > INPUTS QUESTION
				id: IP1PAB,
				xmlId: R0109G3,
				title:
					'Вкажіть суму страхової виплати упродовж звітного періоду в частині що перевищує визначені обсяги',
				name: IP1P,
				element: INPUT,
				placeholder: 'Введіть суму (грн., коп.)'
			}
		]
	},

	//! ============================================================================
	//! ============================================================================
	//! ============================================================================
	{
		// SECOND MAIN QUESTION
		id: IP2,
		title: 'Оберіть вид пенсії, який Ви отримували',
		name: IP2,
		quizOpen: B5EA,
		question_title: true
	},
	{
		// FIRST CB IN IP2
		parent: IP2,
		id: IP2A,
		title:
			"державна пенсія або довічне грошове утримання (щомісячна пенсійна виплата в солідарній системі загальнообов'язкового державного пенсійного страхування, яку отримує застрахована особа в разі досягнення нею передбаченого законодавством України пенсійного віку чи визнання її особою з інвалідністю, або отримують члени її сім'ї у випадках, визначених законодавством http://zakon.rada.gov.ua/laws/show/1058-15)",
		name: IP2A,
		quizOpen: B5EA,
		helper: [
			'Підказка. Оподаткування пенсій відповідно до пп. 164.2.19 діяло до 27.02.2018. Ставка податку становила 18 % до суми, що перевищувала еквівалент 10 прожиткових мінімумів для осіб, що втратили працездатність.'
		],
		element: CHECKBOX,
		answers: [
			{
				// FIRST CB > CB
				id: IP2AA,
				// xmlId: R0113G3,
				title:
					'Підтвердьте, що, відповідно до довідки про доходи, виданої Пенсійним фондом України, із Вас не було утримано ПДФО і ВЗ',
				name: IP2AA,
				// element: CHECKBOX,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			},
			{
				// FIRST CB > CB > INPUT
				id: IP2AAA,
				xmlId: R0113G3,
				// title: '',
				name: IP2A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			},
			{
				// FIRST CB > INPUT
				id: IP2AB,
				xmlId: R0109G3,
				title: 'Вкажіть суму ПДФО і ВЗ відповідно до довідки про доходи',
				name: IP2A,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		// SECOND CB IN IP2
		parent: IP2,
		id: IP2B,
		title:
			'пенсія, призначена учасникам бойових дій, інвалідам війни та особам, на яких поширюється чинність статті 10 Закону України "Про статус ветеранів війни, гарантії їх соціального захисту"',
		name: IP2B,
		quizOpen: B5EA,
		element: CHECKBOX,
		answers: [
			{
				// SECOND CB > INPUT
				id: IP2BA,
				xmlId: R0113G3,
				title: 'Вкажіть суму доходу  відповідно до довідки про доходи',
				name: IP2B,
				element: INPUT,
				placeholder: 'Введіть суму доходу (грн., коп.)'
			}
		]
	},
	{
		// THIRD CB IN IP2
		parent: IP2,
		id: IP2C,
		title: 'пенсія з іноземних джерел',
		name: IP2C,
		quizOpen: B5EB,
		helper: [
			"Підказка 1. Ви можете зменшити річне податкового зобов'язання на суму податків, сплачених за кордоном згідно з нормами міжнародних договорів про уникнення подвійного оподаткування.",
			'Підказка 2. У разі можливості неоднозначного трактування норм ПКУ рекомендуємо звернутися за індивідуальною податковою консультацією до контролюючого органу',
			'Підказка 3. У разі відсутності документів щодо отриманого іноземного доходу та утриманого з нього податку необхідно подати заяву про перенесення строку подання податкової декларації до 31 грудня року, наступного за звітним (до контролюючого органу за своєю податковою адресою)'
		],
		element: CHECKBOX,
		answers: [
			{
				parent: IP2C,
				id: IP2CA,
				title: 'Kраїнa',
				xmlId: R0106G3,
				name: 'country_name',
				element: SELECT,
				options: countries
			},
			{
				parent: IP2C,
				id: IP2CB,
				title: 'Валюта',
				xmlId: R0106G3,
				name: 'currency_name',
				element: SELECT,
				options: currency
			},
			{
				parent: IP2C,
				id: IP2CC,
				title: 'Дата',
				xmlId: R0106G3,
				name: 'currency_date',
				element: DATE
			},
			{
				parent: IP2C,
				id: IP2CD,
				title:
					'Вкажіть оціночну / орієнтовну вартість об’єкта спадщини (дарування), який Ви отримали протягом звітного періоду, згідно із свідоцтвом про спадщину  перераховану у гривні за валютним курсом Національного банку України, що діє на момент нарахування / отримання таких доходів',
				element: INPUT,
				name: 'revenue',
				placeholder: 'Введіть суму (грн., коп.)',
				xmlId: R0106G3
			},
			{
				parent: IP2C,
				id: 'IP2CE',
				// title:
				// 	'Вкажіть суму  сплаченого з цих доходів податку в іноземній юрисдикції, перераховану у гривні за валютним курсом Національного банку України',
				element: INPUT,
				name: 'tax',
				placeholder: 'Введіть суму доходу (грн., коп.)',
				xmlId: R021G3
			}
		]
	}
];
