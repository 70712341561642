import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { StyledButton } from '../../../components/Button/Button';

import './styles.scss';

const LeftSideAdvantages = () => {
	return (
		<Grid container justify="center" alignItems="center" style={{ minHeight: '100%' }}>
			<Grid item className="advantages">
				<h2 className="advantages_title">Переваги електронного цифрового підпису:</h2>
				<ul className="advantages_list">
					<li className="advantages_item">Онлайн подача Декларації</li>
					<li className="advantages_item">Перевірка інформації з реєстрів</li>
					<li className="advantages_item">Спрощення при заповненні полей</li>
				</ul>
				<Link to="/" className="advantages_link">
					<StyledButton className="advantages-btn" variant="contained">
						Отримати
					</StyledButton>
				</Link>
			</Grid>
		</Grid>
	);
};

export default LeftSideAdvantages;
