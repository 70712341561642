import { R024G3, R024G4, R025G3, R025G4, R028G3, R028G4 } from '../_xmlConstId';
import { PENALTY1, PENALTY2, PENALTY3 } from '../_declaretion_answer_id';
import { B13 } from '../../_questions_OLD';
import { INPUT } from '../../elementTypes';

export const Penalty = [
	{
		id: PENALTY1,
		title:
			'Зазначте суму податку та/або збору, які підлягали перерахуванню до бюджету або поверненню, за даними звітного (податкового) періоду, в якому виявлена помилка',
		xmlId: R024G3,
		// helpers: [
		// 	'Підказка. Для цього можете скористатись он лайн калькулятором. Пеню обчислюють при самостійному донарахування податкових зобов’язань від суми заниження податку за кожний календарний день прострочення у її сплаті, включаючи день погашення, із розрахунку 100 % річних облікової ставки НБУ, діючої на кожний такий день (п. 129.4 ПКУ). П = ЗПЗ х Снбу : 100% : 365 х Кдн , де: П - сума пені (грн); ЗПЗ - сума заниження податкових зобов’язань (недоплата) (грн); Кдн - кількість календарних днів прострочення у сплаті, включаючи день сплати; Снбу - облікова ставка НБУ у період прострочення сплати (%). Якщо рік високосний – при розрахунку беремо не 565, а 366 днів.'
		// ],
		quizOpen: [B13],
		answers: [
			{
				id: 'PENALTY1A',
				xmlId: R024G3,
				name: R024G3,
				element: INPUT,
				placeholder: 'Податок на доходи фізичних осіб(грн, коп.)'
			},
			{
				id: 'PENALTY1B',
				xmlId: R024G4,
				name: R024G4,
				element: INPUT,
				placeholder: 'Військовий збір (грн, коп.)'
			}
		]
	},
	{
		id: PENALTY2,
		title:
			"Зазначте уточнені суми податкових зобов'язань або сума до повернення за звітний (податковий) період, у якому виявлена помилка",
		xmlId: R025G3,
		// helpers: [
		// 	'Підказка. Для цього можете скористатись он лайн калькулятором. Пеню обчислюють при самостійному донарахування податкових зобов’язань від суми заниження податку за кожний календарний день прострочення у її сплаті, включаючи день погашення, із розрахунку 100 % річних облікової ставки НБУ, діючої на кожний такий день (п. 129.4 ПКУ). П = ЗПЗ х Снбу : 100% : 365 х Кдн , де: П - сума пені (грн); ЗПЗ - сума заниження податкових зобов’язань (недоплата) (грн); Кдн - кількість календарних днів прострочення у сплаті, включаючи день сплати; Снбу - облікова ставка НБУ у період прострочення сплати (%). Якщо рік високосний – при розрахунку беремо не 565, а 366 днів.'
		// ],
		quizOpen: [B13],
		answers: [
			{
				id: 'PENALTY2A',
				xmlId: R025G3,
				name: R025G3,
				element: INPUT,
				placeholder: 'Податок на доходи фізичних осіб(грн, коп.)'
			},
			{
				id: 'PENALTY2B',
				xmlId: R025G4,
				name: R025G4,
				element: INPUT,
				placeholder: 'Військовий збір (грн, коп.)'
			}
		]
	},
	{
		id: PENALTY3,
		title: 'Зазначте суму пені',
		xmlId: R028G3,
		// helpers: [
		// 	'Підказка. Для цього можете скористатись он лайн калькулятором. Пеню обчислюють при самостійному донарахування податкових зобов’язань від суми заниження податку за кожний календарний день прострочення у її сплаті, включаючи день погашення, із розрахунку 100 % річних облікової ставки НБУ, діючої на кожний такий день (п. 129.4 ПКУ). П = ЗПЗ х Снбу : 100% : 365 х Кдн , де: П - сума пені (грн); ЗПЗ - сума заниження податкових зобов’язань (недоплата) (грн); Кдн - кількість календарних днів прострочення у сплаті, включаючи день сплати; Снбу - облікова ставка НБУ у період прострочення сплати (%). Якщо рік високосний – при розрахунку беремо не 565, а 366 днів.'
		// ],
		quizOpen: [B13],
		answers: [
			{
				id: 'PENALTY3A',
				xmlId: R028G3,
				name: R028G3,
				element: INPUT,
				placeholder: 'Податок на доходи фізичних осіб(грн, коп.)'
			},
			{
				id: 'PENALTY3B',
				xmlId: R028G4,
				name: R028G4,
				element: INPUT,
				placeholder: 'Військовий збір (грн, коп.)'
			}
		]
	}
];
