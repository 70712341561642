import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import hoc from '../../HOC/hoc';
import { StyledButton } from '../../components/Button/Button';
import { getDocuments } from '../../store/actions/documents_list_action';
import { getAnswers } from '../../store/actions/quiz_actions';

const DocumentsList = props => {
	useEffect(() => {
		if (!props.answers) {
			props.dispatch(getAnswers());
		}
		props.dispatch(getDocuments(props.answers));
	}, [props.answers]);

	const handleClickNextButton = () => {
		props.history.push('/documents/declaration/common');
	};

	const { documents } = props;
	return (
		<div className="documents-list-wrap">
			<Fragment>
				<h3 className="documents-list-title">
					Документи, які вам будуть необхідні під час заповнення декларації:
				</h3>
				<ul className="documents-list-block">
					{documents.map((item, i) => (
						<li key={i} className="documents-list-block__item">
							{item.link ? <Link to="#">{item}</Link> : <span>{item}</span>}
						</li>
					))}
				</ul>
				{documents.length ? (
					<Grid className="documents-list-btn-wrap" container justify="flex-start">
						<StyledButton variant="outlined">Завантажити ввесь список</StyledButton>
						<StyledButton
							className="documents-list-next-btn"
							variant="contained"
							onClick={handleClickNextButton}
						>
							Далі
						</StyledButton>
					</Grid>
				) : null}
			</Fragment>
		</div>
	);
};

const mapStateToProps = state => ({
	documents: state.documents.documents,
	answers: state.quiz.answers
});

export default connect(mapStateToProps)(hoc()(DocumentsList));
