import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { composeWithDevTools } from 'redux-devtools-extension'; //TODO: just for development
import storage from 'redux-persist/lib/storage'; //TODO: just for development
import { persistStore, persistReducer } from 'redux-persist'; //TODO: just for development

import reducer from './reducers';

const persistConfig = {
	key: 'zvit',
	storage,
	whitelist: ['user']
};

export default function setUpStore() {
	const middlewares = applyMiddleware(promise, thunk);
	const enhancer = composeWithDevTools({})(middlewares);
	const persistedReducer = persistReducer(persistConfig, reducer);
	const preloadedState = {};

	const store = createStore(persistedReducer, preloadedState, enhancer);
	const persistor = persistStore(store);

	return { store, persistor };
}
