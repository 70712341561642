import React from 'react';

import NewStepperCheckBox from '../NewStepperCheckBox';

const NewStepperCheckBoxField = ({ field: { name, value, onChange, onBlur }, id, ...props }) => {
	return (
		<NewStepperCheckBox
			name={name}
			id={id}
			value={name}
			checked={value}
			onChange={onChange}
			onBlur={onBlur}
			{...props}
		/>
	);
};

export default NewStepperCheckBoxField;
