import React, { Component } from 'react';
import Axios from 'axios';
// import UserService from '../../services/user_service';

// import { GOOGLE_CLIENT_ID } from '../../configs';

class GoogleAuth extends Component {
	componentDidMount() {
		window.gapi.load('client:auth2', () => {
			window.gapi.client.init({
				// clientId: GOOGLE_CLIENT_ID,
				clientId: '198100416028-jj25ta3vgces0ccs4v6l88m04gbae58r.apps.googleusercontent.com',
				// clientId: '432054382085-vv3ejo2tivk1ku4d333g0cskri9bu74r.apps.googleusercontent.com',
				uxMode: 'popup',
				cookie_policy: 'single_host_origin',
				fetch_basic_profile: true,
				scope: 'profile email'
			});
			// .then(() => {
			// 	this.auth = window.gapi.auth2.getAuthInstance();
			// 	// this.token = this.auth.currentUser.get().getAuthResponse().access_token;
			// 	// this.userId = this.auth.currentUser.get().getId();
			// 	// this.onAuthChange(this.auth.isSignedIn.get());

			// 	// UserService.googleLogin(this.token, this.userId)
			// 	// 	.then(data => {
			// 	// 		console.log(data, '🚀');
			// 	// 		// save('api_token', data.api_token);
			// 	// 	})
			// 	// 	.catch(response => {
			// 	// 		console.log(response, 'action error response');
			// 	// 	});
			// 	// console.log(this.token, this.userId);
			// 	// Axios.post(
			// 	// 	'http://api.zvit.pure-agency.co/auth/login/google',
			// 	// 	{
			// 	// 		code: this.token,
			// 	// 		userID: this.userId
			// 	// 	},
			// 	// 	{
			// 	// 		headers: {
			// 	// 			'Content-Type': 'application/json',
			// 	// 			Accept: 'application/json'
			// 	// 		}
			// 	// 	}
			// 	// )
			// 	// 	.then(res => console.log(res))
			// 	// 	.catch(console.error);
			// 	// // this.auth.isSignedIn.listen(this.onAuthChange);
			// });
		});
	}

	// onAuthChange = isSignIn => {
	// 	if (isSignIn) {
	// 		console.log(this.token, this.userId);
	// 		// UserService.googleLogin(this.token, this.userId)
	// 		// 	.then(data => {
	// 		// 		console.log(data, '🚀');
	// 		// 		// save('api_token', data.api_token);
	// 		// 	})
	// 		// 	.catch(response => {
	// 		// 		console.log(response, 'action error response');
	// 		// 	});
	// 		// this.props.googleSignIn(this.token, this.userId);
	// 		console.log('object');
	// 	} else {
	// 		console.log('XXXXXXXX');
	// 	}
	// };

	onSignInClick = () => {
		const auth2 = window.gapi.auth2.getAuthInstance();
		auth2.signIn({}).then(res => {
			let token = res.Zi.access_token;
			let userId = res.El;
			Axios.post(
				'http://api.zvit.pure-agency.co/auth/login/google',
				{
					code: token,
					userID: userId
				},
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					}
					// headers: {
					// 	'Content-Type': 'text/html',
					// 	Accept: 'application/json'
					// }
				}
			)
				.then(res => console.log(res))
				.catch(console.error);
			// UserService.googleLogin(token, userId)
			// 	.then(data => {
			// 		console.log(data, '🚀');
			// 		// save('api_token', data.api_token);
			// 	})
			// 	.catch(response => {
			// 		console.log(response, 'action error response');
			// 	});
		});
		// .then(res => console.log(res))
		// .catch(console.error);
	};

	render() {
		return (
			<div onClick={this.onSignInClick} className="google-button">
				<div className="google-button__emblem">
					<img className="google-button__img" src="/img/logo_google.svg" alt="google emblem" />
				</div>
				<div className="google-button__text">Sign in with Google</div>
			</div>
		);
	}
}

export default GoogleAuth;
