import { EI_TAI } from '../../../data/declaration/sub-sections/EI_TA';
import { GET_EITA_DATA } from '../../actions/declaration_action';

const initialState = {
	questions: { ...EI_TAI },
	subId: null,
	data: {}
};

const ei_ta = (state = initialState, action) => {
	switch (action.type) {
		case GET_EITA_DATA:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default ei_ta;
