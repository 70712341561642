import { connect } from 'react-redux';

import Disc from '../subsections/Disc';
import { getDiscData, setStepIndex } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.Disc.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName),
	currentStepIndex: state.declaration.currentStepIndex
});

const mapDispatchToProps = {
	getDiscData,
	setStepIndex
};

const DiscContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Disc);

export default DiscContainer;
