import React from 'react';

class htmlGenerator {
	constructor() {
		this.docContent = false;
		this.flushDoc();
	}

	flushDoc = docName => {
		this.userData = {};
		this.partial = true;
		return this;
	};

	fetchDoc = (name = false) => {
		let docName = name !== false ? name : this.docName;
		let docObject = require(`./${docName}.js`);

		this.docContent = docObject['default'];
		return this;
	};

	setDocName = docName => {
		this.docName = docName;
		return this;
	};

	getDocName = () => {
		return this.docName;
	};

	setUserData = (userData, partial = true) => {
		this.userData = Object.assign({}, userData);
		this.partial = partial;
		return this;
	};

	getUserData = () => {
		return this.userData;
	};

	prepare = (partial = true) => {
		if (this.docContent) {
			this.html = this.docContent;
		}
		return this;
	};

	open = () => {
		const View = this.html;
		let userData = Object.assign({}, this.getUserData());

		return props => (
			<div className={props.className}>
				<View userData={userData} partial={this.partial} />
			</div>
		);
	};
}

export default new htmlGenerator();
