import { IPA } from '../../../data/declaration/sub-sections/IPA';
import { GET_IPA_DATA } from '../../actions/declaration_action';

const initialState = {
	questions: { ...IPA },
	subId: null,
	data: {}
};

const ipa = (state = initialState, action) => {
	switch (action.type) {
		case GET_IPA_DATA:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default ipa;
