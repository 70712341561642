import { SalePro } from '../../../data/declaration/sub-sections/sale_pro';
import { GET_SALEPRO_DATA } from '../../actions/declaration_action';

const initialState = {
	questions: { ...SalePro },
	subId: null,
	data: {}
};

const salePro = (state = initialState, action) => {
	switch (action.type) {
		case GET_SALEPRO_DATA:
			return {
				...state,
				data: action.payload
			};

		default:
			return state;
	}
};

export default salePro;
