import { connect } from 'react-redux';

import WP from '../subsections/WP';
import { getWpData } from '../../../store/actions/declaration_action';
import { getNextSubssection } from '../../../store/reducers';

const mapStateToProps = (state, ownProps) => ({
	currentIndexSubsection: state.declaration.currentIndex,
	subsection: state.declaration.WP.questions,
	nextSubSection: getNextSubssection(state, ownProps.subsectionName)
});

const mapDispatchToProps = {
	getWpData
};

const WPContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(WP);

export default WPContainer;
